import b from 'b_';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import TextStore from '../../stores/helpers/TextStore';
import InputStore from '../../stores/InputStore';
import Icon from '../Icon/Icon';
import './FuseTextfield.css';

const block = b.lock('FuseTextfield');

class FuseTextfield extends Component {
    static propTypes = {
        store: PropTypes.oneOfType([InputStore.PropType, TextStore.PropTypes]),
        className: PropTypes.string,
        type: PropTypes.string,
        label: PropTypes.node,
        placeholder: PropTypes.node,
        bottomText: PropTypes.node,
        leftIcon: PropTypes.string,
        rightIcon: PropTypes.string,
        rightIconClassName: PropTypes.string,
        requiredText: PropTypes.string,
        rightChild: PropTypes.node,
        error: PropTypes.bool,
        focus: PropTypes.bool,
        hover: PropTypes.bool,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        showRequired: PropTypes.bool,
        onRightIconClick: PropTypes.func,
        onKeyDown: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        fullWidth: PropTypes.bool,
        qa: PropTypes.string,
    };

    static defaultProps = {
        onRightIconClick: () => null,
        onKeyDown: () => null,
    };

    state = {
        focus: false,
    };

    onFocus = () => {
        this.setState({ focus: true });
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    onBlur = () => {
        this.setState({ focus: false });
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    render() {
        const {
            store,
            className,
            type = 'text',
            label,
            placeholder,
            bottomText,
            leftIcon,
            rightIcon,
            onRightIconClick,
            rightIconClassName,
            showRequired,
            requiredText,
            onKeyDown,
            error,
            focus,
            hover,
            disabled,
            readOnly,
            rightChild,
            fullWidth,
            qa,
            ...otherProps
        } = this.props;

        return <label
            className={cn(block({
                error,
                focus: focus === undefined ? this.state.focus : focus,
                hover,
                disabled,
                readOnly,
                fullWidth,
            }), className)}
            {...qa ? { 'data-qa': qa } : {}}
        >
            {label ? <div className={block('Label')} {...qa ? { 'data-qa': qa + '-label' } : {}}>
                {label}
                {showRequired && otherProps.required ?
                    <span className={block('RequiredText')}>{requiredText || '(required)'}</span> : null}
            </div> : null}
            <div className={block('InputContainer')}>
                {leftIcon ? <Icon
                    className={block('Icon', { left: true })}
                    type={leftIcon}
                /> : null}
                <div className={block('InputWrapper')}>
                    {placeholder && !store.getValue() ? <div
                        className={block('Placeholder')}
                    >{placeholder}</div> : null}
                    <input
                        className={block('Input')}
                        type={type}
                        disabled={disabled || readOnly}
                        onFocus={this.onFocus}
                        value={store.getValue()}
                        onChange={store.onChangeHandler}
                        onKeyDown={onKeyDown}
                        ref={store.setRef}
                        {...otherProps}
                        onBlur={this.onBlur}
                        {...qa ? { 'data-qa': qa + '-input' } : {}}
                    />
                </div>
                {rightChild}
                {rightIcon ? <Icon
                    className={cn(block('Icon', { right: true }), rightIconClassName)}
                    onClick={onRightIconClick}
                    type={rightIcon}
                /> : null}
            </div>
            {bottomText ? <div
                className={block('BottomText')}
            >{bottomText}</div> : null}
        </label>;
    }
}

export default observer(FuseTextfield);
