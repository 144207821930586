import debug from '../../debug';
import RequestGenerator from '../RequestGenerator';
import Socket from '../Socket';
import { API_CLIENT_TO_BACKGROUND, API_BACKGROUND_TO_CLIENT } from '../sharedConstants';
import UncommittedBuffer from '../UncommittedBuffer';
import MessageReceiver from '../MessageReceiver';
import EventLogger from '../../ClientEventLogger';

const logger = debug.create('ClientAPI', false);

const toCompactBool = (x) => !!x | 0;
const toInt = (x) => x | 0;
const toIntOrUndef = (x) => (x === undefined ? x : toInt(x));

/**
 * @typedef {Object} ClientAPI
 * @property {RequestGenerator} requestGenerator
 */
class ClientAPI extends Socket {
    requestGenerator = new RequestGenerator();

    constructor() {
        super();
        this.nextRequestId = 1;
        this.requestMap = {};
        this.isBufferOverflowed = false;
        this.onMethod(API_BACKGROUND_TO_CLIENT.response, this.requestGenerator.processResponse);

        this.buffer = new UncommittedBuffer('client');
        this.receiver = new MessageReceiver('client', (id) => this.commit(id));
        this.onMethod(API_BACKGROUND_TO_CLIENT.commitMessages, (id) => this.buffer.commit(id));

        let connectedOnce = false;
        this.on(this.EVENT_OPEN, () => {
            if (connectedOnce) {
                this.buffer.getAll().forEach((message) => this.send(message));
            } else {
                connectedOnce = true;
            }
        });
    }

    generateRequest = this.requestGenerator.generateRequest;

    sendMethod(methodName, args = [], shouldBuffer = true) {
        // remove undefineds from args tail and replace with 0s in the middle
        let i = args.length;
        while (i) {
            i -= 1;
            if (args[i] === undefined || args[i] === null) {
                if (i === args.length - 1) {
                    args.pop();
                } else {
                    args[i] = 0;
                }
            }
        }

        const message = [methodName, args];

        if (shouldBuffer) {
            const id = this.buffer.getNextId();
            message.push(id);

            if (this.buffer.add(message)) {
                this.send(message);
            } else {
                if (!this.isBufferOverflowed) {
                    this.isBufferOverflowed = true;
                    EventLogger.sendEvent(EventLogger.EVENT_CLIENT_WS_BUFFER_OVERFLOW, {
                        connectionErrorCount: this.connectionErrorCount,
                        lastMessageDate: this.lastMessageDate,
                        isConnected: this.isConnected,
                    });
                }
                this.forceDisconnect('buffer overflow');
            }
        } else {
            this.send(message);
        }
    }

    setScreenSize(screen) {
        this.sendMethod(
            API_CLIENT_TO_BACKGROUND.setScreenSize,
            [screen],
        );
    }

    setValue = (watcherName, frameId, nodeId, value) => {
        this.sendMethod(
            API_CLIENT_TO_BACKGROUND.setValue,
            [watcherName, toInt(frameId), toInt(nodeId), value],
        );
    };

    handleJSDialog(accept, text) {
        this.sendMethod(
            API_CLIENT_TO_BACKGROUND.handleJSDialog,
            [toCompactBool(accept), text],
        );
    }

    makeKeyboardAction(data) {
        logger.log('makeKeyboardAction', data);
        this.sendMethod(API_CLIENT_TO_BACKGROUND.makeKeyboardAction, [data]);
    }

    makeMouseAction(
        type, mod, frameId, nodeId, relativeTo, x, y, clickCount, sel, actionCounter,
    ) {
        // debug.info('mouse', type, x, y);
        this.sendMethod(
            API_CLIENT_TO_BACKGROUND.makeMouseAction,
            [
                type, mod, toInt(frameId), toInt(nodeId),
                toCompactBool(relativeTo), x, y, toIntOrUndef(clickCount),
                sel, toIntOrUndef(actionCounter),
            ],
        );
    }

    makeTouchAction(data) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.makeTouchAction, [data]);
    }

    pasteEvent(data) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.pasteEvent, [data]);
    }

    cutEvent(data) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.cutEvent, [data]);
    }

    textChanges(data) {
        debug.info('TEXT CHANGES', JSON.stringify(data));
        this.sendMethod(API_CLIENT_TO_BACKGROUND.textChanges, [data]);
    }

    wheelEvent(frameId, targetId, eventData) {
        this.sendMethod(
            API_CLIENT_TO_BACKGROUND.wheelEvent,
            [toInt(frameId), toIntOrUndef(targetId), eventData],
        );
    }

    dragDropEvent(frameId, targetId, eventData) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.dragEvent, [toInt(frameId), toIntOrUndef(targetId), eventData]);
    }

    startPrintOnServer(frameId) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.startPrintOnServer, [toInt(frameId)]);
    }

    finishPrintOnServer(frameId) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.finishPrintOnServer, [toInt(frameId)]);
    }

    updateDebugEventMode(debugEventMode) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.updateDebugEventMode, [debugEventMode]);
    }

    closePage() {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.closePage);
    }

    navigation(navigationId) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.navigation, [navigationId]);
    }

    extendSession() {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.extendSession);
    }

    uploadInProgress(uploadInfo) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.uploadInProgress, [uploadInfo]);
    }

    cancelSession() {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.cancelSession);
    }

    commit(id) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.commitMessages, [id], false);
    }

    shouldIgnoreMessage(message) {
        return this.receiver.shouldIgnoreMessage(message);
    }

    getReconnectURL() {
        return `${this.url}?mid=${this.receiver.receivedId}`;
    }

    getDebugInfo() {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.getDebugInfo, [request.id]);
        return request;
    }

    getScreenshot(tabId) {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.getScreenshot, [request.id, tabId]);
        return request;
    }

    profilerStart() {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.profilerStart, [request.id]);
        return request;
    }

    profilerStop() {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.profilerStop, [request.id]);
        return request;
    }

    exitFullscreen(frameId, nodeId, actionCounter = null) {
        const data = [
            toInt(frameId),
            toInt(nodeId),
        ];

        if (actionCounter) {
            data.push(toInt(actionCounter));
        }

        this.sendMethod(API_CLIENT_TO_BACKGROUND.exitFullscreen, data);
    }

    sendBasicAuthorization(args = { username: '', password: '', cancel: false }) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.sendBasicAuthorization, [args]);
    }

    sendFeedback(data) {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.sendFeedback, [request.id, data]);
        return request;
    }

    enableHarForDebug() {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.enableHarForDebug, [request.id]);
        return request;
    }

    getDebugHar() {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.getDebugHar, [request.id]);
        return request;
    }

    overrideForwardedHeader(args = { disableForwarded: false, disableXForwardedFor: false, ipOverride: null }) {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.overrideForwardedHeader, [request.id, args]);
        return request;
    }

    inspectALoggerEvent(event) {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.inspectALoggerEvent, [request.id, { event }]);
        return request;
    }

    cancelUpload(id) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.cancelUpload, [id]);
    }

    processUploadedBunch(bunch) {
        this.sendMethod(API_CLIENT_TO_BACKGROUND.processUploadedBunch, [bunch]);
    }

    changeConfig(newConfig) {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.changeConfig, [request.id, newConfig]);
        return request;
    }

    getCanvasFrameLoadingMs(frameId, nodeId, actionId) {
        const request = this.generateRequest();
        this.sendMethod(API_CLIENT_TO_BACKGROUND.measure,
            [request.id, { label: `canvasFrameLoadingMs_${frameId}_${nodeId}_${actionId}` }]);
        return request;
    }

    recordImportantEvent = (label, data) => {
        logger.log(label, data);
        this.sendMethod(API_CLIENT_TO_BACKGROUND.recordImportantEvent, [{ label, data }]);
    }
}

export default new ClientAPI();
