import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { FTHeadline } from './Text';
import './Page.css';

export default function Page({ className, title, children, ...props }) {
    return <div className={cn('Page', className)} {...props}>
        {title ? <FTHeadline className={'Page__Title'}>{title}</FTHeadline> : null}
        {children}
    </div>;
}

Page.propTypes = {
    title: PropTypes.node,
};
