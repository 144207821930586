export default class DLPSettings {
    constructor(data = {}) {
        this.allInput = !!data.allInput;
        this.clipboardPaste = !!(data.clipboardPaste || data.pasteDisabled);
        this.pasteLimitEnabled = !!data.pasteLimitEnabled;
        this.pasteLimit = Number.isFinite(data.pasteLimit) ? data.pasteLimit : -1;
        this.reason = data.reason || null;
        this.allowDownloads = !!data.allowDownloads;
        this.allowFilePreview = !!data.allowFilePreview;
        this.downloadsFileDlpScan = !!data.downloadsFileDlpScan;
        this.downloadsFileThreatScan = !!data.downloadsFileThreatScan;
        this.allowUploads = !!data.allowUploads;
        this.uploadsFileDlpScan = !!data.uploadsFileDlpScan;
        this.uploadsFileThreatScan = !!data.uploadsFileThreatScan;
        this.maxFilesSize = data.maxFilesSize || null;
    }
}
