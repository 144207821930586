const counterByName = {};

/**
 * Use it to set id attribute in react element to prevent id collision if multiple instances of component present
 * Example:
 * makeElementId('foo') === 'foo' // true
 * makeElementId('foo') === 'foo_1' // true
 * @param name
 * @return {string}
 */
export default function makeElementId(name) {
    if (counterByName[name] === undefined) {
        counterByName[name] = 0;
        return name;
    }
    counterByName[name] += 1;
    return `${name}_${counterByName[name]}`;
}
