import debug from './debug';
import { configureCSRF } from './API';

function parseJSONProp(object, propName) {
    if (object[propName]) {
        try {
            object[propName] = JSON.parse(object[propName]);
        } catch (e) {
            debug.warn(`failed to parse "${propName}" value`, e, object);
        }
    }
}

export default function getInitData(defaultValue = {}) {
    const dataEl = document.getElementById('data');
    const data = {};

    if (dataEl) {
        // Note properties of dataset are not enumerable in Safari9
        Object.keys(dataEl.dataset).forEach((key) => { data[key] = dataEl.dataset[key]; });

        parseJSONProp(data, 'stores');
        parseJSONProp(data, 'features');

        debug.alwaysLog('init data', data);
        dataEl.parentNode.removeChild(dataEl);
    } else {
        debug.warn('missing #data element');
    }

    configureCSRF(data.csrfHeaderName, data.csrfToken);

    return Object.assign(defaultValue, data);
}
