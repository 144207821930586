import React, { Component } from 'react';
import { observer } from 'mobx-react';
import b from 'b_';
import * as DOM from '../../mirror/DOM';
import InputStore from '../../stores/InputStore';
import Contenteditable from '../Contenteditable';
import FuseButton from '../FuseControls/FuseButton';
import './FuseWysiwyg.css';

const block = b.lock('FuseWysiwyg');

const exec = (command, value = null) => document.execCommand(command, false, value);

function sanitizeMessage(node) {
    if (node.nodeType === DOM.ELEMENT_NODE) {
        if (DOM.isElementOneOf(node, DOM.TAG_SCRIPT, DOM.TAG_OBJECT, DOM.TAG_EMBED)) {
            DOM.removeNode(node);
            return false;
        }

        if (DOM.isElementOneOf(node, DOM.TAG_A)) {
            if (!node.target || node.target.toLowerCase() !== '_blank') {
                node.target = '_top';
            }
        }

        return true;
    }
}

class FuseWysiwyg extends Component {
    static propTypes = {
        store: InputStore.PropType.isRequired,
    };

    contentRef = React.createRef();

    createLink = (url) => {
        const a = document.createElement('a');
        a.href = url;
        const selection = window.getSelection();
        const el = this.contentRef.current.el.current;
        if (selection.focusNode && el.contains(selection.anchorNode.parentNode)) {
            if (selection.toString()) {
                exec('createLink', url);
            } else {
                a.textContent = url;
                selection.getRangeAt(0).insertNode(a);
            }
        } else {
            a.textContent = url;
            el.append(a);
        }
    };

    actions = [{
        icon: 'format_bold',
        onClick: () => exec('bold'),
    }, {
        icon: 'format_italic',
        onClick: () => exec('italic'),
    }, {
        icon: 'format_underline',
        onClick: () => exec('underline'),
    }, {
        icon: 'insert_link',
        onClick: () => {
            // eslint-disable-next-line no-alert
            const url = window.prompt('Enter the link URL');
            if (url) {
                this.createLink(url);
            }
        },
    }];

    render() {
        const { store, ...otherProps } = this.props;
        return <div className={block()} {...otherProps}>
            <div className={block('Actions')}>
                {this.actions.map((props, i) => <FuseButton
                    key={i}
                    type="quaternary"
                    className={block('Button')}
                    wrapperClassName={block('ButtonWrapper')}
                    iconClassName={block('ButtonIcon')}
                    {...props}
                />)}
            </div>
            <div className={block('ContentWrapper')}>
                <Contenteditable
                    className={block('Content')}
                    ref={this.contentRef}
                    html={store.value}
                    setRef={store.setRef}
                    onInput={({ target }) => {
                        DOM.walkDOM(target, sanitizeMessage);
                        store.setValue(target.innerHTML);
                    }}
                />
            </div>
        </div>;
    }
}

export default observer(FuseWysiwyg);

