import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isElementOneOf, TAG_A, walkDOM } from '../mirror/DOM';

function sanitizeExternalLinks(rootNode) {
    if (rootNode) {
        walkDOM(rootNode, (node) => {
            if (isElementOneOf(node, TAG_A)) {
                node.setAttribute('target', '_blank');
                node.setAttribute('rel', 'noopener noreferrer');
            }
            return true;
        });
    }
}

export default class CustomerContent extends Component {
    static propTypes = {
        html: PropTypes.string.isRequired,
    }

    render() {
        return <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: this.props.html }}
            ref={sanitizeExternalLinks}
        />;
    }
}
