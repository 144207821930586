import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import FuseInfoTooltip from '../FuseControls/FuseTooltip/FuseInfoTooltip';
import { FTBody, FTCaption, FTLabel } from './Text';
import './Field.css';

export default function Field({ className, label, caption, tooltip, tooltipProps, children, ...props }) {
    return <div className={cn('Field', className)} {...props}>
        {label || caption || tooltip ? <div className="Field__Label">
            {label ? <FTLabel>{label}</FTLabel> : null}
            {caption ? <FTCaption>{caption}</FTCaption> : null}
            {tooltip ? <FuseInfoTooltip {...tooltipProps}>{tooltip}</FuseInfoTooltip> : null}
        </div> : null}
        <FTBody className="Field__Content">
            {children}
        </FTBody>
    </div>;
}

Field.propTypes = {
    label: PropTypes.node,
    caption: PropTypes.node,
    tooltip: PropTypes.node,
    tooltipProps: PropTypes.shape(FuseInfoTooltip.propTypes),
};
