import b from 'b_';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { apiCall } from '../../API';
import {
    WAITING_BROWSER_INTERVAL,
    LAST_PING, RESERVING_BROWSER_PAGE_TIME_ORIGIN_ASSUMPTION,
} from '../../mirror/sharedConstants';
import { PfptLogo } from '../Logo/PfptLogo';
import debug from '../../debug';
import Bg from '../Bg/Bg';
import Icon from '../Icon/Icon';
import LoaderBar from '../Loaders/LoaderBar';
import eventLogger from '../../ClientEventLogger';
import './ReservingBrowserPage.css';

const block = b.lock('ReservingBrowserPage');

const MAX_NUMBER_OF_RETRIES = 50;
const MAX_BACKOFF = 64;
class ReservingBrowserPage extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    reserveCounter = 0;

    componentDidMount() {
        const api = apiCall('/browser/reserve', { isUserAction: false });
        const currentTime = new Date().getTime();
        const documentCreatedTimestamp = currentTime - (window.performance && window.performance.now() ?
            window.performance.now() : RESERVING_BROWSER_PAGE_TIME_ORIGIN_ASSUMPTION);
        const waitingForBrowserData = JSON.parse(localStorage.getItem('waitForBrowserEvent'));

        if (!waitingForBrowserData) {
            eventLogger.sendStartWaitingEvent(currentTime);
        } else if (documentCreatedTimestamp - parseInt(waitingForBrowserData.lastTimePing, 10) < LAST_PING) {
            waitingForBrowserData.numOfRefreshes = parseInt(waitingForBrowserData.numOfRefreshes, 10) + 1;
            localStorage.setItem('waitForBrowserEvent', JSON.stringify(waitingForBrowserData));
        } else {
            eventLogger.sendEndWaitingEvent(
                false,
                waitingForBrowserData.lastTimePing,
                waitingForBrowserData.numOfRefreshes
            );
            eventLogger.sendStartWaitingEvent(documentCreatedTimestamp);
        }

        let shouldUpdateLastPing = true;
        const reserve = () => api().then((isReserved) => {
            if (isReserved) {
                const endTime = new Date().getTime();
                shouldUpdateLastPing = false;
                const waitForBrowserEventJSON = localStorage.getItem('waitForBrowserEvent');
                if (waitForBrowserEventJSON) {
                    const waitForBrowserEvent = JSON.parse(waitForBrowserEventJSON);
                    eventLogger.sendEndWaitingEvent(
                        true,
                        endTime,
                        waitForBrowserEvent.numOfRefreshes
                    );
                }
                window.location.reload();
            } else if (this.reserveCounter < MAX_NUMBER_OF_RETRIES) {
                const reserveTimeout = Math.round(1000 *
                    Math.min(2 ** this.reserveCounter + Math.random(), MAX_BACKOFF)
                );
                debug.log(`Failed to reserve browser, will try again in ${reserveTimeout} ms.`);
                setTimeout(reserve, reserveTimeout);
                this.reserveCounter += 1;
            }
        });

        const updateLastTimePing = () => {
            const waitingForBrowserDataUpdatePing = JSON.parse(localStorage.getItem('waitForBrowserEvent'));
            if (shouldUpdateLastPing && waitingForBrowserDataUpdatePing) {
                waitingForBrowserDataUpdatePing.lastTimePing = new Date().getTime();
                localStorage.setItem('waitForBrowserEvent', JSON.stringify(waitingForBrowserDataUpdatePing));
                setTimeout(updateLastTimePing, WAITING_BROWSER_INTERVAL);
            }
        };

        updateLastTimePing();
        reserve();
    }

    render() {
        const { t } = this.props;
        return <Bg className={block()}>
            <div className={block('Content')}>
                <PfptLogo noShadow className={block('Logo')} />
                <div className={block('Title')}>
                    <Icon
                        className={block('TitleIcon')}
                        type="web"
                    />
                    <span className={block('ReadabilityWrap')}>
                        {t('ReservingBrowserPage.title')}
                    </span>
                </div>
                <div className={block('Text')}>
                    <span className={block('ReadabilityWrap')}>
                        {t('ReservingBrowserPage.text')}
                    </span>
                </div>
                <LoaderBar className={block('Progress')} />
                <div className={block('Subtext')}>
                    <span className={block('ReadabilityWrap')}>
                        {t('ReservingBrowserPage.subtext')}
                    </span>
                </div>
            </div>
        </Bg>;
    }
}

export default translate()(ReservingBrowserPage);
