import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { ResearchStoreProp, EnvStoreProps } from '../../stores/props';
import Flag from '../Flag/Flag';
import Box from '../Box/Box';
import Modal from '../Modal';
import TopBarDropdown from '../TopBarDropdown/TopBarDropdown';
import './LocationMenu.css';

class LocationMenu extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        research: ResearchStoreProp,
        env: EnvStoreProps,
        afterChange: PropTypes.func,
    };

    static defaultProps = {
        afterChange: () => {},
    };

    getLocationName(location) {
        const key = 'location.' + location.id;
        const translation = this.props.t(key);
        return translation === key
            ? (location.name || location.id) // missing translation
            : translation;
    }

    renderModal(content) {
        const { research, env } = this.props;

        return (env.screen.isMobile || env.screen.isTablet || env.isTouchDevice)
            ? <Modal
                isOpen
                onClose={research.locationMenu.close}
                shouldCloseOnOverlayClick={false}
                style={{ content: { maxWidth: 420, width: '100%' } }}
            >{content}</Modal>
            : <TopBarDropdown
                className="LocationMenu"
                type="research"
                onClose={research.locationMenu.close}
            >{content}</TopBarDropdown>;
    }

    render() {
        const { t, research, afterChange } = this.props;
        return this.renderModal(<Box
            theme="research"
            noPadding
            titleClassName={'LocationMenu__Title'}
            title={t('locationMenu.title')}
            contentClassName="LocationMenu__ContentWrapper"
        >
            {research.locations.map((location) => <div
                key={location.id}
                className="LocationMenu__Item"
                onClick={() => {
                    research.setLocation(location.id);
                    research.locationMenu.close();
                    afterChange();
                }}
            >
                <Flag
                    className="LocationMenu__Icon"
                    type={location.flag}
                />
                <div>{this.getLocationName(location)}</div>
            </div>)}
        </Box>);
    }
}

export default translate()(inject('research', 'browser', 'env')(observer(LocationMenu)));
