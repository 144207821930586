import { makeSafePropName } from '../DOMUtils';

export const PROP_FRAME_ID = makeSafePropName('fid');
const PROP_NODE_ID = makeSafePropName('wid');
const PROP_UID = makeSafePropName('uid');
const PROP_CLIENT_NODE_ID = makeSafePropName('cid');
export const PROP_PREV_STYLE = makeSafePropName('prevStyle');
export const PROP_INLINE_STYLE_CACHE = makeSafePropName('styleAttrCache');
const PROP_EVENT_HANDLERS = makeSafePropName('handlers');
const PROP_NODE_TIMERS = makeSafePropName('timers');
const PROP_NODE_INTERVALS = makeSafePropName('intervals');

class NodeRegistry {
    constructor() {
        this.nextNodeId = 1;
        this.nodeById = {};
        this.nextClientNodeId = -1;
        this.nodeByClientId = {};
    }

    getNextNodeId() {
        const nodeId = this.nextNodeId;
        this.nextNodeId += 1;
        return nodeId + '';
    }

    getNextClientNodeId() {
        const clientNodeId = this.nextClientNodeId;
        this.nextClientNodeId -= 1;
        return clientNodeId + '';
    }
}

const makePropGetter = (propName) => (node) => {
    if (node) {
        return node[propName];
    }
};

export class DOMRegistry {
    getFrameId = makePropGetter(PROP_FRAME_ID);
    getNodeId = makePropGetter(PROP_NODE_ID);
    getUid = makePropGetter(PROP_UID);
    getClientNodeId = makePropGetter(PROP_CLIENT_NODE_ID);

    nodeRegistryByFrameId = {};

    makeUid = (frameId, nodeId) => `${frameId}_${nodeId}`;

    /** @returns {NodeRegistry} */
    createNewNodeRegistry(frameId) {
        const nodeReg = new NodeRegistry();
        this.nodeRegistryByFrameId[frameId] = nodeReg;
        return nodeReg;
    }

    /** @returns {NodeRegistry} */
    getRegByFrameId(frameId) {
        return this.nodeRegistryByFrameId[frameId] || this.createNewNodeRegistry(frameId);
    }

    getNode(frameId, nodeId) {
        const reg = this.getRegByFrameId(frameId);
        return reg.nodeById[nodeId] || reg.nodeByClientId[nodeId];
    }

    hasNode(node) {
        return node[PROP_NODE_ID] !== undefined;
    }

    addNode(frameId, node, nodeId) {
        if (this.hasNode(node)) {
            return;
        }

        const reg = this.getRegByFrameId(frameId);

        if (nodeId === undefined) {
            nodeId = reg.getNextNodeId();
        }

        node[PROP_NODE_ID] = nodeId;
        node[PROP_FRAME_ID] = frameId;
        node[PROP_UID] = this.makeUid(frameId, nodeId);
        reg.nodeById[nodeId] = node;

        return nodeId;
    }

    addClientNode(frameId, node, clientNodeId) {
        if (node[PROP_CLIENT_NODE_ID] !== undefined) {
            return;
        }

        const reg = this.getRegByFrameId(frameId);

        if (clientNodeId === undefined) {
            clientNodeId = reg.getNextClientNodeId();
        }


        node[PROP_CLIENT_NODE_ID] = clientNodeId;
        node[PROP_FRAME_ID] = frameId;
        reg.nodeByClientId[clientNodeId] = node;

        return clientNodeId;
    }

    addEventListener(node, eventName, handler, capture = false) {
        if (!node) {
            return;
        }

        if (!node[PROP_EVENT_HANDLERS]) {
            node[PROP_EVENT_HANDLERS] = [];
        }

        node[PROP_EVENT_HANDLERS].push({ eventName, handler, capture });

        node.addEventListener(eventName, handler, capture);
    }

    removeEventListener(node, eventName, handler, capture = false) {
        if (!node) {
            return;
        }

        node.removeEventListener(eventName, handler, capture);

        if (!node[PROP_EVENT_HANDLERS]) {
            return;
        }

        const index = node[PROP_EVENT_HANDLERS].findIndex((item) => item.eventName === eventName
            && item.handler === handler
            && item.capture === capture
        );

        if (index !== -1) {
            node[PROP_EVENT_HANDLERS].splice(index, 1);
        }
    }

    removeAllEventListeners(node) {
        if (!node) {
            return;
        }

        if (!node[PROP_EVENT_HANDLERS]) {
            return;
        }

        node[PROP_EVENT_HANDLERS].forEach(({ eventName, handler, capture }) => {
            node.removeEventListener(eventName, handler, capture);
        });

        delete node[PROP_EVENT_HANDLERS];
    }

    setTimeout(node, callback, ms) {
        if (!node) {
            return;
        }

        if (!node[PROP_NODE_TIMERS]) {
            node[PROP_NODE_TIMERS] = new Set();
        }

        const timerId = setTimeout(() => {
            node[PROP_NODE_TIMERS].delete(timerId);
            callback();
        }, ms);
        node[PROP_NODE_TIMERS].add(timerId);

        return timerId;
    }

    clearTimeout(node, timerId) {
        clearTimeout(timerId);
        if (!node) {
            return;
        }

        if (!node[PROP_NODE_TIMERS]) {
            return;
        }

        node[PROP_NODE_TIMERS].delete(timerId);
    }

    clearAllTimeouts(node) {
        if (!node) {
            return;
        }

        if (!node[PROP_NODE_TIMERS]) {
            return;
        }

        node[PROP_NODE_TIMERS].forEach((timerId) => clearTimeout(timerId));
        delete node[PROP_NODE_TIMERS];
    }

    setInterval(node, callback, ms) {
        if (!node) {
            return;
        }

        if (!node[PROP_NODE_INTERVALS]) {
            node[PROP_NODE_INTERVALS] = new Set();
        }

        const intervalId = setInterval(callback, ms);
        node[PROP_NODE_INTERVALS].add(intervalId);

        return intervalId;
    }

    clearInterval(node, intervalId) {
        clearInterval(intervalId);

        if (!node) {
            return;
        }

        if (!node[PROP_NODE_INTERVALS]) {
            return;
        }

        node[PROP_NODE_INTERVALS].delete(intervalId);
    }

    clearAllIntervals(node) {
        if (!node) {
            return;
        }

        if (!node[PROP_NODE_INTERVALS]) {
            return;
        }

        node[PROP_NODE_INTERVALS].forEach((intervalId) => clearInterval(intervalId));
        delete node[PROP_NODE_INTERVALS];
    }

    clearInlineStyleCache(node) {
        delete node?.[PROP_PREV_STYLE];
    }

    removeNode(node) {
        if (!node) {
            return;
        }

        this.removeAllEventListeners(node);
        this.clearAllTimeouts(node);
        this.clearInlineStyleCache(node);

        const frameId = this.getFrameId(node);
        if (frameId === undefined) {
            return;
        }

        const reg = this.getRegByFrameId(frameId);
        const nodeId = this.getNodeId(node);
        if (nodeId !== undefined) {
            delete reg.nodeById[nodeId];
        }
        const clientNodeId = this.getClientNodeId(node);
        if (clientNodeId !== undefined) {
            delete reg.nodeByClientId[clientNodeId];
        }

        delete node[PROP_FRAME_ID];
        delete node[PROP_NODE_ID];
        delete node[PROP_UID];
        delete node[PROP_CLIENT_NODE_ID];
    }

    popClientNode(frameId, clientNodeId) {
        const reg = this.getRegByFrameId(frameId);
        const node = reg.nodeByClientId[clientNodeId];
        if (node) {
            delete reg.nodeByClientId[clientNodeId];
            delete node[PROP_CLIENT_NODE_ID];
            return node;
        }
    }
}

const reg = new DOMRegistry();
export default reg;
