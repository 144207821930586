import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import b from 'b_';
import Button from './Button';
import './Modal.css';

const block = b.lock('Modal');

export default function Modal(props) {
    const {
        onClose,
        overlayClassName,
        className,
        ...otherProps
    } = props;

    let children = props.children;

    if (children) {
        children = React.Children.map(children, (el) => React.cloneElement(el, { onClose }));
    }

    return <ReactModal
        className={cn(block('Content'), className)}
        overlayClassName={cn(block('Overlay'), overlayClassName)}
        ariaHideApp={false}
        {...otherProps}
    >
        {children}
        {onClose ? <Button
            aria-label="Close"
            className={block('Close')}
            icon="close"
            onClick={onClose}
            raised
            closeOrange
        /> : null}
    </ReactModal>;
}

Modal.propTypes = {
    onClose: PropTypes.func,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    closeClassName: PropTypes.string,
    closeTextClassName: PropTypes.string,
    ...ReactModal.propTypes,
};

// TODO: Deprecated
Modal.Hint = function ModalHint(props) {
    return <div className={block('Hint')}>
        {props.children}
    </div>;
};
