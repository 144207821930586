import PropTypes from 'prop-types';
import ValueStore from './ValueStore';

export default class TextStore extends ValueStore {
    static _PropType = {
        ...ValueStore._PropTypes,

        setRef: PropTypes.func.isRequired,
        focus: PropTypes.func.isRequired,
        blur: PropTypes.func.isRequired,
        onChangeHandler: PropTypes.func.isRequired,
    };

    static PropTypes = PropTypes.shape(TextStore._PropType);

    constructor(defaultValue = '', data = {}) {
        super(defaultValue, {
            normalize: String,
            ...data,
        });

        this.ref = null;
    }

    setRef = (newRef) => {
        this.ref = newRef;
    };

    focus = () => {
        if (this.ref && this.ref.focus) {
            this.ref.focus();
        }
    };

    blur = () => {
        if (this.ref && this.ref.blur) {
            this.ref.blur();
        }
    };

    onChangeHandler = ({ target }) => {
        this.setValue(target.value);
    };
}
