import React, { Component } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import UDSpinner from '../UD/Fragments/UDSpinner';
import './FuseButton.css';

const block = b.lock('FuseButton');

const ENTER_KEY_CODE = 13;

export function Loader({ className }) {
    return <svg
        className={cn(block('Loader'), className)}
        viewBox="0 0 66 66"
    >
        <circle
            className={block('LoaderPath')}
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
        />
    </svg>;
}

class FuseButton extends Component {
    static type = {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        TERTIARY: 'tertiary',
        QUATERNARY: 'quaternary',
    }
    static color = {
        BLUE: 'blue',
        RED: 'red',
        GREEN: 'green',
        BLACK: 'black',
    }
    static defaultProps = {
        type: 'primary',
        submit: false,
    };

    static propTypes = {
        className: PropTypes.string,
        wrapperClassName: PropTypes.string,
        primary: PropTypes.bool,
        noHalo: PropTypes.bool, // removes default background from disabled quaternary buttons, e.g. pfpt logo in tb
        type: PropTypes.oneOf([...Object.values(FuseButton.type)]),
        color: PropTypes.oneOf([...Object.values(FuseButton.color)]),
        loading: PropTypes.bool,
        small: PropTypes.bool,
        extraSmall: PropTypes.bool,
        icon: PropTypes.oneOf(Object.keys(Icon.codes)),
        iconRight: PropTypes.oneOf(Object.keys(Icon.codes)),
        noCenter: PropTypes.bool,
        fullWidth: PropTypes.bool,
        iconClassName: PropTypes.string,
        iconRightClassName: PropTypes.string,
        hover: PropTypes.bool,
        focus: PropTypes.bool,
        active: PropTypes.bool,
        invertBackgroundColor: PropTypes.bool,
        tabIndex: PropTypes.number,
        submit: PropTypes.bool,
        qa: PropTypes.string,
        href: PropTypes.string,
    };

    state = {
        active: false,
    };

    constructor(props) {
        super(props);
        this.buttonRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.focus && !this.props.focus) {
            if (this.buttonRef && this.buttonRef.current) {
                this.buttonRef.current.focus();
            }
        }
    }

    onKeyDown = (event) => {
        if (event.keyCode === ENTER_KEY_CODE) {
            this.setState({ active: true });
            setTimeout(() => this.setState({ active: false }), 200);
        }
    };

    render() {
        const {
            loading,
            small, extraSmall, // TODO: make enum 'prop'
            hover, focus, active,
            type,
            color,
            noHalo,
            primary, // TODO: use 'type' prop
            children,
            className,
            wrapperClassName,
            icon,
            iconRight,
            noCenter,
            fullWidth,
            iconClassName,
            iconRightClassName,
            invertBackgroundColor,
            submit,
            tabIndex,
            qa,
            href,
            ...otherProps
        } = this.props;
        const iconOnly = !!(icon || iconRight) && !(icon && iconRight) && !children;

        const finalClassName = cn(block({
            loading,
            primary, // TODO: use 'type' props
            type,
            color,
            small,
            extraSmall,
            hover: hover === true,
            focus: focus === true,
            active: active === true || this.state.active,
            invert: invertBackgroundColor === true,
            iconOnly,
            fullWidth,
            noHalo,
        }), className);

        const iconEl = (curIcon, right) => <Icon
            type={curIcon}
            className={cn(block('Icon', {
                right: right && !iconOnly,
                left: !right && !iconOnly,
            }), right ? iconRightClassName : iconClassName)}
        />;

        const content = <div
            className={cn(block('Wrapper', {
                leftIcon: !!icon && !iconOnly,
                rightIcon: !!iconRight && !iconOnly,
                small,
            }), wrapperClassName)}
            tabIndex={'-1'}
        >
            {noCenter && !loading ? null : <div className={block('Space')} />}
            {loading ? <UDSpinner
                className={block('Loader')}
                iconClassName={block('Spinner')}
                type="fuse"
            /> : null}
            {icon && !loading ? iconEl(icon) : null}
            {!loading && !iconOnly ? <div className={block('Content')}>{children}</div> : null}
            {iconRight && !loading ? iconEl(iconRight, true) : null}
            {noCenter && !loading ? null : <div className={block('Space')} />}
        </div>;

        return React.createElement(href ? 'a' : 'button', {
            className: finalClassName,
            type: submit ? 'submit' : 'button',
            onKeyDown: this.onKeyDown,
            ref: this.buttonRef,
            href,
            ...(qa ? { 'data-qa': qa } : {}),
            ...(tabIndex ? { tabIndex } : {}),
            ...otherProps
        }, content);
    }
}

export default FuseButton;
