import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import b from 'b_';
import FlagStore from '../../stores/FlagStore';
import './FlipNext.css';

const block = b.lock('FlipNext');

class FlipNext extends Component {
    static propTypes = {
        store: FlagStore.PropType,
        flip: PropTypes.bool,
    };

    render() {
        const { store, children } = this.props;
        const flip = store ? store.value : this.props.flip;

        return <div className={block()}>
            <div className={block('Side', { flip, front: true })}>
                <div className={block('SideContent')}>
                    {flip ? null : children}
                </div>
            </div>
            <div className={cn(block('Side', { flip, back: true }))}>
                <div className={block('SideContent')}>
                    {flip ? children : null}
                </div>
            </div>
        </div>;
    }
}

export default observer(FlipNext);
