import PropTypes from 'prop-types';
import b from 'b_';
import React, { Component } from 'react';
import { Interpolate, translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import BrowserStore from '../../browser/stores/BrowserStore';
import { EnvStoreProps } from '../../stores/props';
import SnackStore from '../../stores/SnackStore';
import Actions from '../Actions/Actions';
import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import { TBody } from '../Text/Text';
import TopBarDropdown from '../TopBarDropdown/TopBarDropdown';
import Button from '../Button';
import Modal from '../Modal';
import './AdBlockerModal.css';

const adBlockerOn = 'ads_blocked';
const adBlockerOff = 'ads_allowed';
const options = [adBlockerOn, adBlockerOff];

class AdBlockerModal extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        env: EnvStoreProps,
        snack: SnackStore.PropType.isRequired,
        alternative: PropTypes.bool,
        afterChange: PropTypes.func,
    };

    static defaultProps = {
        afterChange: () => {},
    };

    renderContent() {
        const { t, browser, snack, afterChange } = this.props;
        const snackContent = options.map((x) => t(`AdBlockerModal.snackAfter.${x}`));

        return <Box title={t('AdBlockerModal.title')} contentClassName="AdBlockerModal__ContentWrapper">
            <TBody style={{ marginBottom: 20 }}>
                <Interpolate
                    i18nKey="AdBlockerModal.hint.template"
                    highlight1={<strong>{t('AdBlockerModal.hint.highlight1')}</strong>}
                    highlight2={<strong>{t('AdBlockerModal.hint.highlight2')}</strong>}
                />
            </TBody>
            <div className="AdBlockerModal__Body">
                {options.map((optName) => <div className="AdBlockerModal__ExplanationRow" key={optName}>
                    <Icon className="AdBlockerModal__ExplanationIcon" type={optName} />
                    <TBody>{t(`AdBlockerModal.actionExplained.${optName}`)}</TBody>
                </div>)}
            </div>
            <Actions style={{ marginTop: 15 }}>
                {options.map((optName, i) => <Button
                    key={optName}
                    secondaryWhite
                    raised
                    fullWidth
                    icon={optName}
                    onClick={() => {
                        browser.adBlock.setEnabled(optName === adBlockerOn);
                        browser.adBlockModal.close();
                        afterChange();
                        snack.showSnack({ type: 'success', content: snackContent[i] });
                    }}
                >{t(`AdBlockerModal.action.${optName}`)}</Button>)}
            </Actions>
        </Box>;
    }

    render() {
        const { browser, env, alternative } = this.props;

        return env.screen.isMobile || env.isTouchDevice
            ? <Modal
                isOpen
                onClose={browser.adBlockModal.close}
                shouldCloseOnOverlayClick={false}
                style={{ content: { maxWidth: 450, width: '100%' } }}
            >{this.renderContent()}</Modal>
            : <TopBarDropdown
                className={b.lock('AdBlockerModal')({ alternative })}
                type="default"
                onClose={browser.adBlockModal.close}
                role="region"
                aria-labelledby={browser.adBlockTopBarButtonId}
            >{this.renderContent()}</TopBarDropdown>;
    }
}

export default translate()(inject('browser', 'snack', 'env')(observer(AdBlockerModal)));
