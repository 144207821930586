import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import b from 'b_';
import { FTBody, FTTitle } from '../Fuse';
import FuseButton from './FuseButton';
import FlagStore from '../../stores/FlagStore';
import './FuseAccordion.css';

const block = b.lock('FuseAccordion');

class FuseAccordion extends Component {
    static propTypes = {
        flagStore: FlagStore.PropType,
        title: PropTypes.node,
        className: PropTypes.string,
        small: PropTypes.bool,
        children: PropTypes.node,
        qa: PropTypes.string,
    };

    static defaultProps = {
        small: false,
    };

    state = {
        showBody: false,
    };

    onClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.flagStore) {
            this.props.flagStore.toggle();
        }
        this.setState({ showBody: !this.state.showBody });
    };

    render() {
        const {
            flagStore,
            className,
            small,
            qa,
            children,
        } = this.props;

        const showBody = flagStore ? flagStore.isTrue : this.state.showBody;

        return <div
            className={cn(block(), className)}
            data-qa={qa}
        >
            <div
                className={block('TitleWrapper', { small })}
                onClick={this.onClick}
            >
                {small
                    ? <FTBody className={block('Title', { small })}>{this.props.title}</FTBody>
                    : <FTTitle className={block('Title')}>{this.props.title}</FTTitle>}
                <FuseButton
                    className={block('Arrow')}
                    icon={showBody ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    onClick={this.onClick}
                    qa={`${qa}-toggle`}
                />
            </div>
            <div
                className={block('ContentWrapper', { small, hide: !showBody })}
                data-qa={`${qa}-content`}
            >{children}</div>
        </div>;
    }
}

export default FuseAccordion;
