import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import './Toggle.css';
import FlagStore from '../../stores/FlagStore';

export default function Toggle(props) {
    const {
        onLabel,
        offLabel,
        className,
        ...inputProps
    } = props;

    return <label className={cn('Toggle', className)}>
        <input
            className="Toggle__Input"
            type="checkbox"
            {...inputProps}
        />
        {offLabel ? <div className="Toggle__offLabel">{offLabel}</div> : null }
        <div className="Toggle__Track" />
        {onLabel ? <div className="Toggle__onLabel">{onLabel}</div> : null }
    </label>;
}

Toggle.propTypes = {
    onLabel: PropTypes.node,
    offLabel: PropTypes.node,
};

export const ObservableToggle = observer(({ store, ...props }) => <Toggle
    checked={store.isTrue}
    onChange={store.toggle}
    {...props}
/>);

ObservableToggle.propTypes = {
    store: FlagStore.PropType.isRequired,
    ...Toggle.propTypes,
};
