import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { translate } from 'react-i18next';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import { ResearchStoreProp } from '../../stores/props';
import BrowserStore from '../../browser/stores/BrowserStore';
import './UserAgentLabel.css';

class UserAgentLabel extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        research: ResearchStoreProp,
    };

    renderContent() {
        const { t, research } = this.props;
        if (!research.userAgent) {
            return <div style={{ width: 144 }}>{t('userAgentMenu.barTitle')} </div>;
        }

        const fontSize = this.props.browser.isTopBarMinificationAllowed() ? 14 : 20;
        const { os, browser } = research.getUserAgentInfo(research.userAgent);
        if (os && browser) {
            return <Fragment>
                <Icon
                    type={`os_${os.toLowerCase()}`}
                    style={{ fontSize, marginRight: 4 }}
                />
                <span>{t(`userAgentMenu.${os}`)}</span>
                <Icon
                    type={`browser_${browser.toLowerCase()}`}
                    style={{ fontSize, marginRight: 4, marginLeft: 10 }}
                />
                <span>{t(`userAgentMenu.${browser}`)}</span>
            </Fragment>;
        }

        return <span
            className="UserAgentLabel__Text"
            title={research.userAgent}
        >{research.userAgent}</span>;
    }

    render() {
        return <div className={cn('UserAgentLabel', this.props.className)}>
            {this.renderContent()}
        </div>;
    }
}

export default translate()(inject('research', 'browser')(observer(UserAgentLabel)));
