import once from 'lodash/once';
import { AGENT_BASE } from './API';
import debug from './debug';

const logger = debug.create('pollScanStatus');

export function getScanStatus(scanName, clickId) {
    return new Promise((resolve, reject) => {
        const xhr = new window.XMLHttpRequest();
        const handler = once(() => {
            if (xhr.status === 404) {
                resolve('scanning');
                return;
            }

            if ((xhr.status === 200) && xhr.response) {
                try {
                    resolve(JSON.parse(xhr.response));
                    return;
                } catch (e) { /* whatever */ }
            }

            reject();
        });
        xhr.onload = handler;
        xhr.onerror = handler;
        xhr.open('GET', `${AGENT_BASE}/proxy/v3/scanresult/${
            encodeURIComponent(`weblife://ws/${scanName}`)
        }${clickId ? '/' + clickId : ''}`);
        xhr.withCredentials = true;
        xhr.send();
    });
}

// long polling, there is 10 second wait time on agent side
export default function pollScanStatus(scanName, clickId, cb) {
    getScanStatus(scanName, clickId)
        .then((result) => {
            logger.log(scanName, result);
            if (result === 'scanning') {
                pollScanStatus(scanName, clickId, cb);
            } else {
                cb(result);
            }
        })
        .catch((error) => logger.warn(scanName, error));
}
