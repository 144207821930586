import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import us from 'flag-icon-css/flags/4x3/us.svg';
import gb from 'flag-icon-css/flags/4x3/gb.svg';
import de from 'flag-icon-css/flags/4x3/de.svg';
import jp from 'flag-icon-css/flags/4x3/jp.svg';
import ca from 'flag-icon-css/flags/4x3/ca.svg';
import in_ from 'flag-icon-css/flags/4x3/in.svg';
import au from 'flag-icon-css/flags/4x3/au.svg';

import './Flag.css';

// Flags from: http://flag-icon-css.lip.is/

const Flag = (props) => {
    const { className, type, ...otherProps } = props;
    return <span
        style={{ backgroundImage: `url('${Flag.codes[type]}')` }}
        className={cn('Flag', className)}
        {...otherProps}
    />;
};

Flag.codes = { us, gb, de, jp, ca, au, in: in_ };

Flag.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(Flag.codes)).isRequired,
};

export default Flag;
