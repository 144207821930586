import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import b from 'b_';
import { translate } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import BrowserStore from '../../browser/stores/BrowserStore';
import { ResearchStoreProp } from '../../stores/props';
import FuseInfoTooltip from '../FuseControls/FuseTooltip/FuseInfoTooltip';
import Icon from '../Icon/Icon';
import Toggle from '../Toggle/Toggle';
import ConsoleModal from '../ConsoleModal/ConsoleModal';
import Button from '../Button';
import { ObservableTextfield } from '../Textfield';
import LocalStorage, { LS_KEY } from '../../LocalStorage';
import './BrowserSettingsModal.css';

const block = b.lock('BrowserSettingsModal');

const MenuGroup = ({ titleIcon, title, subTitle, children }) => <Fragment>
    <div className={block('Title')}>
        <Icon type={titleIcon} className={block('Icon')} />
        <div>
            {title}&nbsp;
            {subTitle ? <span className={block('Subtitle')}>({subTitle})</span> : null}
        </div>
    </div>
    {children}
    <div className={block('Separator')} />
</Fragment>;

MenuGroup.propTypes = {
    titleIcon: Icon.propTypes.type,
    title: PropTypes.node,
    subTitle: PropTypes.node,
};

class BrowserSettingsModal extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        research: ResearchStoreProp,
    };

    save = () => {
        const { browser, research } = this.props;

        browser.settingsModal.close();
        research.researchMode.commit();
        browser.topBarUnpinned.commit();
        browser.topBarNeedsMinimization.setValue(!browser.topBarUnpinned.getValue());
    };

    onAccessCodeSubmit = (event) => {
        event.preventDefault();
        const { t, browser } = this.props;
        const { accessCodeSettingsStore } = browser;
        accessCodeSettingsStore.inputErrorMsg.setValue('');
        accessCodeSettingsStore.onAccessCodeSubmit()
            .then(() => {
                accessCodeSettingsStore.accessCodeInput.setValue('');
                browser.isAccessCodeApplyMode.setTrue();
                accessCodeSettingsStore.apiDestroy().then(() => {
                    // need to show snack after navigating to home page
                    LocalStorage.set(LS_KEY.isAccessCodeApplyMode, true);
                    window.top.location.href = '/browser';
                });
            })
            .catch((e) => {
                const msg = t(`settingsModal.accessCodes.error_messages.${e.message}`);
                accessCodeSettingsStore.inputErrorMsg.setValue(msg);
            });
    };

    reset = () => {
        const { browser, research } = this.props;

        browser.topBarUnpinned.reset();
        research.researchMode.reset();
    }

    render() {
        const { t, browser, research } = this.props;
        const { accessCodeSettingsStore } = browser;

        return <ConsoleModal
            isOpen={browser.settingsModal.isOpen}
            onClose={() => {
                browser.settingsModal.close();
                this.reset();
            }}
            title={t('settingsModal.title')}
            closeTitle={t('settingsModal.closeTitle')}
            style={{ content: { width: '100%', maxWidth: 410 } }}
            shouldReturnFocusAfterClose={false}
            headerActions={<Button
                style={{ minWidth: 100 }}
                disabled={
                    !research.researchMode.isDirty() &&
                    !browser.topBarUnpinned.isDirty()
                }
                onClick={this.save}
                primaryGreen
                raised
            >{t('settingsModal.saveButton')}</Button>}
        >
            <MenuGroup
                titleIcon="web"
                title={t('settingsModal.topBar.title')}
            >
                <div className={block('Info')}>{t('settingsModal.topBar.info')}</div>
                <Toggle
                    title={t('settingsModal.topBar.title')}
                    aria-label={t('settingsModal.topBar.title')}
                    className={block('Toggle')}
                    onLabel={'ON'}
                    offLabel={'OFF'}
                    checked={!browser.topBarUnpinned.getDirtyValue()}
                    onChange={browser.topBarUnpinned.toggle}
                />
            </MenuGroup>
            {browser.settingsResearchMode ? <MenuGroup
                titleIcon="work"
                title={t('settingsModal.research.title')}
                subTitle={t('settingsModal.research.subtitle')}
            >
                <div className={block('Info')}>{t('settingsModal.research.info')}</div>
                <Toggle
                    title={t('settingsModal.research.title')}
                    aria-label={t('settingsModal.research.title')}
                    className={block('Toggle')}
                    onLabel={'ON'}
                    offLabel={'OFF'}
                    checked={research.researchMode.getDirtyValue()}
                    onChange={research.researchMode.toggle}
                />
            </MenuGroup> : null}
            {browser.allowAccessCodes ? <MenuGroup
                titleIcon="key"
                title={t('settingsModal.accessCodes.title')}
                subTitle={t('settingsModal.accessCodes.subtitle')}

            >
                <div className={block('Info')}>
                    {t('settingsModal.accessCodes.info')}
                    <FuseInfoTooltip inline position={'bottom'}>
                        {t('settingsModal.accessCodes.toolTip')}
                    </FuseInfoTooltip>
                </div>
                <form
                    noValidate
                    className={block('AccessCodeContainer')}
                    onSubmit={this.onAccessCodeSubmit}
                >
                    <ObservableTextfield
                        label={t('settingsModal.accessCodes.label')}
                        className={block('AccessCodeInput')}
                        store={accessCodeSettingsStore.accessCodeInput}
                        errorLabel={accessCodeSettingsStore.inputErrorMsg.value}
                    />
                    <Button
                        primaryGreen
                        raised
                        loading={accessCodeSettingsStore.applyStatus.isLoading}
                        onClick={this.onAccessCodeSubmit}
                    >{t('settingsModal.accessCodes.applyButton')}</Button>

                </form>
            </MenuGroup> : null}

            <div className={block('ActivePolicy')}>
                <span className={block('ActivePolicyLabel')}>{t(browser.useRedesignedPolicy
                    ? 'settingsModal.activeRole' : 'settingsModal.activePolicy')}</span>&nbsp;
                <span>{browser.useRedesignedPolicy
                    ? browser.roleName : browser.activePolicy}</span>
            </div>
        </ConsoleModal>;
    }
}

export default translate()(inject('browser', 'research')(observer(BrowserSettingsModal)));
