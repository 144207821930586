import throttle from 'lodash/throttle';
import debug from '../debug';

export default class MessageReceiver {
    onCommit = null;
    logger = null;
    committedId = 0;
    receivedId = 0;
    isLost = false;

    constructor(pageId, onCommit) {
        this.onCommit = onCommit;
        this.logger = debug.create('MessageReceiver', false, pageId);
    }

    isDuplicate(messageId) { return messageId <= this.receivedId; }
    shouldIgnoreMessage(message) {
        if (!Array.isArray(message)) {
            return false;
        }
        const messageId = message[2];
        if (!messageId) {
            return false;
        }
        // ignore duplicates, may happen on reconnect
        if (this.isDuplicate(messageId)) {
            this.logger.info('duplicate', this.receivedId, messageId);
            return true;
        }
        // this could only theoretically happen on reconnect
        // in this case messages will be resend in the right order
        const diff = messageId - this.receivedId;
        if (diff > 1) {
            this.isLost = true;
            this.logger.info('Lost messages', diff - 1, this.receivedId, messageId);
            return true;
        }

        this.receivedId = messageId;
        if (this.isLost) {
            this.isLost = false;
            this.logger.info('Recovered', this.receivedId);
        }
        this.commit();
        return false;
    }

    commit = throttle(() => {
        if (this.onCommit) {
            this.logger.info('Committing', this.committedId, this.receivedId);
            this.committedId = this.receivedId;
            this.onCommit(this.committedId);
        } else {
            this.logger.recordError('No commit callback');
        }
    }, 5000, { leading: false, trailing: true });
}
