/* eslint-disable max-len */
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './PfptLogo.css';

function ProofpointWord(props) {
    return <g {...props}>
        <path d="M188.5,2.3h-5.9V8h-2.9v4h2.9v11.4c0,4.4,1.1,6,6,6c0.6,0,1.8-0.1,3.4-0.1V25h-1.5c-1.6,0-2-0.2-2-1.3V12h3.4V8h-3.4V2.3z" />
        <polygon points="191.9,29.2 191.9,29.2 191.9,29.2" />
        <path d="M170.6,7.5c-2.8,0-5.1,1-6.8,3.1V8H158v21.1h6V16.6c0-2.8,1.8-4.4,4.6-4.4c2.6,0,3.4,1.3,3.4,4.2v12.7h6V14.7 C177.9,9.9,175.5,7.5,170.6,7.5z" />
        <rect x="148.4" y="0.6" width="6" height="5.1" />
        <rect x="148.4" y="8" width="6" height="21.1" />
        <path d="M134.8,7.3c-7,0-11.3,4.2-11.3,11.3v0c0,7,4.3,11.3,11.3,11.3c7,0,11.3-4.2,11.3-11.3C146.1,11.5,141.8,7.3,134.8,7.3z M134.8,25.1c-3.3,0-5.2-2.3-5.2-6.5h0c0-4.2,1.9-6.5,5.2-6.5c3.3,0,5.2,2.3,5.2,6.5C140,22.7,138.1,25.1,134.8,25.1z" />
        <path d="M88,6.1v2h-3.2v4H88l0,17.1h5.8V12h3.7V8h-3.7V6.5c0-1.3,0.5-1.6,2.2-1.6h1.6V0.5c-1-0.1-2-0.1-2.8-0.1 C90.1,0.4,88,2.1,88,6.1z" />
        <path d="M73.1,7.3c-7,0-11.3,4.3-11.3,11.3h0c0,7,4.3,11.3,11.3,11.3c7,0,11.3-4.2,11.3-11.3C84.4,11.5,80.1,7.3,73.1,7.3z M73.1,25.1c-3.3,0-5.2-2.3-5.2-6.5c0-4.2,1.9-6.5,5.2-6.5c3.3,0,5.2,2.3,5.2,6.5C78.3,22.7,76.4,25.1,73.1,25.1z" />
        <path d="M49.2,7.3c-7,0-11.3,4.3-11.3,11.3c0,7,4.3,11.3,11.3,11.3c7,0,11.3-4.2,11.3-11.3C60.5,11.5,56.2,7.3,49.2,7.3z M49.2,25.1c-3.3,0-5.2-2.3-5.2-6.5c0-4.2,1.9-6.5,5.2-6.5c3.3,0,5.2,2.3,5.2,6.5C54.4,22.7,52.5,25.1,49.2,25.1z" />
        <path d="M29.9,11.6V8h-5.5v21.1h6V17.9c0-3.2,1.7-4.8,4.9-4.8h2V7.5c-0.3,0-0.6,0-0.9,0C33.5,7.4,31.5,8.7,29.9,11.6z" />
        <path d="M12.8,7.3c-2.9,0-4.9,1.1-6.6,3.6V8H0.5v29.5h5.8V26.5c1.5,2.2,3.7,3.3,6.6,3.3c5.2,0,9.1-4.4,9.1-11.3 C22,11.7,18.2,7.3,12.8,7.3z M16.1,18.7c0,3.9-1.9,6.2-4.8,6.2c-3.1,0-5-2.2-5-6.2c0-4.3,1.7-6.5,4.9-6.5S16.1,14.4,16.1,18.7 L16.1,18.7z" />
        <path d="M197.7,27.4L197.7,27.4c0.3-0.1,0.4-0.3,0.5-0.6c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0-0.9,0.1 v2.3h0.5l0-0.9h0.2c0.3,0,0.4,0.1,0.5,0.4c0,0.2,0.1,0.4,0.2,0.6h0.6c-0.1-0.2-0.1-0.4-0.2-0.6C198.1,27.7,197.9,27.5,197.7,27.4z M197.1,27.2h-0.2v-0.6c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0.1,0.5,0.3S197.4,27.2,197.1,27.2z" />
        <path d="M199.5,27.3c0-1.3-1-2.3-2.3-2.2c0,0-0.1,0-0.1,0c-1.3,0-2.3,1.1-2.3,2.4c0,1.3,1.1,2.3,2.4,2.3 C198.5,29.7,199.5,28.6,199.5,27.3C199.5,27.3,199.5,27.3,199.5,27.3z M197.2,29.1C197.2,29.1,197.1,29.1,197.2,29.1 c-1,0-1.8-0.8-1.8-1.8c0,0,0-0.1,0-0.1c0-1,0.9-1.7,1.8-1.7c1,0,1.7,0.9,1.7,1.8c0,0,0,0,0,0.1C198.9,28.4,198.2,29.1,197.2,29.1z " />
        <path d="M112.4,7.3c-2.9,0-4.9,1.1-6.6,3.6V8h-5.7v29.6h5.8V26.5c1.5,2.2,3.7,3.3,6.6,3.3c5.2,0,9.1-4.4,9.1-11.3 C121.6,11.7,117.8,7.3,112.4,7.3z M115.7,18.7c0,3.9-1.9,6.2-4.8,6.2c-3.1,0-5-2.2-5-6.2c0-4.3,1.7-6.5,4.9-6.5 C113.9,12.1,115.7,14.4,115.7,18.7L115.7,18.7z" />
    </g>;
}

export function LogoProofpointWord(props) {
    const { mainColor, shadowColor, wordClassName, ...otherProps } = props;
    return <svg viewBox="0 0 200 38" {...otherProps} aria-label="Proofpoint">
        {shadowColor ? <ProofpointWord className={wordClassName} fill={shadowColor} transform="translate(1, 1)" /> : null}
        <ProofpointWord className={wordClassName} fill={mainColor} />
    </svg>;
}

LogoProofpointWord.propTypes = {
    mainColor: PropTypes.string,
    shadowColor: PropTypes.string,
    wordClassName: PropTypes.string,
};

function IsolationWord(props) {
    return <g {...props}>
        <path d="M0.5,0.6h5.1v29.8H0.5V0.6z" />
        <path d="M11,7.7c0-2.4,0.6-4.3,1.9-5.6c1.2-1.3,3.1-1.9,5.6-1.9s4.4,0.6,5.6,1.9C25.3,3.5,26,5.3,26,7.7v0.9h-4.8V7.4 c0-1.1-0.2-1.8-0.7-2.3c-0.4-0.4-1.1-0.7-1.9-0.7c-0.8,0-1.4,0.2-1.9,0.7c-0.4,0.4-0.7,1.2-0.7,2.3c0,1,0.2,1.9,0.7,2.7 c0.5,0.8,1.1,1.5,1.8,2.2c0.7,0.7,1.5,1.4,2.4,2.1c0.8,0.7,1.6,1.5,2.4,2.3c0.7,0.8,1.4,1.8,1.8,2.8c0.5,1.1,0.7,2.3,0.7,3.7 c0,2.4-0.6,4.3-1.9,5.6c-1.3,1.3-3.2,1.9-5.7,1.9s-4.4-0.6-5.7-1.9c-1.3-1.3-1.9-3.1-1.9-5.6v-1.8h4.8v2.1c0,1.1,0.2,1.8,0.7,2.3 c0.5,0.4,1.1,0.6,1.9,0.6c0.8,0,1.4-0.2,1.9-0.6c0.5-0.4,0.7-1.2,0.7-2.3c0-1-0.2-1.9-0.7-2.7c-0.5-0.8-1.1-1.5-1.8-2.2 c-0.7-0.7-1.5-1.4-2.4-2.1c-0.8-0.7-1.6-1.5-2.4-2.3c-0.7-0.8-1.4-1.8-1.8-2.8C11.2,10.4,11,9.2,11,7.7z" />
        <path d="M30.8,7.7c0-2.4,0.7-4.3,2-5.6c1.3-1.3,3.2-1.9,5.7-1.9c2.5,0,4.4,0.6,5.7,1.9c1.3,1.3,2,3.1,2,5.6v15.5 c0,2.4-0.7,4.3-2,5.6c-1.3,1.3-3.2,1.9-5.7,1.9c-2.5,0-4.4-0.6-5.7-1.9c-1.3-1.3-2-3.1-2-5.6V7.7z M35.9,23.6 c0,1.1,0.2,1.8,0.7,2.3c0.5,0.4,1.1,0.7,1.9,0.7c0.8,0,1.4-0.2,1.9-0.7c0.5-0.4,0.7-1.2,0.7-2.3V7.4c0-1.1-0.2-1.8-0.7-2.3 c-0.5-0.4-1.1-0.7-1.9-0.7c-0.8,0-1.4,0.2-1.9,0.7c-0.5,0.4-0.7,1.2-0.7,2.3V23.6z" />
        <path d="M51.8,0.6h5.1v25.6h8.4v4.3H51.8V0.6z" />
        <path d="M86.3,30.4h-5.1L80.2,25H74l-0.9,5.4h-4.7l5.2-29.8h7.4L86.3,30.4z M74.7,21h4.9L77.1,5.9L74.7,21z" />
        <path d="M89.4,0.6h15.7v4.3h-5.3v25.6h-5.1V4.8h-5.3V0.6z" />
        <path d="M109.7,0.6h5.1v29.8h-5.1V0.6z" />
        <path d="M120.4,7.7c0-2.4,0.7-4.3,2-5.6c1.3-1.3,3.2-1.9,5.7-1.9c2.5,0,4.4,0.6,5.7,1.9c1.3,1.3,2,3.1,2,5.6v15.5 c0,2.4-0.7,4.3-2,5.6c-1.3,1.3-3.2,1.9-5.7,1.9c-2.5,0-4.4-0.6-5.7-1.9c-1.3-1.3-2-3.1-2-5.6V7.7z M125.4,23.6 c0,1.1,0.2,1.8,0.7,2.3c0.5,0.4,1.1,0.7,1.9,0.7c0.8,0,1.4-0.2,1.9-0.7c0.5-0.4,0.7-1.2,0.7-2.3V7.4c0-1.1-0.2-1.8-0.7-2.3 c-0.5-0.4-1.1-0.7-1.9-0.7c-0.8,0-1.4,0.2-1.9,0.7c-0.5,0.4-0.7,1.2-0.7,2.3V23.6z" />
        <path d="M145.9,8.8v21.6h-4.6V0.6h6.4l5.2,17.9V0.6h4.5v29.8h-5.2L145.9,8.8z" />
    </g>;
}

export function LogoIsolationWord(props) {
    const { mainColor, shadowColor, ...otherProps } = props;
    return <svg viewBox="0 0 158 31" {...otherProps} aria-label="Isolation">
        {shadowColor ? <IsolationWord fill={shadowColor} transform="translate(1, 1)" /> : null}
        <IsolationWord fill={mainColor} />
    </svg>;
}

LogoIsolationWord.propTypes = {
    mainColor: PropTypes.string,
    shadowColor: PropTypes.string,
};

// TODO: maybe make it part of Icon.js?
export function LogoProofpointIcon(props) {
    return <svg viewBox="0 0 100 100" {...props} role="presentation">
        <polygon fill="#195595" points="10.1,26.9 10.1,73.7 50.2,96.1 90.1,73.5 90.1,27.4 50.2,3.9" />
        <polygon fill="#0398E2" points="10.1,26.9 50.2,51.1 90.1,27.4 50.2,3.9" />
        <path fill="#ffffff" d="M56.9,25.5c-6.5,0-10.9,2.3-14.8,7.4V27H29.4v57.7l12.9,7.1V65.3c3.3,4.5,8.3,6.9,14.7,6.9 c11.6,0,20.4-9.2,20.4-23.6C77.4,34.7,68.9,25.5,56.9,25.5z M64.3,49.2c0,8.2-4.2,12.9-10.8,12.9c-7,0-11.1-4.6-11.1-12.9 c0-9,3.8-13.6,10.9-13.6C60.3,35.6,64.3,40.3,64.3,49.2L64.3,49.2z" />
    </svg>;
}

// sometimes there is shadow, sometimes there is not...
// TODO: refactor after final design stay same for a month
export function IconShadow(props) {
    const { className, ...otherProps } = props;
    return <div className={cn('IconShadow', className)} {...otherProps} />;
}

export function PfptWordsVertical({ className, ...otherProps }) {
    return <div className={cn('PfptLogo', className)} {...otherProps}>
        <LogoProofpointWord
            mainColor="black"
            style={{ width: 200, height: 40 }}
        />
        <LogoIsolationWord
            mainColor="#03A9F4"
            style={{ width: 160, height: 30, marginTop: 5 }}
        />
    </div>;
}

export function PfptLogo(props) {
    const { className, noShadow, ...otherProps } = props;
    return <div className={cn('PfptLogo', className)} {...otherProps} role="presentation">
        <LogoProofpointIcon style={{ width: 52, height: 52 }} />
        <IconShadow style={{ marginTop: 5, opacity: noShadow ? 0 : 1 }} />

        <PfptWordsVertical style={{ marginTop: 10 }} />
    </div>;
}

PfptLogo.propTypes = {
    noShadow: PropTypes.bool,
};

export function PfptOnPC(props) {
    return <svg viewBox="0 0 163 101" {...props}>
        <path fill="#808080" d="M146.3,89.5c3.6,0,6.5-2.9,6.5-6.5V50.5c0-3.6-2.9-6.5-6.5-6.5h-52c-3.6,0-6.5,2.9-6.5,6.5V83 c0,3.6,2.9,6.5,6.5,6.5h-13V96h78v-6.5H146.3z M94,83V50.5h0.2h52h0.2V83h-0.2h-52H94z" />
        <g>
            <polygon fill="#195595" points="36.1,31.2 36.1,51.1 53.2,60.6 70.1,51 70.1,31.4 53.2,21.4" />
            <polygon fill="#0398E2" points="36.1,31.2 53.2,41.5 70.1,31.4 53.2,21.4" />
            <path fill="#FFFFFF" d="M56,30.6c-2.7,0-4.7,1-6.3,3.2v-2.5h-5.4v24.5l5.5,3V47.5c1.4,1.9,3.5,2.9,6.2,2.9c4.9,0,8.7-3.9,8.7-10 C64.7,34.5,61.1,30.6,56,30.6z M59.1,40.6c0,3.5-1.8,5.5-4.6,5.5c-3,0-4.7-2-4.7-5.5c0-3.8,1.6-5.8,4.6-5.8 C57.4,34.9,59.1,36.9,59.1,40.6L59.1,40.6z" />
        </g>
        <g>
            <polygon fill="#195595" points="110.3,60.2 110.3,71.9 120.3,77.5 130.3,71.9 130.3,60.4 120.3,54.5" />
            <polygon fill="#0398E2" points="110.3,60.2 120.3,66.3 130.3,60.4 120.3,54.5" />
            <path fill="#FFFFFF" d="M122,59.9c-1.6,0-2.7,0.6-3.7,1.9v-1.5h-3.2v14.4l3.2,1.8v-6.6c0.8,1.1,2.1,1.7,3.7,1.7c2.9,0,5.1-2.3,5.1-5.9 C127.1,62.2,125,59.9,122,59.9z M123.8,65.8c0,2-1.1,3.2-2.7,3.2c-1.8,0-2.8-1.2-2.8-3.2c0-2.2,1-3.4,2.7-3.4 C122.8,62.4,123.8,63.6,123.8,65.8L123.8,65.8z" />
        </g>
        <path fill="#808080" d="M62.8,77.7H84v-9.1H12.8V14.1h81.8V40h9.1V14.1c0-5-4.1-9.1-9.1-9.1H12.8c-5,0-9.1,4.1-9.1,9.1v54.5 c0,5,4.1,9.1,9.1,9.1h31.8v9.1h-9.1v9.1h36.4v-9.1h-9.1V77.7z" />
    </svg>;
}

function UrlIsolationWord(props) {
    return <g {...props}>
        <path d="M13.1,6.9v20c0,0.9,0.2,1.6,0.6,2c0.4,0.4,0.9,0.6,1.5,0.6c0.6,0,1.2-0.2,1.5-0.6s0.6-1,0.6-2v-20h3.9v19.7c0,2.1-0.5,3.7-1.5,4.8c-1,1.1-2.5,1.7-4.5,1.7s-3.5-0.6-4.5-1.7c-1-1.1-1.5-2.7-1.5-4.8V6.9H13.1z" />
        <path d="M33.9,32.8c0-0.2-0.1-0.3-0.1-0.5c0-0.1-0.1-0.3-0.1-0.6c0-0.2-0.1-0.5-0.1-0.9c0-0.3,0-0.8,0-1.3v-4.1c0-1.2-0.2-2.1-0.6-2.6c-0.4-0.5-1.1-0.7-2-0.7h-1.4v10.6h-4.1V6.9h6.1c2.1,0,3.7,0.5,4.6,1.5c0.9,1,1.4,2.5,1.4,4.5v2c0,2.7-0.9,4.4-2.7,5.3c1,0.4,1.7,1.1,2.1,2.1c0.4,1,0.6,2.1,0.6,3.5v4c0,0.6,0,1.2,0.1,1.7c0,0.5,0.2,1,0.4,1.5H33.9z M29.5,10.6v8h1.6c0.8,0,1.4-0.2,1.8-0.6c0.4-0.4,0.6-1.1,0.6-2.1v-2.6c0-0.9-0.2-1.6-0.5-2c-0.3-0.4-0.9-0.6-1.6-0.6H29.5z" />
        <path d="M41.8,6.9h4.1v22.2h6.7v3.7H41.8V6.9z" />
        <path d="M64.1,6.9h4.4v26h-4.4V6.9z" />
        <path d="M73.2,13.5c0-2.1,0.5-3.7,1.6-4.9c1.1-1.1,2.7-1.7,4.9-1.7s3.8,0.6,4.9,1.7c1.1,1.1,1.6,2.7,1.6,4.9v0.8h-4.2v-1.1c0-0.9-0.2-1.6-0.6-2c-0.4-0.4-0.9-0.6-1.6-0.6c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,2c0,0.9,0.2,1.7,0.6,2.4c0.4,0.7,1,1.3,1.6,1.9c0.6,0.6,1.3,1.2,2.1,1.8c0.7,0.6,1.4,1.3,2.1,2c0.6,0.7,1.2,1.5,1.6,2.5c0.4,0.9,0.6,2,0.6,3.2c0,2.1-0.6,3.7-1.7,4.9c-1.1,1.1-2.8,1.7-4.9,1.7s-3.8-0.6-4.9-1.7c-1.1-1.1-1.7-2.7-1.7-4.9v-1.6h4.2v1.9c0,0.9,0.2,1.6,0.6,2c0.4,0.4,1,0.6,1.7,0.6c0.7,0,1.3-0.2,1.7-0.6c0.4-0.4,0.6-1,0.6-2c0-0.9-0.2-1.7-0.6-2.4c-0.4-0.7-1-1.3-1.6-1.9c-0.6-0.6-1.3-1.2-2.1-1.8c-0.7-0.6-1.4-1.3-2.1-2c-0.6-0.7-1.2-1.5-1.6-2.5C73.4,15.8,73.2,14.7,73.2,13.5z" />
        <path d="M90.5,13.5c0-2.1,0.6-3.7,1.7-4.9c1.2-1.1,2.8-1.7,5-1.7s3.8,0.6,5,1.7c1.2,1.1,1.7,2.7,1.7,4.9V27c0,2.1-0.6,3.7-1.7,4.9c-1.2,1.1-2.8,1.7-5,1.7s-3.8-0.6-5-1.7c-1.2-1.1-1.7-2.7-1.7-4.9V13.5z M94.9,27.3c0,0.9,0.2,1.6,0.6,2c0.4,0.4,1,0.6,1.7,0.6c0.7,0,1.3-0.2,1.7-0.6c0.4-0.4,0.6-1,0.6-2V13.2c0-0.9-0.2-1.6-0.6-2c-0.4-0.4-1-0.6-1.7-0.6c-0.7,0-1.3,0.2-1.7,0.6c-0.4,0.4-0.6,1-0.6,2V27.3z" />
        <path d="M108.8,6.9h4.4v22.3h7.3V33h-11.7V6.9z" />
        <path d="M138.9,33h-4.5l-0.8-4.7h-5.4l-0.8,4.7h-4.1l4.5-26h6.5L138.9,33z M128.7,24.7h4.3l-2.1-13.2L128.7,24.7z" />
        <path d="M141.7,6.9h13.7v3.7h-4.6V33h-4.4V10.6h-4.6V6.9z" />
        <path d="M159.3,6.9h4.4v26h-4.4V6.9z" />
        <path d="M168.6,13.5c0-2.1,0.6-3.7,1.7-4.9c1.2-1.1,2.8-1.7,5-1.7c2.2,0,3.8,0.6,5,1.7c1.2,1.1,1.7,2.7,1.7,4.9V27c0,2.1-0.6,3.7-1.7,4.9c-1.2,1.1-2.8,1.7-5,1.7c-2.2,0-3.8-0.6-5-1.7c-1.2-1.1-1.7-2.7-1.7-4.9V13.5z M173.1,27.3c0,0.9,0.2,1.6,0.6,2c0.4,0.4,1,0.6,1.7,0.6c0.7,0,1.3-0.2,1.7-0.6c0.4-0.4,0.6-1,0.6-2V13.2c0-0.9-0.2-1.6-0.6-2c-0.4-0.4-1-0.6-1.7-0.6c-0.7,0-1.3,0.2-1.7,0.6c-0.4,0.4-0.6,1-0.6,2V27.3z" />
        <path d="M190.9,14.1V33h-4v-26h5.6l4.6,15.6V6.9h4v26h-4.6L190.9,14.1z" />
    </g>;
}

export function LogoUrlIsolationWord(props) {
    const { mainColor, shadowColor, ...otherProps } = props;
    return <svg viewBox="0 0 208 39" {...otherProps}>
        {shadowColor ? <UrlIsolationWord fill={shadowColor} transform="translate(1, 1)" /> : null}
        <UrlIsolationWord fill={mainColor} />
    </svg>;
}

LogoUrlIsolationWord.propTypes = {
    mainColor: PropTypes.string,
    shadowColor: PropTypes.string,
};

export function ProofpointUrlIsolationLogo(props) {
    const { className, noShadow, ...otherProps } = props;
    return <div className={cn('PfptLogo', className)} {...otherProps}>
        <LogoProofpointIcon style={{ width: 52, height: 52 }} />
        <IconShadow style={{ marginTop: 5, opacity: noShadow ? 0 : 1 }} />
        <LogoProofpointWord
            mainColor="black"
            style={{ width: 240, height: 41, paddingLeft: 10, marginTop: 10 }}
        />
        <LogoUrlIsolationWord
            mainColor="#03A9F4"
            style={{ width: 240, height: 42 }}
        />
    </div>;
}
ProofpointUrlIsolationLogo.propTypes = {
    noShadow: PropTypes.bool,
};
