export const BrandingOption = {
    DEFAULT: 'DEFAULT',
    CUSTOM: 'CUSTOM',
};

export const HelpButton = {
    DISABLED: 'DISABLED',
    ICON: 'ICON',
    BRANDING: 'BRANDING',
};

export const HelpButtonAction = {
    LINK: 'LINK',
    POPUP: 'POPUP',
};

/**
 * Note: keep this as plain object instead of class/constructor to make MobX auto wrap it in observable when passed
 * @return {BrowserBarOptions}
 */
export function getDefaultBrowserBarOptions() {
    return /** @class BrowserBarOptions */ {
        brandingOption: BrandingOption.DEFAULT,
        customBrandingText: '',
        externalLink: '',
        helpButton: HelpButton.DISABLED,
        helpButtonAction: HelpButtonAction.LINK,
        popupMessage: '',
        popupContent: null,
    };
}
