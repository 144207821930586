import React, { Component } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import cn from 'classnames';
import { IS_BROWSER_IE11 } from '../../env';
import './Actions.css';

const block = b.lock('Actions');

export default class Actions extends Component {
    static propTypes = {
        wrapperClassName: PropTypes.string,
    };

    state = {
        layoutFix: false,
    };

    onRef = IS_BROWSER_IE11 ? (node) => {
        if (!node) {
            return;
        }
        if (this.state.layoutFix) {
            return;
        }
        if (node.clientWidth < node.scrollWidth) {
            this.setState({ layoutFix: true });
        }
    } : null;

    render() {
        const { children, className, wrapperClassName, ...otherProps } = this.props;
        return <div
            className={cn(block(), className)}
            {...otherProps}
        >
            {React.Children.map(children.filter(Boolean), (thisArg) => <div
                className={cn(block('Wrapper', { fix: this.state.layoutFix }), wrapperClassName)}
                ref={this.onRef}
            >{thisArg}</div>)}
        </div>;
    }
}

