import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import BrowserStore from '../../browser/stores/BrowserStore';
import './LearnMore.css';

class LearnMore extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        section: PropTypes.number,
    };

    onClick = (event) => {
        event.preventDefault();
        this.props.browser.openFaq(this.props.section);
    };

    render() {
        return <a
            className="LearnMore"
            href="#faq"
            onClick={this.onClick}
        >{this.props.t('LearnMore.text')}</a>;
    }
}

export default translate()(inject('browser')(observer(LearnMore)));
