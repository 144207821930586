import EventEmitter from 'events';
import debounce from 'lodash/debounce';
import forEach from 'lodash/forEach';
import { IS_EXTENSION, IS_CLIENT } from '../../env';
import reg from '../DOMRegistry';
import { normalizeTagName } from '../DOM';

const EVENT_CHANGE = 'change';

export default class AbstractWatcher extends EventEmitter {
    constructor(name, dirtyCheckFrequency) {
        super();
        this.name = name;

        this.nodeByUid = {};
        this.valueByUid = {};
        this.nodeCount = 0;

        this.debug = false;

        if (dirtyCheckFrequency) {
            this.dirtyCheck = debounce(() => {
                this.checkAllNodes();
                this.dirtyCheck();
            }, dirtyCheckFrequency);
        }
    }

    IS_EXTENSION = IS_EXTENSION;
    IS_CLIENT = IS_CLIENT;

    onChange(...args) {
        return this.on(EVENT_CHANGE, ...args);
    }

    emitChange(node, value) {
        this.emit(EVENT_CHANGE, this.name, reg.getFrameId(node), reg.getNodeId(node), value);
    }

    isWatching(node) {
        // when it was '!== undefined' MS Edge was throwing "Permission denied" error on "hangouts.google.com"
        // for the <html> node of iframe
        // TODO: research why?!
        return !!this.nodeByUid[reg.getUid(node)];
    }

    register = (node) => {
        if (this.isWatching(node)) {
            return;
        }

        const tag = normalizeTagName(node);
        if (this.shouldWatch(node, tag)) {
            const uid = reg.getUid(node);
            if (uid) {
                this.nodeByUid[uid] = node;
                this.nodeCount += 1;
                this.afterRegister(node, uid);
                if (this.IS_EXTENSION) {
                    this.checkNode(node);
                }
                if (this.dirtyCheck) {
                    this.dirtyCheck();
                }
            }
        }
    };

    unregister = (uid) => {
        if (uid && this.nodeByUid[uid]) {
            this.beforeUnregister(this.nodeByUid[uid], uid);
            delete this.nodeByUid[uid];
            delete this.valueByUid[uid];
            this.nodeCount = Math.max(0, this.nodeCount - 1);
            if (this.dirtyCheck && this.nodeCount === 0) {
                this.dirtyCheck.cancel();
            }
        }
    };

    compareValues(a, b) {
        return a === b;
    }

    checkNode(node, emitChange = false, ...args) {
        const uid = reg.getUid(node);
        if (!uid) {
            return;
        }

        const value = this.getValue(node, ...args);
        const oldValue = this.valueByUid[uid];

        if (!this.compareValues(value, oldValue)) {
            // debug.group('WATCHER CHANGE', {
            //     name: this.name,
            //     node,
            //     value,
            //     oldValue,
            // });
            emitChange = true;
            this.valueByUid[uid] = value;
        }

        if (emitChange) {
            this.emitChange(node, value);
            return value;
        }
    }

    checkNodeAndReturnChange(node) {
        const uid = reg.getUid(node);
        if (this.nodeByUid[uid] !== undefined) {
            const value = this.getValue(node);
            const oldValue = this.valueByUid[uid];
            if (!this.compareValues(value, oldValue)) {
                this.valueByUid[uid] = value;
                return value;
            }
        }
    }

    forEachNode(callback) {
        forEach(this.nodeByUid, callback);
    }

    checkAllNodes() {
        this.forEachNode((node) => this.checkNode(node));
    }

    restoreAllNodes() {
        this.forEachNode((node, uid) => this.setValue(node, uid, this.valueByUid[uid]));
    }

    shouldWatch(/* node, tag */) { /* override me */ }

    afterRegister() { /* override me */ }

    beforeUnregister(/* node, uid */) { /* override me */ }

    getValue() { /* override me */ }

    setValue() { /* override me */ }
}
