import debug, { DEBUG } from '../../debug';
import Lock from '../Lock';
import reg from '../DOMRegistry';
import watchers from '../watchers';
import MediaSourceRepository from './MediaSourceRepository';
import selectionCtrl from './ClientSelectionController';
import { API_CLIENT_TO_BROWSER } from '../sharedConstants';
import { messageToBrowser } from './browserMessageBus';

export const FRAME_REF_PROP = '_frame';
const ATTR_DEBUG_FRAME_ID = 'frame-id';

export default class Frame {
    static frames = {};

    static create(frameId) {
        const frame = new Frame(frameId);
        this.frames[frameId] = frame;
        return frame;
    }

    static get(frameId) {
        return this.frames[frameId];
    }

    constructor(id) {
        this.id = id;
        this.isTop = id === 0;
        this.mediaRepository = new MediaSourceRepository(this);
        this.loadingType = null;
        this.renderLock = new Lock(`Render for frame${id}`);
        this.textWatcherLock = new Lock(`Text watcher for frame${id}`);
        this.needToWaitToBodyTag = true;
        this.printIsReady = false;
        this.printInProgress = false;
        this.useNativePrint = true;
        this.cssBlockRenderLock = null;
        /** @type {Document} */
        this.doc = null;
        /** @type {Document} needed to be able to reference real document for frame if rendered in shadowDOM */
        this.realDoc = null;

        // TODO: move into call, merge with "protocol.js" logic
        // new dict should have built in TAGs and couple namespaces (svg, xlink)
        this.dict = {};

        this.nodeByOriginalSrc = {};
    }

    setInitData({ doctype = '', head = '' }) {
        this.doctype = doctype;
        this.head = head;
        this.hasInitData = true;
    }

    updateCssBlockRender(cssIsLoaded) {
        if (!this.cssBlockRenderLock) {
            this.cssBlockRenderLock = new Lock(`Css block for frame${this.id}`);
            this.cssBlockRenderLock.onUnlock(() => {
                if (this.isReadyToPaint()) {
                    this.show();
                }
            });
        }
        if (cssIsLoaded) {
            this.cssBlockRenderLock.unlock();
        } else {
            this.cssBlockRenderLock.lock();
        }
    }

    isReadyToPaint() {
        return !this.needToWaitToBodyTag && !(this.cssBlockRenderLock && this.cssBlockRenderLock.isLocked());
    }

    destroy() {
        // destroy everything that may have references on frame and frame.doc
        this.renderLock.removeAllListeners();
        this.textWatcherLock.removeAllListeners();
        this.mediaRepository.destroy();

        Object.keys(reg.getRegByFrameId(this.id).nodeById)
            .forEach((nodeId) => {
                const node = reg.getNode(this.id, nodeId);
                watchers.unregisterFromAll(reg.getUid(node));

                if (node[FRAME_REF_PROP]) {
                    node[FRAME_REF_PROP].destroy();
                }

                reg.removeNode(node);
            });


        if (this.textMutationObserver) {
            this.textMutationObserver.disconnect();
            delete this.textMutationObserver;
        }

        reg.removeAllEventListeners(this.doc);
        reg.clearAllTimeouts(this.doc);
        reg.clearAllIntervals(this.doc);

        if (this.realDoc) {
            reg.removeAllEventListeners(this.realDoc);
            reg.clearAllTimeouts(this.realDoc);
            reg.clearAllIntervals(this.realDoc);
        }

        // delete nodes and frame's document in the end, to avoid NPEs
        delete reg.nodeRegistryByFrameId[this.id];
        delete this.doc;
        delete this.realDoc;
        // null on top frame
        if (this.iframeNode) {
            if (this.iframeNode[FRAME_REF_PROP] === this) {
                if (DEBUG) {
                    this.iframeNode.removeAttribute(ATTR_DEBUG_FRAME_ID);
                }
                delete this.iframeNode[FRAME_REF_PROP];
            }
            delete this.iframeNode;
        }
        delete this.actionQueue;
        delete Frame.frames[this.id];
    }

    setFrameNode(node) {
        this.iframeNode = node;
        node[FRAME_REF_PROP] = this;
        if (DEBUG) {
            node.setAttribute(ATTR_DEBUG_FRAME_ID, this.id);
        }
    }

    iframeNode = null;
    initialRenderingIsDone = false;

    nextChangeId = 1;
    frameVersion = 0;
    dispatchIsScheduled = false;

    actionCounter = 0;
    incrementActionCounter() {
        this.actionCounter += 1;
        selectionCtrl.isClientHasUncommitedSelection = false;
        return this.actionCounter;
    }

    show() {
        setTimeout(() => {
            if (this.isTop) {
                messageToBrowser(API_CLIENT_TO_BROWSER.showIframe, {});
            }
        }, 0);
    }
    textMutationObserver = null;

    // for compositional input (IME)
    isComposing = false;

    actionQueue = [];

    /** @type {Object.<number,CSSStyleSheet>} */
    cssStyleSheetById = {};
    getCssStyleSheet = (id) => {
        if (!this.cssStyleSheetById[id]) {
            const CSSStyleSheet = this?.doc?.defaultView?.CSSStyleSheet;
            try {
                this.cssStyleSheetById[id] = new CSSStyleSheet();
            } catch (e) {
                debug.warn('getCssStyleSheet failed to make sheet', { id, e });
            }
        }
        return this.cssStyleSheetById[id];
    };
}
