import PropTypes from 'prop-types';
import React from 'react';
import b from 'b_';
import cn from 'classnames';
import { translate } from 'react-i18next';
import { IS_BROWSER_IE11 } from '../../../env';
import './UDSpinner.css';

const block = b.lock('UDSpinner');

// TODO: rename to just /Component/Spinner/Spinner.js
function UDSpinner({ className = '', iconClassName = '', type = 'small', title, t }) {
    title = title ?? t('UDSpinner.spinnerTitle');

    // IE11 does not support stroke-dashoffset animation
    // chrome has blurry border animation rendering bug
    return <div className={cn(block({ type }), className)}
        title={title}
        aria-label={title}
    >
        {IS_BROWSER_IE11
            ? <div className={block('Spinner')} role="presentation">
                <div className={block('Inner')}>
                    <div className={block('Gap')}/>
                    <div className={block('Half', { left: true })}>
                        <div className={block('HalfCircle', { left: true })}/>
                    </div>
                    <div className={block('Half', { right: true })}>
                        <div className={block('HalfCircle', { right: true })}/>
                    </div>
                </div>
            </div>
            : <svg
                className={cn(block('SVG'), iconClassName)}
                viewBox="0 0 20 20"
                role="presentation"
            >
                <circle
                    className={block('Circle', { type })}
                    cx="10"
                    cy="10"
                    r={type === 'tiny' || type === 'fuse' ? 7.5 : 8}
                />
            </svg>}
    </div>;
}

UDSpinner.propTypes = {
    type: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'fuse']),
    title: PropTypes.string,
    iconClassName: PropTypes.string,
    t: PropTypes.func.isRequired,
};

export default translate()(UDSpinner);

