import React, { Component } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { translate } from 'react-i18next';
import { AuthPage, AuthBox } from './AuthLayout';
import { FTBody, FTHeadline } from '../Fuse';
import FuseButton from '../FuseControls/FuseButton';
import './BrowserLoginFailure.css';

const block = b.lock('BrowserLoginFailure');

class BrowserLoginFailure extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    onSubmit = (event) => {
        event.preventDefault();
        window.location = '/login';
    };

    render() {
        return (
            <AuthPage>
                <AuthBox onSubmit={this.onSubmit}>
                    <FTHeadline className={block('MarginBottom')}>
                        {this.props.t('PasswordlessAuth.failure.genericError.title')}
                    </FTHeadline>
                    <FTBody className={block('MarginBottom', { section: 'textBody' })}>
                        {this.props.t('PasswordlessAuth.failure.genericError.message')}
                    </FTBody>
                    <FuseButton
                        submit
                        type={'primary'}
                        color={'blue'}
                        fullWidth
                        onClick={this.onSubmit}
                    >{this.props.t('PasswordlessAuth.failure.genericError.button')}</FuseButton>
                </AuthBox>
            </AuthPage>
        );
    }
}

export default translate()(BrowserLoginFailure);
