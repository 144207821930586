import { IS_DEV } from './env';

// notably <form> node can have all kind of props because of <input name="wid" />
export const antiCollisionSuffix = IS_DEV ? '_' : Math.random().toString(36).substr(2);
export function makeSafePropName(name) {
    return `__${name}_${antiCollisionSuffix}`;
}

/**
 * Returns the closest root (ShadowRoot or Document)
 * @param el
 * @returns {null|Document|ShadowRoot}
 */
export function getRootNode(el) {
    if (!el) {
        return null;
    }

    if (el.getRootNode) {
        return el.getRootNode();
    }

    return el.ownerDocument;
}

export function getRoot(doc) {
    if (doc) {
        return doc.documentElement || doc.querySelector(':root');
    }
}

export function getParentElement(node) {
    if (node.parentElement) {
        return node.parentElement;
    }
    if (node.host) {
        return node.host;
    }
    if (node.parentNode?.host) {
        return node.parentNode.host;
    }
    return null;
}

function isParentNode(possibleParent, node) {
    let pEl = node;

    while (pEl) {
        if (pEl === possibleParent) {
            return true;
        }
        pEl = getParentElement(pEl);
    }

    return false;
}

export function isSVGNode(node) {
    return !!node && node.namespaceURI === 'http://www.w3.org/2000/svg';
}


let lastIntersectionObserverResult = false;
let lastIntersectionObserverTs = 0;
const INTERSECTION_RESULT_THROTTLE_MS = 300;

/**
 * Check the target to be out of viewport of be covered by another block (usual case: by fixed block)
 * @param xPx - X-position of the point
 * @param yPx - Y-position of the point
 * @param target - touch/click target
 * @param cb - callback
 * @returns boolean
 */
export function isOutOfViewportOrCovered(xPx, yPx, target, cb) {
    if (yPx < 0) {
        cb(true, 'out of window height -');
        return;
    } else if (yPx > window.innerHeight) {
        cb(true, 'out of window height +');
        return;
    }

    if (xPx < 0) {
        cb(true, 'out of window width -');
        return;
    } else if (xPx > window.innerWidth) {
        cb(true, 'out of window width +');
        return;
    }

    const doc = getRootNode(target) || document;
    const pointElement = doc.elementFromPoint(xPx, yPx);
    if (!isParentNode(target, pointElement)) {
        cb(true, 'elementFromPoint is not the same');
        return;
    }

    // it's not necessary to use Intersection API to check visibility in case of top frame
    if (window.top === window) {
        cb(false, 'top frame');
        return;
    }

    if (isSVGNode(target)) {
        cb(false, 'svg element');
        return;
    }

    if (Date.now() - lastIntersectionObserverTs < INTERSECTION_RESULT_THROTTLE_MS) {
        cb(lastIntersectionObserverResult, 'cached');
        return;
    }

    let removeObserverListenerTimeout;
    const targetObserver = new window.IntersectionObserver((entries) => {
        clearTimeout(removeObserverListenerTimeout);
        targetObserver.disconnect();
        lastIntersectionObserverTs = Date.now();

        if (!entries || !entries.length) {
            lastIntersectionObserverResult = true;
            cb(true, 'IntersectionObserver: empty entries list');
            return;
        }

        const intersectionEntry = entries[0];
        if (intersectionEntry.intersectionRatio === 0) {
            lastIntersectionObserverResult = true;
            cb(true, 'IntersectionObserver: intersectionRatio is zero');
            return;
        }

        const interRect = intersectionEntry.intersectionRect;
        if (
            interRect.left > xPx || interRect.right < xPx ||
            interRect.top > yPx || interRect.bottom < yPx
        ) {
            lastIntersectionObserverResult = true;
            cb(true, 'IntersectionObserver: out of rect');
            return;
        }

        lastIntersectionObserverResult = false;
        cb(false, 'IntersectionObserver: ok');
    }, { threshold: 0 });

    targetObserver.observe(target);

    // wait InteractionObserver event for 100ms max
    removeObserverListenerTimeout = setTimeout(() => {
        targetObserver.disconnect();
        cb(false, 'IntersectionObserver: timeout');
    }, 100);
}

export function getFullscreenElement(doc) {
    const getFullscreenEl = (docEl) => {
        if (!docEl) {
            return null;
        }

        return docEl.fullscreenElement
            || docEl.fullScreenElement
            || docEl.webkitFullscreenElement
            || docEl.mozFullScreenElement
            || docEl.msFullscreenElement;
    };

    let el = getFullscreenEl(doc);
    while (el) {
        const possibleEl = getFullscreenEl(el.shadowRoot);
        if (possibleEl) {
            el = possibleEl;
        } else {
            break;
        }
    }

    return el;
}

export function getEventTarget(event) {
    if (event.composedPath) { // actual spec
        return event.composedPath()[0];
    }
    if (event.path) { // legacy chrome specific
        return event.path[0];
    }
    // just in case and ie11
    return event.target;
}

export function getDocumentActiveElement(doc) {
    if (!doc) {
        return null;
    }

    let activeEl = doc.activeElement;

    while (activeEl?.shadowRoot) {
        const shadowActiveEl = activeEl.shadowRoot.activeElement;
        if (shadowActiveEl) {
            activeEl = shadowActiveEl;
        } else {
            break;
        }
    }

    return activeEl;
}

export function detachNodeAndPutItBackWhereItWas(node) {
    const parent = node.parentNode;
    if (parent) {
        const nextSibling = node.nextSibling;
        parent.removeChild(node);
        if (nextSibling) {
            parent.insertBefore(node, nextSibling);
        } else {
            parent.appendChild(node);
        }
    }
}
