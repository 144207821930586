import b from 'b_';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactModal from 'react-modal';
import { translate } from 'react-i18next';
import BrowserStore from '../../browser/stores/BrowserStore';
import DebugStore from '../../browser/stores/DebugStore';
import ClearCookiesStore from '../../browser/stores/ClearCookiesStore';
import ResearchStore from '../../browser/stores/ResearchStore';
import makeElementId from '../../makeElementId';
import EnvStore from '../../stores/EnvStore';
import LS, { LS_KEY } from '../../LocalStorage';
import { AGENT_BASE } from '../../API';
import Flag from '../Flag/Flag';
import Button from '../Button';
import KeyboardShortcuts from '../KeyboardShortcuts';
import './TopBarMenu.css';

const block = b.lock('TopBarMenu');
const MenuGroup = ({ children }) => <Fragment>
    {children}
    <div className={block('Separator')} aria-hidden="true" />
</Fragment>;

class TopBarMenu extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        clearCookies: ClearCookiesStore.PropType.isRequired,
        debug: DebugStore.PropTypes.isRequired,
        research: ResearchStore.PropType.isRequired,
        env: EnvStore.PropType.isRequired,
    };

    elementId = makeElementId('TopBarMenu');

    onClearCookiesClick = () => {
        this.props.browser.topBarMenu.close();
        this.props.clearCookies.modal.open();
    };

    onFeedbackClick = () => {
        this.props.browser.topBarMenu.close();
        this.props.browser.sendFeedbackStore.openProductFeedbackModal();
    };

    onAdBlockSwitchClick = () => {
        this.props.browser.topBarMenu.close();
        this.props.browser.adBlockModal.toggle();
    };

    onFaqClick = () => {
        this.props.browser.topBarMenu.close();
        this.props.browser.openFaq();
    };

    onSettingsClick = () => {
        this.props.browser.topBarMenu.close();
        this.props.browser.settingsModal.open();
    };

    onDebugPointerClick = () => {
        LS.set(LS_KEY.debugPointerEvents, !LS.get(LS_KEY.debugPointerEvents, false));
        window.location.reload();
    };

    onLocationClick = () => {
        this.props.browser.topBarMenu.close();
        this.props.research.locationMenu.open();
    };

    onUserAgentClick = () => {
        this.props.browser.topBarMenu.close();
        this.props.research.userAgentMenu.open();
    };

    menuItemRefs = {};

    getMenuItemsAndFocusedIndex = () => {
        const menuItems = Object.values(this.menuItemRefs).filter((item) => !item?.disabled);
        const focusedIndex = menuItems.indexOf(document.activeElement);
        return [menuItems, focusedIndex];
    };

    focusNextMenuItem = () => {
        const [menuItems, focusedIndex] = this.getMenuItemsAndFocusedIndex();
        if (focusedIndex > -1) {
            menuItems[(focusedIndex + 1) % menuItems.length].focus();
        } else {
            menuItems[0].focus();
        }
    };

    focusPrevMenuItem = () => {
        const [menuItems, focusedIndex] = this.getMenuItemsAndFocusedIndex();
        if (focusedIndex > -1) {
            menuItems[(focusedIndex - 1 + menuItems.length) % menuItems.length].focus();
        } else {
            menuItems[menuItems.length - 1].focus();
        }
    };

    render() {
        const { t, env, browser, debug, research } = this.props;
        const shouldDisplay = !(env.isUrlIsolation || env.isSaasIsolation);
        const displayResearch = research.researchMode.isEnabled && (env.screen.isTablet || env.screen.isMobile);
        const userAgentInfo = displayResearch && research.getUserAgentInfo(research.userAgent);
        const showAdBlockIcon = browser.adBlockSwitchEnabled
            && env.screen.isMobile
            && !(env.isUrlIsolation || env.isSaasIsolation);


        const MenuItem = ({ type, icon, noIcon, children, disabled, ...props }) => <Button
            buttonRef={(ref) => {
                if (ref) {
                    this.menuItemRefs[type] = ref;
                } else {
                    delete this.menuItemRefs[type];
                }
            }}
            role="menuitem"
            className={block('Item', { type })}
            iconClassName={block('ItemIcon')}
            icon={!noIcon ? (icon || type) : null}
            disabled={disabled}
            aria-disabled={disabled}
            noCenter
            {...props}
        >{children || t(`TopBarMenu.${type}`)}</Button>;

        return <ReactModal
            id={this.elementId}
            aria-hidden={!browser.topBarMenu.isOpen}
            aria-label={t('browserTopBar.menuButtonTitle')}
            role="menu"
            className={block()}
            overlayClassName={block('Overlay', {
                troubleshootingMode: browser.troubleshootingMode,
                alternativeMobile: env.screen.isMobile && browser.isTopBarMinimizable(),
            })}
            isOpen={browser.topBarMenu.isOpen}
            onClose={browser.topBarMenu.close}
            onRequestClose={browser.topBarMenu.close}
            closeTimeoutMS={200}
            shouldCloseOnEsc
            shouldFocusAfterRender
            shouldReturnFocusAfterClose
        >
            <KeyboardShortcuts
                down={this.focusNextMenuItem}
                up={this.focusPrevMenuItem}
            />

            {shouldDisplay && env.screen.isMobile && <MenuGroup>
                <MenuItem
                    type="newTab"
                    icon="add"
                    href="/browser"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={browser.topBarMenu.close}
                />
            </MenuGroup>}

            {displayResearch ? <MenuGroup>
                {research.locations.length > 1 ? <MenuItem
                    type="location"
                    noIcon
                    onClick={this.onLocationClick}
                >
                    <Flag
                        type={research.getLocationById(research.location).flag}
                        className={block('ItemIcon', { flag: true })}
                    />
                    {t('location.' + research.location)}
                </MenuItem> : null}

                <MenuItem
                    type="userAgent"
                    onClick={this.onUserAgentClick}
                    icon={userAgentInfo.os ? 'os_' + userAgentInfo.os.toLowerCase() : 'phonelink_setup'}
                >
                    {userAgentInfo.browser ? t(`userAgentMenu.${userAgentInfo.browser}`) : t('userAgentMenu.barTitle')}
                </MenuItem>
            </MenuGroup> : null}

            <MenuGroup>
                {browser.printEnabled ? <MenuItem
                    type="print"
                    onClick={browser.printPage}
                    disabled={!browser.hasPage}
                /> : null}
                {showAdBlockIcon ? <MenuItem
                    type="ads"
                    icon={browser.adBlock.isEnabled ? 'ads_blocked' : 'ads_allowed'}
                    onClick={this.onAdBlockSwitchClick}
                /> : null}
                {browser.clearCookiesEnabled ? <MenuItem
                    type="cookies"
                    icon="remove_circle_outline"
                    onClick={this.onClearCookiesClick}
                /> : null}
            </MenuGroup>

            <MenuGroup>
                {browser.feedbackEnabled ? <MenuItem
                    type="feedback"
                    onClick={this.onFeedbackClick}
                >{t('TopBarMenu.feedback')}</MenuItem> : null}
                {browser.faqEnabled ? <MenuItem
                    type="faq"
                    icon="help_outline"
                    onClick={this.onFaqClick}
                /> : null}
            </MenuGroup>

            <MenuGroup>
                {shouldDisplay ? <MenuItem
                    type="settings"
                    icon="settings"
                    onClick={this.onSettingsClick}
                /> : null}

                {env.mightBeCustomerAdmin && shouldDisplay ? <MenuItem
                    type="console"
                    icon="open_in_browser"
                    href="/console"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={browser.topBarMenu.close}
                /> : null}
            </MenuGroup>

            {browser.isDeveloper ? <MenuGroup>
                <MenuItem
                    type="screenshot"
                    icon="wallpaper"
                    onClick={() => window._.getScreenshot(null, true)}
                    disabled={!browser.hasPage}
                >[DEV] Screenshot</MenuItem>

                <MenuItem
                    type="devtools"
                    icon="build"
                    onClick={debug.openDevTools}
                >[DEV] DevTools</MenuItem>

                <MenuItem
                    type="debug_pointer"
                    icon={LS.get(LS_KEY.debugPointerEvents, false) ? 'toggle_on' : 'toggle_off'}
                    onClick={this.onDebugPointerClick}
                >[DEV] Toggle pointer debug</MenuItem>

                <MenuItem
                    type="debug_info"
                    icon="info_outline"
                    onClick={() => window.open(AGENT_BASE + '/debug-info', '_blank')}
                >[DEV] Debug Info</MenuItem>
            </MenuGroup> : null}
        </ReactModal>;
    }
}

export default translate()(inject(
    'browser',
    'clearCookies',
    'research',
    'env',
    'debug',
)(observer(TopBarMenu)));
