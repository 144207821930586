import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import b_ from 'b_';
import cn from 'classnames';
import FlagStore from '../../stores/FlagStore';
import FuseButton from '../FuseControls/FuseButton';
import FusePopUp from '../FuseControls/FusePopUp';
import FuseCheckbox from '../FuseControls/FuseCheckbox';
import Actions from '../Actions/Actions';
import { FTBody } from '../Fuse';
import './FuseConfirmationModal.css';

const block = b_.lock('FuseConfirmationModal');

class FuseConfirmationModal extends Component {
    static propTypes = {
        store: FlagStore.PropType.isRequired,
        title: PropTypes.string,
        cancelLabel: PropTypes.string,
        text: PropTypes.node,
        onConfirm: PropTypes.func,
        onClose: PropTypes.func,
        confirmLabel: PropTypes.string,
        textClass: PropTypes.string,
        confirmCheckBox: PropTypes.string,
    };

    static defaultProps = {
        title: 'Unsaved Changes',
        text: 'Are you sure you want to lose your latest changes?',
        confirmLabel: 'Discard Changes',
        cancelLabel: 'Return to Edit',
    };

    state = {
        confirmCheckBox: false,
    };

    setConfirmCancel = ({ target }) => {
        this.setState({ confirmCheckBox: target.checked });
    };

    onConfirm = () => {
        this.props.onConfirm();
        this.setState({ confirmCheckBox: false });
        this.props.store.close();
    };

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.props.store.close();
    };

    render() {
        const {
            store,
            title,
            text,
            confirmLabel,
            cancelLabel,
            textClass,
            onConfirm,
            children,
            confirmCheckBox,
        } = this.props;

        return <FusePopUp
            className={block()}
            isOpen={store.isOpen}
            title={title}
        >
            <FTBody className={cn(block('Text'), textClass)}>{children || text}</FTBody>

            {confirmCheckBox ? <FuseCheckbox
                className={block('ConfirmCheckbox')}
                store={new FlagStore(this.state.confirmCheckBox)}
                onChange={this.setConfirmCancel}
                label={confirmCheckBox}
            /> : <Fragment />}

            <Actions
                className={block('Actions')}
                wrapperClassName={block('ActionsWrapper')}
            >
                {onConfirm ? <FuseButton
                    type={'secondary'}
                    color={confirmCheckBox ? 'red' : 'blue'}
                    fullWidth
                    disabled={confirmCheckBox && !this.state.confirmCheckBox}
                    onClick={this.onConfirm}
                    qa={'confirm-modal-cta'}
                >{confirmLabel}</FuseButton> : null }

                <FuseButton
                    type={'primary'}
                    color={'blue'}
                    fullWidth
                    disabled={confirmCheckBox && this.state.confirmCheckBox}
                    onClick={this.onClose}
                    qa={'cancel-modal-cta'}
                >{cancelLabel}</FuseButton>
            </Actions>
        </FusePopUp>;
    }
}

export default observer(FuseConfirmationModal);
