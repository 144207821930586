import React from 'react';
import b from 'b_';
import { LogoProofpointIcon } from '../Logo/PfptLogo';
import './UDHumanCheckPage.css';

const block = b.lock('UDHumanCheckPage');

export default function UDHumanCheckPage() {
    return <div className={block()}>
        <div className={block('TopBlock')}>
            <div className={block('TopBlockTitle')}>Advanced Threat Protection</div>
            <div className={block('TopBlockContent')}>
                Stop threats before they reach your people and respond quickly when things go wrong. Our innovative
                Advanced Threat Protection solutions prevent, detect, and notify you of advanced threats – through
                email, mobile, social, and the desktop – and help you resolve them before they cause lasting harm.
            </div>
        </div>

        <div className={block('BottomBlock')}>
            <div className={block('BottomBlockTitle')}>
                <LogoProofpointIcon style={{ width: 34, top: 5, position: 'relative' }} />Proofpoint URL Isolation
            </div>

            <div className={block('BottomBlockSubTitle')}>TARGETED ATTACK PROTECTION</div>
            <div className={block('BottomBlockContent')}>
                Prevent known and unknown threats with Advanced Threat Protection—even those
                that don’t use malware—from reaching your people. Advanced Threat Protection is a security solution that
                defends against complex malware or hacking attacks that target sensitive data within an organization.
                Protect against advanced threats with the industry’s most robust email security platform and the most
                accurate view of the email threat landscape.
            </div>

            <div className={block('BottomBlockSubTitle')}>GET COMPLETE VISIBILITY</div>
            <div className={block('BottomBlockContent')}>
                Advanced Threat Protection provides
                the insight you need to stop and respond to today’s advanced threats. Apply fully verified threat
                intelligence that includes proof of conviction, data on more than 40 threat categories, five-year
                history, and related samples, domains, and IPs.
            </div>

            <div className={block('BottomBlockSubTitle')}>GET COMPLETE VISIBILITY</div>
            <div className={block('BottomBlockContent')}>
                Avoid alert fatigue with Advanced Threat Protection. Investigate, prioritize and verify the threats that
                matter. Respond faster to incidents by automating and streamlining manual tasks. Manage incidents across
                your entire security infrastructure in a single view.
            </div>
        </div>
    </div>;
}
