import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import es419 from './locales/es-419.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import ja from './locales/ja.json';
import ko from './locales/ko.json';
import nl from './locales/nl.json';
import ptBR from './locales/pt-BR.json';
import ptPT from './locales/pt-PT.json';
import ru from './locales/ru.json';
import th from './locales/th.json';
import zhCN from './locales/zh-Hans-CN.json';
import zhHK from './locales/zh-Hant-HK.json';

import debug, { DEBUG } from '../debug';

export const whitelist = [
    'de',
    'en',
    'es',
    'es-es',
    'es-419',
    'fr',
    'it',
    'ja',
    'ko',
    'nl',
    'pt',
    'pt-br',
    'pt-pt',
    'ru',
    'th',
    'zh',
    'zh-cn',
    'zh-hk',
];

export const es419whitelist = [
    'es-ar',
    'es-bo',
    'es-cl',
    'es-do',
    'es-ec',
    'es-gt',
    'es-hn',
    'es-co',
    'es-cr',
    'es-mx',
    'es-ni',
    'es-pa',
    'es-py',
    'es-pe',
    'es-pr',
    'es-sv',
    'es-uy',
    'es-ve',
];

export const defaultLanguage = 'en';

const logger = {
    type: 'logger',
    log(args) { debug.log(args[0]); },
    warn(args) { debug.warn(args[0]); },
    error(args) { debug.error(args[0]); },
};

i18n
    .use(LanguageDetector)
    .use(logger)
    .init({
        whitelist,
        nonExplicitWhitelist: true,
        lowerCaseLng: true,
        fallbackLng: {
            es: ['es-es', defaultLanguage],
            ...(es419whitelist.reduce((result, next) => {
                result[next] = ['es-419', defaultLanguage];
                return result;
            }, {})),
            default: [defaultLanguage],
        },
        detection: {
            caches: [],
            order: ['navigator', 'htmlTag'],
        },
        resources: {
            en: { translation: en },
            de: { translation: de },
            'es-es': { translation: es },
            'es-419': { translation: es419 },
            fr: { translation: fr },
            it: { translation: it },
            ja: { translation: ja },
            ko: { translation: ko },
            nl: { translation: nl },
            'pt-br': { translation: ptBR },
            'pt-pt': { translation: ptPT },
            ru: { translation: ru },
            th: { translation: th },
            'zh-cn': { translation: zhCN },
            'zh-hk': { translation: zhHK },
        },
        debug: DEBUG,
    });

export default i18n;
