import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { translate } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import b from 'b_';
import { CSSTransitionGroup } from 'react-transition-group';
import BrowserStore from '../../browser/stores/BrowserStore';
import CommonBrowserModals from '../CommonBrowserModals/CommonBrowserModals';
import TopBarAlternative from './../TopBarAlternative/TopBarAlternative';
import UDSpinner from '../UD/Fragments/UDSpinner';
import { TroubleshootingInstructions } from '../TroubleshootingModeScreen';
import ThePage from '../ThePage/ThePage';
import Bg from '../Bg/Bg';
import { EnvStoreProps } from '../../stores/props';
import './SaasBrowser.css';
import './../../Components/MinimizableBrowser.css';

const block = b.lock('SaasBrowser');
const mBlock = b.lock('MinimizableBrowser');

const PageOverlayAnimation = (props) => <CSSTransitionGroup
    transitionName={block('PageOverlayAnimation').toString()}
    transitionEnterTimeout={150}
    transitionLeave={false}
    {...props}
/>;

class SaasBrowser extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        iframeSrc: PropTypes.string,
        iframeOpacity: PropTypes.number,
        isLoading: PropTypes.bool,
        onLoad: PropTypes.func,
        onRefresh: PropTypes.func,
        env: EnvStoreProps,
    };

    render() {
        const { iframeSrc, browser, t, env } = this.props;
        const { isHomePage } = browser;

        return <div
            className={cn(block(), mBlock({
                minimizable: !isHomePage && browser.isTopBarMinimizable(),
                mobile: env.screen.isMobile,
            }))}
        >
            <TopBarAlternative onRefresh={this.props.onRefresh} />

            <div className={block('AbsoluteContainer', { minimized: browser.isMinimized() })}>
                {browser.troubleshootingInstructionsScreen ? <TroubleshootingInstructions /> : null}

                {iframeSrc && !browser.troubleshootingInstructionsScreen ? <ThePage
                    iframeSrc={this.props.iframeSrc}
                    iframeOpacity={this.props.iframeOpacity}
                    onLoad={this.props.onLoad}
                    blur={this.props.isLoading || browser.topBarMenu.isOpen}
                /> : null}

                <PageOverlayAnimation>
                    {this.props.isLoading ? <UDSpinner
                        className={block('Spinner')}
                        type="large"
                        title={t('Browser.spinnerTitle')}
                    /> : null}
                </PageOverlayAnimation>

                {browser.directDownloadPage.isOpen ? <Bg key="bg" className={block('PageBg')} type="bottom" /> : null}
            </div>

            <CommonBrowserModals onRefresh={this.props.onRefresh} />
        </div>;
    }
}

export default translate()(inject('browser', 'env')(observer(SaasBrowser)));
