import {
    TAG_INPUT,
    INPUT_TYPE_CHECKBOX,
    INPUT_TYPE_RADIO,
    normalizeInputType,
    dispatchChangeEvent,
    dispatchInputEvent,
} from '../DOM';
import { F_VALUE } from '../sharedConstants';
import AbstractWatcher from './AbstractWatcher';

function normalizeValue(value) {
    return (!!value) | 0;
}

export class CheckedWatcher extends AbstractWatcher {
    shouldWatch(node, tag) {
        if (tag === TAG_INPUT) {
            const type = normalizeInputType(node);
            return type === INPUT_TYPE_CHECKBOX || type === INPUT_TYPE_RADIO;
        }
    }

    afterRegister(node, uid) {
        // default value
        this.valueByUid[uid] = normalizeValue(node.hasAttribute('checked'));
    }

    getValue(node) {
        return normalizeValue(node.checked);
    }

    setValue(node, uid, value) {
        const newValue = normalizeValue(value);
        const currentValue = this.getValue(node);
        this.valueByUid[uid] = newValue;
        if (currentValue !== newValue) {
            node.checked = !!newValue;

            if (this.IS_EXTENSION) {
                dispatchInputEvent(node);
                dispatchChangeEvent(node);
            }
        }
    }
}

export default new CheckedWatcher(F_VALUE.checked, 250);
