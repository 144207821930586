import { IS_DEV } from '../env';
import { normalizeTagName } from './DOM';

const USE_TAG_ENCODING = !IS_DEV;

const TAGS = [
    // top priority tags, single digit code
    'a', 'br', 'img', 'div', 'li', 'span', 'p', 'td',

    // tags in alphabetical order
    'abbr', 'acronym', 'address', 'area', 'article', 'aside', 'audio',
    'b', 'bdi', 'bdo', 'big', 'blockquote', 'body', 'br', 'button',
    'canvas', 'caption', 'center', 'cite', 'code', 'col', 'colgroup',
    'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'dir', 'dl', 'dt', 'em',
    'fieldset', 'figcaption', 'figure', 'font', 'footer', 'form', 'frameset',
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head', 'header', 'hr', 'html',
    'i', 'iframe', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'link',
    'main', 'map', 'mark', 'menu', 'menuitem', 'meta', 'meter', 'nav',
    'ol', 'optgroup', 'option', 'output', 'param', 'pre', 'progress',
    'q', 'rp', 'rt', 'ruby',
    's', 'samp', 'section', 'select', 'small', 'source', 'strike', 'strong', 'style', 'sub', 'summary', 'sup',
    'table', 'tbody', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'tt',
    'u', 'ul', 'var', 'video', 'wbr',
];

const TAG_OFFSET = 1;
const tagToId = {};
const idToTag = {};

TAGS.forEach((tagName, tagIndex) => {
    const tag = tagName.toUpperCase();
    const id = tagIndex + TAG_OFFSET;
    tagToId[tag] = id;
    idToTag[id] = tag;
});

export const encodeNodeType = USE_TAG_ENCODING
    ? (node) => {
        const tag = normalizeTagName(node);
        return tagToId[tag] || tag;
    }
    : (node) => normalizeTagName(node);

export const decodeNodeType = USE_TAG_ENCODING
    ? (value) => idToTag[value] || value
    : (value) => value;
