export default class RequestGenerator {
    constructor() {
        this.nextRequestId = 1;
        this.requestMap = {};
    }

    generateRequest = () => {
        const requestId = this.nextRequestId;
        this.nextRequestId += 1;
        const promise = new Promise((resolve) => {
            this.requestMap[requestId] = resolve;
        });
        promise.id = requestId;
        return promise;
    }

    processResponse = (requestId, ...args) => {
        if (this.requestMap[requestId]) {
            this.requestMap[requestId](...args);
            delete this.requestMap[requestId];
        }
    }
}
