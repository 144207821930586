import debug from '../../debug';
import EventLogger from '../../ClientEventLogger';

const logger = debug.create('MediaDownloader');

const MAX_NUMBER_OF_PARALLEL_DOWNLOADS = 2;
const MAX_CACHED_CHUNKS = 2;

export default class MediaDownloader {
    constructor(id) {
        this.id = id;
        this.isDestroyed = false;
        this.latestRequestedTime = -1;
        this.cachedButNotUsedChunkcsCount = 0;
        this.downdloadsFinished = MAX_NUMBER_OF_PARALLEL_DOWNLOADS;
        this.queue = [];
        this.singleUseBufferStore = {};

        logger.log('constructor', this, id);
    }

    destroy() {
        if (this.isDestroyed) {
            return;
        }
        logger.log('destroy', this.id);
        this.isDestroyed = true;
        this.queue = [];
        this.singleUseBufferStore = [];
    }

    getBuffer(meta, onLoad, id) {
        if (this.isDestroyed) {
            return;
        }

        if (!this.singleUseBufferStore[meta.url]) {
            this.queue.push(this.singleUseBufferStore[meta.url] = {
                meta,
                onLoad,
                id,
            });
        } else if ('value' in this.singleUseBufferStore[meta.url]) {
            onLoad(this.singleUseBufferStore[meta.url].value);
            delete this.singleUseBufferStore[meta.url];
        } else {
            this.singleUseBufferStore[meta.url].onLoad = onLoad;
        }

        this.runDownloadQueue();
    }

    runDownloadQueue() {
        if (this.isDestroyed) {
            return;
        }

        if (this.downdloadsFinished !== MAX_NUMBER_OF_PARALLEL_DOWNLOADS) {
            return;
        }

        while (this.downdloadsFinished && this.cachedButNotUsedChunkcsCount < MAX_CACHED_CHUNKS) {
            const item = this.queue.shift();

            if (!item) {
                return;
            }

            const { meta, id } = item;

            this.performDownload(meta, id);
        }
    }

    releaseChunk() {
        this.cachedButNotUsedChunkcsCount -= 1;

        if (!this.cachedButNotUsedChunkcsCount) {
            logger.warn('cachedButNotUsedChunkcsCount less than zero');
            this.cachedButNotUsedChunkcsCount = 0;
        }

        this.runDownloadQueue();
    }

    performDownload(meta, id) {
        this.downdloadsFinished -= 1;
        this.cachedButNotUsedChunkcsCount += 1;

        logger.log('download', this.id, 'started', id, meta);

        const xhr = new window.XMLHttpRequest();
        // cos IE throws if you set some things before .open
        xhr.open('GET', meta.url, true);

        let errorHandled = false;

        xhr.addEventListener('load', () => {
            if (this.isDestroyed) {
                return;
            }

            if (errorHandled) {
                return;
            }

            this.downdloadsFinished += 1;
            this.runDownloadQueue();

            if (!this.singleUseBufferStore[meta.url]) {
                return;
            }

            const downloaded = Date.now();

            errorHandled = true;

            if (xhr.response) {
                if (xhr.response.byteLength !== meta.byteLength) {
                    EventLogger.recordMediaEvent({
                        type: 'downloadError',
                        reason: 'mismatch',
                        statusText: xhr.statusText,
                        status: xhr.status,
                        readyState: xhr.readyState,
                        state: {
                            created: meta.created,
                            byteLength: xhr.response.byteLength,
                            expectedLength: meta.byteLength,
                            ttlSpend: Math.round((downloaded - meta.created) / 1000),
                            downloaded,
                        },
                    });
                }
            } else {
                EventLogger.recordMediaEvent({
                    type: 'downloadError',
                    reason: 'empty',
                    statusText: xhr.statusText,
                    status: xhr.status,
                    readyState: xhr.readyState,
                    state: {},
                    url: meta.url,
                });

                if (!meta.requestedTwice) {
                    meta.requestedTwice = true;
                    setTimeout(() => this.performDownload(meta, id));
                    return;
                }
            }

            logger.log('download', this.id, 'fetched', id);

            const item = this.singleUseBufferStore[meta.url];

            if (!item.onLoad) {
                this.singleUseBufferStore[meta.url].value = xhr.response || null;
                return;
            }

            delete this.singleUseBufferStore[meta.url];

            item.onLoad(xhr.response || null);
        });

        xhr.addEventListener('error', () => {
            if (errorHandled) {
                return;
            }

            errorHandled = true;
            this.downdloadsFinished += 1;

            if (!meta.requestedTwice) {
                meta.requestedTwice = true;
                setTimeout(() => this.performDownload(meta, id));
                return;
            }

            this.runDownloadQueue();

            EventLogger.recordMediaEvent({
                type: 'downloadError',
                reason: 'onerror',
                statusText: xhr.statusText,
                status: xhr.status,
                readyState: xhr.readyState,
                state: {},
                url: meta.url,
            });

            if (!this.singleUseBufferStore[meta.url]) {
                return;
            }

            if (!this.singleUseBufferStore[meta.url].onLoad) {
                this.singleUseBufferStore[meta.url].value = null;
                return;
            }
            this.singleUseBufferStore[meta.url].onLoad(null);
            delete this.singleUseBufferStore[meta.url];
        });

        xhr.withCredentials = true;
        xhr.responseType = 'arraybuffer';

        xhr.send();
    }
}

