import { isObservable } from 'mobx';
import debug from '../../../debug';
import * as DOM from '../../../mirror/DOM';

export const createParagraphNode = (children = [{ text: '' }]) => ({
    type: 'paragraph',
    children
});

export const createLinkNode = (href, text) => ({
    type: 'link',
    href,
    children: [{ text }]
});

export const createImageNode = (alt, src, align) => ({
    type: 'image',
    alt,
    src,
    align,
    children: [{ text: '' }],
});

export const fixEditorValue = (value) => {
    if (!value) {
        return [createParagraphNode()];
    }

    if (isObservable(value)) {
        return JSON.parse(JSON.stringify(value));
    }

    return value;
};

export const isNotEmpty = (value) => {
    if (!value) {
        return false;
    }
    if (Array.isArray(value)) {
        return value.some(isNotEmpty);
    }
    if (value?.text) {
        return true;
    }
    if (value?.type === 'image') {
        return true;
    }
    return isNotEmpty(value?.children);
};

const ELEMENT_TAGS = {
    A: (el) => ({ type: 'link', href: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'block-quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-one' }),
    H3: () => ({ type: 'heading-two' }),
    H4: () => ({ type: 'heading-two' }),
    H5: () => ({ type: 'heading-two' }),
    H6: () => ({ type: 'heading-two' }),
    IMG: (el) => ({ type: 'image', src: el.getAttribute('src'), alt: el.getAttribute('alt') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    DIV: () => ({ type: 'paragraph' }),
    UL: () => ({ type: 'bulleted-list' }),
};

const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    STRONG: () => ({ bold: true }),
    B: () => ({ bold: true }),
    U: () => ({ underline: true }),
};

const deserialize = (el, attributes = {}) => {
    if (DOM.isTextNode(el)) {
        return { ...attributes, text: el.textContent };
    }
    if (!DOM.isElementNode(el)) {
        return null;
    }
    if (DOM.isElementOneOf(el, [DOM.TAG_BR])) {
        return '\n';
    }

    const { nodeName } = el;
    let attrs = { ...attributes };

    if (TEXT_TAGS[nodeName]) {
        attrs = { ...attrs, ...TEXT_TAGS[nodeName](el) };
    }

    let children = Array.from(el.childNodes)
        .map((child) => deserialize(child, attrs))
        .flat()
        .filter(Boolean);

    if (children.length === 0) {
        children = [{ text: '' }];
    }

    if (ELEMENT_TAGS[nodeName]) {
        return {
            ...attrs,
            ...ELEMENT_TAGS[nodeName](el),
            children,
        };
    }

    return children;
};
export const htmlToSlate = (html) => {
    try {
        const parsed = new window.DOMParser().parseFromString(html, 'text/html');
        return deserialize(parsed.body);
    } catch (e) {
        debug.error('htmlToSlate', e, html);
        return [createParagraphNode()];
    }
};
