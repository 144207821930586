import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import b from 'b_';
import cn from 'classnames';
import PropTypes from 'prop-types';
import BrowserStore from '../../browser/stores/BrowserStore';
import { IS_BROWSER_SAFARI } from '../../env';
import FuseButton from '../FuseControls/FuseButton';
import { ResearchStoreProp } from '../../stores/props';
import './../TopBarGuide.css';
import './TopBarNewTab.css';

const guideBlock = b.lock('TopBarGuide');

class TopBarNewTab extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        research: ResearchStoreProp,
        browser: BrowserStore.PropType.isRequired,
        isWizard: PropTypes.bool,
        className: PropTypes.string,
        btnProps: PropTypes.shape(FuseButton.propTypes),
    };
    static getWizard({ isWizard, t }) {
        return isWizard ? <div className={guideBlock({ type: 'top', alternative: true })}>
            {t('browserTopBar.guides.newTabButton')}
        </div> : null;
    }
    // TODO: just use normal link!
    onClick = (e) => {
        if (this.props.browser.isConsolePreview) {
            return;
        }

        if (e.shiftKey) {
            window.open(
                '/browser',
                'new_window',
                `height=${document.body.clientHeight},width=${document.body.clientWidth}`);
        } else {
            window.open('/browser', '_blank');
        }
        document.activeElement.blur();
    }
    render() {
        const { research, t, isWizard, className, btnProps } = this.props;

        return <div className={cn('TopBarNewTab', className)}>
            <FuseButton
                type="quaternary"
                icon="add"
                title={t(IS_BROWSER_SAFARI ? 'browserTopBar.newTabTitleSafari_ped' : 'browserTopBar.newTabTitle_ped')}
                aria-label={t('browserTopBar.newTabAriaLabel')}
                color={research.researchMode.isEnabled ? 'black' : null}
                {...(isWizard ? { tabIndex: -1 } : {})}
                onClick={this.onClick}
                {...btnProps}
            />
            {TopBarNewTab.getWizard(this.props)}
        </div>;
    }
}
export default translate()(inject('browser', 'research')(observer(TopBarNewTab)));
