import React, { Component } from 'react';
import { translate } from 'react-i18next';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ResearchStoreProp } from '../../stores/props';
import UserAgentLabel from '../UserAgentMenu/UserAgentLabel';
import UserAgentMenu from '../UserAgentMenu/UserAgentMenu';
import FuseButton from '../FuseControls/FuseButton';
import BrowserStore from '../../browser/stores/BrowserStore';
import './TopBarResearchUA.css';

class TopBarResearchUA extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        research: ResearchStoreProp,
        onRefresh: PropTypes.func,
        className: PropTypes.string,
        btnProps: PropTypes.shape(FuseButton.propTypes),
    };
    commonProps() {
        const { research, t } = this.props;
        return {
            onClick: research.userAgentMenu.toggle,
            title: t('browserTopBar.menuButtonTitle'),
        };
    }
    getModal() {
        return this.props.research.userAgentMenu.isOpen
            ? <UserAgentMenu afterChange={this.props.onRefresh} />
            : null;
    }
    renderAlternative() {
        const { browser, btnProps, className } = this.props;
        const isTopBarMinified = browser.isTopBarMinificationAllowed();

        return <div className={cn('TopBarResearchUA', className)} >
            <FuseButton
                type="quaternary"
                color="black"
                iconRight="arrow_drop_down"
                {...this.commonProps()}
                extraSmall={isTopBarMinified}
                {...btnProps}
            >
                {this.getLabel()}
            </FuseButton>
            {this.getModal()}
        </div>;
    }
    getLabel() {
        return <UserAgentLabel />;
    }
    render() {
        return this.renderAlternative();
    }
}
export default translate()(inject('env', 'research', 'browser')(observer(TopBarResearchUA)));
