import throttle from 'lodash/throttle';
import debug from '../../debug';
import reg from '../DOMRegistry';
import AbstractWatcher from './AbstractWatcher';
import { TAG_AUDIO, TAG_VIDEO } from '../DOM';
import { F_VALUE } from '../sharedConstants';
import { equals } from './../../float';

const logger = debug.create('PlaybackWatcher', false);
const metaDataByUid = {};

class MediaUpdateTransaction {
    static getTransaction(uid) {
        return metaDataByUid[uid] && metaDataByUid[uid].transaction;
    }
    static hasTransaction(uid) {
        return !!MediaUpdateTransaction.getTransaction(uid);
    }

    static cancelCurrentTransaction(uid) {
        const transaction = MediaUpdateTransaction.getTransaction(uid);
        if (transaction) {
            transaction.destroy();
        }
    }

    static performTransaction(node, time, shouldPlay, playbackRate, callback) {
        const uid = reg.getUid(node);
        MediaUpdateTransaction.cancelCurrentTransaction(uid);
        const transaction = new MediaUpdateTransaction(node, uid);
        transaction.execute(time, shouldPlay, playbackRate, callback);
    }

    constructor(node, nodeUid) {
        this.node = node;
        this.nodeUid = nodeUid;
        this.isDestroyed = false;
        this.seekingHappenedOnce = false;
        this.currentEvents = [];
        metaDataByUid[this.nodeUid].transaction = this;
    }

    execute(time, shouldPlay, playbackRate, callback) {
        this.setTime(time, () => {
            this.setPlaybackRate(playbackRate, () => {
                this.setPlayingState(shouldPlay, (success) => {
                    // wait for possible events to happend
                    // and listeners in watcher to be blocked
                    // and then finish transaction
                    setTimeout(() => {
                        if (this.isDestroyed) {
                            return;
                        }

                        this.destroy();
                        callback(success);
                    }, 10);
                });
            });
        });
    }

    addEventListener(eventName, callback, capture = false) {
        this.node.addEventListener(eventName, callback, capture);
        this.currentEvents.push(() => this.node.removeEventListener(eventName, callback, capture));
    }

    removeAllListeners() {
        this.currentEvents.forEach((unlisten) => unlisten());
        this.currentEvents = [];
    }

    waitForMetaData(callback) {
        // IE11 will fire an exception if readyState is HAS_NOTHING,
        // at least metadata required to change currentTime
        if (this.node.readyState === 0) {
            this.addEventListener('loadedmetadata', () => {
                this.removeAllListeners();
                callback();
            });
        } else {
            callback();
        }
    }

    setPlaybackRate(playbackRate, callback) {
        if (this.isDestroyed) {
            return;
        }

        if ((playbackRate === undefined) || (playbackRate === this.node.playbackRate)) {
            callback();
            return;
        }

        this.waitForMetaData(() => {
            this.addEventListener('ratechange', () => {
                this.removeAllListeners();
                callback();
            });

            this.node.playbackRate = playbackRate;
        });
    }

    setTime(time, callback) {
        if (this.isDestroyed) {
            return;
        }
        if ((time === undefined) || (time === this.node.currentTime)) {
            callback();
            return;
        }
        this.waitForMetaData(() => {
            this.addEventListener('seeking', () => {
                this.seekingHappenedOnce = true;
            });
            this.addEventListener('seeked', () => {
                this.removeAllListeners();
                callback();
            });

            this.node.currentTime = time;
        });
    }

    setPlayingState(shouldPlay, callback) {
        const uid = reg.getUid(this.node);
        if (this.node.paused && shouldPlay) {
            // "play" event happens only once in IE11, playing happens everytime
            this.addEventListener('playing', () => {
                logger.info('play success!', this.node.getAttribute('wid'));
                this.removeAllListeners();
                callback(true);
            });
            const promise = this.node.play();

            if (promise) {
                this.removeAllListeners();
                promise.then(() => {
                    if (!this.isDestroyed) {
                        logger.info('play success', uid);
                        setTimeout(callback, 0, true);
                    }
                }).catch((e) => {
                    if (!this.isDestroyed) {
                        logger.info('play failure', uid);
                        logger.error(e);
                        this.removeAllListeners();
                        this.node.pause();
                        setTimeout(callback, 0, false);
                    }
                });
            }
        } else if (!this.node.paused && !shouldPlay) {
            this.addEventListener('pause', () => {
                this.removeAllListeners();
                callback(true);
            });
            this.node.pause();
        } else {
            logger.log('Ignore play set', shouldPlay, uid);
            callback(true);
        }
    }

    destroy() {
        if (this.isDestroyed) {
            logger.warn('destroyed twice');
            return;
        }
        this.removeAllListeners();
        this.isDestroyed = true;
        if (metaDataByUid[this.nodeUid].transaction === this) {
            metaDataByUid[this.nodeUid].transaction = null;
        }
        this.node = null;
    }
}

// we disabled autoplay policies on server browser
// in order to rely only clients autoplay policies.
// this allows us to await for user gestures only on client
// if client blocked "autoplaying" video
function waitForClick(node) {
    const uid = reg.getUid(node);
    if (uid && metaDataByUid[uid] && metaDataByUid[uid].awaitingClick) {
        return;
    }

    const doc = node.ownerDocument;
    metaDataByUid[uid].awaitingClick = true;

    const onNextUserAction = () => {
        reg.removeEventListener(doc, 'mousedown', onNextUserAction, false);
        reg.removeEventListener(doc, 'touchstart', onNextUserAction, false);

        if (!metaDataByUid[uid]) {
            return;
        }

        metaDataByUid[uid].awaitingClick = false;
        if (node.isConnected && node.paused) {
            metaDataByUid[uid].ignorePlayingEvent = true;

            MediaUpdateTransaction.performTransaction(
                node,
                node.currentTime || 0,
                true,
                node.playbackRate || 1,
                (success) => {
                    metaDataByUid[uid].ignorePlayingEvent = false;

                    if (success) {
                        MediaUpdateTransaction.performTransaction(
                            node,
                            node.currentTime || 0,
                            false,
                            node.playbackRate || 1,
                            () => {
                                metaDataByUid[uid].clickCounter = 0;
                                logger.info(
                                    'autoplay block fixed, next play command from server will start this video',
                                    node
                                );
                            });
                    } else if (metaDataByUid[uid].clickCounter < 5) {
                        logger.info('autoplay block not fixed', node);
                        waitForClick(node);
                    } else {
                        logger.error('cant play video even with autoplay fix');
                    }
                }
            );
        }
    };

    reg.addEventListener(doc, 'mousedown', onNextUserAction, false);
    reg.addEventListener(doc, 'touchstart', onNextUserAction, false);
}

export class PlaybackWatcher extends AbstractWatcher {
    shouldWatch(node, tag) {
        return tag === TAG_VIDEO || tag === TAG_AUDIO;
    }

    beforeUnregister(node, uid) {
        MediaUpdateTransaction.cancelCurrentTransaction(uid);
        delete metaDataByUid[uid];
    }

    compareValues(newValue, oldValue) {
        return newValue.isPlaying === oldValue.isPlaying
            && newValue.time === oldValue.time
            && newValue.sourceRefresh === oldValue.sourceRefresh
            && newValue.playbackRate === oldValue.playbackRate;
    }

    getValue(node) {
        return {
            sourceRefresh: node.sourceRefresh || 0,
            isPlaying: !node.paused,
            time: node.currentTime,
            playbackRate: node.playbackRate,
        };
    }

    afterRegisterOnClient(node, uid) {
        // we want to sync currentTime on server after it started on client,
        // coz client donwloads content slower then server,
        // and it will be better if it will be finished on client before finished on server
        reg.addEventListener(node, 'playing', () => {
            logger.info('event', 'playing', uid);
            if (!metaDataByUid[uid].ignorePlayingEvent && !MediaUpdateTransaction.hasTransaction(uid)) {
                this.checkNode(node);
            }
        });

        reg.addEventListener(node, 'ended', () => {
            logger.info('event', 'ended', uid);
            if (node.ended && !node.paused) {
                MediaUpdateTransaction.performTransaction(node, node.currentTime, false, node.playbackRate, () => {
                    // natively IE11 does not pause video after finish
                    logger.info('forced pause after end');
                });
            }
        });
    }

    afterRegisterOnServer(node, uid) {
        const meta = metaDataByUid[uid];
        reg.addEventListener(node, 'playing', () => {
            logger.info('event', 'playing', uid);
            if (!MediaUpdateTransaction.hasTransaction(uid)) {
                if (meta.waitingProgressOnSrcChange) {
                    meta.waitingProgressOnSrcChange = false;
                    node.sourceRefresh = Date.now();
                }
                this.checkNode(node);
            }
        });
        reg.addEventListener(node, 'emptied', () => {
            logger.info('event', 'emptied', uid);
            meta.waitingProgressOnSrcChange = true;
            node.sourceRefresh = Date.now();
            this.checkNode(node);
        });
    }

    afterRegister(node, uid) {
        this.valueByUid[uid] = {};
        metaDataByUid[uid] = {
            transaction: null,
            ignorePlayingEvent: false,
            awaitingClick: false,
            waitingProgressOnSrcChange: false,
            clickCounter: 0,
        };

        const throttledCheck = throttle(() => {
            this.checkNode(node);
        }, 250, { leading: false, trailing: true });

        if (this.IS_CLIENT) {
            this.afterRegisterOnClient(node, uid);
        } else if (this.IS_EXTENSION) {
            this.afterRegisterOnServer(node, uid);
        }

        const listener = (event) => {
            logger.info('event', event.type, uid);
            // we are ignoring most events caused by transaction
            if (!MediaUpdateTransaction.hasTransaction(uid)) {
                throttledCheck.cancel();
                this.checkNode(node);
            }
        };

        // both events could happen on client caused by user actions (e.g. media controls, touchbar, context menu)
        ['pause', 'seeked', 'ratechange'].forEach((eventName) => {
            reg.addEventListener(node, eventName, listener);
        });

        reg.addEventListener(node, 'seeking', () => {
            logger.info('event', 'seeking', uid);
            const transaction = MediaUpdateTransaction.getTransaction(uid);
            if (!transaction || transaction.seekingHappenedOnce) {
                if (transaction) {
                    // one seeking event before seeked event
                    // otherwise destroy transaction
                    logger.info('seeking happened > once');
                    transaction.destroy();
                }
                throttledCheck();
            }
        });

        reg.addEventListener(node, 'error', (event) => {
            logger.error(event, { node, uid, event });
            this.checkNode(node);
        });
    }

    setValueOnServer(node, uid, newValue) {
        const currentValue = this.valueByUid[uid] || {};
        logger.info('setValue', newValue, { ...currentValue }, uid);

        // ignore value, coz client sent a change event
        // while server already sent change event for a new source
        if (currentValue.sourceRefresh !== newValue.sourceRefresh) {
            return;
        }
        MediaUpdateTransaction.performTransaction(
            node,
            newValue.time,
            newValue.isPlaying,
            newValue.playbackRate,
            (success) => {
                logger.info('success?', node, success);
                // it should work each time on server
                // coz we are disabling auto play policites on server browser
                // but just in case something is broken in video stream
                if (!success) {
                    this.checkNode(node, true);
                }
            }
        );
        Object.assign(currentValue, newValue);
    }

    ignoreSetValuePauseCall(node, newValue) {
        return node.playbackRate === newValue.playbackRate &&
            (node.sourceRefresh || 0) === newValue.sourceRefresh &&
            !node.paused &&
            !newValue.isPlaying &&
            equals(newValue.time, node.duration) &&
            // undeterministic - anything over 0.2 we will respect and allow it through
            // NOTE: allowing it through is fine in general, except for Chrome, where
            // setting the currentTime to the duration (e.g. to the max time) breaks the internal state of some mp3s
            // thus causing them to no longer be playable. See ISO-2246 for details
            Math.abs(newValue.time - node.currentTime) < 0.2;
    }

    setValueOnClient(node, uid, newValue) {
        const currentValue = this.valueByUid[uid] || {};
        logger.info('setValue', newValue, { ...currentValue }, uid);

        const setNewValue = () => MediaUpdateTransaction.performTransaction(
            node,
            newValue.time,
            newValue.isPlaying,
            newValue.playbackRate,
            (success) => {
                node.sourceRefresh = newValue.sourceRefresh;

                if (!success) {
                    waitForClick(node);
                    this.checkNode(node, true);
                }
            }
        );

        // source changed — reset playback state first
        if (currentValue.sourceRefresh !== newValue.sourceRefresh) {
            MediaUpdateTransaction.performTransaction(node, 0, false, 1, setNewValue);
        } else {
            setNewValue();
        }
        Object.assign(currentValue, newValue);
    }

    setValue(node, uid, newValue) {
        if (this.ignoreSetValuePauseCall(node, newValue)) {
            logger.info('Ignoring transaction, letting video finish playback instead');
            // Note - once the video finishes playing, it will pause on it's own, and will have the same time
            // as the one in the newValue
            return;
        }
        if (this.IS_EXTENSION) {
            this.setValueOnServer(node, uid, newValue);
        } else if (this.IS_CLIENT) {
            this.setValueOnClient(node, uid, newValue);
        }
    }

    onDisconnect() {
        Object.values(this.nodeByUid).forEach((node) => {
            if (!node.controls) {
                MediaUpdateTransaction.performTransaction(node, node.currentTime || 0, false, 1, () => {
                    logger.log('paused');
                });
            }
        });
    }
}

export default new PlaybackWatcher(F_VALUE.playback, false);
