import { action, extendObservable } from 'mobx';
import PropTypes from 'prop-types';

export default class InputStore {
    static PropType = PropTypes.shape({
        // TODO: String only
        value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
            PropTypes.bool.isRequired,
        ]),
        errorMessage: PropTypes.string,

        isTouched: PropTypes.bool.isRequired,
        isValid: PropTypes.bool.isRequired,
        isEmpty: PropTypes.bool.isRequired,

        setRef: PropTypes.func.isRequired,
        onChangeHandler: PropTypes.func.isRequired,
        setValue: PropTypes.func.isRequired,

        equals: PropTypes.func.isRequired,
    });

    constructor(data = {}) {
        this.ref = null;
        this.validator = () => true;

        extendObservable(this, /** @class InputStore */{
            value: '',
            isTouched: false,
            isValid: true,
            errorMessage: '',

            get isEmpty() {
                return !this.value;
            },

            equals: (value) => this.value === String(value),
        }, data);
    }

    setRef = (newRef) => {
        this.ref = newRef;
    };

    focus = () => {
        if (this.ref && this.ref.focus) {
            this.ref.focus();
        }
    };

    blur = () => {
        if (this.ref && this.ref.blur) {
            this.ref.blur();
        }
    };

    onChangeHandler = ({ target }) => {
        this.setValue(target.value);
    };

    setValue = action((newValue) => {
        if (newValue === undefined || newValue === null) {
            newValue = '';
        }
        this.value = String(newValue);
        this.isTouched = true;
        const validationResult = this.validator(this.value);
        this.errorMessage = typeof validationResult === 'string' ? validationResult : '';
        this.isValid = validationResult === true;
    });

    getValue = () => this.value;

    setTouched = action((value) => {
        this.isTouched = value;
    });

    reset = action(() => {
        this.value = '';
        this.isTouched = false;
    });

    setValidator = action((validator) => {
        this.validator = validator;
        this.isValid = this.validator(this.value) === true;
    });
}
