import b from 'b_';
import React from 'react';
import ReactDOM from 'react-dom';
import { Transforms } from 'slate';
import { useFocused, useSelected, useSlateStatic, useReadOnly, ReactEditor } from 'slate-react';
import { FTBody, FTHeadline, FTTitle } from '../Text';
import FuseButton from '../../FuseControls/FuseButton';

const block = b.lock('FuseWysiwyg');

/* eslint-disable react/prop-types */
const Image = ({ attributes, element, children }) => {
    const selected = useSelected();
    const focused = useFocused();
    const editor = useSlateStatic();
    const readOnly = useReadOnly();

    const path = ReactEditor.findPath(editor, element);
    const highlight = selected && focused && !readOnly;
    const src = element.src?.startsWith('data:') ? element.src : null;

    return <div
        {...attributes}
        className={block('Image', { highlight, align: element.align })}
    >
        <div contentEditable={false} className={block('ImageWrap')}>
            <img
                alt={element.alt}
                src={src}
                className={block('ImageTag')}
            />

            {selected && !readOnly
                ? <FuseButton
                    className={block('ImageDeleteButton')}
                    icon="delete"
                    title="Delete the image"
                    onClick={() => Transforms.removeNodes(editor, { at: path })}
                />
                : null}
        </div>
        {children}
    </div>;
};

class LinkPopover extends React.Component {
    state = {
        rootFocused: true,
    };

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.el.style.setProperty('position', 'absolute');
        this.ref = React.createRef();
    }

    updatePosition() {
        const bounds = this.ref.current?.getBoundingClientRect();
        const rootBounds = this.root.getBoundingClientRect();
        const firstElement = this.el?.firstElementChild;

        if (bounds && firstElement) {
            firstElement.style.setProperty('max-width', (rootBounds.width - 4) + 'px');

            const popoverBounds = firstElement.getBoundingClientRect();

            const left = Math.min(bounds.left - rootBounds.left, rootBounds.width - popoverBounds.width - 2);
            const top = bounds.bottom - rootBounds.top;
            this.el.style.setProperty('top', top + 'px');
            this.el.style.setProperty('left', left + 'px');
        }
    }

    onDocClick = ({ target }) => this.setState({ rootFocused: this.root.contains(target) });

    removeEl = () => {
        if (this.root) {
            this.root.ownerDocument.removeEventListener('click', this.onDocClick, true);
            this.root.removeChild(this.el);
        }
    };

    updateRoot() {
        this.removeEl();
        this.root = this.props.root || document.body;
        this.root.appendChild(this.el);
        this.root.ownerDocument.addEventListener('click', this.onDocClick, true);
    }

    componentDidMount() {
        this.updateRoot();
        this.updatePosition();
    }

    componentWillUnmount() {
        this.removeEl();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.root !== this.props.root) {
            this.updateRoot();
        }

        if (this.props.isOpen) {
            this.updatePosition();
        }
    }

    render() {
        return <div ref={this.ref} contentEditable={false}>
            {/* show only when the Editor is in focus and the Link Element is selected */}
            {this.props.isOpen && this.state.rootFocused ? ReactDOM.createPortal(this.props.children, this.el) : null}
        </div>;
    }
}

/* eslint-disable react/prop-types */
const Link = ({ attributes, element, children, root }) => {
    const selected = useSelected();
    const editor = useSlateStatic();
    const readOnly = useReadOnly();

    const path = ReactEditor.findPath(editor, element);

    const href = /^https?:\/\//i.test(element.href || '')
        ? element.href : 'https://' + element.href;

    return <div className={block('Link')}>
        <a
            {...attributes}
            href={href}
            target="_blank"
            title={element.href}
        >{children}</a>

        {!readOnly ? <LinkPopover isOpen={selected} root={root}>
            <div className={block('LinkDropdown')}>
                <a
                    className={block('LinkPopoverElement')}
                    href={href}
                    target="_blank"
                    title={element.href}
                >{element.href}</a>
                <FuseButton
                    icon="edit"
                    title="Change the URL"
                    className={block('LinkEditButton')}
                    onClick={() => {
                        // eslint-disable-next-line no-alert
                        let newHref = window.prompt('Enter the URL of the link:', element.href);
                        newHref = (newHref || '').trim();
                        if (newHref) {
                            Transforms.setNodes(editor, { href: newHref }, { at: path });
                        }
                    }}
                />
                <FuseButton
                    icon="link_off"
                    title="Remove the URL"
                    className={block('LinkDeleteButton')}
                    onClick={() => Transforms.unwrapNodes(editor, { at: path })}
                />
            </div>
        </LinkPopover> : null }
    </div>;
};

export const Leaf = ({ attributes, children, leaf }) => {
    const style = attributes?.style ?? {};
    if (leaf.bold) {
        style.fontWeight = 'bold';
    }
    if (leaf.code) {
        style.fontFamily = 'monospace';
    }
    if (leaf.italic) {
        style.fontStyle = 'italic';
    }
    if (leaf.underline) {
        style.textDecoration = 'underline';
    }
    if (leaf.fontSize) {
        style.fontSize = `${leaf.fontSize}px`;
    }
    if (leaf.color) {
        style.color = leaf.color;
    }
    return <span {...attributes} style={style}>{children}</span>;
};

/* eslint-disable react/prop-types */
export const Element = (props) => {
    const { attributes, children, element, root } = props;
    const style = { textAlign: element.align };
    switch (element.type) {
    case 'block-quote':
        return <blockquote style={style} {...attributes}>
            {children}
        </blockquote>;
    case 'bulleted-list':
        return <ul style={style} {...attributes}>
            {children}
        </ul>;
    case 'heading-one':
        return <div {...attributes}>
            <FTHeadline style={style}>{children}</FTHeadline>
        </div>;
    case 'heading-two':
        return <div {...attributes}>
            <FTTitle style={style}>{children}</FTTitle>
        </div>;
    case 'list-item':
        return <li style={style} {...attributes}>
            {children}
        </li>;
    case 'numbered-list':
        return <ol style={style} {...attributes}>
            {children}
        </ol>;
    case 'link':
        return <Link {...props} root={root}>{children}</Link>;
    case 'image':
        return <Image style={style} {...props}>
            {children}
        </Image>;
    default:
        return <div {...attributes}>
            <FTBody style={style}>{children}</FTBody>
        </div>;
    }
};
