import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import b from 'b_';
import cn from 'classnames';
import { observer } from 'mobx-react';
import makeElementId from '../../makeElementId';
import Icon from '../Icon/Icon';
import FlagStore from '../../stores/FlagStore';
import LearnMore from '../FAQ/LearnMore';
import './NotificationContent.css';

const block = b.lock('NotificationContent');

class NotificationContent extends Component {
    contentId = makeElementId('NotificationContent');

    static propTypes = {
        icon: PropTypes.oneOfType([PropTypes.object, Icon.propTypes.type]),
        title: PropTypes.node,
        toggleStore: FlagStore.PropType,
        isDarkIcon: PropTypes.bool,
        learnMoreSection: PropTypes.number,
        type: PropTypes.string,
    };

    onHeadClick = () => {
        const { toggleStore } = this.props;

        if (toggleStore) {
            toggleStore.toggle();
        }
    }

    render() {
        const {
            className,
            icon,
            title,
            toggleStore,
            isDarkIcon,
            learnMoreSection,
            children,
            type,
            ...props
        } = this.props;
        const toggle = !!toggleStore;

        return <div className={cn(block({ toggle }), className)} {...props}>
            {title || icon ? <div
                className={block('Head', { toggle })}
                onClick={this.onHeadClick}
                onKeyPress={this.onHeadClick}
                aria-expanded={toggle && toggleStore.isOpen}
                aria-controls={this.contentId}
                aria-label={title}
                tabIndex="0"
            >
                {icon ? <Icon className={block('TitleIcon', { dark: isDarkIcon, type })} type={icon}/> : null}
                <div className={block('Title', { toggle })}>{title}</div>
                {toggle ? <Icon
                    className={block('ToggleIcon')}
                    type={toggleStore.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                /> : null}
            </div> : null}
            <div
                id={this.contentId}
                aria-hidden={!(toggle && toggleStore.isOpen)}
                className={block('Body', { toggle, expanded: toggle && toggleStore.isOpen })}
            >
                <span>{children}</span>
                {learnMoreSection ? <Fragment>
                    &nbsp;
                    <LearnMore section={learnMoreSection} />
                </Fragment> : null}
            </div>
        </div>;
    }
}

export default observer(NotificationContent);
