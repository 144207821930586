import PropTypes from 'prop-types';
import { action, extendObservable } from 'mobx';
import { apiCall } from '../API';
import { sendToClientWithResponse } from '../mirror/client/browserMessageBus';
import { API_BROWSER_TO_CLIENT } from '../mirror/sharedConstants';
import FuseSelectStore from './FuseSelectStore';
import TextStore from './helpers/TextStore';
import FlagStore from './FlagStore';
import AsyncActionStore from './AsyncActionStore';
import { emailValidator } from '../email';
import eventLogger from '../ClientEventLogger';

export default class SendFeedbackStore {
    static PropType = PropTypes.shape({
        email: TextStore.PropType,
        caseId: PropTypes.string,
        comment: TextStore.PropType,
        type: FuseSelectStore.PropType,
        debugData: PropTypes.object,
        isCustomerSupport: PropTypes.bool,

        isValid: PropTypes.func.isRequired,

        modal: FlagStore.PropType.isRequired,
        exitConfirmationModal: FlagStore.PropType.isRequired,
        submitModal: FlagStore.PropType.isRequired,
    });

    constructor(data = {}) {
        extendObservable(this, /** @class SendFeedbackStore */{
            getFrameEl: () => null,
            email: new TextStore(),
            caseId: null,
            comment: new TextStore(),
            type: new FuseSelectStore({ options: [] }),
            isCustomerSupport: false,
            // retry directly through mirror in case something went wrong, e.g. there was no iframe -
            // we are on the homepage, or
            // there was some different error.
            send: (info) => this.wsSend(info).catch(() => this.apiSend(info)),
            apiSend: apiCall('/feedback/send'),
            wsSend: (info) => sendToClientWithResponse(this.getFrameEl(), API_BROWSER_TO_CLIENT.forwardFeedback, info),
            sendStatus: new AsyncActionStore(),

            isValid() {
                return !!this.comment.getValue()
                    && (this.isCustomerSupport ? this.email.isValid() : !this.type.isEmpty());
            },

            hasChanged() {
                return this.comment.getValue()
                    || (this.isCustomerSupport ? this.email.getValue() : !this.type.isEmpty());
            },

            modal: new FlagStore(false),
            exitConfirmationModal: new FlagStore(false),
            submitModal: new FlagStore(false),
        }, data);

        this.email.setValidator(emailValidator);
    }

    openCustomerSupportModal = action((caseId) => {
        this.caseId = caseId;
        this.isCustomerSupport = true;
        this.modal.open();
    });

    openProductFeedbackModal = action(() => {
        this.isCustomerSupport = false;
        this.modal.open();
    });

    closeModal = action(() => {
        if (this.isCustomerSupport || this.hasChanged()) {
            this.exitConfirmationModal.open();
        } else {
            this.clearAndCloseModal();
        }
    });

    clearAndCloseModal = action(() => {
        this.email.setValue('');
        this.comment.setValue('');
        this.type.setSelectedValues([]);
        this.caseId = null;
        this.modal.close();
    });

    toObject = () => ({
        type: this.isCustomerSupport ? 'Customer Support' : this.type.getFirstValueOrEmpty(),
        email: this.email.getValue(),
        caseId: this.caseId,
        comment: this.comment.getValue(),
        debugData: JSON.stringify(this.debugData),
    });

    sendFeedback = action(() => {
        if (!this.sendStatus.isLoading) {
            if (!this.isValid()) {
                return Promise.reject();
            }
            eventLogger.sendEvent(eventLogger.EVENT_CLIENT_FEEDBACK, {
                feedbackType: this.type.getFirstValueOrEmpty(),
                feedback: this.comment.getValue(),
            });
            return this.sendStatus
                .setLoading(this.send(this.toObject()))
                .then((result) => {
                    this.submitModal.open();
                    this.clearAndCloseModal();
                    return result;
                });
        }
        return this.sendStatus.promise;
    });
}
