import { action, extendObservable, reaction } from 'mobx';
import PropTypes from 'prop-types';
import { DEFAULT_EGRESS_ID } from '../../mirror/sharedConstants';
import LocalStorage, { LS_KEY } from '../../LocalStorage';
import syncStoreWithLocalStorage from '../../stores/helpers/syncStoreWithLocalStorage';
import { userAgentInfo } from '../../userAgents';
import FlagStore from '../../stores/FlagStore';

const defaultLocation = DEFAULT_EGRESS_ID;

export default class ResearchStore {
    static PropType = PropTypes.shape({
        researchMode: FlagStore.PropType,
        location: PropTypes.string.isRequired,
        setLocation: PropTypes.func.isRequired,
        locationMenu: FlagStore.PropType,
        userAgent: PropTypes.string,
        setUserAgent: PropTypes.func.isRequired,
        userAgentMenu: FlagStore.PropType,
        getUserAgentInfo: PropTypes.func.isRequired,
    });

    constructor(data = {}, useLocalStorage = true) {
        this.useLocalStorage = useLocalStorage;
        const sync = this.useLocalStorage ? syncStoreWithLocalStorage : (x) => x;

        extendObservable(this, /** @class ResearchStore */ {
            researchMode: sync(
                new FlagStore(false, { trackDirty: true }),
                LS_KEY.researchMode
            ),
            location: this.getDefault(LS_KEY.researchLocation, defaultLocation),
            locations: [],
            getLocationById: (id) => this.locations.find((x) => x.id === id),

            setLocation: action((newValue) => {
                if (!newValue || !this.getLocationById(newValue)) {
                    return;
                }

                this.location = newValue;
            }),

            locationMenu: new FlagStore(false),

            userAgent: this.getDefault(LS_KEY.researchUserAgent, null),
            setUserAgent: action((newValue) => {
                this.userAgent = newValue;
            }),
            userAgentMenu: new FlagStore(false),

            get isFocused() {
                return this.locationMenu.isOpen
                    || this.userAgentMenu.isOpen;
            },
        }, data);

        this.locations.unshift({
            id: defaultLocation,
            flag: 'us',
            name: 'Default Location',
        });

        if (!this.getLocationById(this.location)) {
            this.setLocation(defaultLocation);
        }

        reaction(() => this.researchMode.value, (newValue) => {
            if (this.useLocalStorage) {
                LocalStorage.set(LS_KEY.researchMode, newValue);
            }
        });

        reaction(() => this.location, (newValue) => {
            if (this.useLocalStorage) {
                LocalStorage.set(LS_KEY.researchLocation, newValue);
            }
        });

        reaction(() => this.userAgent, (newValue) => {
            if (this.useLocalStorage) {
                LocalStorage.set(LS_KEY.researchUserAgent, newValue);
            }
        });

        if (useLocalStorage) {
            window.addEventListener('storage', (event) => {
                if (event.key === LS_KEY.researchMode) {
                    this.researchMode.setEnabled(LocalStorage.get(LS_KEY.researchMode, false));
                }
                if (event.key === LS_KEY.researchUserAgent) {
                    this.setUserAgent(LocalStorage.get(LS_KEY.researchUserAgent));
                }
                if (event.key === LS_KEY.researchLocation) {
                    this.setLocation(LocalStorage.get(LS_KEY.researchLocation));
                }
            });
        }
    }

    getDefault(lsKey, fallback) {
        return this.useLocalStorage
            ? LocalStorage.get(lsKey, fallback)
            : fallback;
    }

    getUserAgentInfo(ua) {
        if (userAgentInfo[ua] === undefined) {
            return { custom: true };
        }
        return userAgentInfo[ua];
    }
}
