import PropTypes from 'prop-types';
import { extendObservable, action } from 'mobx';
import { IS_TOUCH_DEVICE } from '../env';

function isMobileScreenMediaQuery(offset = 0) {
    return window.matchMedia(`(max-width: ${640 + offset}px)`);
}

function isTabletScreenMediaQuery(offset = 0) {
    return window.matchMedia(`(min-width: ${640 + offset}px) and (max-width: ${960 + offset}px)`);
}
function isSmallDesktopScreenMediaQuery(offset = 0) {
    return window.matchMedia(`(min-width: ${960 + offset}px) and (max-width: ${1080 + offset}px)`);
}
export default class EnvStore {
    static PropType = PropTypes.shape({
        mightBeCustomerAdmin: PropTypes.bool.isRequired,
        isBrowserDefense: PropTypes.bool.isRequired,
        isWebmailDefense: PropTypes.bool.isRequired,
        isUrlIsolation: PropTypes.bool.isRequired,
        isSaasIsolation: PropTypes.bool.isRequired,
    });

    constructor(data = {}) {
        extendObservable(this, /** @class EnvStore */{
            sessionId: '',
            customerId: null,
            mightBeCustomerAdmin: false,
            isBrowserDefense: true,
            isWebmailDefense: false,
            isUrlIsolation: false,
            isSaasIsolation: false,

            hasOpener: !!window.top.opener,
            canGoBack: window.top.history.length > 1,
            mirrorFeatureFlags: [],

            screenWidthOffset: 0, // for previews in console
            screen: {
                isMobile: isMobileScreenMediaQuery(this.screenWidthOffset).matches,
                isTablet: isTabletScreenMediaQuery(this.screenWidthOffset).matches,
                isSmallDesktop: isSmallDesktopScreenMediaQuery(this.screenWidthOffset).matches,
            },

            isTouchDevice: IS_TOUCH_DEVICE,

            attachListeners: () => {
                isMobileScreenMediaQuery(this.screenWidthOffset).addListener(action((evt) => {
                    this.screen.isMobile = evt.matches;
                }));

                isTabletScreenMediaQuery(this.screenWidthOffset).addListener(action((evt) => {
                    this.screen.isTablet = evt.matches;
                }));

                isSmallDesktopScreenMediaQuery(this.screenWidthOffset).addListener(action((evt) => {
                    this.screen.isSmallDesktop = evt.matches;
                }));
            },
        }, data);

        this.attachListeners();
    }
}
