import PropTypes from 'prop-types';
import React from 'react';
import b from 'b_';
import cn from 'classnames';
import './Bg.css';

// TODO: draw mesh programmatically
export default function Bg({ type, className, children, ...otherProps }) {
    return <div
        className={cn(b.lock('Bg')({ type }), className)}
        {...otherProps}
    >{children}</div>;
}

Bg.propTypes = {
    type: PropTypes.oneOf(['none', 'bottom', 'center']),
};

Bg.defaultProps = {
    type: 'bottom',
};
