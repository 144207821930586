import PropTypes from 'prop-types';
import React from 'react';
import b from 'b_';
import cn from 'classnames';
import LoaderBar from '../Loaders/LoaderBar';
import './ScanStatus.css';

const block = b.lock('ScanStatus');

const ScanStatus = (props) => {
    const { wrapperClassName, loader } = props;
    return <div className={cn(block(), wrapperClassName)}>
        <div className={block('Icon')}>{props.iconLeft}</div>
        <div className={block('Content')}>
            <div className={block('TextContainer')}>
                <div className={block('Text')}>{props.textLeft}</div>
                <div className={block('Text')}>{props.textRight}</div>
            </div>
            <LoaderBar {...loader} />
        </div>
        <div className={cn(block('Icon', { right: true }))}>{props.iconRight}</div>
    </div >;
};

ScanStatus.propTypes = {
    wrapperClassName: PropTypes.string,
    textLeft: PropTypes.string.isRequired,
    textRight: PropTypes.string.isRequired,
    iconLeft: PropTypes.element.isRequired,
    iconRight: PropTypes.element,
    loader: PropTypes.shape(LoaderBar.propTypes),
};

export default ScanStatus;
