import debug from '../debug';

const MAX_BUFFER_SIZE = 10000;

export default class UncommittedBuffer {
    logger = null;
    buffer = [];
    currentMessageId = 0;
    committedId = 0;

    constructor(pageId) {
        this.logger = debug.create('UncommittedBuffer', false, pageId);
    }

    getNextId() {
        this.currentMessageId += 1;
        return this.currentMessageId;
    }

    add(message) {
        if (this.buffer.length >= MAX_BUFFER_SIZE) {
            this.logger.error('Buffer overflow');
            return false;
        }
        this.buffer.push(message);
        return true;
    }

    commit(id) {
        if (id <= this.committedId) {
            return;
        }
        this.logger.info('Committing', id);
        this.buffer = this.buffer.slice(id - this.committedId);
        this.committedId = id;
    }

    getAll() {
        return this.buffer.slice();
    }

    toJSON() {
        return {
            ...this,
            buffer: this.buffer.map((item) => [item[0], item[2]]),
        };
    }
}
