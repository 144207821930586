import debug from '../../debug';
import { detachNodeAndPutItBackWhereItWas } from '../../DOMUtils';
import LockedQueue from '../LockedQueue';
import EventLogger from '../../ClientEventLogger';
import SourceBufferProxy from './SourceBufferProxy';

const logger = debug.create('MediaSourceProxy');

export default class MediaSourceProxy {
    constructor(id, actionId, onDestroy, frame) {
        this.frame = frame;
        this.id = id;
        this.lockedQueue = new LockedQueue();
        this.onDestroy = onDestroy;
        this.actionsById = {};
        this.nextActionId = actionId;
        this.buffers = new Map();
        this.bufferWaitingTimer = 0;

        this.mediaSource = new frame.doc.defaultView.MediaSource();

        this.onOpenHandler = () => {
            if (this.onOpenQueue) {
                this.onOpenQueue.forEach((callback) => callback());

                this.onOpenQueue = null;
            }
        };

        this.onCloseHandler = () => this.destroy();
        this.mediaSource.addEventListener('sourceclose', this.onCloseHandler);
        this.mediaSource.addEventListener('sourceopen', this.onOpenHandler);

        logger.log('MediaSourceProxy.constructor', this, this.mediaSource);
    }

    setNode(node) {
        const mediaNode = node?.closest('audio, video') ?? node;
        const isPaused = mediaNode.paused && !mediaNode.ended;

        node.src = this.frame.doc.defaultView.URL.createObjectURL(this.mediaSource);
        // video does not start playing if you render <video><source></video> and some time later set src on source
        detachNodeAndPutItBackWhereItWas(node);

        if (!isPaused) {
            const onFirstProgress = () => {
                mediaNode.removeEventListener('progress', onFirstProgress, false);
                if (this.mediaSource) {
                    const promise = mediaNode.play();

                    if (promise) {
                        promise.catch(debug.warn);
                    }
                }
            };

            mediaNode.addEventListener('progress', onFirstProgress, false);
        }

        if (logger.isEnabled()) {
            node.setAttribute('weblife-media-source', this.id);
        }
    }

    destroy() {
        if (this.isDestroyed) {
            return;
        }

        logger.log('MediaSourceProxy.destroy', this.id);

        this.mediaSource.removeEventListener('sourceopen', this.onOpenHandler);
        this.mediaSource.removeEventListener('sourceclose', this.onCloseHandler);
        this.buffers.forEach((bufferProxy) => bufferProxy.destroy());
        this.lockedQueue.destroy();
        this.onOpenQueue = null;
        this.frame = null;
        this.isDestroyed = true;
        this.actionsById = null;
        this.buffers = null;
        this.mediaSource = null;
        this.lockedQueue = null;
        clearTimeout(this.bufferWaitingTimer);

        this.onDestroy();
    }

    onBufferDestroy(sourceBufferProxy) {
        if (this.isDestroyed) {
            return;
        }

        try {
            this.buffers.delete(sourceBufferProxy.id);
            this.mediaSource.removeSourceBuffer(sourceBufferProxy.sourceBuffer);
        } catch (e) {
            logger.error(e);
        }
    }

    onAction(mediaAction) {
        if (this.isDestroyed) {
            logger.warn('MediaSourceProxy.onAction: it is destroyed');
            return;
        }

        this.actionsById[mediaAction.actionId] = mediaAction;

        while (this.actionsById[this.nextActionId]) {
            if (this.isDestroyed) {
                return;
            }

            const currentAction = this.actionsById[this.nextActionId];
            delete this.actionsById[this.nextActionId];

            this.nextActionId += 1;
            // logger.log('MediaSourceProxy.onAction (progress)', this.nextActionId);

            this.tryNext(currentAction);
        }
    }

    whenOpened(callback) {
        if (this.isDestroyed) {
            return;
        }

        if (this.mediaSource.readyState !== 'open' && !this.onOpenQueue) {
            this.onOpenQueue = [];
        }

        if (this.onOpenQueue) {
            this.onOpenQueue.push(callback);
        } else {
            callback();
        }
    }

    whenBuffersAreNotUpdating(callback) {
        if (this.isDestroyed) {
            return;
        }

        const buffers = Array.from(this.buffers.values());
        if (buffers.some((buffer) => buffer.isUpdating())) {
            this.bufferWaitingTimer = setTimeout(() => {
                this.whenBuffersAreNotUpdating(callback);
            }, 10);
            return;
        }

        callback();
    }

    tryNext({ type, mseId, action, parentId, actionId }) {
        if (this.isDestroyed) {
            return;
        }

        this.whenOpened(() => {
            if (parentId) {
                const sourceBufferProxy = this.buffers.get(mseId);

                if (sourceBufferProxy) {
                    sourceBufferProxy.preloadFromAction(type, action, actionId);

                    this.lockedQueue.add((unlock) => sourceBufferProxy.tryNext(
                        { type, action, actionId },
                        unlock
                    ));
                }
                return;
            }

            if (type === 'construct') {
                return;
            }

            if (type === 'set') {
                this.lockedQueue.add((unlock) => {
                    this.whenBuffersAreNotUpdating(() => {
                        logger.log('MediaSourceProxy.set', this.id, action.key, action.value);
                        this.mediaSource[action.key] = action.value;
                        unlock();
                    });
                });
                return;
            }

            if (type === 'endOfStream') {
                this.lockedQueue.add((unlock) => {
                    this.whenBuffersAreNotUpdating(() => {
                        logger.log('MediaSourceProxy.endOfStream', this.id);
                        this.mediaSource.endOfStream(...action.args);
                        unlock();
                    });
                });
                return;
            }

            if (type === 'failedToCreateSourceBuffer') {
                EventLogger.recordMediaEvent({
                    type: 'failedToCreateSourceBuffer',
                    codec: action.codec,
                });
                return;
            }

            if (type === 'addSourceBuffer') {
                this.lockedQueue.add((unlock) => {
                    try {
                        if (this.mediaSource.readyState === 'open') {
                            const sourceBuffer = this.mediaSource.addSourceBuffer(action.codec);

                            this.buffers.set(action.id, new SourceBufferProxy(
                                action.id, action.codec, sourceBuffer, this
                            ));
                        }
                    } catch (e) {
                        EventLogger.recordMediaEvent({
                            type: 'addSourceBufferError',
                            message: e.message,
                            actionId: action.id,
                            codec: action.codec,
                        });
                    }

                    unlock();
                });
                return;
            }

            if (type === 'removeSourceBuffer') {
                this.lockedQueue.add((unlock) => {
                    logger.log('MediaSourceProxy.removeSourceBuffer', action.id);
                    try {
                        const bufferProxy = this.buffers.get(action.id);

                        if (bufferProxy) {
                            bufferProxy.destroy();
                        }
                    } catch (e) {
                        EventLogger.recordMediaEvent({
                            type: 'removeSourceBufferError',
                            message: e.message,
                            actionId: action.id,
                        });
                    }

                    unlock();
                });
                return;
            }


            EventLogger.recordMediaEvent({
                type: 'unknownMethodMediaSource',
                method: type,
            });
        });
    }
}
