let textarea = null;

export default function copyText(text) {
    if (!textarea) {
        textarea = document.createElement('textarea');
        textarea.setAttribute('style', 'position: fixed; left: -10000px;');
        textarea.setAttribute('aria-hidden', 'true');
        document.body.appendChild(textarea);
    }
    textarea.value = text;
    textarea.focus();
    textarea.select();

    let result = false;
    try {
        result = document.execCommand('copy');
    } catch (e) {
        result = false;
    }

    textarea.value = '';

    return result;
}

export function copyTextOnClick(text) {
    if (!window.navigator.clipboard) {
        copyText(text);
        return;
    }

    window.navigator.clipboard.writeText(text);
}
