import React from 'react';
import b from 'b_';
import cn from 'classnames';
import './Text.css';

const block = b.lock('FuseText');

function renderText(cls, { className, ...otherProps }) {
    return <div className={cn(block(cls), className)} {...otherProps} />;
}

// FTDisplay: Fuse Text Display
export function FTDisplay(props) {
    return renderText('Display', props);
}


export function FTHeadline(props) {
    return renderText('Headline', props);
}

export function FTTitle(props) {
    return renderText('Title', props);
}

export function FTHeading(props) {
    return renderText('Heading', props);
}

export function FTBody(props) {
    return renderText('Body', props);
}

export function FTBodyNoColor(props) {
    return renderText('BodyNoColor', props);
}

export function FTInput(props) {
    return renderText('Input', props);
}

export function FTLabel(props) {
    return renderText('Label', props);
}

export function FTCaption(props) {
    return renderText('Caption', props);
}

export function FTButtonsTabs(props) {
    return renderText('ButtonsTabs', props);
}
