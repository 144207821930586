import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Interpolate, translate } from 'react-i18next';
import b from 'b_';
import Bg from '../Components/Bg/Bg';
import Icon from '../Components/Icon/Icon';
import Button from '../Components/Button';
import { PfptLogo, ProofpointUrlIsolationLogo } from '../Components/Logo/PfptLogo';
import { TBody } from '../Components/Text/Text';
import './BrowserErrorPages.css';

const block = b.lock('BrowserErrorPage');

const Page = ({ titleIcon, title, text, actions, isUrlIsolation }) => <Bg className={block()}>
    {isUrlIsolation
        ? <ProofpointUrlIsolationLogo noShadow className={block('Logo')} />
        : <PfptLogo noShadow className={block('Logo')} />}
    <div className={block('Title')}>
        {titleIcon ? <Icon type={titleIcon} className={block('TitleIcon')} /> : null}
        {title}
    </div>
    <TBody className={block('Text')}>{text}</TBody>
    <div className={block('Actions')}>{actions}</div>
</Bg>;
Page.propTypes = {
    titleIcon: PropTypes.string,
    title: PropTypes.node,
    text: PropTypes.node,
    actions: PropTypes.node,
    isUrlIsolation: PropTypes.bool,
};

const RefreshPageButton = translate()(({ t }) => <Button
    className={block('Button')}
    icon="refresh"
    raised
    primaryGreen
    onClick={() => window.top.location.reload()}
>{t('browserErrorPage.refresh')}</Button>);

const HomePageButton = translate()(({ t, location = '/browser', hide }) => (hide ? null : <Button
    className={block('Button')}
    onClick={() => window.top.location.assign(location)}
    icon="home"
    raised
    secondaryWhite
>{t('browserErrorPage.home')}</Button>));
HomePageButton.propTypes = { location: PropTypes.string };

export const GenericErrorPage = translate()(({ t, isUrlIsolation, isSaasIsolation }) => <Page
    titleIcon="block"
    title={t('browserErrorPage.genericError.title')}
    text={<Interpolate
        i18nKey={isUrlIsolation || isSaasIsolation ? 'browserErrorPage.genericError.urlIsolationTemplate'
            : 'browserErrorPage.genericError.template'}
        linebreak={<br />}
        highlight1={<strong>{t('browserErrorPage.genericError.highlight1')}</strong>}
        highlight2={<strong>{t('browserErrorPage.genericError.highlight2')}</strong>}
    />}
    actions={<Fragment>
        <HomePageButton hide={isUrlIsolation || isSaasIsolation} />
        <RefreshPageButton />
    </Fragment>}
    isUrlIsolation={isUrlIsolation}
/>);

export const ServerFailurePage = translate()(({ t, isUrlIsolation }) => <Page
    titleIcon="error_outline"
    title={t('browserErrorPage.serverFailure.title')}
    text={<Interpolate
        i18nKey="browserErrorPage.serverFailure.template"
        linebreak={<br />}
        highlight1={<strong>{t('browserErrorPage.serverFailure.highlight1')}</strong>}
    />}
    actions={<RefreshPageButton />}
    isUrlIsolation={isUrlIsolation}
/>);


export const UnreachableWebsitePage = translate()(({ t, url, isUrlIsolation }) => <Page
    titleIcon="block"
    title={t('browserErrorPage.unreachableError.title')}
    text={<Interpolate
        i18nKey="browserErrorPage.unreachableError.template"
        linebreak={<br />}
        url={<span className={block('URL')}>{url}</span>}
        highlight1={<strong>{t('browserErrorPage.unreachableError.highlight1')}</strong>}
        highlight2={<strong>{t('browserErrorPage.unreachableError.highlight2')}</strong>}
    />}
    actions={<RefreshPageButton />}
    isUrlIsolation={isUrlIsolation}
/>);
UnreachableWebsitePage.propTypes = { url: PropTypes.string.isRequired };


export const TimedOutPage = translate()(({ t, isUrlIsolation, isSaasIsolation }) => <Page
    titleIcon="error_outline"
    title={t('browserErrorPage.timedOutWebsite.title')}
    text={<Interpolate
        i18nKey={isUrlIsolation || isSaasIsolation ? 'browserErrorPage.timedOutWebsite.urlIsolationTemplate'
            : 'browserErrorPage.timedOutWebsite.template'}
        linebreak={<br />}
    />}
    actions={<Fragment>
        <HomePageButton hide={isUrlIsolation || isSaasIsolation} />
        <RefreshPageButton />
    </Fragment>}
    isUrlIsolation={isUrlIsolation}
/>);


export const SavedFilePage = translate()(({ t, isUrlIsolation, isSaasIsolation }) => {
    const tld = isUrlIsolation ? 'https://urlisolation.com' : 'https://proofpointisolation.com';
    return <Page
        titleIcon="error_outline"
        title={t('browserErrorPage.SavedFilePage.title')}
        text={<Interpolate
            i18nKey="browserErrorPage.SavedFilePage.template_ped"
            url={<a href={tld}>{tld}</a>}
        />}
        actions={<HomePageButton location={tld} hide={isUrlIsolation || isSaasIsolation} />}
        isUrlIsolation={isUrlIsolation}
    />;
});

export const CantRenderDocument = translate()(({ t, isUrlIsolation, isSaasIsolation }) => <Page
    titleIcon="error_outline"
    title={t('browserErrorPage.cantRenderDocument.title')}
    actions={<Fragment>
        <HomePageButton hide={isUrlIsolation || isSaasIsolation} />
    </Fragment>}
    isUrlIsolation={isUrlIsolation}
/>);

export const EmptyPageContent = translate()(({ t, isUrlIsolation, isSaasIsolation, statusLine }) => <Page
    titleIcon="error_outline"
    title={t('browserErrorPage.emptyPageContent.title')}
    text={statusLine}
    actions={<Fragment>
        <HomePageButton hide={isUrlIsolation || isSaasIsolation} />
        <RefreshPageButton />
    </Fragment>}
    isUrlIsolation={isUrlIsolation}
/>);

export const TabSelfClosed = translate()(({ t, isUrlIsolation, isSaasIsolation }) => <Page
    title={t('browserErrorPage.tabSelfClosed.title')}
    actions={<Fragment>
        <HomePageButton hide={isUrlIsolation || isSaasIsolation} />
        <RefreshPageButton />
    </Fragment>}
    isUrlIsolation={isUrlIsolation}
/>);
