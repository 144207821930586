import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import BrowserStore from '../../browser/stores/BrowserStore';
import { openFilePicker } from '../../mirror/client/ClientUploadManager';
import InterpolateWithProduct from '../../Translate';
import ClearCookiesModal from '../ClearCookiesModal/ClearCookiesModal';
import FuseConfirmationModal from '../ConfirmationModal/FuseConfirmationModal';
import FAQModal from '../FAQ/FAQModal';
import SendFeedbackModal from '../SendFeedbackModal/SendFeedbackModal';
import TopBarMenu from '../TopBarMenu/TopBarMenu';
import { TroubleshootingInstructionsModal } from '../TroubleshootingModeScreen';

class CommonBrowserModals extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        onRefresh: PropTypes.func,
    };

    renderUploadPopup = () => <FuseConfirmationModal
        store={this.props.browser.uploadPopup}
        title={this.props.t('uploadPopupBlockedModal.popupTitle')}
        confirmLabel={this.props.t('uploadPopupBlockedModal.cancel')}
        cancelLabel={this.props.t('uploadPopupBlockedModal.allowPopup')}
        onClose={() => {
            openFilePicker(this.props.browser.frameEl);
            this.props.browser.uploadPopup.close();
        }}
        onConfirm={this.props.browser.uploadPopup.close}
    >
        <InterpolateWithProduct i18nKey="uploadPopupBlockedModal.popup" />
    </FuseConfirmationModal>;

    render() {
        return <Fragment>
            <TopBarMenu />
            <ClearCookiesModal onRefresh={this.props.onRefresh} />
            <TroubleshootingInstructionsModal />
            <SendFeedbackModal />
            <FAQModal />
            {this.renderUploadPopup()}
        </Fragment>;
    }
}

export default translate()(inject('browser')(observer(CommonBrowserModals)));
