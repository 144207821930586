const radix = 36;
const batchSize = 50;

function toMask(arr) {
    const bigMask = arr.map((x) => +x).join('');
    const masks = [];
    let i = 0;
    while (i < bigMask.length) {
        masks.push(parseInt('1' + bigMask.substr(i, batchSize), 2).toString(radix));
        i += batchSize;
    }
    return masks.join('_');
}

function toArray(mask) {
    return [].concat(...mask.split('_').map((str) =>
        (parseInt(str, radix)).toString(2)
            .substr(1)
            .split('')
            .map((char) => !!(char | 0))));
}

export default {
    toArray,
    toMask,
};
