import makeInitStoresFn from '../stores/makeInitStoresFn';
import BrowserStore from './stores/BrowserStore';
import ClearCookiesStore from './stores/ClearCookiesStore';
import ResearchStore from './stores/ResearchStore';
import HistoryStore from './stores/HistoryStore';
import DebugStore from './stores/DebugStore';
import EnvStore from '../stores/EnvStore';
import PasswordlessAuthStore from '../stores/PasswordlessAuthStore';
import SnackStore from '../stores/SnackStore';

const initBrowserStores = makeInitStoresFn({
    snack: SnackStore,
    browser: BrowserStore,
    clearCookies: ClearCookiesStore,
    research: ResearchStore,
    history: HistoryStore,
    env: EnvStore,
    passwordlessAuth: PasswordlessAuthStore,
    debug: DebugStore,
});

export default initBrowserStores;
