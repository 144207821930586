import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import EnvStore from '../../stores/EnvStore';
import Accordion from '../Accordion/Accordion';
import { TBody } from '../Text/Text';

// see en.json "FAQ"
export const SECTIONS = {
    BROWSER_SUPPORT: 1,
    LANGUAGE_SUPPORT: 2,
    MOBILE_SUPPORT: 3,
    REGISTRATION_PROMPT: 4,
    BOOKMARK: 5,
    TAB: 6,
    WEBSITE_ISSUE: 7,
    SESSION_TIMEOUT: 8,
    WHAT_IS_UD: 9,
    DLP: 10,
    DOWNLOADS: 11,
    UPLOADS: 12,
    PHISHING: 13,
    MALWARE: 14,
    WHAT_IS_SAAS: 15,
};

const browserIsolationSections = [
    SECTIONS.BROWSER_SUPPORT,
    SECTIONS.LANGUAGE_SUPPORT,
    SECTIONS.REGISTRATION_PROMPT,
    SECTIONS.BOOKMARK,
    SECTIONS.TAB,
    SECTIONS.WEBSITE_ISSUE,
    SECTIONS.SESSION_TIMEOUT,
    SECTIONS.DLP,
    SECTIONS.DOWNLOADS,
    SECTIONS.UPLOADS,
    SECTIONS.PHISHING,
    SECTIONS.MALWARE,
];

const urlIsolationSections = [
    SECTIONS.WHAT_IS_UD,
    SECTIONS.DLP,
    SECTIONS.DOWNLOADS,
    SECTIONS.UPLOADS,
    SECTIONS.PHISHING,
    SECTIONS.MALWARE,
];

const saasIsolationSections = [
    SECTIONS.WHAT_IS_SAAS,
    SECTIONS.DLP,
    SECTIONS.DOWNLOADS,
    SECTIONS.UPLOADS,
    SECTIONS.PHISHING,
    SECTIONS.MALWARE,
];

class FAQContent extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        env: EnvStore.PropType.isRequired,
        openSection: PropTypes.number,
    };

    render() {
        const { t, env, openSection } = this.props;
        let content = env.isUrlIsolation ? urlIsolationSections : browserIsolationSections;
        if (env.isSaasIsolation) {
            content = saasIsolationSections;
        }

        return <div>
            {content.map((i) => <div
                key={i}
                ref={(ref) => {
                    if (ref && i === openSection) {
                        setTimeout(() => ref.scrollIntoView(), 200);
                    }
                }}
            >
                <Accordion
                    label={t(`FAQ.q${i}`)}
                    defaultExpanded={i === openSection}
                >
                    <TBody dangerouslySetInnerHTML={{ __html: t(`FAQ.a${i}`) }} />
                </Accordion>
            </div>)}
        </div>;
    }
}

export default translate()(inject('env')(observer(FAQContent)));
