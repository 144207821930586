/* eslint-disable no-console */
import Raven from 'raven-js';
import * as env from './env';
import LocalStorage from './LocalStorage';
import { getRoot } from './DOMUtils';

window.Raven = Raven;
export { Raven };

let shouldSendToSentry = true;
let defaultLogger = null;

export function disableSentryNotifications() {
    shouldSendToSentry = false;
}

// [mirror content] - our debug log in index.content.js, for developers only
// Permission denied - IE11 is mad
// ResizeObserver - ublock both client's and ours
const reIgnoreErrors = /^__BROWSERTOOLS_CONSOLE_SAFEFUNC|Permission\s+denied|ResizeObserver/gi;

if (env.IS_PROD && env.SENTRY_CREDENTIALS) {
    Raven.config(env.SENTRY_CREDENTIALS, {
        release: env.BUILD_TIME,
        logger: `javascript:${env.APP}`,
        shouldSendCallback: (data) => {
            if (!shouldSendToSentry) {
                return false;
            }

            // filter out errors from extensions(scripts injected by 3rd party extensions) on client's Chrome
            if ((env.IS_BROWSER || env.IS_CLIENT) && env.IS_BROWSER_CHROME) {
                if (data && data.culprit && data.culprit.indexOf('anonymous') !== -1) {
                    return false;
                }
            }
            if (data && data.exception && data.exception.values && data.exception.values.length) {
                return !!data.exception.values.find((item) => {
                    // it may be instance of CustomEvent triggered by window.onerror
                    // e.g. this happens on https://www.wetteronline.de/14-tage-wetter
                    if (item.value && typeof item.value.match === 'function') {
                        return !item.value.match(reIgnoreErrors);
                    }
                    if (defaultLogger) {
                        defaultLogger.warn('weird error, most likely CustomEvent', data, item);
                    }
                    return false;
                });
            }
            return true;
        },
    }).install();
    Raven.setExtraContext({ IS_TOP: env.IS_TOP });
}

function isString(str) {
    return typeof str === 'string';
}

export const DEBUG_FLAG = !!LocalStorage.getRaw('debug');

export const DEBUG = !env.IS_PROD || DEBUG_FLAG;

function safeConsole(method, ...args) {
    try {
        if (window.console[method]) {
            window.console[method](...args);
        } else if (window.console.log) {
            window.console.log(method.toUpperCase(), ...args);
        }
    } catch (e) {
        setTimeout(() => {
            try {
                Raven._ignoreNextOnError();
            } finally {
                // eslint-disable-next-line
                throw e;
            }
        });
    }
}

const DEFAULT_LOGGER_NAME = 'default';
// TODO: listen to LS events
const loggerSettings = LocalStorage.get('loggers') || Object.create(null);
const loggers = Object.create(null);

function configureLogger(name, isEnabled) {
    loggerSettings[name] = !!isEnabled;
    LocalStorage.set('loggers', loggerSettings);
}

class Logger {
    static globalPrefix = '';
    /** @returns {Logger} */
    create(name, isEnabled, tag) {
        return loggers[name] || new Logger(name, isEnabled, tag);
    }

    setGlobalPrefix(prefix) {
        Logger.globalPrefix = prefix;
    }

    enableAll() {
        Object.keys(loggers).forEach((key) => loggers[key].enable());
    }

    disableAll() {
        Object.keys(loggers).forEach((key) => loggers[key].disable());
    }

    constructor(name = DEFAULT_LOGGER_NAME, isEnabled = true, tag = '') {
        this.isDefault = name === DEFAULT_LOGGER_NAME;
        this.name = name;
        this.tag = tag ? ':' + tag : '';
        loggers[name] = this;

        if (loggerSettings[name] === undefined) {
            configureLogger(name, this.isDefault ? DEBUG : isEnabled);
        }
    }

    enable() {
        configureLogger(this.name, true);
    }

    disable() {
        configureLogger(this.name, false);
    }

    isEnabled() {
        return !!loggerSettings[this.name];
    }

    now() {
        const d = new Date();
        const m = ('00' + d.getMinutes()).substr(-2);
        const s = ('00' + d.getSeconds()).substr(-2);
        const ms = ('000' + d.getMilliseconds()).substr(-3);
        return `[${m}:${s}:${ms}]`;
    }

    getLabel() {
        return Logger.globalPrefix + (this.isDefault ? `${this.now()}` : `${this.now()} ${this.name}${this.tag}`);
    }

    log = (...args) => {
        if (this.isEnabled()) {
            safeConsole('log', this.getLabel(), ...args);
        }
    };

    info = (...args) => {
        if (this.isEnabled()) {
            safeConsole('info', this.getLabel(), ...args);
        }
    };

    warn = (...args) => {
        if (this.isEnabled()) {
            safeConsole('warn', this.getLabel(), ...args);
        }
    };

    latestErrors = [];

    error = (...args) => {
        this.latestErrors.push(args);

        if (this.latestErrors.length > 100) {
            this.latestErrors.shift();
        }

        if (this.isEnabled()) {
            safeConsole('error', this.getLabel(), ...args);
        }
    };

    recordWarning(message, args) {
        if (!isString(message)) {
            if (message && isString(message.message)) {
                message = message.message;
            } else {
                message = JSON.stringify(message);
            }
        }
        this.warn(message, args);
        if (args) {
            args.loggerLabel = this.getLabel();
        }
        Raven.captureException(message, { extra: args, level: 'warning' });
    }

    recordError(err, args = {}) {
        if (typeof args === 'string') {
            args = {
                context: args,
            };
        }
        if (!(err instanceof Error)) {
            err = new Error(err);
        }
        this.error(err, args);
        if (args) {
            args.loggerLabel = this.getLabel();
        }
        Raven.captureException(err, { extra: args, level: 'error' });
    }

    group(groupInfo, data, collapsed = false) {
        if (this.isEnabled()) {
            if (!Array.isArray(groupInfo)) {
                groupInfo = [groupInfo];
            }
            // IE cannot group log multiple arguments
            const groupHeader = [this.getLabel(), ...groupInfo].join(' ');
            safeConsole(collapsed ? 'groupCollapsed' : 'group', groupHeader);
            Object.keys(data).forEach((key) => {
                let row = data[key];
                if (!Array.isArray(row)) {
                    row = [row];
                }
                safeConsole('log', key + ':', ...row);
            });
            safeConsole('groupEnd');
        }
    }

    image(dataUrl) {
        const img = new window.Image();
        img.src = dataUrl;
        img.onload = () => {
            const scale = (window.innerWidth - 50) / img.width;
            const width = (scale * img.width) | 0;
            const height = (scale * img.height) | 0;
            safeConsole('log', '%c+', `
                color: transparent;
                font-size: 1px;
                padding: ${height / 2}px ${width / 2}px;
                line-height: ${height}px;
                background: url(${img.src}); background-size: ${width}px ${height}px;
            `);
        };

        return dataUrl;
    }

    alwaysLog(...args) {
        safeConsole('log', this.getLabel(), ...args);
    }

    trace(label) {
        if (this.isEnabled()) {
            safeConsole('trace', `${this.getLabel()} ${label})`);
        }
    }

    addDebugAttribute(doc, name, value) {
        try {
            if (!name.startsWith('weblife-')) {
                this.warn('addDebugAttribute: name should start with "weblife-"', name);
            }
            if (!doc) {
                return;
            }
            const root = getRoot(doc);
            if (!root || !root.setAttribute) {
                return;
            }

            if (value === null) {
                root.removeAttribute(name);
            } else {
                root.setAttribute(name, value);
            }
        } catch (e) {
            this.warn('failed to mark document', e);
        }
    }

    exposeGlobal(obj) {
        // If you ever lose access to this, it might be the case lodash or underscore overwrote it.
        // If that happens see who imported the entire lodash / underscore and not just the fn they needed and fix that
        window._ = Object.assign(window._ || {}, obj);
    }
}

defaultLogger = new Logger();
defaultLogger.loggers = loggers;
defaultLogger.loggerSettings = loggerSettings;

const debug = defaultLogger; // alias for auto imports
export default debug;

if (env.APP) {
    defaultLogger.alwaysLog(`bundle "${env.APP}" build id ${env.BUILD_TIME}, NODE_ENV=${env.NODE_ENV}`);
}

debug.exposeGlobal({ debug });
