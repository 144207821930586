import throttle from 'lodash/throttle';
import AbstractWatcher from './AbstractWatcher';
import reg from '../DOMRegistry';
import { TAG_AUDIO, TAG_VIDEO } from '../DOM';
import debug from '../../debug';
import { F_VALUE } from '../sharedConstants';

const logger = debug.create('VolumeWatcher');

export class VolumeWatcher extends AbstractWatcher {
    shouldWatch(node, tag) {
        return tag === TAG_VIDEO || tag === TAG_AUDIO;
    }

    afterRegister(node, uid) {
        this.valueByUid[uid] = {};

        reg.addEventListener(node, 'volumechange', throttle(() => {
            this.checkNode(node);
        }), 200, { leading: true, trailing: true });
    }

    compareValues(newValue, oldValue) {
        return newValue.muted === oldValue.muted
            && newValue.volume === oldValue.volume;
    }

    getValue(node) {
        return {
            muted: !!node.muted,
            volume: node.volume,
        };
    }

    setValue(node, uid, value) {
        logger.log('setValue', uid, value);

        node.muted = value.muted;
        node.volume = value.volume;

        this.valueByUid[uid] = Object.assign(this.valueByUid[uid], value);
    }
}

export default new VolumeWatcher(F_VALUE.volume, false);
