import ArrayMask from '../../ArrayMask';
import { IS_CLIENT, IS_EXTENSION } from '../../env';
import { TAG_SELECT, dispatchChangeEvent, dispatchInputEvent } from '../DOM';
import reg from '../DOMRegistry';
import { F_VALUE } from '../sharedConstants';
import AbstractWatcher from './AbstractWatcher';

function isMultipleSelect(node) {
    return node.type !== 'select-one';
}

// TODO: check if there is a reason to normalize select values
function normalizeValue(value) {
    return '' + value;
}

export class SelectedWatcher extends AbstractWatcher {
    shouldWatch(node, tag) {
        return tag === TAG_SELECT;
    }

    afterRegister(node, uid) {
        if (isMultipleSelect(node)) {
            this.valueByUid[uid] = {
                mask: ArrayMask.toMask(Array.from(node.options).map((opt) => opt.hasAttribute('selected'))),
            };
        } else {
            let value = '';

            if (IS_CLIENT) {
                let i = node.options.length;
                while (i) {
                    i -= 1;
                    if (node.options[i].hasAttribute('selected') || i === 0) {
                        value = node.options[i].value;
                        // NOTE: set default select value before checking for changes
                        // inserting options from last to first in renderDOM causes default value to be the last option
                        node.options[i].selected = true;
                        break;
                    }
                }
            }

            this.valueByUid[uid] = { value: normalizeValue(value) };
        }

        if (IS_EXTENSION) {
            reg.addEventListener(node, 'input', () => this.checkNode(node));
        }
    }

    getValue(node) {
        return isMultipleSelect(node)
            ? { mask: ArrayMask.toMask(Array.from(node.options).map((opt) => opt.selected)) }
            : { value: normalizeValue(node.value) };
    }

    compareValues(newValue, oldValue) {
        return newValue.value === oldValue.value
            && newValue.mask === oldValue.mask;
    }

    setMultipleSelectValue(node, uid, value) {
        this.valueByUid[uid] = value;
        if (!node.options) {
            return;
        }

        // selecting option could cause scrolling
        const savedScrollTop = node.scrollTop;

        const optionValues = ArrayMask.toArray(value.mask);
        let changed = false;
        Array.from(node.options).forEach((option, index) => {
            const optionValue = !!optionValues[index];
            if (!!option.selected !== optionValue) {
                option.selected = optionValue;
                changed = true;
                node.__IGNORE_SCROLL = true;
            }
        });

        if (this.IS_EXTENSION && changed) {
            dispatchInputEvent(node);
            dispatchChangeEvent(node);
        }

        // restore scroll position
        if (changed) {
            setTimeout(() => {
                delete node.__IGNORE_SCROLL;
                node.scrollTop = savedScrollTop;
            });
        }
    }

    setSingleSelectValue(node, uid, value) {
        this.valueByUid[uid] = value;

        if (normalizeValue(node.value) !== value.value) {
            node.value = value.value;

            if (IS_EXTENSION) {
                dispatchInputEvent(node);
                dispatchChangeEvent(node);
            }
        }
    }

    setValue(node, uid, value) {
        // ignoring select type and value type mismatch
        // on mismatch - do nothing
        // TODO: make a test page with a wild select
        if (isMultipleSelect(node)) {
            if (value.mask !== undefined) {
                this.setMultipleSelectValue(node, uid, value);
            }
        } else if (value.value !== undefined) {
            this.setSingleSelectValue(node, uid, value);
        }

        this.valueByUid[uid] = this.getValue(node);
    }
}

export default new SelectedWatcher(F_VALUE.selected, 250);
