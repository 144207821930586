import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import cn from 'classnames';
import Icon from '../../../../frontend/Components/Icon/Icon';
import './BrowserLoader.css';

const block = b.lock('BrowserLoader');

function BrowserLoader({ title }) {
    return <div title={title} className={cn(block())}>
        <hr className={cn(block('line'))} />
        <Icon type={'loader_grey'} className={cn(block('grey'))}/>
        <Icon type={'loader_colour'} className={cn(block('colour'))}/>
    </div>;
}

BrowserLoader.propTypes = {
    title: PropTypes.string,
};

export default BrowserLoader;
