import forEach from 'lodash/forEach';
import debug from '../../debug';
import reg from '../DOMRegistry';
import { CSS_METHOD } from '../sharedConstants';

const logger = debug.create('CSSOM', true);

// storing rule map on stylesheet, when parent node is deleted it should take care of GC
const RULE_BY_ID = 'WL_RULE_BY_ID';

const cssCallImplementations = {
    [CSS_METHOD.deleteRule]: (sheet, ruleIndex, targetRuleId) => {
        const target = targetRuleId ? sheet[RULE_BY_ID]?.[targetRuleId] : sheet;
        target.deleteRule(ruleIndex);
    },
    [CSS_METHOD.insertRule]: (sheet, cssText, ruleIndex, targetRuleId) => {
        const target = targetRuleId ? sheet[RULE_BY_ID]?.[targetRuleId] : sheet;
        try {
            target.insertRule(cssText, ruleIndex);
        } catch (e) {
            target.insertRule('dummy_for_failed_insert{}', ruleIndex);
        }
    },
    [CSS_METHOD.replace]: (sheet, cssText) => {
        sheet.replace(cssText);
    },
    [CSS_METHOD.replaceSync]: (sheet, cssText) => {
        sheet.replaceSync(cssText);
    },
    [CSS_METHOD.setRuleId]: (sheet, ruleParentId, ruleIndex, ruleId) => {
        const parent = ruleParentId ? sheet[RULE_BY_ID]?.[ruleParentId] : sheet;
        if (!parent) {
            logger.warn('setRuleId: parent not found', { sheet, ruleParentId, ruleIndex, ruleId });
            return;
        }
        const rule = parent.cssRules[ruleIndex];
        if (!rule) {
            logger.warn('setRuleId: rule not found', { sheet, ruleParentId, ruleIndex, ruleId });
            return;
        }
        sheet[RULE_BY_ID] ??= {};
        sheet[RULE_BY_ID][ruleId] = rule;
    },
    [CSS_METHOD.setProperty]: (sheet, propertyName, value, priority, ruleId) => {
        const rule = sheet[RULE_BY_ID]?.[ruleId];
        if (!rule) {
            logger.warn('setProperty: rule not found', { sheet, propertyName, value, priority, ruleId });
            return;
        }
        rule.style.setProperty(propertyName, value, priority);
    },
    [CSS_METHOD.removeProperty]: (sheet, propertyName, ruleId) => {
        const rule = sheet[RULE_BY_ID]?.[ruleId];
        if (!rule) {
            logger.warn('removeProperty: rule not found', { sheet, propertyName, ruleId });
            return;
        }
        rule.style.removeProperty(propertyName);
    },
};

export function applyCssCalls(sheet, cssCalls) {
    if (sheet && cssCalls) {
        cssCalls.forEach(([method, ...args]) => {
            if (cssCallImplementations[method]) {
                try {
                    cssCallImplementations[method](sheet, ...args);
                } catch (e) {
                    logger.warn('applyCssCalls failed to', method, sheet.ownerNode, ...args, e);
                }
            } else {
                logger.warn('applyCssCalls unknown method', method, CSS_METHOD, cssCallImplementations);
            }
        });
    }
}

function onStyleLoad(event) {
    const node = event?.target;
    if (node) {
        if (node.CSSCalls && node.sheet) {
            applyCssCalls(node.sheet, node.CSSCalls);
            delete node.CSSCalls;
        }
        node.removeEventListener('load', onStyleLoad);
    }
}

export function renderCSS(frame, css) {
    forEach(css, (cssCalls, nodeId) => {
        if (nodeId.startsWith('c')) {
            applyCssCalls(frame.getCssStyleSheet(nodeId.substring(1)), cssCalls);
            return;
        }

        const node = reg.getNode(frame.id, nodeId);
        if (node) {
            if (node.sheet) {
                applyCssCalls(node.sheet, cssCalls);
            } else if (node.CSSCalls) {
                node.CSSCalls = node.CSSCalls.concat(cssCalls);
            } else {
                node.CSSCalls = cssCalls;
                node.addEventListener('load', onStyleLoad);
            }
        }
    });
}
