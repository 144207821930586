import debug from '../../debug';
import MediaDownloader from './MediaDownloader';
import EventLogger from '../../ClientEventLogger';

const logger = debug.create('SourceBufferProxy');

export default class SourceBufferProxy {
    constructor(id, codec, sourceBuffer, parentProxy) {
        this.id = id;
        this.codec = codec;
        this.sourceBuffer = sourceBuffer;
        this.parentProxy = parentProxy;
        this.isDestroyed = false;
        this.mediaDownloader = new MediaDownloader(this.id);

        logger.log('SourceBufferProxy.constructor', this.id, this.codec, this, this.sourceBuffer);
    }

    isUpdating() {
        return this.sourceBuffer.updating;
    }

    waitForUpdateFinish(callback) {
        if (this.isDestroyed) {
            return;
        }

        if (this.isUpdating()) {
            // we can't simply wait for event updateend because sometimes
            // this object is 'updating' even after update end
            setTimeout(() => {
                this.waitForUpdateFinish(callback);
            }, 10);
        } else {
            callback();
        }
    }

    safe(callback, finallyCallback) {
        if (this.isDestroyed) {
            return;
        }
        let shouldDestroy = false;
        let error = null;

        try {
            callback();
        } catch (e) {
            EventLogger.recordMediaEvent({
                type: 'sourceBufferActionError',
                message: e.message,
            });
            error = e;
            shouldDestroy = true;
        }

        if (finallyCallback) {
            finallyCallback(error);
        }

        if (shouldDestroy) {
            this.destroy();
        }
    }

    destroy() {
        if (this.isDestroyed) {
            return;
        }
        logger.log('SourceBufferProxy.destroy', this.id);

        this.isDestroyed = true;

        this.mediaDownloader.destroy();
        this.parentProxy.onBufferDestroy(this);
        this.mediaDownloader = null;
        this.sourceBuffer = null;
        this.parentProxy = null;
    }

    runUpdate(updateCallback, onComplete) {
        this.waitForUpdateFinish(() => {
            let handled = false;
            let onUpdateEnd;

            const onAbort = (e) => {
                logger.log('SourceBufferProxy.onAbort', e);

                onUpdateEnd();
            };

            const onError = (e) => {
                logger.error('SourceBufferProxy.onError', e);

                onUpdateEnd();
            };

            onUpdateEnd = () => {
                if (handled) {
                    return;
                }

                handled = true;

                if (!this.isDestroyed) {
                    this.sourceBuffer.removeEventListener('updateend', onUpdateEnd);
                    this.sourceBuffer.removeEventListener('error', onError);
                    this.sourceBuffer.removeEventListener('abort', onAbort);
                }

                this.waitForUpdateFinish(onComplete);
            };

            this.sourceBuffer.addEventListener('updateend', onUpdateEnd);
            this.sourceBuffer.addEventListener('error', onError);
            this.sourceBuffer.addEventListener('abort', onAbort);

            this.safe(updateCallback, (e) => e && onComplete());
        });
    }

    preloadFromAction(type, action, actionId) {
        if (type === 'appendBuffer') {
            this.mediaDownloader.getBuffer(action.data, null, actionId);
        }
    }

    abort(actionId, onComplete) {
        logger.log('SourceBufferProxy.abort', actionId);

        this.safe(() => this.sourceBuffer.abort(), onComplete);
    }

    set({ key, value }, actionId, onComplete) {
        logger.log('SourceBufferProxy.set', this.id, actionId);

        this.safe(() => {
            this.sourceBuffer[key] = value;
        }, onComplete);
    }

    appendBuffer(action, actionId, onComplete) {
        logger.log('SourceBufferProxy.appendBuffer', this.id, actionId);

        this.mediaDownloader.getBuffer(action.data, (arrayBuffer) => {
            if (arrayBuffer) {
                logger.log('SourceBufferProxy.appendBuffer: downloaded', this.id, actionId);
                this.runUpdate(() => {
                    this.sourceBuffer.appendBuffer(arrayBuffer);
                }, () => {
                    logger.log('SourceBufferProxy.appendBuffer: appended', this.id, actionId);
                    this.mediaDownloader.releaseChunk();

                    onComplete();
                });
            } else {
                logger.error('SourceBufferProxy.appendBuffer: no buffer');
                this.mediaDownloader.releaseChunk();
                onComplete();
            }
        }, actionId);
    }

    remove(start, end, actionId, onComplete) {
        this.runUpdate(() => {
            this.sourceBuffer.remove(start, end);
        }, onComplete);
    }

    tryNext({ type, action, actionId }, onComplete) {
        if (this.isDestroyed) {
            onComplete();
            return;
        }

        if (type === 'set') {
            this.set(action, actionId, onComplete);
            return;
        }

        if (type === 'appendBuffer') {
            this.appendBuffer(action, actionId, onComplete);
            return;
        }

        if (type === 'remove') {
            const [start, end] = action.args;
            this.remove(start, end, actionId, onComplete);
            return;
        }

        if (type === 'abort') {
            this.abort(actionId, onComplete);
            return;
        }

        EventLogger.recordMediaEvent({
            type: 'unknownMethodOnSourceBuffer',
            method: type,
        });

        onComplete();
    }
}
