/* eslint-disable max-len */
import { CONTENT_KEY, IS_PROD } from '../env';

export const TOP_FRAME_ID = 0;

// verbose for DEV: { 'foo': 'foo', 'bar': 'bar' }
// optimized for PROD: { 'foo': 0, 'bar': 1 }
function makeApi(apiObj, optimize = IS_PROD) {
    // TODO: alphanumeric keys
    let i = 0;
    return Object.keys(apiObj).sort().reduce((finalApi, key) => {
        finalApi[key] = optimize ? i : `${i}_${key}`;
        i += 1;
        return finalApi;
    }, {});
}

// change fields
export const F_CHANGE = makeApi(/** @name F_CHANGE */{
    init: 'init',
    documentId: 'documentId',
    id: 'id',
    frameId: 'frameId',
    frameVersion: 'frameVersion',
    dom: 'dom',
    removedFrameNodeIds: 'removedFrameNodeIds',
    deepCleanUp: 'deepCleanUp',
    selection: 'selection',
    css: 'css',
    domContentLoadedInServerBrowser: 'domContentLoadedInServerBrowser',
    actionCounter: 'actionCounter',
    values: 'values',
    media: 'media',
    canvas: 'canvas',
    viewport: 'viewport',
    customElements: 'customElements',
});

export const REMOVED_NODE = 0;
export const REMOVED_ATTRIBUTE = 0;
export const COPY_ATTRIBUTE = 1; // e.g. { src: " a lot of data ", _src_: 1 }

// change[F_CHANGE.dom][id] fields
export const F_NODE = makeApi(/** @name F_NODE */{
    ATTR: 'attr',
    ATTR_NS: 'attrNS',
    STYLE: 'style',
    TAG: 'tag',
    TAG_NS: 'tagNS',
    TEXT: 'text',
    CHILDREN: 'children',
    IS_MOVED: 'isMoved',
    CLIENT_ID: 'clientId',
    HOST_ID: 'hostId',
    TEXT_CONTENT: 'textContent',
});


// change.values fields
export const F_VALUE = makeApi(/** @name F_VALUE */{
    // watchers
    scroll: 'scroll',
    value: 'value',
    checked: 'checked',
    selected: 'selected',
    volume: 'volume',
    playback: 'playback',

    // not watchers
    frameId: 'frameId',
    dict: 'dict',
    blob: 'blob',
    src: 'src',
    designMode: 'designMode',
    documentElementId: 'documentElementId',
    customBehaviour: 'customBehaviour',
    startPrint: 'startPrint',
    adoptedStyleSheets: 'adoptedStyleSheets',
    styleContent: 'styleContent',
});

export const LOADING_TYPE = {
    DETECT_ELEMENT: 'detectElement',
    CONTENT_LOAD: 'contentLoad',
};

export const ATTR = {
    PRERENDERED_ID: '__id__',
};

// Input.dispatchMouseEvent types
export const EVENT_MOUSE = {
    DOWN: 1,
    UP: 2,
    MOVE: 3,
};
export const MOUSE_EVENTS = {
    [EVENT_MOUSE.DOWN]: 'mousePressed',
    [EVENT_MOUSE.UP]: 'mouseReleased',
    [EVENT_MOUSE.MOVE]: 'mouseMoved',
};

// Input.dispatchTouchEvent types
export const EVENT_TOUCH = makeApi({
    START: 'touchStart',
    END: 'touchEnd',
    MOVE: 'touchMove',
    CANCEL: 'touchCancel',
});
export const TOUCH_EVENTS = {
    [EVENT_TOUCH.START]: 'touchStart',
    [EVENT_TOUCH.END]: 'touchEnd',
    [EVENT_TOUCH.MOVE]: 'touchMove',
    [EVENT_TOUCH.CANCEL]: 'touchCancel',
};

// Input.dispatchKeyEvent types
export const EVENT_KEY = {
    DOWN: 1,
    UP: 2,
    PRESS: 3,
    INSERT_TEXT: 4,
};
export const KEYBOARD_EVENTS = {
    [EVENT_KEY.DOWN]: 'rawKeyDown',
    [EVENT_KEY.UP]: 'keyUp',
    [EVENT_KEY.PRESS]: 'char',
};

// Custom events for logging
export const CUSTOM_EVENTS = {
    MEDIA_NODE_ADDED_TO_DOM: 'mediaNodeAddedToDom',
    DETACHED_MEDIA_LOG: 'detachedMediaLog',
    WEBAUTHN_LOG: 'webAuthnLog',
    GENERAL_LOG: 'weblifeGeneralLog',
};

// https://developer.chrome.com/extensions/webRequest#type-ResourceType
export const RESOURCE_TYPE = {
    MAIN_FRAME: 'main_frame',
    SUB_FRAME: 'sub_frame',
    STYLESHEET: 'stylesheet',
    SCRIPT: 'script',
    IMAGE: 'image',
    FONT: 'font',
    OBJECT: 'object',
    XHR: 'xmlhttprequest',
    PING: 'ping',
    CSP: 'csp_report',
    MEDIA: 'media',
    WS: 'websocket',
    OTHER: 'other',
};

// extension background --> server application
export const API_BACKGROUND_TO_SERVER = {
    pageContent: 'pageContent',
    pageError: 'pageError',
    pageClose: 'pageClose',
    forClient: 'forClient',
    cookieEvent: 'cookieEvent',
    cookieEventRealTime: 'cookieEventRealTime',
    log: 'log',
    logEvent: 'logEvent',
    closeDueToInactivity: 'closeDueToInactivity',
    closeDueToThreat: 'closeDueToThreat',
    dlpScan: 'dlpScan',
    eventDownloadCancelled: 'eventDownloadCancelled',
    eventFileUploadPrevented: 'eventFileUploadPrevented',
    eventExternalJSDownloaded: 'eventExternalJSDownloaded',
    sandboxScanResult: 'sandboxScanResult',
    tiScanResult: 'tiScanResult',
    metric: 'metric',
    dlpScanDownload: 'dlpScanDownload',
    dlpScanDownloadFTR: 'dlpScanDownloadFTR',
    feedback: 'feedback',
    aggregatedEvent: 'aggregatedEvent',
    activityLoggerEvents: 'activityLoggerEvents'
};

// extension background <--> client js
export const API_CLIENT_TO_BACKGROUND = makeApi(/** @name API_CLIENT_TO_BACKGROUND */{
    setScreenSize: 'setScreenSize',
    setValue: 'setValue',
    makeMouseAction: 'makeMouseAction',
    makeTouchAction: 'makeTouchAction',
    makeKeyboardAction: 'makeKeyboardAction',
    handleJSDialog: 'handleJSDialog',
    pasteEvent: 'pasteEvent',
    cutEvent: 'cutEvent',
    textChanges: 'textChanges',
    wheelEvent: 'wheelEvent',
    dragEvent: 'dragEvent',
    evaluate: 'evaluate',
    updateDebugEventMode: 'updateDebugEventMode',

    extendSession: 'extendSession',
    uploadInProgress: 'uploadInProgress',
    cancelSession: 'cancelSession',

    closePage: 'closePage',

    startPrintOnServer: 'startPrintOnServer',
    finishPrintOnServer: 'finishPrintOnServer',

    navigation: 'navigation',

    sendFeedback: 'sendFeedback',

    // debug
    getDebugInfo: 'getDebugInfo',
    getScreenshot: 'getScreenshot',
    profilerStart: 'profilerStart',
    profilerStop: 'profilerStop',

    commitMessages: 'commitMessages',
    exitFullscreen: 'exitFullscreen',

    enableHarForDebug: 'enableHarForDebug',
    getDebugHar: 'getDebugHar',
    overrideForwardedHeader: 'overrideForwardedHeader',

    sendBasicAuthorization: 'sendBasicAuthorization',

    processUploadedBunch: 'processUploadedBunch',
    cancelUpload: 'cancelUpload',
    uploadStarted: 'uploadStarted',
    uploadCompleted: 'uploadCompleted',
    inspectALoggerEvent: 'inspectALoggerEvent',
    changeConfig: 'changeConfig',
    measure: 'measure',
    recordImportantEvent: 'recordImportantEvent',
});

export const API_BACKGROUND_TO_CLIENT = makeApi(/** @name API_BACKGROUND_TO_CLIENT */{
    heartbeat: 'heartbeat',
    applyChanges: 'applyChanges',
    wsFrame: 'wsFrame',
    showJSDialog: 'showJSDialog',
    checkIfPageIsBlankOnClient: 'checkIfPageIsBlankOnClient',
    notifySessionTimeout: 'notifySessionTimeout',
    notifySessionExtended: 'notifySessionExtended',
    response: 'response',
    closePage: 'closePage',
    openNewWindow: 'openNewWindow',
    enableFakePrint: 'enableFakePrint',
    history: 'history',
    commitMessages: 'commitMessages',
    requestFullscreen: 'requestFullscreen',
    exitFullscreen: 'exitFullscreen',
    setClipboard: 'setClipboard',
    execCommand: 'execCommand',
    setDLP: 'setDLP',
    setDownloadUploadDLP: 'setDownloadUploadDLP',
    openMailClient: 'openMailClient',
    sandboxScanResult: 'sandboxScanResult',
    tsaScanResult: 'tsaScanResult',
    fileScanResult: 'fileScanResult',
    blockAppLaunch: 'blockAppLaunch',
    requestBasicAuthorization: 'requestBasicAuthorization',
    fileProcessing: 'fileProcessing',
    openUploadPopup: 'openUploadPopup',
    deleteRouteCookie: 'deleteRouteCookie',
    tiScanResult: 'tiScanResult',
    iframeBlocked: 'iframeBlocked',
    iframeReputationScan: 'iframeReputationScan',
});

// extension background <--> extension content script
export const API_BACKGROUND_TO_CONTENT = {
    preInit: 'preInit',
    init: 'init',
    setValue: 'setValue',
    beforeKeyDownAction: 'beforeKeyDownAction',
    beforeMouseAction: 'beforeMouseAction',
    beforeTouchAction: 'beforeTouchAction',
    beforeDragAction: 'beforeDragAction',
    execPaste: 'execPaste',
    execCut: 'execCut',
    setTexts: 'setTexts',
    dispatchWheel: 'dispatchWheel',
    dragEvent: 'dragEvent',
    startPrintOnServer: 'startPrintOnServer',
    finishPrintOnServer: 'finishPrintOnServer',
    exitFullscreen: 'exitFullscreen',
    reSendValues: 'reSendValues',
    getDOMForHAR: 'getDOMForHAR',
    beforeContextMenu: 'beforeContextMenu',
    updateSettings: 'updateSettings',
};

export const API_CONTENT_TO_BACKGROUND = {
    newUpdate: 'newUpdate',
    analyticalEvent: 'analyticalEvent',
    pageError: 'pageError',
    resolve: 'resolve',
    heartbeat: 'heartbeat',
    enableFakePrint: 'enableFakePrint',
    checkIfPageIsBlank: 'checkIfPageIsBlank',
    firstContentPaint: 'firstContentPaint',
    log: 'log',
    logImportant: 'logImportant',
    metric: 'metric',
    checkHistory: 'checkHistory',
    requestFullscreen: 'requestFullscreen',
    exitFullscreen: 'exitFullscreen',
    setClipboard: 'setClipboard',
    recordEvent: 'recordEvent',
    uploadButtonClick: 'uploadButtonClick',
    execCommand: 'execCommand',
    domContentReadyForThreatScan: 'domContentReadyForThreatScan',
    contextMenu: 'contextMenu',
    mark: 'mark'
};

export const API_NESTED_IFRAME_TO_BROWSER = {
    nested_iframe_paint_event: 'nestedIframePaintEvent',
};

// client page <--> client browser ui
export const API_CLIENT_TO_BROWSER = {
    serverInfo: 'serverInfo',
    url: 'url', // TODO: split
    socketError: 'socketError',
    showIframe: 'showIframe',
    socketClosedByServer: 'socketClosedByServer',
    socketCloseWithReconnect: 'socketCloseWithReconnect',
    socketReconnect: 'socketReconnect',
    initialConnectIsDone: 'initialConnectIsDone',
    setPageAttributes: 'setPageAttributes',
    notifySessionTimeout: 'notifySessionTimeout',
    notifySessionExtended: 'notifySessionExtended',
    openWindow: 'openWindow',
    setTitle: 'setTitle',
    closeMe: 'closeMe',
    showHomePage: 'showHomePage',
    showOnlyWebmail: 'showOnlyWebmail',
    loadErrorClosedOnServer: 'loadErrorClosedOnServer',
    firstRenderFinished: 'firstRenderFinished',
    history: 'history',
    closeSelf: 'closeSelf',
    socketStatus: 'socketStatus',
    requestFullscreen: 'requestFullscreen',
    requestSetClipboard: 'requestSetClipboard',
    requestOpenMailClient: 'requestOpenMailClient',
    exitFullscreen: 'exitFullscreen',
    setDLP: 'setDLP',
    setDownloadUploadDLP: 'setDownloadUploadDLP',
    logOnIframeUnload: 'logOnIframeUnload',
    pageInitError: 'pageInitError',
    sandboxScanResult: 'sandboxScanResult',
    tsaScanResult: 'tsaScanResult',
    tiScanResult: 'tiScanResult',
    fileScanResult: 'fileScanResult',
    setViewport: 'setViewport',
    windowScroll: 'windowScroll',
    appLauncher: 'appLauncher',
    neverIsolate: 'neverIsolate',
    showDlp: 'showDlp',
    response: 'response',
    requestBasicAuthorization: 'requestBasicAuthorization',
    fileProcessing: 'fileProcessing',
    openUploadPopup: 'openUploadPopup',
    redirectToBlockPage: 'redirectToBlockPage',
    disableDownload: 'disableDownload',
    deleteRouteCookie: 'deleteRouteCookie',
    setExitIso: 'setExitIso',
    setRole: 'setRole',
    iframeBlocked: 'iframeBlocked',
    iframeReputationScan: 'iframeReputationScan',
    saveActivityLoggerEvent: 'saveActivityLoggerEvent',
    allowCopyUrl: 'allowCopyUrl',
};

export const API_BROWSER_TO_CLIENT = {
    extendSession: 'extendSession',
    cancelSession: 'cancelSession',
    historyChange: 'historyChange',
    sendBasicAuthorization: 'sendBasicAuthorization',
    cancelUpload: 'cancelUpload',
    nestedIframePaintEvent: 'nested_iframe_paint_event',
    forwardFeedback: 'forwardFeedback',
};

// extension background <--> go agent, through native port
export const API_AGENT_TO_BG = {
    connect: 'connect',
    checkContextMenu: 'checkContextMenu',
    configure: 'configure',
    socketOpen: 'socketOpen',
    socketClose: 'socketClose',
    openPage: 'openPage',
    closePage: 'closePage',
    clientMessage: 'clientMessage',
    clearCookies: 'clearCookies',
    closePageByClientPageId: 'closePageByClientPageId',
    scanResult: 'scanResult', // sandboxScanResult
    waitSandboxResult: 'waitSandboxResult',
    debugInfo: 'debugInfo',
    downloadCompleted: 'downloadCompleted',
    dlpDownloadResult: 'dlpDownloadResult',
    dlpUploadResult: 'dlpUploadResult',
    destroy: 'destroy',
    response: 'response',
};

// version of `cc` cookie
export const CLIENT_CONFIG_VERSION = 1;

export const HTML_NAMESPACE = 'http://www.w3.org/1999/xhtml';

export const CSS_METHOD = makeApi(/** @name CSS_METHOD */{
    insertRule: 'insertRule',
    deleteRule: 'deleteRule',
    replace: 'replace',
    replaceSync: 'replaceSync',
    setRuleId: 'setRuleId',
    setProperty: 'setProperty',
    removeProperty: 'removeProperty',
});

export const DEFAULT_EGRESS_ID = 'auto';

export const PAGE_ERROR = {
    EXT_CLOSED_BY_AGENT: 'EXT_CLOSED_BY_AGENT',
    EXT_CLOSED_BY_CLIENT: 'EXT_CLOSED_BY_CLIENT',
    EXT_CLOSED_BY_NATURAL_CAUSE: 'EXT_CLOSED_BY_NATURAL_CAUSE',
    EXT_TIME_OUT: 'EXT_TIME_OUT',
    EXT_UNKNOWN: 'EXT_UNKNOWN',
    EXT_DOCUMENT_RENDER_ERROR: 'EXT_DOCUMENT_RENDER_ERROR',
    EXT_CONTENT_SCRIPT_FAILED: 'EXT_CONTENT_SCRIPT_FAILED',
    EXT_SOCKET_BUFFER_OVERFLOW: 'EXT_SOCKET_BUFFER_OVERFLOW',
    EXT_FILE_DOWNLOAD: 'EXT_FILE_DOWNLOAD',
    EXT_BLACKLISTED: 'EXT_BLACKLISTED',
    EXT_NEVER_ISOLATE: 'EXT_NEVER_ISOLATE',
    EXT_NEVER_ISOLATE_INSTANT: 'EXT_NEVER_ISOLATE_INSTANT',
    EXT_UNNECESSARY_DOWNLOAD_TAB: 'EXT_UNNECESSARY_DOWNLOAD_TAB',
    EXT_EMPTY_PAGE_CONTENT: 'EXT_EMPTY_PAGE_CONTENT',
};

export const UA = {
    WIN_FF62: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:63.0) Gecko/20100101 Firefox/62.0',
    WIN_CHROME_75: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.90 Safari/537.36',
    WIN_CHROME_100: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.88 Safari/537.36',
    WIN_CHROME_115: 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 Safari/537.36',
};

export const CLIENT_WINDOW_METHODS = {
    exitFullscreen: 'weblifeExitFullscreen',
    openFullscreen: 'weblifeOpenFullscreen',
    openFilePicker: 'openUploadPopup',
};

export const FAKE_ROOT_ATTR_NAME = 'weblife-fake-root-attr-name';

export const FILE_PROCESSING_STATE = {
    SCANNING: 'SCANNING',
    ALLOWED: 'ALLOWED',
    BLOCKED: 'BLOCKED',
    CANCELED: 'CANCELED',
};

export const SCANNING_STATE = {
    SCANNING: 'SCANNING',
    ALLOWED: 'ALLOWED',
    BLOCKED: 'BLOCKED',
    ERROR: 'ERROR',
};

// 1gb upload/download limit
// this value is deffined in the agent as well
export const MAX_FILE_SIZE_LIMIT = 1073741824; // 1024 * 1024 * 1024;

// This enum should be more or less mimicking what agent/src/dlp/dlp.go has.
// NOT_SECURED is an extra option, not specified in dlp.go. It is created / set inside the extension,
// whereas all other error codes would come from the agent.
// NOT_SECURED is for downloads only.
export const FILE_PROCESSING_BLOCK_STATE = {
    GENERIC_ERROR: 0,
    INPUT: 1,
    BLOCKED_BY_POLICY: 2,
    BLOCKED_BY_DLP: 3,
    SIZE_EXCEEDED: 4,
    TIMEOUT: 5,
    MALWARE: 6,
    MAX_SIZE_EXCEEDED: 7,
    NOT_SECURED: 105,
};

// Reason - the coordinator reason for a block, passed as is by the agent
export const FILE_PROCESSING_SCAN_ERROR_STATE = {
    FILE_SIZE_EXCEEDED: 'FILE_SIZE_EXCEEDED',
    FILE_BLOCKED: 'FILE_BLOCKED',
    FILE_UNSUPPORTED: 'FILE_UNSUPPORTED',
    SCAN_ERROR: 'SCAN_ERROR',
    SCAN_TIMEOUT: 'SCAN_TIMEOUT',
};

export const REASON_TO_SCAN_ERROR_MAP = {
    PRESCAN_FILE_SIZE_LARGER: FILE_PROCESSING_SCAN_ERROR_STATE.FILE_SIZE_EXCEEDED,
    PRESCAN_BLOCK_ALL_FILES: FILE_PROCESSING_SCAN_ERROR_STATE.FILE_BLOCKED,
    PRESCAN_BLOCK_ALL_DOWNLOADS: FILE_PROCESSING_SCAN_ERROR_STATE.FILE_BLOCKED,
    PRESCAN_FILE_TYPE_BLOCKED: FILE_PROCESSING_SCAN_ERROR_STATE.FILE_BLOCKED,
    POSTSCAN_BLOCKED: FILE_PROCESSING_SCAN_ERROR_STATE.FILE_BLOCKED,
    PRESCAN_PWD_PROTECTED_FILE: FILE_PROCESSING_SCAN_ERROR_STATE.FILE_UNSUPPORTED,
    PRESCAN_UNSUPPORTED_FILE: FILE_PROCESSING_SCAN_ERROR_STATE.FILE_UNSUPPORTED,
    POSTSCAN_UNSUPPORTED: FILE_PROCESSING_SCAN_ERROR_STATE.FILE_UNSUPPORTED,
    PRESCAN_ERROR: FILE_PROCESSING_SCAN_ERROR_STATE.SCAN_ERROR,
    POSTSCAN_ERROR: FILE_PROCESSING_SCAN_ERROR_STATE.SCAN_ERROR,
    POSTSCAN_TIMEOUT: FILE_PROCESSING_SCAN_ERROR_STATE.SCAN_TIMEOUT,
};


export const FILE_PROCESSING_TYPE = {
    DOWNLOAD: 'DOWNLOAD',
    UPLOAD: 'UPLOAD',
};

export const FILE_INPUT_UNIQUE_ATTR = 'lmdfnasmncbakjdhjalk1239723r8';

export const RELATIVE_PATH_OF_CLIENT_BUNDLE = 'mirror/client.js';

export const RELATIVE_PATH_OF_BROWSER_BUNDLE = 'public/browser';

export const LAST_PING = 3000;
export const WAITING_BROWSER_INTERVAL = 2000;
export const RESERVING_BROWSER_PAGE_TIME_ORIGIN_ASSUMPTION = 23000;
export const PRE_INIT_INDEX_CLIENT_TIME_ORIGIN_ASSUMPTION = 10000;

export const CONTENT_KEY_HEADER_NAME = 'X-Content-Key';
export const CONTENT_KEY_VALUE = CONTENT_KEY;

// post message mark to separate from our message from others
export const POST_MESSAGE_UNIQUE_FIELD = 'weblifePostMessageUniqueField';
export const BLANK_PAGE = 'about:blank';

export const POST_FILE_EVENT_LOGGER_TYPE = {
    ALLOW: 'ALLOW',
    DISABLED: 'DISABLED',
    CONTENT_BLOCK: 'CONTENT_BLOCK',
    BLOCKED_BY_DLP: 'BLOCKED_BY_DLP',
    BLOCKED_BY_MALWARE: 'BLOCKED_BY_MALWARE',
};

export const FEATURE_FLAGS = {
    DEBUG_DO_NOT_TOUCH_NAVIGATOR: 'DEBUG_DO_NOT_TOUCH_NAVIGATOR',
    DEBUG_DO_NOT_MIRROR_USER_AGENT_DATA: 'DEBUG_DO_NOT_MIRROR_USER_AGENT_DATA',
    DEBUG_MIRROR_FULL_USER_AGENT_DATA: 'DEBUG_MIRROR_FULL_USER_AGENT_DATA',
    DEBUG_DO_NOT_REPLACE_CHROMIUM_IN_USER_AGENT_DATA: 'DEBUG_DO_NOT_REPLACE_CHROMIUM_IN_USER_AGENT_DATA',
    DEBUG_DO_NOT_TOUCH_CODECS: 'DEBUG_DO_NOT_TOUCH_CODECS',
    CANVAS_LEGACY_THROTTLING: 'CANVAS_LEGACY_THROTTLING',
    CANVAS_LEGACY_NO_SIZE_APPRECIATION: 'CANVAS_LEGACY_NO_SIZE_APPRECIATION',
    CANVAS_LEGACY_CLIENT_MESSAGE_AFTER_UPLOAD: 'CANVAS_LEGACY_CLIENT_MESSAGE_AFTER_UPLOAD',
    CANVAS_LEGACY_MIN_FRAME_SPAN_50: 'CANVAS_LEGACY_MIN_FRAME_SPAN_50',
    CANVAS_GL_ENABLED_SOME: 'CANVAS_GL_ENABLED_SOME',
    CANVAS_GL_CLASS_ENABLED_ALL: 'CANVAS_GL_CLASS_ENABLED_ALL',
    CANVAS_GL_CONTEXT_ENABLED_ALL: 'CANVAS_GL_CONTEXT_ENABLED_ALL',
    // We change behaviour on what we do for "some" websites. This is a flag to help potentially A/B test that.
    NO_OBFUSCATION_EXCEPTIONS: 'NO_OBFUSCATION_EXCEPTIONS',
    UNSAFE_ONEHEALTH_CONTENT_HOSTNAME: 'UNSAFE_ONEHEALTH_CONTENT_HOSTNAME',
    // cloud-bees feature flags
    ISO_PUBLISH_GEN_AI: 'isoPublishGenAI',
    ISO_CONSOLE_DATA_REALM: 'isoConsoleDataRealm',
    ISO_STP_HIDE_BTN: 'isoStpHideButton',
};
