import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import cn from 'classnames';
import './LoaderBar.css';

const block = b.lock('LoaderBar');

const LoaderBar = (props) => {
    const { className, linesClassName, styleMoving = {}, complete } = props;
    return <div className={cn(block('Progress'), className)}>
        <div
            className={cn(block('ProgressBar1', { complete }), linesClassName)}
            style={styleMoving}
        />
        <div
            className={cn(block('ProgressBar2', { complete }), linesClassName)}
            style={styleMoving}
        />
    </div>;
};

LoaderBar.propTypes = {
    styleMoving: PropTypes.shape({
        backgroundColor: PropTypes.string,
    }),
    complete: PropTypes.bool,
    className: PropTypes.string,
    linesClassName: PropTypes.string,
};

export default LoaderBar;
