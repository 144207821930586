import isEqual from 'lodash/isEqual';
import debug from '../../debug';
import { getDocumentActiveElement } from '../../DOMUtils';
import reg from '../DOMRegistry';
import * as DOM from '../DOM';
import { valueWatcher } from '../watchers';
import {
    Mod,
    Key,
    isMacTextAlteringShortcut,
    isMacSelectionAlteringShortcut,
} from '../userInput';

const logger = debug.create('ClientSelectionController', false);

function isSafeKey(key, mod) {
    if (mod !== Mod.NONE && Key.isArrow(key)) {
        return false;
    }
    if (key === Key.A && (mod === Mod.META || mod === Mod.CTRL)) { // select all
        return false;
    }
    if (key === Key.TAB && (mod === Mod.NONE || mod === Mod.SHIFT)) { // switching focus to the next/previous element
        return false;
    }
    if (key === Key.ESC) {
        return false;
    }
    if (key === Key.PROCESS) {
        return false;
    }
    if (isMacTextAlteringShortcut(key, mod)) {
        return false;
    }
    if (isMacSelectionAlteringShortcut(key, mod)) {
        return false;
    }
    return true;
}

/** @param {Frame} frame */
const getActiveUid = (frame) => frame.doc && reg.getUid(getDocumentActiveElement(frame.doc));

const EVENT_TYPE = {
    RENDER: 'render',
    KEYDOWN: 'keyboard',
    MOUSE: 'mouse',
    COMPOSITION: 'composition',
    TOUCH: 'touch',
};

// Controller for client page selection(aka caret or cursor)
class ClientSelectionController {
    // Used to preserve selection while User is dragging something
    // not made through actionCounter coz we want to support drag-and-drop
    // like this: https://jqueryui.com/draggable/
    isClientSelectionHasPriorityOverServer = false;

    // TODO: Remove after proper support of keyboard shortcuts
    isClientHasUncommitedSelection = false;

    expectedSelection = null;
    expectedActiveUid = null;

    lastEvent = { type: null };

    constructor() {
        valueWatcher.onChange((watcherName, frameId, nodeId) => {
            const node = reg.getNode(frameId, nodeId);
            if (node) {
                const doc = node.ownerDocument;
                if (doc && (getDocumentActiveElement(doc) === node)) {
                    this.clearExpected();
                }
            }
        });
    }

    clearExpected() {
        this.expectedSelection = null;
        this.expectedActiveUid = null;
    }

    /** @param {Frame} frame */
    checkSelection(frame) {
        const activeElement = getDocumentActiveElement(frame.doc);
        if (!activeElement) {
            return;
        }

        // ignore focusing frames
        const activeTag = DOM.normalizeTagName(activeElement);
        if (activeTag === DOM.TAG_IFRAME || activeTag === DOM.TAG_FRAME) {
            return;
        }

        let selectionIsExpected = false;
        const selection = DOM.getSelection(frame.doc);
        const activeUid = getActiveUid(frame);

        if (this.lastEvent.type === EVENT_TYPE.RENDER) {
            selectionIsExpected = true;
        } else if (this.lastEvent.type === EVENT_TYPE.KEYDOWN) {
            selectionIsExpected = !this.lastEvent.isIgnored
                && isSafeKey(this.lastEvent.key, this.lastEvent.mod);
        } else if (this.lastEvent.type === EVENT_TYPE.MOUSE || this.lastEvent.type === EVENT_TYPE.TOUCH) {
            selectionIsExpected = !this.lastEvent.isIgnored
                && DOM.isFocusSelection(selection);
        } else if (this.lastEvent.type === EVENT_TYPE.COMPOSITION) {
            selectionIsExpected = false;
        }

        if (selectionIsExpected) {
            this.expectedSelection = selection;
            this.expectedActiveUid = activeUid;
        } else {
            this.clearExpected();
        }
    }

    /** @param {Frame} frame */
    registerDocument = (frame) => {
        const check = () => this.checkSelection(frame);
        reg.addEventListener(frame.doc, 'selectionchange', () => {
            // logger.log('on selectionchange', DOM.getSelection(frame.doc), this.lastEvent);
            this.checkSelection(frame);
        });

        const onComposition = () => {
            this.lastEvent = { type: EVENT_TYPE.COMPOSITION };
            check();
        };
        reg.addEventListener(frame.doc, 'compositionupdate', onComposition);
        reg.addEventListener(frame.doc, 'compositionend', onComposition);
    };

    /** @param {Frame} frame */
    renderSelection(frame, newSelection, selectionBeforeRender) {
        const currentSelection = DOM.getSelection(frame.doc);

        // rendering changed selection, which invalidates all other selection tracking logic(magic)
        if (!isEqual(currentSelection, selectionBeforeRender)) {
            logger.log('render changed selection', { currentSelection, selectionBeforeRender });
            this.isClientSelectionHasPriorityOverServer = false;
            this.isClientHasUncommitedSelection = false;
        }

        let willRenderSelection = !this.isClientSelectionHasPriorityOverServer && !this.isClientHasUncommitedSelection;

        logger.log('renderSelection', {
            newSelection,
            currentSelection,
            selectionBeforeRender,
            willRenderSelection,
            frame,
        });

        if (!newSelection) {
            return;
        }

        if (isEqual(currentSelection, newSelection)) {
            logger.log('not rendering sel because same');
            willRenderSelection = false;
        } else if (frame.isComposing) {
            // do not mess with selection during IME
            logger.log('not rendering sel because IME');
            willRenderSelection = false;
        }

        if (willRenderSelection) {
            this.lastEvent = { type: EVENT_TYPE.RENDER };
            const activeElement = getDocumentActiveElement(frame.doc);
            const isActiveElementFrame = DOM.isElementOneOf(activeElement, DOM.TAG_FRAME, DOM.TAG_IFRAME);

            if (frame.iframeNode) {
                if (DOM.activeElementFromNode(frame.iframeNode) !== frame.iframeNode || isActiveElementFrame) {
                    logger.log('renderSelection: focus frame.iframeNode', { frame, iframeNode: frame.iframeNode });
                    frame.iframeNode.focus();
                }
            }

            if (!frame.doc.hasFocus() || isActiveElementFrame) {
                logger.log('renderSelection: focus frame.doc', { frame, doc: frame.doc });
                DOM.focusDocument(frame.doc);
            }

            if (!DOM.setSelection(newSelection, frame.id)) {
                DOM.clearSelectionFromDocument(frame.doc);
            }

            this.checkSelection(frame);
        }
    }

    /** @param {Frame} frame */
    getSelectionForServer(frame) {
        this.checkSelection(frame);

        const currentActiveUid = getActiveUid(frame);
        const currentSelection = DOM.getSelection(frame.doc);
        const isSelectionExpected = (currentActiveUid === this.expectedActiveUid)
            && isEqual(currentSelection, this.expectedSelection);

        // debug.log('getSelectionForServer', JSON.stringify({
        //     isSelectionExpected,
        //     expectedActiveUid: this.expectedActiveUid,
        //     currentActiveUid,
        //     expectedSelection: this.expectedSelection,
        //     currentSelection,
        //     lastEvent: this.lastEvent,
        // }));

        if (!isSelectionExpected) {
            return currentSelection;
        }
    }

    onMouseDown = (frame, isIgnored) => {
        this.lastEvent = { type: EVENT_TYPE.MOUSE, isIgnored };
        if (!isIgnored) {
            this.isClientSelectionHasPriorityOverServer = true;
            setTimeout(() => this.checkSelection(frame));
        } else {
            this.isClientHasUncommitedSelection = true;
        }
    };

    onMouseUp = (frame, isIgnored) => {
        this.lastEvent = { type: EVENT_TYPE.MOUSE, isIgnored };
        if (!isIgnored) {
            this.isClientSelectionHasPriorityOverServer = false;
            setTimeout(() => this.checkSelection(frame));
        }
    };

    onMouseDragRelease = (frame, isIgnored) => {
        this.lastEvent = { type: EVENT_TYPE.MOUSE, isIgnored };
        this.isClientSelectionHasPriorityOverServer = false;
    };

    onKeyDown = (frame, event, isIgnored) => {
        this.lastEvent = {
            type: EVENT_TYPE.KEYDOWN,
            key: event.keyCode,
            mod: Mod.fromEvent(event),
            isIgnored,
        };
        this.checkSelection(frame);
        if (isIgnored) {
            this.isClientHasUncommitedSelection = true;
        }
    };

    onTouchStart = (frame, isIgnored) => {
        this.lastEvent = { type: EVENT_TYPE.TOUCH, isIgnored };
        this.isClientSelectionHasPriorityOverServer = true;
    };

    onTouchEnd = (frame, isIgnored) => {
        this.lastEvent = { type: EVENT_TYPE.TOUCH, isIgnored };
        this.isClientSelectionHasPriorityOverServer = false;
    };
}

export default new ClientSelectionController();
