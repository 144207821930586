import { IS_BROWSER_IE11 } from '../env';
import LocalStorage, { LS_KEY } from '../LocalStorage';

// made it's own module in case business logic grows

export function isHuman() {
    // ie11 privilege
    if (IS_BROWSER_IE11) {
        return true;
    }

    // devices with touch input
    if (window.matchMedia && window.matchMedia('(pointer:coarse)').matches) {
        return true;
    }

    return !!LocalStorage.get(LS_KEY.userGesture, false);
}

export function registerUserGesture() {
    LocalStorage.set(LS_KEY.userGesture, true);
}
