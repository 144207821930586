import b from 'b_';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import BrowserStore from '../../browser/stores/BrowserStore';
import EnvStore from '../../stores/EnvStore';
import Bg from '../Bg/Bg';
import Button from '../Button';
import { PfptLogo } from '../Logo/PfptLogo';
import './BrowserHomeWebmail.css';

const block = b.lock('BrowserHomeWebmail');

class BrowserHomeWebmail extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        env: EnvStore.PropType.isRequired,
    };

    renderSite(siteData) {
        return <a
            key={siteData.id}
            href={`/browser?url=${encodeURIComponent(siteData.url)}`}
            className={block('Site')}
        >{siteData.name}</a>;
    }

    renderWebmailSites() {
        const { providers, visited, otherProvidersVisibility } = this.props.browser.webmail;
        const { t } = this.props;

        if (visited.length === 0) {
            return <Fragment>
                <div className={block('SitesHeader')}>{t('BrowserHomeWebmail.titleAll')}</div>
                <div className={block('SitesContainer')}>
                    {providers.map((item) => this.renderSite(item))}
                </div>
            </Fragment>;
        }

        return <Fragment>
            <div className={block('SitesHeader')}>{t('BrowserHomeWebmail.titleRecent')}</div>
            <div className={block('SitesContainer')}>
                {visited.map((item) => this.renderSite(item))}
            </div>
            <Button
                raised
                secondaryWhite
                onClick={otherProvidersVisibility.toggle}
                style={{ marginBottom: 20 }}
            >{t('BrowserHomeWebmail.titleOther')}</Button>
            {otherProvidersVisibility.isTrue ? <div className={block('SitesContainer')}>
                {providers.map((item) => this.renderSite(item))}
            </div> : null}
        </Fragment>;
    }

    render() {
        return <Bg className={block()}>
            <PfptLogo className={block('Logo')} />
            {this.props.env.isWebmailDefense ? this.renderWebmailSites() : null}
        </Bg>;
    }
}
export default translate()(inject('browser', 'env')(observer(BrowserHomeWebmail)));
