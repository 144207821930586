import React, { Component } from 'react';
import debug from '../../../debug';

export default class WysiwygErrorBoundary extends Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        debug.error('WysiwygErrorBoundary', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <span style={{ color: '#B71C1C' }}>Failed to render WYSIWYG</span>;
        }

        return this.props.children;
    }
}
