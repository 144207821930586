import debug from '../../debug';
import MediaSourceProxy from './MediaSourceProxy';
import EventLogger from '../../ClientEventLogger';

export default class MediaSourceRepository {
    constructor(frame) {
        this.frame = frame;
        this.mediaByBlobURL = new Map();
        this.mseById = new Map();
        this.destroyedIds = new Set();
    }

    destroy() {
        this.mseById.forEach((mediaSourceProxy) => mediaSourceProxy.destroy());
        this.mseById.clear();
    }

    onAction(mediaAction) {
        const mediaSourceProxy = this.getProxyById(mediaAction.parentId || mediaAction.mseId, mediaAction.actionId);

        if (!mediaSourceProxy) {
            return;
        }

        try {
            mediaSourceProxy.onAction(mediaAction);
        } catch (e) {
            EventLogger.recordMediaEvent({
                type: 'repositoryActionError',
                message: e.message || e,
            });
        }
    }

    getProxyById(id, actionId) {
        if (this.destroyedIds.has(id)) {
            return null;
        }

        if (!this.mseById.has(id)) {
            this.mseById.set(id, new MediaSourceProxy(id, actionId, () => {
                this.destroyedIds.add(id);
                this.mseById.delete(id);
            }, this.frame));
        }

        return this.mseById.get(id);
    }

    associateMediaWithBlobUrl(id, blobUrl) {
        this.mediaByBlobURL.set(blobUrl, id);
    }

    captureStreamForId(id, node) {
        this.getProxyById(id).setNode(node);
    }

    captureStreamForBlobURL(blobUrl, node) {
        if (this.mediaByBlobURL.has(blobUrl)) {
            this.captureStreamForId(this.mediaByBlobURL.get(blobUrl), node);
            this.mediaByBlobURL.delete(blobUrl);
        }
    }
}
