import getInitData from '../getInitData';

export default getInitData({
    env: null,
    release: null,
    type: null,
    // @see BrowserStoreInit.java
    stores: {
        env: {},
        browser: {},
        debug: {},
        research: {},
        passwordlessAuth: {},
        diagnostics: {},
    },

    errorViewCode: null,
    errorDetail: null,
});
