import once from 'lodash/once';
import debug from './debug';

const logger = debug.create('codecs', false);

/*
    code to collect codecs on sentry page from time to time:
    ```
        function normalizeCodec(codec) {
            return codec.toLowerCase().replace(/[\s'";]+/g, '');
        }

        function normalize(codecs) {
            const normalizedCodecs = new Set();

            console.log(codecs.filter((codec) => {
                codec = normalizeCodec(codec);

                if (normalizedCodecs.has(codec)) {
                    return false;
                }

                normalizedCodecs.add(codec);

                return true;
            }).sort().map((item) => `'${item}'`).join(',\n'));
        }


        function grabCodecs(result = []) {
            const nextButton = document.querySelector('[title="Next"]');

            if (!nextButton) {
                setTimeout(() => grabCodecs(result), 100);
                return;
            }

            const nextDisabled = nextButton.parentNode.classList.contains('disabled');

            const items = Array.from(document.querySelectorAll('h5 > small'));

            result.push(...items.map((item) => item.innerHTML.slice('Error: We should add this codec to checking list: '.length).trim()).filter(Boolean))

            if (!nextDisabled) {
                nextButton.parentNode.click();
                setTimeout(() => grabCodecs(result), 100);
            } else {
                normalize(result)
            }
        }

        grabCodecs();
    ```
*/

const codecs = Array.from(new Set([
    'application/dash+xml',
    'application/f4m+xml',
    'application/mp4',
    'application/octet-stream',
    'application/ogg',
    'application/vnd.apple.mpegurl;codecs="avc1.42E01E,mp4a.40.2"',
    'application/vnd.apple.mpegurl;codecs="mp4a.40.2"',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/3gpp; codecs="samr"',
    'audio/3gpp;',
    'audio/AMR-NB',
    'audio/AMR-WB',
    'audio/MP4A-LATM',
    'audio/MPA',
    'audio/aac',
    'audio/aac; codecs="flac"',
    'audio/aacp',
    'audio/ac3',
    'audio/acc',
    'audio/amr',
    'audio/amr; codecs="hvc1x"',
    'audio/basic',
    'audio/flac;',
    'audio/gsm',
    'audio/m4a',
    'audio/m4b;',
    'audio/mid',
    'audio/midi',
    'audio/mp3',
    'audio/mp3; codecs="vp9"',
    'audio/mp4',
    'audio/mp4; codecs="ac-3"',
    'audio/mp4; codecs="ec-3"',
    'audio/mp4; codecs="mp3"',
    'audio/mp4; codecs="mp4a.40.2"',
    'audio/mp4; codecs="mp4a.40.2"; bitrate="128290"',
    'audio/mp4; codecs="mp4a.40.2"; bitrate="92416"; channels="2"',
    'audio/mp4; codecs="mp4a.40.2"; channels=2',
    'audio/mp4; codecs="mp4a.40.2"; channels=99',
    'audio/mp4; codecs="mp4a.40.29"',
    'audio/mp4; codecs="mp4a.40.34"',
    'audio/mp4; codecs="mp4a.40.5"',
    'audio/mp4; codecs="opus"',
    'audio/mp4; codecs=bogus',
    'audio/mp4;codecs="mp4a.40.2";bitrate="128000"',
    'audio/mp4;codecs="mp4a.40.2";bitrate="128000";channels="2"',
    'audio/mp4;codecs="mp4a.40.2";bitrate="96000"',
    'audio/mp4;codecs=mp4a.40.2',
    'audio/mpa-robust',
    'audio/mpeg4-generic',
    'audio/mpeg; codecs="mp3"',
    'audio/mpeg; codecs="mp4a.40.2"',
    'audio/mpeg;',
    'audio/mpegurl;codecs="avc1.42E01E,mp4a.40.2"',
    'audio/oga',
    'audio/ogg',
    'audio/ogg; codecs="flac"',
    'audio/ogg; codecs="opus"',
    'audio/ogg; codecs="speex"',
    'audio/ogg; codecs="theora, opus"',
    'audio/ogg; codecs="vorbis"',
    'audio/ogg; codecs=bogus',
    'audio/ogg;codecs="vorbis,opus"',
    'audio/opus',
    'audio/rtmp;codecs="rtmp"',
    'audio/tta',
    'audio/wav',
    'audio/wav; codecs="0"',
    'audio/wav; codecs="1"',
    'audio/wav; codecs="2"',
    'audio/wave',
    'audio/wave; codecs=0',
    'audio/wave; codecs=1',
    'audio/wave; codecs=2',
    'audio/webm',
    'audio/webm; codecs="opus"',
    'audio/webm; codecs="opus"; channels=2',
    'audio/webm; codecs="opus"; channels=99',
    'audio/webm; codecs="vorbis"',
    'audio/webm; codecs="vp8"',
    'audio/webm;',
    'audio/webm;codecs="vp8,vorbis"',
    'audio/wv',
    'audio/x-aac',
    'audio/x-ac3',
    'audio/x-aiff',
    'audio/x-caf;',
    'audio/x-flac;',
    'audio/x-m4a',
    'audio/x-m4a; codecs="mp3"',
    'audio/x-m4a; codecs="vp8, mp4a.40"',
    'audio/x-m4a; codecs="vp9, mp4a.40.2"',
    'audio/x-m4b;',
    'audio/x-midi',
    'audio/x-mp4;',
    'audio/x-mpeg',
    'audio/x-mpegurl',
    'audio/x-mpegurl;codecs="avc1.42E01E,mp4a.40.2"',
    'audio/x-pn-wav',
    'audio/x-pn-wav; codecs=0',
    'audio/x-pn-wav; codecs=1',
    'audio/x-pn-wav; codecs=2',
    'audio/x-wav',
    'audio/x-wav; codecs=0',
    'audio/x-wav; codecs=1',
    'audio/x-wav; codecs=2',
    'mp4a.40.2"',
    'video/3gp',
    'video/3gpp',
    'video/3gpp2',
    'video/3gpp; codecs="mp4v.20.3, mp4a.40.2"',
    'video/3gpp; codecs="mp4v.20.8"',
    'video/3gpp; codecs="mp4v.20.8, samr"',
    'video/aac',
    'video/asf',
    'video/avi',
    'video/f4m',
    'video/flv',
    'video/gma-demuxed',
    'video/mkv',
    'video/mov',
    'video/mp2t; codecs="avc1.42E01E,mp4a.40.2"',
    'video/mp4',
    'video/mp4; codecs="H.264, aac"',
    'video/mp4; codecs="H.264, mp3"',
    'video/mp4; codecs="av01"',
    'video/mp4; codecs="av01.0.00M.08.0.110.06.01.06.0"',
    'video/mp4; codecs="av01.0.01M.08.0.110.06.01.06.0"',
    'video/mp4; codecs="av01.0.09M.08"',
    'video/mp4; codecs="av01.0.12M.08"',
    'video/mp4; codecs="avc1.123456"',
    'video/mp4; codecs="avc1.42000a"',
    'video/mp4; codecs="avc1.42001E, mp4a.40.2"',
    'video/mp4; codecs="avc1.42C01E,mp4a.40.5"',
    'video/mp4; codecs="avc1.42E009"',
    'video/mp4; codecs="avc1.42E01E"',
    'video/mp4; codecs="avc1.42E01E", mp4a.40.2"',
    'video/mp4; codecs="avc1.42E034"',
    'video/mp4; codecs="avc1.42F01E"',
    'video/mp4; codecs="avc1.42c00b"',
    'video/mp4; codecs="avc1.42c00b"; width=256',
    'video/mp4; codecs="avc1.42c00c"',
    'video/mp4; codecs="avc1.42c00c"; width=256',
    'video/mp4; codecs="avc1.42c00d"',
    'video/mp4; codecs="avc1.4D401E"',
    'video/mp4; codecs="avc1.4D401E, mp4a.40.2"',
    'video/mp4; codecs="avc1.4D401F, mp4a.40.2"',
    'video/mp4; codecs="avc1.4d001e, mp4a.40.2"',
    'video/mp4; codecs="avc1.4d0020,mp4a.40.2"',
    'video/mp4; codecs="avc1.4d0020,mp4a.40.5"',
    'video/mp4; codecs="avc1.4d0029"',
    'video/mp4; codecs="avc1.4d400b"',
    'video/mp4; codecs="avc1.4d400b"; width=144',
    'video/mp4; codecs="avc1.4d400b"; width=192',
    'video/mp4; codecs="avc1.4d400b"; width=192; height=144; framerate=25; bitrate=91017',
    'video/mp4; codecs="avc1.4d400b"; width=270',
    'video/mp4; codecs="avc1.4d400b"; width=640',
    'video/mp4; codecs="avc1.4d400b"; width=82',
    'video/mp4; codecs="avc1.4d400c"',
    'video/mp4; codecs="avc1.4d400c"; width=136',
    'video/mp4; codecs="avc1.4d400c"; width=192',
    'video/mp4; codecs="avc1.4d400c"; width=194',
    'video/mp4; codecs="avc1.4d400c"; width=240',
    'video/mp4; codecs="avc1.4d400c"; width=254',
    'video/mp4; codecs="avc1.4d400c"; width=256',
    'video/mp4; codecs="avc1.4d400c"; width=256; height=144; framerate=25; bitrate=115485',
    'video/mp4; codecs="avc1.4d400c"; width=320',
    'video/mp4; codecs="avc1.4d400d"',
    'video/mp4; codecs="avc1.4d400d"; width=202',
    'video/mp4; codecs="avc1.4d400d"; width=204',
    'video/mp4; codecs="avc1.4d400d"; width=320',
    'video/mp4; codecs="avc1.4d400d"; width=320; height=240; framerate=25; bitrate=193881',
    'video/mp4; codecs="avc1.4d400d"; width=322',
    'video/mp4; codecs="avc1.4d400d"; width=426',
    'video/mp4; codecs="avc1.4d400d,mp4a.40.5"',
    'video/mp4; codecs="avc1.4d4014"',
    'video/mp4; codecs="avc1.4d4015"',
    'video/mp4; codecs="avc1.4d4015"; width=270',
    'video/mp4; codecs="avc1.4d4015"; width=360',
    'video/mp4; codecs="avc1.4d4015"; width=424',
    'video/mp4; codecs="avc1.4d4015"; width=426',
    'video/mp4; codecs="avc1.4d4015"; width=426; height=240; framerate=25; bitrate=257646',
    'video/mp4; codecs="avc1.4d4015"; width=480',
    'video/mp4; codecs="avc1.4d4015"; width=480; height=360; framerate=25; bitrate=477584',
    'video/mp4; codecs="avc1.4d4015"; width=640',
    'video/mp4; codecs="avc1.4d4015,mp4a.40.5"',
    'video/mp4; codecs="avc1.4d4016"',
    'video/mp4; codecs="avc1.4d4016"; width=640',
    'video/mp4; codecs="avc1.4d401e"; bitrate=2000000000',
    'video/mp4; codecs="avc1.4d401e"; bitrate=300000',
    'video/mp4; codecs="avc1.4d401e"; eotf=catavision',
    'video/mp4; codecs="avc1.4d401e"; framerate=30',
    'video/mp4; codecs="avc1.4d401e"; framerate=9999',
    'video/mp4; codecs="avc1.4d401e"; height=360',
    'video/mp4; codecs="avc1.4d401e"; height=99999',
    'video/mp4; codecs="avc1.4d401e"; width=406',
    'video/mp4; codecs="avc1.4d401e"; width=480',
    'video/mp4; codecs="avc1.4d401e"; width=636',
    'video/mp4; codecs="avc1.4d401e"; width=640',
    'video/mp4; codecs="avc1.4d401e"; width=640; height=360; framerate=25; bitrate=635812',
    'video/mp4; codecs="avc1.4d401e"; width=640; height=480; framerate=25; bitrate=895000',
    'video/mp4; codecs="avc1.4d401e"; width=854',
    'video/mp4; codecs="avc1.4d401e"; width=854; height=480; framerate=25; bitrate=1164976',
    'video/mp4; codecs="avc1.4d401e"; width=99999',
    'video/mp4; codecs="avc1.4d401f"; width=1280',
    'video/mp4; codecs="avc1.4d401f"; width=1280; height=720; framerate=25; bitrate=2328418',
    'video/mp4; codecs="avc1.4d401f"; width=848',
    'video/mp4; codecs="avc1.4d401f"; width=854',
    'video/mp4; codecs="avc1.4d401f"; width=960',
    'video/mp4; codecs="avc1.4d4020"',
    'video/mp4; codecs="avc1.4d4020"; width=1280',
    'video/mp4; codecs="avc1.4d4028"',
    'video/mp4; codecs="avc1.58A01E"',
    'video/mp4; codecs="avc1.58A01E, mp4a.40.2"',
    'video/mp4; codecs="avc1.640010"',
    'video/mp4; codecs="avc1.640015,mp4a.40.2"',
    'video/mp4; codecs="avc1.64001E"',
    'video/mp4; codecs="avc1.64001E, mp4a.40.2"',
    'video/mp4; codecs="avc1.64001F, mp4a.40.2"',
    'video/mp4; codecs="avc1.64001e"; bitrate="994320"; width="720"; height="406"',
    'video/mp4; codecs="avc1.64001f"',
    'video/mp4; codecs="avc1.64001f"; width=608',
    'video/mp4; codecs="avc1.640020"',
    'video/mp4; codecs="avc1.640020"; width=810',
    'video/mp4; codecs="avc1.640021"',
    'video/mp4; codecs="avc1.640028"',
    'video/mp4; codecs="avc1.640028"; width=1920',
    'video/mp4; codecs="avc1.640028"; width=1920; height=1080; framerate=25; bitrate=4450001',
    'video/mp4; codecs="avc1.640029,mp4a.40.2"',
    'video/mp4; codecs="avc1.640029,mp4a.40.5"',
    'video/mp4; codecs="avc1.64002a"',
    'video/mp4; codecs="avc1.64002a"; width=1920',
    'video/mp4; codecs="avc1.640032"',
    'video/mp4; codecs="avc1.640032, mp4a.40.2"',
    'video/mp4; codecs="avc1.640033"',
    'video/mp4; codecs="avc1.640033, mp4a.40.2"',
    'video/mp4; codecs="avc1x"',
    'video/mp4; codecs="avc3.42001E"',
    'video/mp4; codecs="flac"',
    'video/mp4; codecs="hev1"',
    'video/mp4; codecs="hev1.1.6.L63.90,mp4a.40.29"',
    'video/mp4; codecs="hev1.1.6.L93.B0"',
    'video/mp4; codecs="hvc1.1.6.L60.90"',
    'video/mp4; codecs="hvc1.1.6.L63.90,mp4a.40.29"',
    'video/mp4; codecs="lavc1337"',
    'video/mp4; codecs="mp4a.40.02"',
    'video/mp4; codecs="mp4a.40.2"',
    'video/mp4; codecs="mp4a.40.2,avc1.4d0020"',
    'video/mp4; codecs="mp4a.40.2,avc1.64001e"',
    'video/mp4; codecs="mp4a.40.2,avc1.64002a"',
    'video/mp4; codecs="mp4a.67"',
    'video/mp4; codecs="mp4v.20.240"',
    'video/mp4; codecs="mp4v.20.240, mp4a.40.2"',
    'video/mp4; codecs="mp4v.20.8"',
    'video/mp4; codecs="mp4v.20.8, mp4a.40.2"',
    'video/mp4; codecs="vp09.00.50.08"',
    'video/mp4; codecs=av01.0.05M.08',
    'video/mp4; codecs=av99.0.05M.08',
    'video/mp4; codecs=avc1.42E01E,avc1.4D401E,avc1.640',
    'video/mp4; codecs=bogus',
    'video/mp4;codecs="av01.0.00M.08"',
    'video/mp4;codecs="av01.0.00M.08";eotf=bt709',
    'video/mp4;codecs="av01.0.00M.08.0.110.01.01.06.0";eotf=bt709',
    'video/mp4;codecs="av01.0.00M.08.0.110.06.01.06.0";eotf=bt709',
    'video/mp4;codecs="av01.0.01M.08"',
    'video/mp4;codecs="av01.0.01M.08";eotf=bt709',
    'video/mp4;codecs="av01.0.04M.08"',
    'video/mp4;codecs="av01.0.04M.08";eotf=bt709',
    'video/mp4;codecs="av01.0.05M.08";eotf=bt709',
    'video/mp4;codecs="av01.0.08M.08"',
    'video/mp4;codecs="av01.0.08M.08";eotf=bt709',
    'video/mp4;codecs="av01.0.09M.08";eotf=bt709',
    'video/mp4;codecs="av01.0.12M.08";eotf=bt709',
    'video/mp4;codecs="av01.0.31M.08.0.110.01.01.01.0"',
    'video/mp4;codecs="avc1.42000b"',
    'video/mp4;codecs="avc1.42000c"',
    'video/mp4;codecs="avc1.42000d"',
    'video/mp4;codecs="avc1.420016,mp4a.40.5"',
    'video/mp4;codecs="avc1.42001F,mp4a.40.2"',
    'video/mp4;codecs="avc1.42001e,mp4a.40.5"',
    'video/mp4;codecs="avc1.42801f"',
    'video/mp4;codecs="avc1.42C01E,mp4a.40.2"',
    'video/mp4;codecs="avc1.42E01F"',
    'video/mp4;codecs="avc1.42c00d,mp4a.40.2"',
    'video/mp4;codecs="avc1.42c01e";bitrate="192000";width="416";height="234"',
    'video/mp4;codecs="avc1.42c01e";bitrate="300000";width="416";height="240"',
    'video/mp4;codecs="avc1.42c01e";bitrate="480000";width="640";height="360"',
    'video/mp4;codecs="avc1.42c01e";bitrate="640000";width="640";height="360"',
    'video/mp4;codecs="avc1.42c01e,mp4a.40.2"',
    'video/mp4;codecs="avc1.42c01f";bitrate="1536000";width="960";height="540"',
    'video/mp4;codecs="avc1.42c01f";bitrate="896000";width="640";height="360"',
    'video/mp4;codecs="avc1.42c01f,mp4a.40.2"',
    'video/mp4;codecs="avc1.42c020"',
    'video/mp4;codecs="avc1.42e00a"',
    'video/mp4;codecs="avc1.42e020"',
    'video/mp4;codecs="avc1.4D001F,mp4a.40.2"',
    'video/mp4;codecs="avc1.4D4028,mp4a.40.2"',
    'video/mp4;codecs="avc1.4D481E"',
    'video/mp4;codecs="avc1.4De016"',
    'video/mp4;codecs="avc1.4d000d"',
    'video/mp4;codecs="avc1.4d0016"',
    'video/mp4;codecs="avc1.4d001e,mp4a.40.5"',
    'video/mp4;codecs="avc1.4d001f,mp4a.40.2"',
    'video/mp4;codecs="avc1.4d001f,mp4a.40.5"',
    'video/mp4;codecs="avc1.4d0028"',
    'video/mp4;codecs="avc1.4d400d,mp4a.40.2"',
    'video/mp4;codecs="avc1.4d401e";eotf=bt709',
    'video/mp4;codecs="avc1.4d401e";width="240";height="240"',
    'video/mp4;codecs="avc1.4d401e";width="360";height="360"',
    'video/mp4;codecs="avc1.4d401e";width="426";height="240"',
    'video/mp4;codecs="avc1.4d401e";width="640";height="360"',
    'video/mp4;codecs="avc1.4d401f";bitrate="1280000";width="640";height="360"',
    'video/mp4;codecs="avc1.4d401f";bitrate="1856000";width="960";height="540"',
    'video/mp4;codecs="avc1.4d401f";bitrate="2048000";width="1024";height="576"',
    'video/mp4;codecs="avc1.4d401f";bitrate="640000";width="640";height="360"',
    'video/mp4;codecs="avc1.4d401f";width="1280";height="720"',
    'video/mp4;codecs="avc1.4d401f";width="480";height="480"',
    'video/mp4;codecs="avc1.4d401f,mp4a.40.5"',
    'video/mp4;codecs="avc1.4d4020,mp4a.40.2"',
    'video/mp4;codecs="avc1.4d4029"',
    'video/mp4;codecs="avc1.4d4029,mp4a.40.2"',
    'video/mp4;codecs="avc1.4d402a"',
    'video/mp4;codecs="avc1.4d404f"',
    'video/mp4;codecs="avc1.64000D"',
    'video/mp4;codecs="avc1.64000d"',
    'video/mp4;codecs="avc1.640015"',
    'video/mp4;codecs="avc1.640015,mp4a.40.29"',
    'video/mp4;codecs="avc1.64001F";width="1280";height="720"',
    'video/mp4;codecs="avc1.64001f,mp4a.40.5"',
    'video/mp4;codecs="avc1.640020,mp4a.40.2"',
    'video/mp4;codecs="avc1.640028";width="1920";height="1080"',
    'video/mp4;codecs="avc1.640028,mp4a.40.2"',
    'video/mp4;codecs="avc1.640028,mp4a.40.5"',
    'video/mp4;codecs="avc1.640029";bitrate="3500000";width="1280";height="720"',
    'video/mp4;codecs="avc1.64002a,mp4a.40.2"',
    'video/mp4;codecs="avc1.64081E"',
    'video/mp4;codecs="avc1.64081F"',
    'video/mp4;codecs="avc1.640828"',
    'video/mp4;codecs="avc3.42C015"',
    'video/mp4;codecs="avc3.4D4015";width="512";height="288"',
    'video/mp4;codecs="avc3.4D401E";width="512";height="288"',
    'video/mp4;codecs="avc3.4D401E";width="704";height="396"',
    'video/mp4;codecs="avc3.4D401F";width="704";height="396"',
    'video/mp4;codecs="avc3.4D401F";width="960";height="540"',
    'video/mp4;codecs="avc3.64001F";width="960";height="540"',
    'video/mp4;codecs="avc3.640020";width="1280";height="720"',
    'video/mp4;codecs="hvc1"',
    'video/mp4;codecs="mp4a.40.2,avc1.42000c"',
    'video/mp4;codecs="mp4a.40.2,avc1.42000d"',
    'video/mp4;codecs="mp4a.40.2,avc1.420015"',
    'video/mp4;codecs="mp4a.40.2,avc1.42001E"',
    'video/mp4;codecs="mp4a.40.2,avc1.42001e"',
    'video/mp4;codecs="mp4a.40.2,avc1.42001f"',
    'video/mp4;codecs="mp4a.40.2,avc1.4D001F"',
    'video/mp4;codecs="mp4a.40.2,avc1.4D0028"',
    'video/mp4;codecs="mp4a.40.2,avc1.4D401F"',
    'video/mp4;codecs="mp4a.40.2,avc1.4d0015"',
    'video/mp4;codecs="mp4a.40.2,avc1.4d001e"',
    'video/mp4;codecs="mp4a.40.2,avc1.4d001f"',
    'video/mp4;codecs="mp4a.40.2,avc1.4d0028"',
    'video/mp4;codecs="mp4a.40.2,avc1.64001f"',
    'video/mp4;codecs="mp4a.40.2,avc1.640020"',
    'video/mp4;codecs="mp4a.40.2,avc1.640028"',
    'video/mp4;codecs="mp4a.40.29"',
    'video/mp4;codecs="mp4a.40.5"',
    'video/mp4;codecs="mp4a.40.5,avc1.42000b"',
    'video/mp4;codecs="mp4a.40.5,avc1.42000c"',
    'video/mp4;codecs="mp4a.40.5,avc1.42000d"',
    'video/mp4;codecs="mp4a.40.5,avc1.42C01E"',
    'video/mp4;codecs="mp4a.40.5,avc1.4d001e"',
    'video/mp4;codecs="mp4a.40.5,avc1.4d001f"',
    'video/mp4;codecs="vp09.00.10.08"',
    'video/mp4;codecs=avc1.420015',
    'video/mp4;codecs=avc1.42001e',
    'video/mp4;codecs=avc1.42001f',
    'video/mp4;codecs=avc1.42C01F',
    'video/mp4;codecs=avc1.42c015',
    'video/mp4;codecs=avc1.42c01e',
    'video/mp4;codecs=avc1.4D401F',
    'video/mp4;codecs=avc1.4d0015',
    'video/mp4;codecs=avc1.4d001e',
    'video/mp4;codecs=avc1.4d001f',
    'video/mp4;codecs=avc1.4d0020',
    'video/mp4;codecs=avc1.4d400a',
    'video/mp4;codecs=avc1.640029',
    'video/mp4;codecs=vp09.00.11.08.02;',
    'video/mpeg',
    'video/mpeg; codec="H.264"',
    'video/msvideo',
    'video/ogg',
    'video/ogg; codecs="dirac"',
    'video/ogg; codecs="dirac, vorbis"',
    'video/ogg; codecs="flac"',
    'video/ogg; codecs="opus"',
    'video/ogg; codecs="theora"',
    'video/ogg; codecs="theora, speex"',
    'video/ogg; codecs="theora, vorbis"',
    'video/ogg; codecs="vp8"',
    'video/ogg; codecs=bogus',
    'video/ogg; codecs=speex',
    'video/ogv',
    'video/qt',
    'video/quicktime',
    'video/quicktime; codecs="avc1.42E01E,mp4a.40.2"',
    'video/vnd.mpeg.dash.mpd',
    'video/webm',
    'video/webm: codecs="vp9"',
    'video/webm; codecs="av01.0.04M.08"',
    'video/webm; codecs="vorbis, vp8"',
    'video/webm; codecs="vorbis, vp9"',
    'video/webm; codecs="vp09.00.51.08.01.01.01.01"',
    'video/webm; codecs="vp09.02.10.08"',
    'video/webm; codecs="vp09.02.51.10.01.09.16.09"',
    'video/webm; codecs="vp09.02.51.10.01.09.16.09.00"',
    'video/webm; codecs="vp09.02.51.10.01.09.99.99"',
    'video/webm; codecs="vp8"',
    'video/webm; codecs="vp8, vorbis"',
    'video/webm; codecs="vp8, vorbis"; codecs=',
    'video/webm; codecs="vp8.0, vorbis"',
    'video/webm; codecs="vp9"',
    'video/webm; codecs="vp9"; bitrate=2000000000',
    'video/webm; codecs="vp9"; bitrate=300000',
    'video/webm; codecs="vp9"; eotf=bt709',
    'video/webm; codecs="vp9"; framerate=30',
    'video/webm; codecs="vp9"; framerate=9999',
    'video/webm; codecs="vp9"; height=360',
    'video/webm; codecs="vp9"; height=99999',
    'video/webm; codecs="vp9"; width=1080',
    'video/webm; codecs="vp9"; width=1280',
    'video/webm; codecs="vp9"; width=1280; height=720; framerate=25; bitrate=1633159',
    'video/webm; codecs="vp9"; width=136',
    'video/webm; codecs="vp9"; width=192',
    'video/webm; codecs="vp9"; width=1920',
    'video/webm; codecs="vp9"; width=1920; height=1080; framerate=25; bitrate=2929239',
    'video/webm; codecs="vp9"; width=192; height=144; framerate=25; bitrate=95112',
    'video/webm; codecs="vp9"; width=204',
    'video/webm; codecs="vp9"; width=256',
    'video/webm; codecs="vp9"; width=256; height=144; framerate=13; bitrate=127762',
    'video/webm; codecs="vp9"; width=270',
    'video/webm; codecs="vp9"; width=320',
    'video/webm; codecs="vp9"; width=320; height=240; framerate=25; bitrate=226648',
    'video/webm; codecs="vp9"; width=3840; height=2160; bitrate=2000000',
    'video/webm; codecs="vp9"; width=3840; height=2160; bitrate=2000000',
    'video/webm; codecs="vp9"; width=406',
    'video/webm; codecs="vp9"; width=426',
    'video/webm; codecs="vp9"; width=426; height=240; framerate=25; bitrate=254064',
    'video/webm; codecs="vp9"; width=480',
    'video/webm; codecs="vp9"; width=480; height=360; framerate=25; bitrate=402037',
    'video/webm; codecs="vp9"; width=608',
    'video/webm; codecs="vp9"; width=640',
    'video/webm; codecs="vp9"; width=640; height=360; framerate=25; bitrate=463990',
    'video/webm; codecs="vp9"; width=640; height=480; framerate=25; bitrate=745345',
    'video/webm; codecs="vp9"; width=810',
    'video/webm; codecs="vp9"; width=82',
    'video/webm; codecs="vp9"; width=854',
    'video/webm; codecs="vp9"; width=854; height=480; framerate=25; bitrate=845312',
    'video/webm; codecs="vp9"; width=960',
    'video/webm; codecs="vp9"; width=99999',
    'video/webm; codecs="vp9, opus"',
    'video/webm; codecs="vp9, vorbis"',
    'video/webm; codecs=vorbis',
    'video/webm; codecs=vp8.0',
    'video/webm;codecs="vp09.00.51.08.01.01.01.01";eotf=bt709',
    'video/webm;codecs="vp09.00.51.08.01.01.01.01.00"',
    'video/webm;codecs="vp09.00.51.08.01.01.01.01.00";eotf=bt709',
    'video/webm;codecs="vp9";eotf=catavision',
    'video/webm;codecs="vp9";width=3840;height=2160;bitrate=20000000',
    'video/wmv',
    'video/x-dv; codecs="avc3.12345"',
    'video/x-flv',
    'video/x-flv; codecs="vp6"',
    'video/x-m4v',
    'video/x-m4v; codecs="avc1.42AC23"',
    'video/x-m4v; codecs="avc1.42E01E,mp4a.40.2"',
    'video/x-matroska',
    'video/x-matroska; codecs="theora"',
    'video/x-matroska; codecs="theora, vorbis"',
    'video/x-mpeg',
    'video/x-msvideo',
    'video/x-webm; codecs="vp8, vorbis"',
    'video/youtube',
]));

function normalizeCodec(codec) {
    return codec.toLowerCase().replace(/[\s'";]+/g, '');
}

const ignoredCodecs = new Set([
    'application/mp4;codecs=bogus',
    'application/octet-stream;codecs=bogus',
    'application/ogg;codecs=bogus',
    'samr"',
    'speex"',
    'dfp',
    'audio/wma',
    'vorbis"',
    'audio/aiff',
    'text/mp4;codecs="unknown"',
    'video/x-ms-wmv',
].map((codec) => normalizeCodec(codec)));

const normalizedCodecs = codecs.map((codec) => normalizeCodec(codec));

function isChecked(codec) {
    codec = normalizeCodec(codec);

    if (ignoredCodecs.has(codec) || !codec || codec.includes('mpegurl')) {
        return true;
    }

    return normalizedCodecs.indexOf(codec) !== -1;
}

const CODEC_MSE_SUPPORTED = 1;
const CODEC_HTML5_PROBABLY = 2;
const CODEC_HTML5_MAYBE = 3;

function encode() {
    try {
        const mediaElement = document.createElement('video');
        const isMediaSourceSupported = window && window.MediaSource && window.MediaSource.isTypeSupported;

        const masks = codecs.map((codec) => {
            const isSupported = mediaElement.canPlayType(codec);

            if (isSupported) {
                logger.log('supported codec', codec, isSupported);
            }

            let mask = 0;

            if (isMediaSourceSupported && window.MediaSource.isTypeSupported(codec)) {
                mask |= Math.pow(2, CODEC_MSE_SUPPORTED);
            }

            if (isSupported === 'probably') {
                mask |= Math.pow(2, CODEC_HTML5_PROBABLY);
            } else if (isSupported === 'maybe') {
                if (
                    codec.indexOf('vp9') === -1
                    && codec.indexOf('vp8') === -1
                    && codec.indexOf('webm') === -1
                ) {
                    mask |= Math.pow(2, CODEC_HTML5_MAYBE);
                }
            }

            return mask;
        });

        return window.btoa(String.fromCharCode(...masks));
    } catch (e) {
        logger.recordError(e);
        return codecs.map(() => 0).join('');
    }
}

function decode(mask) {
    const masks = Array.from(window.atob(mask)).map((c) => c.charCodeAt(0));

    return normalizedCodecs.reduce((accum, codec, index) => {
        const bitmask = masks[index];

        accum[codec] = {
            mseSupported: !!(bitmask & Math.pow(2, CODEC_MSE_SUPPORTED)),
            html5Probably: !!(bitmask & Math.pow(2, CODEC_HTML5_PROBABLY)),
            html5Maybe: !!(bitmask & Math.pow(2, CODEC_HTML5_MAYBE)),
        };

        return accum;
    }, {});
}

export default {
    encode: once(encode),
    decode,
    isChecked,
};
