import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import b from 'b_';
import BrowserStore from '../../browser/stores/BrowserStore';
import HistoryStore from '../../browser/stores/HistoryStore';
import ResearchStore from '../../browser/stores/ResearchStore';
import clientConfig from '../../clientConfig';
import { BROWSER_API_VERSION } from '../../env';
import { IFRAME_REAL_SCREEN_SIZE_ATTR } from '../../Screen';

import './ThePage.css';

const block = b.lock('ThePage');

class ThePage extends Component {
    static propTypes = {
        iframeSrc: PropTypes.string.isRequired,
        onLoad: PropTypes.func,
        blur: PropTypes.bool,
        iframeOpacity: PropTypes.number,
        browser: BrowserStore.PropType.isRequired,
        history: HistoryStore.PropType.isRequired,
        research: ResearchStore.PropType.isRequired,
    };

    frameNamePrefix = Date.now();

    onIframeAppearance = (ref) => {
        if (ref && ref.src !== this.props.iframeSrc) {
            clientConfig.updateConfig();
            ref.src = this.props.iframeSrc;
        }
        this.props.browser.setFrameEl(ref);
    };

    render() {
        const { browser, history, research } = this.props;
        const viewportStyle = {};

        if (browser.viewport) {
            const screenRatio = browser.screen.width / browser.viewport.width;
            viewportStyle.transform = screenRatio !== 1 ? `scale(${screenRatio})` : '';
            viewportStyle.width = browser.viewport.width + 'px';
            viewportStyle.height = browser.viewport.height + 'px';
        }

        return <div
            className={block('IframeWrapper')}
            {...{ [IFRAME_REAL_SCREEN_SIZE_ATTR]: true }}
        >
            <div
                className={block('IframeViewport')}
                key={this.props.iframeSrc}
                style={viewportStyle}
            >
                {/* src will be set in onIframeAppearance because we need to change cookie */}
                <iframe
                    title="Page Content"
                    ref={this.onIframeAppearance}
                    key={this.props.iframeSrc}
                    style={{ opacity: this.props.iframeOpacity === 0 ? 0 : 1 }}
                    className={block('Iframe', { blur: this.props.blur })}
                    name={`page${this.frameNamePrefix}_${history.location.state.clientPageId}`}
                    onLoad={this.props.onLoad}
                    onError={this.props.onLoad}
                    data-disable-focus={browser.isFocused() || research.isFocused ? 'true' : ''}
                    data-browser-api-version={BROWSER_API_VERSION}
                    allowFullScreen
                    scrolling="yes"
                />
            </div>
            <div className={block('Overlay', { visible: browser.isPageOverlayVisible() })} />
        </div>;
    }
}


export default inject('browser', 'history', 'research')(observer(ThePage));
