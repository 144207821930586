import PropTypes from 'prop-types';
import React from 'react';
import b from 'b_';
import cn from 'classnames';
import { LogoProofpointWord } from '../Logo/PfptLogo';
import { FTInput } from '../Fuse';
import './AuthLayout.css';


const block = b.lock('AuthLayout');

export function AuthPage({ children, notification, pageContentClass }) {
    return <div className={block()}>
        {notification}
        <div className={cn(block('PageContent'), pageContentClass)} >{children}</div>
    </div>;
}

AuthPage.propTypes = {
    notification: PropTypes.node,
    pageContentClass: PropTypes.string,
};


export function AuthBox({ children, onSubmit, className }) {
    const year = new Date()?.getFullYear() || 2023;
    return <div className={cn(block('FormContainer'), className)} >
        <LogoProofpointWord
            mainColor="#fff"
            className={block('BoxHeaderLogo')}
        />
        <form
            className={block('FormContent')}
            onSubmit={onSubmit}
            noValidate
        >
            {children}
        </form>
        <div className={block('BoxFooter')} >
            <FTInput className={block('FooterText')}>© Proofpoint, Inc. {year} All rights reserved</FTInput>
        </div>
    </div>;
}

AuthBox.propTypes = {
    onSubmit: PropTypes.func,
    className: PropTypes.string,
};
