import { IS_CLIENT, IS_EXTENSION } from '../../env';
import debug from '../../debug';
import { messageToBrowser } from '../client/browserMessageBus';
import * as DOM from '../DOM';
import { API_CLIENT_TO_BROWSER, F_VALUE } from '../sharedConstants';
import AbstractWatcher from './AbstractWatcher';
import reg from '../DOMRegistry';

const logger = debug.create('ValueWatcher', false);

const INPUT_TYPES = new Set([
    DOM.INPUT_TYPE_COLOR,
    DOM.INPUT_TYPE_DATE,
    DOM.INPUT_TYPE_DATETIME_LOCAL,
    DOM.INPUT_TYPE_EMAIL,
    DOM.INPUT_TYPE_MONTH,
    DOM.INPUT_TYPE_NUMBER,
    DOM.INPUT_TYPE_PASSWORD,
    DOM.INPUT_TYPE_RANGE,
    DOM.INPUT_TYPE_SEARCH,
    DOM.INPUT_TYPE_TEL,
    DOM.INPUT_TYPE_TEXT,
    DOM.INPUT_TYPE_TIME,
    DOM.INPUT_TYPE_URL,
    DOM.INPUT_TYPE_WEEK,
]);

function normalizeValue(value) {
    return '' + value;
}

export class ValueWatcher extends AbstractWatcher {
    isAllInputDisabled = false;
    isPasteDisabled = false;

    /** @param {DLPSettings} dlp */
    updateInputRestrictions(dlp) {
        this.isAllInputDisabled = dlp.allInput;
        this.isPasteDisabled = dlp.clipboardPaste || dlp.allInput;
    }

    triggerChangeOnBlur = false;

    shouldWatch(node, tag) {
        if (tag === DOM.TAG_TEXTAREA) {
            return true;
        }
        if (tag === DOM.TAG_INPUT && INPUT_TYPES.has(node.type)) {
            return true;
        }
        return false;
    }

    afterRegister(node, uid) {
        this.valueByUid[uid] = node.getAttribute('value') || '';

        if (IS_EXTENSION) {
            reg.addEventListener(node, 'input', () => this.checkNode(node));
            reg.addEventListener(node, 'blur', () => {
                if (this.triggerChangeOnBlur) {
                    DOM.dispatchChangeEvent(node);
                }
            });
        }

        if (IS_CLIENT) {
            reg.addEventListener(node, 'compositionupdate', () => {
                if (this.isAllInputDisabled) {
                    this.resetNode(node, uid);
                } else {
                    this.checkNode(node);
                }
            });

            reg.addEventListener(node, 'change', () => {
                if (this.isPasteDisabled) {
                    this.resetNode(node, uid);
                }
            });
        }
    }

    resetNode(node, uid) {
        if (this.getValue(node) !== this.valueByUid[uid]) {
            node.value = this.valueByUid[uid];
            messageToBrowser(API_CLIENT_TO_BROWSER.showDlp);
        }
    }

    getValue(node) {
        return normalizeValue(node.value);
    }

    setValue(node, uid, value) {
        const newValue = normalizeValue(value);
        const currentValue = this.getValue(node);
        if (currentValue !== newValue) {
            logger.log('set', uid, { currentValue, newValue });
            node.value = newValue;
            this.valueByUid[uid] = this.getValue(node);

            if (IS_EXTENSION) {
                DOM.dispatchInputEvent(node);
            }
        }
    }
}

export default new ValueWatcher(F_VALUE.value, 250);
