import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import EnvStore from '../stores/EnvStore';
import * as Pages from '../pages/BrowserErrorPages';
import { messageToBrowser } from '../mirror/client/browserMessageBus';
import {
    API_CLIENT_TO_BROWSER,
    PAGE_ERROR,
} from '../mirror/sharedConstants';
import ContentFilteringPage from '../Components/BlockPage/ContentFilteringPage';
import MaliciousThreatPage from '../Components/BlockPage/MaliciousThreatPage';
import ReservingBrowserPage from '../Components/ReservingBrowserPage/ReservingBrowserPage';
import debug from '../debug';
import LocalStorage, { LS_KEY } from '../LocalStorage';
import pollScanStatus from '../pollScanStatus';
import { ERROR_VIEW_CODE } from '../constants';
import initData from './browserInitData';

const errorViewCode = Number(initData.stores.errorViewCode);
const errorDetail = initData.stores.errorDetail || {};
errorDetail.errorViewCode = errorViewCode;

let customPageContent = {};
if (initData.stores.customPageContent) {
    try {
        customPageContent = JSON.parse(initData.stores.customPageContent);
    } catch (e) { debug.error(e); }
}

function showLastFileProcessEvent() {
    const eventData = LocalStorage.get(LS_KEY.fileManagerLastFileEvent);
    if (eventData) {
        messageToBrowser(API_CLIENT_TO_BROWSER.fileProcessing, { ...eventData, ignore: true });
        LocalStorage.remove(LS_KEY.fileManagerLastFileEvent);
    }
}

// see AbstractMirrorException.java
class BrowserErrorPage extends Component {
    static propTypes = {
        env: EnvStore.PropType.isRequired,
        clickId: PropTypes.string,
    };

    componentDidMount() {
        messageToBrowser(API_CLIENT_TO_BROWSER.firstRenderFinished, {
            pageId: errorDetail.pageId,
            browserId: errorDetail.browserId,
            finalUrl: errorDetail.finalUrl,
            errorKind: errorDetail.name,
            errorViewCode,
            serverBrowserVersion: 'unknown',
            type: 'error',
        });
        messageToBrowser(API_CLIENT_TO_BROWSER.setTitle, { title: '' });
        messageToBrowser(API_CLIENT_TO_BROWSER.setPageAttributes, { error: errorDetail });
        if (errorDetail.finalUrl && errorViewCode !== ERROR_VIEW_CODE.NOT_WEBMAIL) {
            // TODO: make logic NICER
            // do NOT update URL Bar on "not webmail" error page
            // so it would not register page as "loaded"
            // but register other error pages to allow refresh on them

            messageToBrowser(API_CLIENT_TO_BROWSER.history, {
                browserTabId: `error_${Date.now()}`,
                currentIndex: 0,
                entries: [{
                    id: 0,
                    url: errorDetail.finalUrl,
                }],
            });
        }

        // unknown error
        if (errorViewCode === ERROR_VIEW_CODE.CLOSED_BY_NATURAL_CAUSE) {
            messageToBrowser(API_CLIENT_TO_BROWSER.loadErrorClosedOnServer, {});
        }

        if (errorViewCode === ERROR_VIEW_CODE.DOWNLOAD_DISABLED) {
            messageToBrowser(API_CLIENT_TO_BROWSER.disableDownload, { showHomePage: true });
            if (errorDetail.name.indexOf('#') !== -1) {
                const errorDataStr = errorDetail.name.substring(errorDetail.name.indexOf('#') + 1);
                try {
                    const { downloadId, tiScanResult } = JSON.parse(errorDataStr);
                    if (downloadId !== undefined) {
                        messageToBrowser(API_CLIENT_TO_BROWSER.fileScanResult, [downloadId, 'SCANNING']);
                    }
                    if (tiScanResult !== undefined) {
                        messageToBrowser(API_CLIENT_TO_BROWSER.tiScanResult, tiScanResult);
                    }
                } catch (e) {
                    debug.warn('failed to parse errorDataStr', errorDataStr);
                }
            }
        }

        // not webmail url notification
        if (errorViewCode === ERROR_VIEW_CODE.NOT_WEBMAIL) {
            messageToBrowser(API_CLIENT_TO_BROWSER.showOnlyWebmail, {});
        }

        if (errorViewCode === ERROR_VIEW_CODE.NEVER_ISOLATE && errorDetail.finalUrl) {
            messageToBrowser(API_CLIENT_TO_BROWSER.neverIsolate);
            setTimeout(() => window.top.location.replace(errorDetail.finalUrl), 2000);
        }

        if (errorViewCode === ERROR_VIEW_CODE.NEVER_ISOLATE_INSTANT && errorDetail.finalUrl) {
            window.top.location.replace(errorDetail.finalUrl);
        }

        debug.addDebugAttribute(document, 'weblife-error-view-code', errorViewCode);
        debug.addDebugAttribute(document, 'weblife-error-name', errorDetail.name);

        if (errorViewCode === ERROR_VIEW_CODE.BLOCKED_UNSAFE) {
            messageToBrowser(API_CLIENT_TO_BROWSER.tiScanResult, 'BLOCK');
        }

        if (this.props.env.isUrlIsolation) {
            if ([
                ERROR_VIEW_CODE.BLOCKED_SITE,
                ERROR_VIEW_CODE.BLOCKED_UNSAFE,
                ERROR_VIEW_CODE.NEVER_ISOLATE,
                ERROR_VIEW_CODE.NEVER_ISOLATE_INSTANT,
                ERROR_VIEW_CODE.RESERVING_BROWSER,
            ].indexOf(errorViewCode) === -1) {
                pollScanStatus(`scanResult-${this.props.clickId}`, this.props.clickId, (result) =>
                    messageToBrowser(API_CLIENT_TO_BROWSER.sandboxScanResult, result));
            }

            if ([
                ERROR_VIEW_CODE.BLOCKED_SITE,
                ERROR_VIEW_CODE.BLOCKED_UNSAFE,
                ERROR_VIEW_CODE.RESERVING_BROWSER,
            ].indexOf(errorViewCode) === -1) {
                messageToBrowser(API_CLIENT_TO_BROWSER.tsaScanResult, { disposition: 'CLEAN' });
            }
        }
    }

    render() {
        const { isUrlIsolation, isSaasIsolation } = this.props.env;

        if (errorViewCode === ERROR_VIEW_CODE.SERVICE_FAILURE) {
            return <Pages.ServerFailurePage isUrlIsolation={isUrlIsolation} />;
        }

        if (errorViewCode === ERROR_VIEW_CODE.UNREACHABLE_SITE) {
            return <Pages.UnreachableWebsitePage
                url={errorDetail.finalUrl || errorDetail.originalRequest}
                isUrlIsolation={isUrlIsolation}
            />;
        }

        if (errorViewCode === ERROR_VIEW_CODE.BLOCKED_SITE) {
            return <ContentFilteringPage
                isUrlIsolation={isUrlIsolation}
                {...customPageContent}
            />;
        }

        if (errorViewCode === ERROR_VIEW_CODE.TIMED_OUT_SITE) {
            return <Pages.TimedOutPage isUrlIsolation={isUrlIsolation} isSaasIsolation={isSaasIsolation} />;
        }

        if (errorViewCode === ERROR_VIEW_CODE.BAD_CLIENT_BROWSER_LOCATION) {
            return <Pages.SavedFilePage isUrlIsolation={isUrlIsolation} isSaasIsolation={isSaasIsolation} />;
        }

        if (errorViewCode === ERROR_VIEW_CODE.BLOCKED_UNSAFE) {
            return <MaliciousThreatPage
                isUrlIsolation={isUrlIsolation}
                {...customPageContent}
            />;
        }

        if (errorViewCode === ERROR_VIEW_CODE.NOT_WEBMAIL || errorViewCode === ERROR_VIEW_CODE.DOWNLOAD_DISABLED) {
            // there is no error page for a NOT_WEBMAIL nor for a DOWNLOAD_DISABLED
            // inside /browser iframe is removed and homepage is shown instead
            return null;
        }

        if (errorViewCode === ERROR_VIEW_CODE.DOCUMENT_RENDER_ERROR) {
            return <Pages.CantRenderDocument isUrlIsolation={isUrlIsolation} isSaasIsolation={isSaasIsolation} />;
        }

        if (errorViewCode === ERROR_VIEW_CODE.RESERVING_BROWSER) {
            if (isSaasIsolation) {
                messageToBrowser(API_CLIENT_TO_BROWSER.redirectToBlockPage);
                return null;
            }

            return <ReservingBrowserPage />;
        }

        if (errorViewCode === ERROR_VIEW_CODE.NEVER_ISOLATE) {
            return null;
        }

        if (errorViewCode === ERROR_VIEW_CODE.NEVER_ISOLATE_INSTANT) {
            return null;
        }

        if (errorViewCode === ERROR_VIEW_CODE.EMPTY_PAGE_CONTENT) {
            const statusLine = errorDetail.name.replace(PAGE_ERROR.EXT_EMPTY_PAGE_CONTENT + '#', '');

            return <Pages.EmptyPageContent
                isUrlIsolation={isUrlIsolation}
                isSaasIsolation={isSaasIsolation}
                statusLine={statusLine}
            />;
        }

        if (errorDetail.name === PAGE_ERROR.EXT_UNNECESSARY_DOWNLOAD_TAB) {
            showLastFileProcessEvent();
            window.addEventListener('storage', (event) => {
                if (event.key === LS_KEY.fileManagerLastFileEvent) {
                    showLastFileProcessEvent();
                }
            });
            return null;
        }

        if (errorViewCode === ERROR_VIEW_CODE.CLOSED_BY_NATURAL_CAUSE) {
            return <Pages.TabSelfClosed
                isUrlIsolation={isUrlIsolation}
                isSaasIsolation={isSaasIsolation}
            />;
        }

        return <Pages.GenericErrorPage isUrlIsolation={isUrlIsolation} isSaasIsolation={isSaasIsolation} />;
    }
}

export default inject('env')(observer(BrowserErrorPage));
