import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Editable, withReact, Slate } from 'slate-react';
import {
    createEditor,
} from 'slate';
import { fixEditorValue } from './utils';
import WysiwygErrorBoundary from './WysiwygErrorBoundary';
import { Leaf, Element } from './wysiwygRender';


class FuseWysiwygReadOnly extends Component {
    static propTypes = {
        content: PropTypes.any,
    };

    editor = withReact(createEditor());

    renderElement = (props) => <Element {...props} />;

    renderLeaf = (props) => <Leaf {...props} />;

    renderSlate() {
        return <Slate
            editor={this.editor}
            value={fixEditorValue(this.props.content)}
        >
            <Editable
                renderElement={this.renderElement}
                renderLeaf={this.renderLeaf}
                readOnly
            />
        </Slate>;
    }

    render() {
        return <WysiwygErrorBoundary>
            {this.renderSlate()}
        </WysiwygErrorBoundary>;
    }
}

export default FuseWysiwygReadOnly;
