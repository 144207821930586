import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import b from 'b_';
import FuseButton from './FuseButton';
import { FTTitle } from '../Fuse';
import './FusePopUp.css';

const block = b.lock('FusePopUp');


export default function FusePopUp(props) {
    const {
        title,
        footer,
        onClose,
        overlayClassName,
        className,
        ...otherProps
    } = props;

    let children = props.children;

    if (children) {
        children = React.Children.map(children, (el) => React.cloneElement(el, { onClose }));
    }

    return <ReactModal
        className={cn(block('Wrapper'), className)}
        overlayClassName={cn(block('Overlay'), overlayClassName)}
        ariaHideApp={false}
        {...otherProps}
    >
        <div className={block('Content')}>
            <div className={block('PopUpHeader')}>
                <FTTitle>{title}</FTTitle>
                {onClose ? <FuseButton className={block('Close')} icon="close" onClick={onClose} /> : null}
            </div>
            {children}
            {footer ? <div className={block('Footer')}>{footer}</div> : null}
        </div>
    </ReactModal>;
}

FusePopUp.propTypes = {
    title: PropTypes.string,
    footer: PropTypes.element,
    onClose: PropTypes.func,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    closeClassName: PropTypes.string,
    closeTextClassName: PropTypes.string,
    ...ReactModal.propTypes,
};
