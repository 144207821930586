import debounce from 'lodash/debounce';
import debug from '../../debug';
import Screen from '../../Screen';
import { IS_TOP, IS_MOBILE } from '../../env';
import api from './ClientAPI';
import { API_CLIENT_TO_BROWSER } from '../sharedConstants';
import { messageToBrowser } from './browserMessageBus';

const logger = debug.create('ScreenSizeController', false);

const setScreenSizeOnServer = debounce(() => {
    api.setScreenSize(Screen.getCurrentScreen());
}, 250, { leading: true, trailing: true });

const style = document.createElement('style');
let previousMaxWidth = 0;
function setDocumentMaxWidth(maxWidth) {
    if (previousMaxWidth === maxWidth) {
        return;
    }
    try {
        if (document.documentElement) {
            previousMaxWidth = maxWidth;
            style.innerHTML = `
                @media (width: ${maxWidth}px) {
                    html {
                        max-width: ${maxWidth}px!important;
                    }
                }
            `;
            document.documentElement.appendChild(style);
        }
    } catch (e) { debug.error(e); }
}

export class ScreenSizeController {
    interval = 0;
    prevScreen = null;

    constructor() {
        window.addEventListener('resize', this.checkIfScreenChanged);
        window.addEventListener('orientationchange', this.checkIfScreenChanged);

        if (!IS_TOP) {
            try {
                window.top.addEventListener('focus', this.checkIfScreenChanged);
                window.top.addEventListener('resize', this.checkIfScreenChanged);
            } catch (e) {
                logger.error(e);
            }
        } else {
            window.addEventListener('focus', this.checkIfScreenChanged);
        }

        this.interval = setInterval(this.checkIfScreenChanged, 1000);
        this.checkIfScreenChanged();
    }

    destroy() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.checkIfScreenChanged);
        window.removeEventListener('orientationchange', this.checkIfScreenChanged);

        if (!IS_TOP) {
            try {
                window.top.removeEventListener('focus', this.checkIfScreenChanged);
                window.top.removeEventListener('resize', this.checkIfScreenChanged);
            } catch (e) {
                logger.error(e);
            }
        } else {
            window.removeEventListener('focus', this.checkIfScreenChanged);
        }
    }

    setViewport(viewport) {
        if (!IS_TOP && IS_MOBILE) {
            // on mobile we need to limit HTML width in main iframe, otherwise it will grow
            // *at least on iOS
            if (Screen.isEqualOrientation(Screen.getCurrentScreen(), viewport)) {
                setDocumentMaxWidth(viewport.width);
            }
        }
        messageToBrowser(API_CLIENT_TO_BROWSER.setViewport, viewport);
    }

    checkIfScreenChanged = (event) => {
        const screen = Screen.getCurrentScreen();

        if (event && event.type) {
            logger.log('onChange', event.type, screen);
        }

        if (
            Screen.isEqualSize(screen, this.prevScreen)
            && Screen.isEqualOrientation(screen, this.prevScreen)
        ) {
            return;
        }

        this.prevScreen = screen;
        setScreenSizeOnServer();
    };
}

const screenSizeController = new ScreenSizeController();
export default screenSizeController;
