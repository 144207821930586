import debug from '../../debug';
import { makeSafePropName } from '../../DOMUtils';
import { API_CLIENT_TO_BROWSER } from '../sharedConstants';
import { Key } from '../userInput';
import { isEditable } from '../DOM';
import reg from '../DOMRegistry';
import { messageToBrowser } from './browserMessageBus';

const logger = debug.create('CustomBehaviour', false);

const PROP_SPACE = makeSafePropName('space');
const PROP_SPACE_DEEP = makeSafePropName('space-deep');
const PROP_APP_LAUNCHER = makeSafePropName('app-launcher');

export function markNode(node, label) {
    node[makeSafePropName(label)] = true;
}

export default function initCustomBehaviour(frame) {
    // prevent default scrolling behaviour of SPACE
    reg.addEventListener(frame.doc, 'keypress', (event) => {
        if (event.keyCode !== Key.SPACE) {
            return;
        }

        if (isEditable(event.target)) {
            return;
        }

        if (event.target[PROP_SPACE]) {
            event.preventDefault();
            logger.log('preventDefault on space because', PROP_SPACE, { target: event.target });
            return;
        }

        let el = event.target;
        while (el) {
            if (el[PROP_SPACE_DEEP]) {
                event.preventDefault();
                logger.log('preventDefault on space because', PROP_SPACE_DEEP, { target: event.target, el });
                return;
            }
            el = el.parentNode;
        }
    });

    ['mousedown', 'touchstart'].forEach((eventName) =>
        reg.addEventListener(frame.doc, eventName, (event) => {
            let el = event.target;
            while (el) {
                if (el[PROP_APP_LAUNCHER]) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    messageToBrowser(API_CLIENT_TO_BROWSER.appLauncher);
                    return;
                }
                el = el.parentNode;
            }
        }, true));
}
