import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import b from 'b_';
import Icon from '../Icon/Icon';
import BrowserStore from '../../browser/stores/BrowserStore';
import Button from '../Button';
import './TroubleshootingModeScreen.css';

const block = b.lock('TroubleshootingModeScreen');

class TroubleshootingModeScreen extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
    };

    openCustomerSupportModal = () => {
        const { sendFeedbackStore, troubleshootingCaseId } = this.props.browser;

        sendFeedbackStore.openCustomerSupportModal(troubleshootingCaseId);
    };

    render() {
        const { t, browser } = this.props;

        return <div className={block()}>
            <div className={block('TopBar')}>
                <div className={block('RecordIndicator')}>
                    <span className={block('RecordIndicatorIcon')} />
                    {t('troubleshootingMode.recordIndicator')}
                </div>
                <div className={block('Spacer', { hideOnMobile: true })} />
                <div
                    className={block('Title')}
                    onClick={browser.troubleshootingInstructionsModal.toggle}
                >
                    <span>{t('troubleshootingMode.title')}</span>
                    <Icon type="info_outline" className={block('TileInfoIcon')} />
                </div>
                <div className={block('Spacer')} />
                <Button
                    className={block('RecordButton')}
                    red
                    raised
                    secondaryWhite
                    onClick={this.openCustomerSupportModal}
                >
                    {t('troubleshootingMode.recordButton')}
                </Button>
            </div>
            <div className={block('ContentWrapper')}>
                {this.props.children}
            </div>
        </div>;
    }
}

export default translate()(inject('browser')(observer(TroubleshootingModeScreen)));
