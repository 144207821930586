import EventEmitter from 'events';
import { IS_EXTENSION, IS_DEV, AGENT_PORT } from '../env';
import debug from '../debug';


const logger = debug.create('Socket', true);

const TIMES_TO_RECONNECT_AFTER_ERROR = IS_EXTENSION ? 10000 : 6;
const DEFAULT_RECONNECT_DELAY = 100;
const MAX_NETWORK_ERROR_TIME = 55000;

function ping(pageId) {
    if (IS_EXTENSION) {
        return Promise.resolve({ isNetworkError: false });
    }
    return new Promise((resolve, reject) => {
        const xhr = new window.XMLHttpRequest();
        let finished = false;
        const handler = () => {
            if (finished) {
                return;
            }
            finished = true;

            if (xhr.responseText) {
                if (xhr.status === 200) {
                    reject(new Error(xhr.responseText));
                } else {
                    // revealed during load test
                    // if status is not 200 it means that NGINX gone mad and started sending 504 or something
                    // and we want to try to reconnect in this case
                    resolve({ isNetworkError: false });
                }
            } else if (xhr.status === 200 || xhr.status === 0) {
                resolve({ isNetworkError: xhr.status === 0 });
            } else {
                reject(new Error('browser is dead'));
            }
        };

        xhr.onload = handler;
        xhr.onerror = handler;
        const loc = window.location;
        const url = `${loc.protocol}//${loc.hostname}${IS_DEV ? `:${AGENT_PORT}` : ''}/socket/page/${pageId}/ping`;
        xhr.open('GET', url, true);
        xhr.responseType = 'text';
        xhr.withCredentials = true;
        xhr.send();
    });
}

export default class Socket extends EventEmitter {
    constructor() {
        super();

        this.EVENT_MESSAGE = 'message';
        this.EVENT_OPEN = 'open';
        this.EVENT_ON_ERROR = 'socket_error';
        this.EVENT_ON_CLOSE_INITIATED_BY_SERVER = 'socket_closed_by_server';
        this.EVENT_ON_CLOSE_WITH_RECONNECT = 'socket_closed_with_reconnect';
        this.EVENT_ON_CLOSE = 'socket_closed';
        this.EVENT_ON_FORCED_CLOSE = 'socket_forced_close';

        this._socket = null;
        this._forcedClosed = false;
        this.isConnected = false;
        this.didConnectedOnce = false;
        this.reconnectDelay = DEFAULT_RECONNECT_DELAY;
        this.sendQueue = []; // TODO: Think on how to get rid of this buffer in favor of uncommited buffer
        this.connectionErrorCount = 0;
        this.reconnectCount = 0;
        this.lastMessageDate = 0;
        this.reconnectTm = 0;
        this.isShutDown = false;
        this.hasReconnectFeature = false;

        // heartbeat things
        this.heartbeatInterval = 5 * 1000;
        this.heartbeatMessage = 'h';
        setInterval(() => {
            if (this.isConnected) {
                this.send(this.heartbeatMessage, true);
            }
        }, this.heartbeatInterval);
    }

    forcedClose() {
        if (this._forcedClosed) {
            return;
        }

        if (this._socket) {
            try {
                this._socket.close();
            } catch (e) {
                debug.error(e);
            }
        }

        this.isConnected = false;
        this._forcedClosed = true;

        this.emit(this.EVENT_ON_FORCED_CLOSE);
    }

    makeMethodEventName(methodName) {
        return `method:${methodName}`;
    }
    setReconnectOnMessageLossFeature = (hasFeature) => {
        this.hasReconnectFeature = hasFeature;
    }
    onMethod(methodName, ...args) {
        return this.on(this.makeMethodEventName(methodName), ...args);
    }

    rawSend(string) {
        try {
            if (this.isShutDown) {
                return;
            }
            this._socket.send(string);
        } catch (e) {
            debug.error(e, string);
        }
    }

    send(msg, raw = false) {
        const jsonMsg = raw ? msg : JSON.stringify(msg);
        if (this.isConnected) {
            this.rawSend(jsonMsg);
        } else {
            this.sendQueue.push(jsonMsg);
        }
    }

    connect(url, pageId) {
        if (this.reconnectTm) {
            clearTimeout(this.reconnectTm);
            this.reconnectTm = 0;
        }

        this.cleanSocket();
        this.url = this.url || url;
        this.pageId = pageId || this.pageId;
        // close existing socket
        if (this._socket && this._socket.readyState !== this._socket.CLOSED) {
            this._socket.close();
        }

        const ws = new window.WebSocket(url);
        ws.addEventListener('open', this.onOpen);
        ws.addEventListener('error', this.onError);
        ws.addEventListener('close', this.onClose);
        ws.addEventListener('message', this.onMessage);

        this._socket = ws;
    }

    getReconnectURL() {
        return this.url;
    }

    reconnect(immediate) {
        if (immediate) {
            this.connect(this.getReconnectURL());
        } else if (!this.reconnectTm) {
            ping(this.pageId).then(({ isNetworkError }) => {
                // if network is down, we should try to reconnect anyway,
                // coz page will be alive for 1 minute
                if (isNetworkError && ((Date.now() - this.lastMessageDate) < MAX_NETWORK_ERROR_TIME)) {
                    this.connectionErrorCount = 0;
                }

                this.reconnectTm = setTimeout(() => this.connect(this.getReconnectURL()), this.reconnectDelay);
                this.reconnectDelay = Math.min(1000, this.reconnectDelay * 2);
            }).catch((error) => {
                this.emit(this.EVENT_ON_CLOSE_INITIATED_BY_SERVER, {
                    code: -1,
                    reason: error.message,
                    forcedClose: this._forcedClosed,
                    wasConnectedBeforeDisconnect: this.isConnected,
                    didConnectedOnce: this.didConnectedOnce,
                });
            });
        }
    }

    forceDisconnect(reason) {
        this.forcedClose();
        this.emit(this.EVENT_ON_CLOSE_INITIATED_BY_SERVER, {
            code: -1,
            reason,
            forcedClose: this._forcedClosed,
            wasConnectedBeforeDisconnect: this.isConnected,
            didConnectedOnce: this.didConnectedOnce,
        });
    }

    cleanSocket() {
        if (this._socket) {
            this._socket.removeEventListener('open', this.onOpen);
            this._socket.removeEventListener('error', this.onError);
            this._socket.removeEventListener('close', this.onClose);
        }
    }

    shutdown() {
        this.isShutDown = true;
        this.removeAllListeners();
        this.cleanSocket();
    }

    onOpen = () => {
        this.isConnected = true;
        this.didConnectedOnce = true;
        this.reconnectDelay = DEFAULT_RECONNECT_DELAY;

        // dispatch msg queue
        while (this.sendQueue.length) {
            const jsonMsg = this.sendQueue.shift();
            this.rawSend(jsonMsg);
        }

        this.emit(this.EVENT_OPEN);
    };

    recordError() {
        // if it is already waiting for reconnect ignore eror
        if (this.reconnectTm) {
            return false;
        }

        this.connectionErrorCount += 1;

        if (this.connectionErrorCount >= TIMES_TO_RECONNECT_AFTER_ERROR) {
            this.forcedClose();
            return true;
        }

        return false;
    }

    handleReconnectWhenMessageLost() {
        // if it is already waiting for reconnect return false
        if (this.reconnectTm) {
            return false;
        }

        this.reconnectCount += 1;

        if (this.reconnectCount >= TIMES_TO_RECONNECT_AFTER_ERROR) {
            return false;
        }

        return true;
    }

    onError = (event) => {
        this.isConnected = false;
        logger.error('onError', event);
        if (this.recordError()) {
            this.emit(this.EVENT_ON_ERROR, event);
        } else {
            this.reconnect();
            this.emit(this.EVENT_ON_CLOSE_WITH_RECONNECT, {
                code: -1,
                reason: 'error',
                forcedClose: this._forcedClosed,
                wasConnectedBeforeDisconnect: this.isConnected,
                didConnectedOnce: this.didConnectedOnce,
            });
        }
    };

    onClose = (event) => {
        logger.warn('onClose isConnected:' + this.isConnected, event, event.code, event.reason);

        if (!this.isConnected) {
            return;
        }

        const { code, reason } = event;

        this.isConnected = false;
        this.cleanSocket();
        this._socket = null;

        this.emit(this.EVENT_ON_CLOSE, event);

        if (!this._forcedClosed && (
            reason === 'forceReconnect'
            || reason === 'unknownConnectionError'
            || (!reason && !this.recordError())
        )) {
            this.reconnect(reason === 'forceReconnect');
            this.emit(this.EVENT_ON_CLOSE_WITH_RECONNECT, {
                code,
                reason,
                forcedClose: this._forcedClosed,
                wasConnectedBeforeDisconnect: this.isConnected,
                didConnectedOnce: this.didConnectedOnce,
            });
        } else {
            this.emit(this.EVENT_ON_CLOSE_INITIATED_BY_SERVER, {
                code,
                reason,
                forcedClose: this._forcedClosed,
                wasConnectedBeforeDisconnect: this.isConnected,
                didConnectedOnce: this.didConnectedOnce,
            });
        }
    };

    onMessage = (event) => {
        this.connectionErrorCount = 0;
        this.lastMessageDate = Date.now();

        let message;
        try {
            message = JSON.parse(event.data);
        } catch (e) {
            message = event.data;
        }

        this.emit(this.EVENT_MESSAGE, message);
        this.emitMessage(message);
    };

    emitMessage = (message) => {
        // message could have two formats
        // 1. { method: 'name', args: ... }
        // 2. [methodName, ...args]
        if (message) {
            if (Array.isArray(message)) {
                if (!this.shouldIgnoreMessage(message)) {
                    this.reconnectCount = 0;
                    const [method, args] = message;
                    this.emit(this.makeMethodEventName(method), ...(args || []));
                } else if (this.receiver && this.receiver.isLost && this.hasReconnectFeature) {
                    if (this.handleReconnectWhenMessageLost()) {
                        this.reconnect();
                        this.emit(this.EVENT_ON_CLOSE_WITH_RECONNECT, {
                            code: -1,
                            reason: 'Reconnect due to message loss',
                            forcedClose: this._forcedClosed,
                            wasConnectedBeforeDisconnect: this.isConnected,
                            didConnectedOnce: this.didConnectedOnce,
                        });
                    } else if (this.isConnected) {
                        this.forceDisconnect('Disconnected due to multiple reconnect on message loss');
                    }
                } else {
                    this.reconnectCount = 0;
                }
            } else if (message.method) {
                this.emit(this.makeMethodEventName(message.method), message.args);
            }
        }
    };

    shouldIgnoreMessage() {
        // override
        return false;
    }
}
