import PropTypes from 'prop-types';
import bem from 'b_';
import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import FuseSnackbar from './Components/FuseControls/FuseSnackbar';
import SnackStore from './stores/SnackStore';
import { IS_IOS } from './env';
import clientConfig from './clientConfig';
import './RootPage.css';

const block = bem.lock('RootPage');

function updateHTMLClassName(appName) {
    // ios modificator prevent page bouncing on scrolling
    document.documentElement.className = block('HTML', { [appName]: true, ios: IS_IOS });
}

export default class RootPage extends Component {
    static propTypes = {
        appName: PropTypes.oneOf(['browser', 'console', 'superduper']).isRequired,
    };

    constructor(...args) {
        super(...args);

        clientConfig.updateConfig();
    }

    componentDidMount() {
        updateHTMLClassName(this.props.appName);
    }

    componentDidUpdate(prevProps) {
        if (this.props.appName !== prevProps.appName) {
            updateHTMLClassName(this.props.appName);
        }
    }

    render() {
        return <div
            key={this.props.appName}
            className={block()}
        >
            <div className={block('Wrapper')}>
                {this.props.children}
            </div>
            <Provider snack={new SnackStore()}>
                <FuseSnackbar />
            </Provider>
        </div>;
    }
}
