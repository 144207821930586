import { action, extendObservable } from 'mobx';

export default class InactivityStore {
    constructor(data = {}) {
        this.intervalId = null;
        this.countdownStart = null;

        extendObservable(this, /** @class InactivityStore */{
            timeTotal: -1,
            timeLeft: -1,
        }, data);

        ['mousedown', 'keydown', 'touchstart']
            .forEach((event) => document.addEventListener(event, () => {
                if (this.timeLeft > -1) {
                    this.stopCountdown();
                }
            }, true));
    }

    stopCountdown = action(() => {
        this.timeLeft = -1;
        clearInterval(this.intervalId);
    });

    startCountdown = action((seconds) => {
        this.stopCountdown();
        this.countdownStart = Date.now();
        this.timeTotal = seconds;
        this.timeLeft = seconds;
        this.intervalId = setInterval(this.checkTime, 1000);
    });

    checkTime = action(() => {
        const timeLeft = this.timeTotal - Math.floor((Date.now() - this.countdownStart) / 1000);
        this.timeLeft = timeLeft < 0 ? -1 : timeLeft;
        if (this.timeLeft < 0) {
            this.stopCountdown();
        }
    });
}
