import { action, extendObservable } from 'mobx';
import PropTypes from 'prop-types';

export default class ValueStore {
    static valuePropType = PropTypes.any;

    static _PropType = {
        defaultValue: ValueStore.valuePropType,
        originalValue: ValueStore.valuePropType,
        currentValue: ValueStore.valuePropType,

        changed: PropTypes.bool.isRequired,
        valid: PropTypes.bool.isRequired,
        errorMessage: PropTypes.string,

        equals: PropTypes.func.isRequired,
        getValue: PropTypes.func.isRequired,
        setValue: PropTypes.func.isRequired,
        resetToOriginal: PropTypes.func.isRequired,
        resetToDefault: PropTypes.func.isRequired,
        commit: PropTypes.func.isRequired,
        commitValue: PropTypes.func.isRequired,
        setValidator: PropTypes.func.isRequired,
        isValid: PropTypes.func.isRequired,
        hasChanged: PropTypes.func.isRequired,
    };

    static PropTypes = PropTypes.shape(ValueStore._PropType);

    constructor(defaultValue = null, data = {}) {
        this.validator = () => true;

        this.normalize = data.normalize || ((x) => x);
        this.init(this.normalize(defaultValue), data);
    }

    init(defaultValue, data) {
        extendObservable(this, /** @class ValueStore */{
            defaultValue,
            originalValue: defaultValue,
            currentValue: defaultValue,

            changed: false,
            valid: true,
            errorMessage: '',
        }, data);
    }

    equals = (value) => this.currentValue === this.normalize(value);

    getValue = () => this.currentValue;

    validate = action(() => {
        const validationResult = this.validator(this.currentValue);
        this.errorMessage = typeof validationResult === 'string' ? validationResult : '';
        this.valid = validationResult === true;
    });

    setValue = action((newValue, commit = false) => {
        this.currentValue = this.normalize(newValue);
        if (commit) {
            this.originalValue = this.currentValue;
        }
        this.changed = this.originalValue !== this.currentValue;
        this.validate();
    });

    resetToOriginal = action(() => this.setValue(this.originalValue));

    resetToDefault = action((commit = true) => this.setValue(this.defaultValue, commit));

    commit = action(() => this.setValue(this.currentValue, true));

    commitValue = action((newValue) => this.setValue(newValue, true));

    setValidator = action((validator) => {
        this.validator = validator;
        this.validate();
    });

    isValid = () => this.valid;

    hasChanged = () => this.changed;
}
