import { action, extendObservable } from 'mobx';
import PropTypes from 'prop-types';

export default class FlagStore {
    static PropType = PropTypes.shape({
        value: PropTypes.bool.isRequired,
        dirtyValue: PropTypes.bool.isRequired,
        isTrue: PropTypes.bool.isRequired,
        isOpen: PropTypes.bool.isRequired,
        isEnabled: PropTypes.bool.isRequired,

        setValue: PropTypes.func.isRequired,
        setEnabled: PropTypes.func.isRequired,

        setTrue: PropTypes.func.isRequired,
        open: PropTypes.func.isRequired,
        show: PropTypes.func.isRequired,
        on: PropTypes.func.isRequired,

        setFalse: PropTypes.func.isRequired,
        close: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,

        toggle: PropTypes.func.isRequired,

        equals: PropTypes.func.isRequired,

        isDirty: PropTypes.func.isRequired,
        getDirtyValue: PropTypes.func.isRequired,
        commit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
    });

    /**
     * @param {boolean} defaultValue
     * @param {Object|function} [opts] - Options. If a fn X is passed, it's converted to { alternativeAction: X }
     * @param {function} [opts.alternativeAction] for storybook mocks to log when the real value changes
     * @param {boolean} [opts.trackDirty] - Whether to require an explicit "commit" before reflecting changes to the
     * value.
     */
    constructor(defaultValue = false, opts = {}) {
        if (typeof opts === 'function') {
            opts = { alternativeAction: opts };
        }
        const { trackDirty, alternativeAction } = opts;

        const originalSetValue = action((newValue) => {
            this.dirtyValue = !!newValue;

            if (!trackDirty) {
                this.value = !!newValue;
            }
        });
        // Note - it is not sure whether we prevent value change by design if alternativeAction is enabled
        const setValue = (alternativeAction && !trackDirty)
            ? action((newValue) => {
                newValue = !!newValue;
                if (newValue !== this.value) {
                    alternativeAction(newValue, originalSetValue);
                }
            })
            : originalSetValue;
        const setTrue = action(() => setValue(true));
        const setFalse = action(() => setValue(false));
        const toggle = action(() => {
            setValue(trackDirty ? !this.dirtyValue : !this.value);
        });
        const getValue = () => this.value;

        const getDirtyValue = () => this.dirtyValue;
        const isDirty = () => { return this.value !== this.dirtyValue; };
        const commit = action(() => {
            if (!trackDirty || !this.isDirty()) {
                return;
            }
            // Change the value regardless if we want to execute any extra action
            this.value = this.dirtyValue;

            if (alternativeAction) {
                alternativeAction(this.dirtyValue);
            }
        });
        const reset = action(() => { this.dirtyValue = this.value; });

        extendObservable(this, /** @class FlagStore */{
            value: defaultValue,
            getValue,
            get isTrue() {
                return this.value;
            },
            get isOpen() {
                return this.value;
            },
            get isEnabled() {
                return this.value;
            },

            setValue,
            setEnabled: setValue,

            setTrue,
            open: setTrue,
            show: setTrue,
            on: setTrue,

            setFalse,
            close: setFalse,
            hide: setFalse,
            off: setFalse,

            toggle,

            equals: (value) => this.value === value,

            dirtyValue: defaultValue,
            getDirtyValue,
            isDirty,
            commit,
            reset,
        });
    }
}
