import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import b from 'b_';
import Icon from '../Icon/Icon';
import { FTBody, FTHeading } from '../Fuse';
import FuseButton from './FuseButton';
import './FuseAlert.css';

const block = b.lock('FuseAlert');

export default class FuseAlert extends Component {
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.oneOf(['danger', 'warning', 'success']).isRequired,
        title: PropTypes.node,
        subtitle: PropTypes.node,
        icon: PropTypes.string,
        iconClassName: PropTypes.string,
        centerIcon: PropTypes.bool,
        collapsible: PropTypes.bool,
    };

    static defaultProps = {
        type: 'warning',
        centerIcon: false,
        collapsible: false,
    };

    state = {
        isOpen: true,
    };

    defaultIconByType = {
        success: 'check',
        warning: 'info',
    };

    render() {
        const {
            className, type, title, subtitle,
            icon, iconClassName, centerIcon, collapsible,
            children, ...otherProps
        } = this.props;
        const { isOpen } = this.state;

        return <div className={cn(block({ type }), className)} {...otherProps}>
            <div className={block('IconWrapper')}>
                <Icon
                    className={cn(block('Icon', { center: centerIcon }), iconClassName)}
                    type={icon || this.defaultIconByType[type] || 'warning'}
                />
            </div>
            <div className={block('ContentWrapper')}>
                {collapsible ? <FuseButton
                    className={block('Toggle')}
                    icon={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    onClick={() => this.setState({ isOpen: !isOpen })}
                /> : null}

                {title || subtitle ? <div className={block('Header', { collapsible })}>
                    {title ? <FTHeading className={block('Title')}>{title}</FTHeading> : null}
                    {subtitle ? <FTBody className={block('Subtitle')}>{subtitle}</FTBody> : null}
                </div> : null}

                {this.state.isOpen && children ? <FTBody className={block('Content')}>
                    {children}
                </FTBody> : null}
            </div>
        </div>;
    }
}
