import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import b from 'b_';
import cn from 'classnames';
import FuseButton from '../FuseControls/FuseButton';
import { FTTitle } from '../Fuse/Text';
import './FuseConsoleModal.css';

const block = b.lock('FuseConsoleModal');

export default function FuseConsoleModal(props) {
    const {
        children,
        onClose,
        title,
        subTitle,
        headerActions,
        className,
        headerClassName,
        contentClassName,
        positionCenter,
        hideCloseButton,
        large,
        small,
        overlayClassName,
        ...otherProps
    } = props;

    return <ReactModal
        className={cn(block({ positionCenter, large, small }), className)}
        overlayClassName={cn(block('Overlay'), overlayClassName)}
        ariaHideApp={false}
        onClose={onClose}
        closeTimeoutMS={200}
        {...otherProps}
    >
        <div className={cn(block('Header'), headerClassName)}>
            {hideCloseButton ? null :
                <FuseButton
                    className={block('Close')}
                    icon="close"
                    primary
                    onClick={onClose}
                    qa="modal_close"
                />}
            <FTTitle className={block('Title')} title={`${title}${subTitle || ''}`}>{title}
                {subTitle ? <FTTitle className={block('SubTitle')}>{subTitle}</FTTitle> : null}
            </FTTitle>
            <span className={block('HeaderRight')}>
                {headerActions}
            </span>
        </div>
        <div className={block('ContentWrapper')}>
            <div className={cn(block('Content'), contentClassName)}>
                {children}
            </div>
        </div>
    </ReactModal>;
}

FuseConsoleModal.propTypes = {
    onClose: PropTypes.func,
    title: PropTypes.node,
    subTitle: PropTypes.node,
    headerActions: PropTypes.node,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    positionCenter: PropTypes.bool,
    large: PropTypes.bool,
    small: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    overlayClassName: PropTypes.string,
    ...ReactModal.propTypes,
};
