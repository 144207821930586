import { Component } from 'react';
import PropTypes from 'prop-types';
import { isElementOneOf, TAG_INPUT, TAG_TEXTAREA } from '../mirror/DOM';
import { Key } from '../mirror/userInput';

export default class KeyboardShortcuts extends Component {
    static keyToProp = {
        [Key.ESC]: 'esc',
        [Key.LEFT]: 'left',
        [Key.RIGHT]: 'right',
        [Key.UP]: 'up',
        [Key.DOWN]: 'down',
    };

    static propTypes = {
        disabled: PropTypes.bool,
        ...(Object.values(KeyboardShortcuts.keyToProp).reduce((acc, key) => {
            acc[key] = PropTypes.func;
            return acc;
        }, {})),
    };

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown = (event) => {
        if (this.props.disabled) {
            return;
        }

        if (isElementOneOf(event.target, TAG_INPUT, TAG_TEXTAREA)) {
            return;
        }

        const propName = KeyboardShortcuts.keyToProp[event.keyCode];
        if (propName) {
            const propValue = this.props[propName];
            if (propValue) {
                propValue();
            }
        }
    };

    render() {
        return null;
    }
}
