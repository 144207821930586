/** @deprecated use Store.PropType instead */
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import AsyncActionStore from './AsyncActionStore';
import FlagStore from './FlagStore';

export const ClearCookiesStoreProp = PropTypes.shape({
    modal: FlagStore.PropType,
    isApiCallLoading: PropTypes.bool.isRequired,
    doClearCookies: PropTypes.func.isRequired,
}).isRequired;

export const ResearchStoreProp = PropTypes.shape({
    researchMode: FlagStore.PropType,

    location: PropTypes.string.isRequired,
    setLocation: PropTypes.func.isRequired,
    locationMenu: FlagStore.PropType,

    userAgent: PropTypes.string,
    setUserAgent: PropTypes.func.isRequired,
    userAgentMenu: FlagStore.PropType,

    getUserAgentInfo: PropTypes.func.isRequired,
}).isRequired;

export const EnvStoreProps = PropTypes.shape({
    hasOpener: PropTypes.bool.isRequired,
    canGoBack: PropTypes.bool.isRequired,
}).isRequired;

export const WebmailWhitelistProps = PropTypes.shape({
    entries: MobxPropTypes.observableArray,
    apiState: AsyncActionStore.PropType.isRequired,
    getAll: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
}).isRequired;
