import PropTypes from 'prop-types';
import React, { Component } from 'react';
import b from 'b_';
import cn from 'classnames';
import './TimerSpinner.css';

const block = b.lock('TimerSpinner');

export default class TimerSpinner extends Component {
    static propTypes = {
        totalTime: PropTypes.number.isRequired,
        currentTime: PropTypes.number,
        className: PropTypes.string,
        counterClockwise: PropTypes.bool,
    };
    static defaultProps = {
        currentTime: 0,
    };

    render() {
        const { className, currentTime, totalTime, counterClockwise } = this.props;
        const partialTime = currentTime / totalTime;
        const degValue = 90 + (partialTime <= 0.5 ? partialTime : partialTime - 0.5) * 360;
        const color = partialTime <= 0.5 ? '#FFFFFF' : '#666666';
        const colorGradient = partialTime === 0 || partialTime === 1 ? '50%' : '55%';
        const backgroundImage = `linear-gradient(${degValue}deg, transparent 50%, ${color} ${colorGradient}),
           linear-gradient(90deg, white 50%, transparent 50%)`;

        return <div className={cn(block(), className)}>
            <div
                style={{ backgroundImage }}
                className={block('Spinner', { counterClockwise })}
            />
        </div>;
    }
}
