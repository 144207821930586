import debounce from 'lodash/debounce';
import { extendObservable, action } from 'mobx';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import uuid from 'uuid/v4';
import debug from '../../debug';
import { IS_BROWSER_MICROSOFT } from '../../env';
import requestAnimationFrameOrElse from '../../mirror/requestAnimationFrameOrElse';

const logger = debug.create('HistoryStore', false);
const BROWSER_PATHNAME = '/browser';

let latestPageId = null;
function newPageId() {
    latestPageId = uuid();
    return latestPageId;
}

const locationByTempId = {};

function getLocationByTempId(tempId) {
    try {
        if (window.sessionStorage[tempId]) {
            return JSON.parse(window.sessionStorage[tempId]);
        }
    } catch (e) {
        debug.warn(e);
    }

    return locationByTempId[tempId];
}

function setLocationByTempId(tempId, location) {
    try {
        // session storage is per tab and automatically cleans when tab is closed
        window.sessionStorage[tempId] = JSON.stringify(location);
    } finally {
        locationByTempId[tempId] = location;
    }
}

let nextTempId = 0;
const tempIdKey = 'tempId';
function getNextTempId() {
    try {
        if (window.sessionStorage[tempIdKey]) {
            nextTempId = JSON.parse(window.sessionStorage[tempIdKey]);
        } else {
            nextTempId = 0;
        }
    } catch (e) {
        debug.warn(e);
    }

    nextTempId += 1;

    try {
        window.sessionStorage[tempIdKey] = JSON.stringify(nextTempId);
    } catch (e) {
        debug.warn(e);
    }

    return nextTempId;
}

function makeQuery(url, frameUrl, traceToken, clickId) {
    const query = {};

    if (IS_BROWSER_MICROSOFT) {
        query.c = getNextTempId();
    }

    if (url) {
        query.url = url;
    }
    if (frameUrl) {
        query.frameUrl = frameUrl;
    }
    if (traceToken) {
        query.traceToken = traceToken;
    }
    if (clickId) {
        query.clickId = clickId;
    }

    return query;
}

export function getEmptyLocation(pageCounter, totalPageCounter) {
    return {
        state: { clientPageId: '', entries: [], currentIndex: -1, pageCounter, totalPageCounter },
        query: makeQuery('', '', ''),
        shouldNotifyServerBrowser: false,
    };
}

let latestPageCounter = null;
const debouncedCheckPopStateEvent = debounce((historyStore) => {
    const currentLocation = historyStore.browserHistory.getCurrentLocation();
    const { query } = currentLocation;
    const pageCounter = query ? query.c : -1;
    if (pageCounter !== latestPageCounter) {
        latestPageCounter = pageCounter;
        // if it accidentially will be called twice it should be ok, code in _syncState handles that
        historyStore.syncState(currentLocation);
    }

    debouncedCheckPopStateEvent(historyStore);
}, 2000, { leading: true });

function checkPopStateWithSmallDelay(historyStore) {
    if (!IS_BROWSER_MICROSOFT) {
        return;
    }
    debouncedCheckPopStateEvent.cancel();
    requestAnimationFrameOrElse(() => {
        debouncedCheckPopStateEvent.cancel();
        debouncedCheckPopStateEvent(historyStore);
    });
}

export default class HistoryStore {
    static PropType = PropTypes.shape({
        homeRedirect: PropTypes.string,
        traceToken: PropTypes.string,
        clickId: PropTypes.string,

        location: PropTypes.shape({
            query: PropTypes.shape({
                url: PropTypes.string,
                frameUrl: PropTypes.string,
                traceToken: PropTypes.string,
                clickId: PropTypes.string,
            }),
            state: PropTypes.shape({}),
        }),
        openPage: PropTypes.func.isRequired,
        updateHistory: PropTypes.func.isRequired,
        forward: PropTypes.func.isRequired,
        back: PropTypes.func.isRequired,
        reloadPage: PropTypes.func.isRequired,
    });

    constructor(data = {}, useLocalStorage = true) {
        this.uncommitted = false;
        this.uncommittedLocation = null;
        this.browserTabId = '';
        this.shouldNotifyServerBrowser = false;
        this.diffInProgress = 0;
        if (data.browserHistory) {
            this.browserHistory = data.browserHistory;
            delete data.browserHistory;
        } else {
            this.browserHistory = browserHistory;
        }

        extendObservable(this, /** @class HistoryStore */{
            homeRedirect: null,
            traceToken: null,
            clickId: null,
            location: null,
        }, data);

        const { query, pathname, state } = this.browserHistory.getCurrentLocation();
        this.location = getEmptyLocation(state && state.pageCounter || 0, state && state.totalPageCounter || 0);

        if (!useLocalStorage) {
            this.disabled = true;
            return;
        }

        this.browserHistory.listen(this.syncState);

        if (pathname === BROWSER_PATHNAME) {
            if (query.url || query.frameUrl) {
                this.openPage(query.url, query.frameUrl, true);
            } else {
                this.openHomePage(true);
            }
        }

        // IE and Edge may lose state and therefore popstate event.
        // because you can't pop state that you lost
        if (IS_BROWSER_MICROSOFT) {
            checkPopStateWithSmallDelay(this);
        }
    }

    syncState = (event) => this._syncState(event);
    _syncState = action((currentLocation) => {
        if (IS_BROWSER_MICROSOFT) {
            latestPageCounter = currentLocation.query.c;
        }

        if (this.disabled) {
            logger.log('_syncState', 'disabled');
            return;
        }

        // IE and Edge may lose history on popstate events
        // IE and Edge may lose access to the .state field in popup window after backward/forward navigation
        // so, please, don't trust the IE 11 history.state at all
        // TODO: remove state from history and store it manually through query parameter for every browser
        if (IS_BROWSER_MICROSOFT && currentLocation.query.c && currentLocation.action === 'POP') {
            const cachedLocation = getLocationByTempId(currentLocation.query.c);
            // prevent users (qa engineers, actually) from hijacking url
            if (cachedLocation
                && cachedLocation.query
                && cachedLocation.state
                && cachedLocation.query.url === currentLocation.query.url
            ) {
                currentLocation.state = cachedLocation.state;
            }
        }

        const { query, pathname, state } = currentLocation;

        logger.log(
            '_syncState',
            currentLocation.action,
            this.uncommittedLocation ? 'HAS UNCOMMITTED STATE' : '',
            this.shouldNotifyServerBrowser ? 'shouldNotifyServerBrowser' : '',
            query,
            state,
            pathname,
            currentLocation.key
        );

        if (!state) {
            return;
        }

        if (currentLocation.action === 'POP') {
            let nextLocation = null;
            this.diffInProgress = 0;

            if (this.uncommittedLocation) { // back/forward from server
                logger.log('_syncState', 'async', 'CAS succeed');
                nextLocation = this.uncommittedLocation;
                this.uncommittedLocation = null;
                nextLocation.state.pageCounter = state.pageCounter;
                nextLocation.state.totalPageCounter = this.location.state.totalPageCounter;
            } else { // native arrows, our UI arrows, shortcuts, any client side history change
                this.shouldNotifyServerBrowser = true;
                nextLocation = {
                    pathname,
                    query,
                    state: {
                        ...state,
                        totalPageCounter: this.location.state.totalPageCounter,
                    },
                };
            }

            this.browserHistory.replace(nextLocation);
            return;
        }

        if (state.clientPageId === latestPageId || (state.clientPageId === this.location.state.clientPageId)) {
            const { shouldNotifyServerBrowser } = this;
            this.shouldNotifyServerBrowser = false;
            this.uncommitted = false;
            const newLocation = { query, state, shouldNotifyServerBrowser };
            this.location = newLocation;

            // query.c is used as reference to the location,
            // this is needed for situation when IE11 loses state of a history item
            // and used only in that case
            // could be considered as a memory leak of a kind, but I don't think that it could be an issue
            // because we may see problems after 10000 consequent page views or something like that
            if (IS_BROWSER_MICROSOFT && query.c) {
                setLocationByTempId(query.c, newLocation);
            }
            return;
        }

        const newState = { ...state };
        if (newState.clientPageId) {
            this.pageLoadExpected = true;
            newState.clientPageId = newPageId();
        } else {
            // home page
            latestPageId = '';
            this.pageLoadExpected = false;
        }
        this.browserHistory.replace({ pathname, query, state: newState });
    });

    openPage(url, frameUrl, isInit) {
        if (this.disabled) {
            return;
        }

        const clientPageId = newPageId();
        this.pageLoadExpected = true;
        const currentIndex = isInit ? 0 : this.location.state.currentIndex + 1;
        const newLocation = {
            pathname: BROWSER_PATHNAME,
            query: makeQuery(url, frameUrl, this.traceToken, this.clickId),
            state: {
                clientPageId,
                entries: [
                    ...this.location.state.entries.slice(0, currentIndex),
                    {
                        id: clientPageId,
                        isClientInitiated: true,
                        url,
                    },
                ],
                currentIndex,
            },
        };

        if (isInit) {
            newLocation.state.pageCounter = this.location.state.pageCounter;
            newLocation.state.totalPageCounter = this.location.state.totalPageCounter;
            this.browserHistory.replace(newLocation);
        } else {
            newLocation.state.pageCounter = this.location.state.pageCounter + 1;
            newLocation.state.totalPageCounter = newLocation.state.pageCounter;
            this.browserHistory.push(newLocation);
        }
    }

    openHomePage = (isInitialization, noReload) => {
        if (this.disabled) {
            return;
        }

        if (this.homeRedirect) {
            // TODO: replace?
            window.location.assign(this.homeRedirect);
            return;
        }

        latestPageId = '';
        this.browserTabId = '';
        this.pageLoadExpected = false;

        const { pageCounter, totalPageCounter } = this.location.state;
        const newLocation = {
            pathname: BROWSER_PATHNAME,
            ...getEmptyLocation(
                isInitialization ? pageCounter : pageCounter + 1,
                isInitialization ? totalPageCounter : pageCounter + 1
            ),
        };

        if (isInitialization) {
            this.browserHistory.replace(newLocation);
        } else if (!this.location.query.url && !this.location.query.frameUrl) {
            if (noReload) {
                this.browserHistory.replace(newLocation);
            } else {
                window.top.location.reload();
            }
        } else {
            this.browserHistory.push(newLocation);
        }
    };

    updateHistory = (browserTabId, currentIndex, entries) => {
        if (this.disabled) {
            return;
        }

        logger.log('updateHistory',
            browserTabId, currentIndex,
            this.location.state.currentIndex, this.pageLoadExpected,
        );

        const isSameTab = this.browserTabId === browserTabId;
        this.browserTabId = browserTabId;
        const lostSomeHistory = this.location.state.entries.length - entries.length > 0;
        const currentEntry = entries[currentIndex];
        const diff = currentIndex - this.location.state.currentIndex;

        const isReplace = this.pageLoadExpected || (!diff && isSameTab && !lostSomeHistory);

        this.pageLoadExpected = !!currentEntry.frameUrl;
        this.uncommitted = true;

        const newLocation = {
            pathname: BROWSER_PATHNAME,
            query: makeQuery(
                currentEntry.url, currentEntry.frameUrl, this.traceToken, this.clickId
            ),
            state: {
                clientPageId: currentEntry.frameUrl ? newPageId() : this.location.state.clientPageId,
                entries,
                currentIndex,
            },
        };

        if (isReplace) {
            newLocation.state.pageCounter = this.location.state.pageCounter;
            newLocation.state.totalPageCounter = this.location.state.totalPageCounter;
            this.browserHistory.replace(newLocation);
        } else if (isSameTab && lostSomeHistory) {
            newLocation.state.pageCounter = this.location.state.pageCounter + 1;
            newLocation.state.totalPageCounter = newLocation.state.pageCounter;
            this.browserHistory.push(newLocation);
        } else if (isSameTab && entries.length === this.location.state.entries.length) {
            this.uncommittedLocation = newLocation;
            this.uncommittedLocation.state.pageCounter = -1; // will be fixed automatically in _syncState
            this.uncommittedLocation.state.totalPageCounter = -1; // will be fixed automatically in _syncState

            const realDiff = diff - this.diffInProgress;

            if (realDiff) { // 0 means that .go already in progress
                this.diffInProgress += realDiff;
                this.browserHistory.go(realDiff);
            }
        } else {
            newLocation.state.pageCounter = this.location.state.pageCounter + 1;
            newLocation.state.totalPageCounter = newLocation.state.pageCounter;
            this.browserHistory.push(newLocation);
        }
    };

    back() {
        if (this.disabled) {
            return;
        }

        if (this.uncommitted) {
            debug.warn('back before previous action committed');
            return;
        }

        this.uncommitted = true;
        logger.log('back');
        this.browserHistory.goBack();
        checkPopStateWithSmallDelay(this);
    }

    forward() {
        if (this.disabled) {
            return;
        }

        if (this.uncommitted) {
            debug.warn('forward before previous action committed');
            return;
        }

        this.uncommitted = true;
        logger.log('forward');
        this.browserHistory.goForward();
        checkPopStateWithSmallDelay(this);
    }

    reloadPage() {
        if (this.disabled) {
            return;
        }

        const { query, state, pathname } = this.browserHistory.getCurrentLocation();
        if (state.clientPageId) {
            this.browserHistory.replace({ pathname, query, state: { ...state, clientPageId: newPageId() } });
        }
    }
}
