import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import b from 'b_';
import cn from 'classnames';
import TextStore from '../../stores/helpers/TextStore';
import UDSpinner from '../UD/Fragments/UDSpinner';
import Icon from '../Icon/Icon';
import { FTBody, FTCaption, FTTitle } from './Text';
import FuseSelect from '../FuseControls/FuseSelect';
import FuseButton from '../FuseControls/FuseButton';
import InputStore from '../../stores/InputStore';
import FuseSearchField from '../FuseControls/FuseSearchField';
import './Table.css';

const block = b.lock('Table');

const DEFAULT_PAGE_SIZES = [10, 20, 50, 100];

class Table extends Component {
    static propTypes = {
        items: MobxPropTypes.arrayOrObservableArray,
        columns: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            title: PropTypes.node,
            render: PropTypes.func,
            shouldRender: PropTypes.func,
            sortable: PropTypes.bool,
            qa: PropTypes.string,
        })),
        rowClassName: PropTypes.string,
        cellsContainerClassName: PropTypes.string,
        cellClassName: PropTypes.string,
        focusedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        loading: PropTypes.bool,
        rowsDraggable: PropTypes.bool,
        pagination: PropTypes.bool,
        pageSizes: PropTypes.arrayOf(PropTypes.number),
        canItemMove: PropTypes.func,
        onItemMove: PropTypes.func,
        onRowClick: PropTypes.func,
        onRowDoubleClick: PropTypes.func,
        header: PropTypes.node,
        subHeader: PropTypes.node,
        search: PropTypes.oneOfType([InputStore.PropType, TextStore.PropTypes]),
        searchPlaceholder: PropTypes.string,
        actions: PropTypes.arrayOf(PropTypes.shape({
            qa: PropTypes.string,
            disabled: PropTypes.bool,
            label: PropTypes.string,
            icon: PropTypes.string,
            onPress: PropTypes.func.isRequired,
        })),
        onSort: PropTypes.func,
        noItemsText: PropTypes.node,
        qa: PropTypes.string,
    };

    static defaultProps = {
        onItemMove: () => null,
        canItemMove: () => true,
        items: [],
        pageSizes: DEFAULT_PAGE_SIZES,
    };

    state = {
        pageSize: DEFAULT_PAGE_SIZES[0],
        pageIndex: 0,
        sortedColumn: { name: null, desc: true },
    };

    dragColumn = {
        name: 'drag',
        title: '',
        render: (item, itemIndex) => (this.props.canItemMove(item, itemIndex) ?
            <Icon className={block('DragIcon')} type={'drag_indicator'} /> : null),
    };

    currentDragEnterElement = null;
    currentDragEnterRowElement = null;

    componentWillMount() {
        this.onPageSizeSelect(this.props.pageSizes);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.props.items) {
            this.setState({ pageIndex: 0 });
        }
    }

    handleDragStart = (event, itemIndex) => {
        event.dataTransfer.setData('index', itemIndex);
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.setDragImage(event.target.parentElement, 0, 0);
    };

    handleDragEnter = (event, item, itemIndex) => {
        const element = event.target;
        this.currentDragEnterElement = element;
        if (this.isRowElement(element)) {
            this.currentDragEnterRowElement = element;
            if (this.props.canItemMove(item, itemIndex)) {
                element.style.setProperty('background-color', '#00000022');
            }
        }
    };

    handleDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    handleOnDrop = (event, item, itemIndex) => {
        event.preventDefault();
        if (this.props.canItemMove(item, itemIndex)) {
            const draggedIndex = parseInt(event.dataTransfer.getData('index'), 10);
            this.props.onItemMove(draggedIndex, itemIndex);
        }
        this.currentDragEnterElement = null;
        if (this.currentDragEnterRowElement) {
            this.currentDragEnterRowElement.style.removeProperty('background-color');
            this.currentDragEnterRowElement = null;
        }
    };

    handleDragLeave = (event) => {
        const element = event.target;
        if (this.isRowElement(element) && (
            !this.currentDragEnterElement ||
            element === this.currentDragEnterElement ||
            !element.contains(this.currentDragEnterElement))
        ) {
            element.style.removeProperty('background-color');
        }
    };

    isRowElement = (element) => {
        return element && element.tagName.toLowerCase() === 'div' && element.className.indexOf('CellsContainer') >= 0;
    };

    onPageSizeSelect = (selectedValues) => {
        if (selectedValues && selectedValues.length > 0) {
            this.setState({ pageSize: selectedValues[0], pageIndex: 0 });
        }
    };

    onPageChange = (diff) => {
        this.setState({ pageIndex: this.state.pageIndex + diff });
    };

    onSort = (column) => {
        if (column.sortable && this.props.onSort) {
            const { sortedColumn } = this.state;

            if (!sortedColumn.name) {
                sortedColumn.name = column.name;
            } else if (sortedColumn.name !== column.name) {
                sortedColumn.name = column.name;
                sortedColumn.desc = true;
            } else if (sortedColumn.desc) {
                sortedColumn.desc = false;
            } else {
                sortedColumn.name = null;
                sortedColumn.desc = true;
            }
            this.setState({ sortedColumn });
            this.props.onSort(sortedColumn);
        }
    };

    render() {
        const {
            className,
            rowClassName,
            cellsContainerClassName,
            cellClassName,
            columns,
            items,
            focusedItemId,
            loading,
            onRowClick,
            onRowDoubleClick,
            rowsDraggable,
            canItemMove,
            pagination,
            pageSizes,
            header,
            search,
            searchPlaceholder,
            actions,
            onSort,
            noItemsText,
            subHeader
        } = this.props;
        const { pageSize, pageIndex, sortedColumn } = this.state;

        const fixedColumns = rowsDraggable ? [this.dragColumn, ...columns] : columns;
        const firstPageItemIndex = pagination ? pageIndex * pageSize : 0;
        const lastPageItemIndex = pagination ? Math.min(firstPageItemIndex + pageSize, items.length) : items.length;
        const displayedItems = items.slice(firstPageItemIndex, lastPageItemIndex);
        const lastPageIndex = Math.ceil(items.length / pageSize) - 1;

        return <div className={cn(block(), className)} data-qa={this.props.qa}>
            {header || search || actions ? (
                <div className={block('ActionsBar')}>
                    {header ? <FTTitle className={block('Header')}>{header}</FTTitle> : null}

                    {search ? <FuseSearchField
                        className={block('SearchField')}
                        store={search}
                        placeholder={searchPlaceholder || 'Search...'}
                        qa="search_input"
                    /> : null}

                    <div className={block('Buttons')}>
                        {(actions || []).map((action, actionIndex) => (
                            <FuseButton
                                key={actionIndex}
                                className={block('ActionButton', { first: actionIndex === 0 })}
                                icon={action.icon}
                                disabled={action.disabled}
                                type={action.type || 'primary'}
                                color={'blue'}
                                loading={action.loading}
                                onClick={action.onPress}
                                qa={action.qa}
                            >
                                {action.label}
                            </FuseButton>
                        ))}
                    </div>
                </div>
            ) : null}

            {subHeader || null}

            <div className={cn(block('Row'), rowClassName && b(rowClassName, { header: true }))}>
                <div className={block('CellsContainer', { header: true })}>
                    {fixedColumns.map((c) => <div
                        key={c.name}
                        onClick={() => this.onSort(c)}
                        className={cn(
                            block('Cell', {
                                header: true,
                                type: c.name,
                                titleEmpty: !c.title,
                                sortable: c.sortable && !!onSort,
                                sorted: sortedColumn.name === c.name,
                            }),
                            cellClassName && b(cellClassName, { header: true, type: c.name }),
                        )}
                    >
                        {c.title}
                        <Icon
                            className={block('SortableIcon')}
                            type={sortedColumn.name === c.name && !sortedColumn.desc ? 'arrow_up' : 'arrow_down'}
                        />
                    </div>)}
                </div>
            </div>
            {!loading ? displayedItems.map((item, displayedItemIndex) => {
                const itemIndex = firstPageItemIndex + displayedItemIndex;
                return <div
                    key={itemIndex}
                    className={cn(block('Row', { clickable: !!onRowClick || !!onRowDoubleClick }), rowClassName)}
                    onDragStart={(event) => this.handleDragStart(event, itemIndex)}
                    onDragEnter={(event) => this.handleDragEnter(event, item, itemIndex)}
                    onDragOver={(event) => this.handleDragOver(event)}
                    onDragLeave={(event) => this.handleDragLeave(event)}
                    onDrop={(event) => this.handleOnDrop(event, item, itemIndex)}
                    onClick={() => onRowClick && onRowClick(item)}
                    onDoubleClick={() => onRowDoubleClick && onRowDoubleClick(item)}
                    data-qa={`row-${itemIndex} row-for-item-${item.id}`}
                >
                    <div
                        className={cn(block('CellsContainer',
                            { focused: item.id != null && item.id === focusedItemId }), cellsContainerClassName)}
                    >
                        {fixedColumns
                            .filter((column) => !column.shouldRender || column.shouldRender(item, itemIndex))
                            .map((column) => <div
                                key={column.name}
                                draggable={rowsDraggable && column.name === 'drag' && canItemMove(item, itemIndex)}
                                className={cn(block('Cell', { type: column.name }),
                                    cellClassName && b(cellClassName, { type: column.name }))}
                                data-qa={`col_${column.name}`}
                            >{column.render(item, itemIndex)}</div>)}
                    </div>
                </div>;
            }) : null}

            {!loading && displayedItems.length === 0 ?
                <FTBody className={block('NoResults')}>{noItemsText || 'No results found'}</FTBody> : null}

            {!loading && pagination ? <div className={block('Footer')}>
                <div className={block('PagesSelector')}>
                    <FTCaption>Rows per page:</FTCaption>
                    <FuseSelect
                        selectedValues={[this.state.pageSize]}
                        singleSelection
                        containerClassName={block('PageSizeSelect')}
                        onSelect={this.onPageSizeSelect}
                        options={pageSizes.map((size) => ({ value: size, label: size.toString() }))}
                    />
                </div>
                <FTCaption className={block('PaginationLabel')}>
                    {`${firstPageItemIndex + 1}-${lastPageItemIndex} of ${items.length}`}
                </FTCaption>
                <div>
                    <FuseButton
                        className={block('PaginationButton')}
                        icon="arrow_first_page"
                        disabled={pageIndex <= 0}
                        onClick={() => this.onPageChange(-pageIndex)}
                    />
                    <FuseButton
                        className={block('PaginationButton')}
                        icon="keyboard_arrow_left"
                        disabled={pageIndex <= 0}
                        onClick={() => this.onPageChange(-1)}
                    />
                    <FuseButton
                        className={block('PaginationButton')}
                        icon="keyboard_arrow_right"
                        disabled={lastPageItemIndex >= items.length}
                        onClick={() => this.onPageChange(1)}
                    />
                    <FuseButton
                        className={block('PaginationButton')}
                        icon="arrow_last_page"
                        disabled={lastPageItemIndex >= items.length}
                        onClick={() => this.onPageChange(lastPageIndex - pageIndex)}
                    />
                </div>
            </div> : null}

            {loading ? <div className={block('SpinnerWrapper')}>
                <UDSpinner className={block('Spinner')} type="small" />
            </div> : null}
        </div>;
    }
}

export default observer(Table);
