import { extendObservable } from 'mobx';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import FlagStore from './FlagStore';
import LocalStorage, { LS_KEY } from '../LocalStorage';
import providers from '../webmailProviders.json';

function prepareURL(url) {
    return url.toLowerCase()
        .trim()
        .replace(/^http(?:s)?:\/\//, '')
        .replace(/\/.*$/, '')
        .replace(/^www\./, '');
}

export default class WebmailStore {
    static PropType = PropTypes.shape({
        otherProvidersVisibility: FlagStore.PropType,
        providers: MobxPropTypes.arrayOrObservableArray.isRequired,
        visited: MobxPropTypes.arrayOrObservableArray.isRequired,
    });

    constructor(data = {}, useLocalStorage = true) {
        this.useLocalStorage = useLocalStorage;

        extendObservable(this, /** @class WebmailStore */{
            otherProvidersVisibility: new FlagStore(false),

            providers,

            get visited() {
                const visited = this.useLocalStorage
                    ? LocalStorage.get(LS_KEY.webmailProvidersVisits, {})
                    : [];
                return this.providers
                    .map((item) => Object.assign({}, item, { visited: visited[item.id] || 0 }))
                    .filter((item) => !!item.visited)
                    .sort((a, b) => a.visited - b.visited);
            },

            registerVisited(url) {
                if (url) {
                    url = prepareURL(url);
                    const provider = this.providers.find((item) => prepareURL(item.url) === url);
                    if (provider) {
                        const visits = LocalStorage.get(LS_KEY.webmailProvidersVisits, {});
                        visits[provider.id] = Date.now();
                        LocalStorage.set(LS_KEY.webmailProvidersVisits, visits);
                    }
                }
            },
        }, data);
    }
}
