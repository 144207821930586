import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import FileManagerStore from '../stores/FileManagerStore';
import { FILE_PROCESSING_BLOCK_STATE,
    FILE_PROCESSING_STATE,
    FILE_PROCESSING_TYPE,
    REASON_TO_SCAN_ERROR_MAP
} from '../mirror/sharedConstants';

const FileEventController = ({ fileEvent, t, render }) => {
    let isScanning = false;
    let notificationTitle = '';
    let notificationStatus = '';
    let notificationType = FileManagerStore.NOTIFICATION_TYPE.INFO;

    if (fileEvent.state === FILE_PROCESSING_STATE.SCANNING) {
        notificationTitle = t(`Notification.PROCESSING_${fileEvent.type}.title`);
        notificationStatus = t(`Notification.PROCESSING_${fileEvent.type}.status`);
        isScanning = true;
    }

    if (fileEvent.state === FILE_PROCESSING_STATE.BLOCKED) {
        notificationType = FileManagerStore.NOTIFICATION_TYPE.WARNING;
        notificationTitle = t(`Notification.${fileEvent.type}_BLOCKED.title`);
        notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.generic`);

        if (fileEvent.errorId === FILE_PROCESSING_BLOCK_STATE.BLOCKED_BY_POLICY ||
            fileEvent.errorId === FILE_PROCESSING_BLOCK_STATE.SIZE_EXCEEDED) {
            notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.byPolicy`);
        }

        if (fileEvent.errorId === FILE_PROCESSING_BLOCK_STATE.MAX_SIZE_EXCEEDED) {
            notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.byMaxFileSize`);
        }

        if (fileEvent.errorId === FILE_PROCESSING_BLOCK_STATE.NOT_SECURED) {
            notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.byInsecure`);
        }

        if (fileEvent.errorId === FILE_PROCESSING_BLOCK_STATE.BLOCKED_BY_DLP) {
            notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.byDLP`);
        }

        if (fileEvent.errorId === FILE_PROCESSING_BLOCK_STATE.MALWARE) {
            notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.byMalware`);
        }

        const scanErrorReason = fileEvent.threatScanErrorReason || fileEvent.dlpScanErrorReason;
        const scanError = REASON_TO_SCAN_ERROR_MAP[scanErrorReason];

        if (scanError !== undefined) {
            if (fileEvent.errorId === FILE_PROCESSING_BLOCK_STATE.TIMEOUT) {
                if (fileEvent.threatScanErrorReason) {
                    notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.byFileRule.${scanError}`);
                } else if (fileEvent.dlpScanErrorReason) {
                    notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.byDLPRule.${scanError}`);
                }
            }
            if (fileEvent.errorId === FILE_PROCESSING_BLOCK_STATE.MALWARE) {
                notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.byFileRule.${scanError}`);
            }

            if (fileEvent.errorId === FILE_PROCESSING_BLOCK_STATE.BLOCKED_BY_DLP) {
                notificationStatus = t(`Notification.${fileEvent.type}_BLOCKED.byDLPRule.${scanError}`);
            }
        }
    }

    if (fileEvent.state === FILE_PROCESSING_STATE.ALLOWED) {
        notificationTitle = t(`Notification.${fileEvent.type}_SCAN_COMPLETE.title`);
        notificationStatus = t(`Notification.${fileEvent.type}_SCAN_COMPLETE.status`);
        notificationType = FileManagerStore.NOTIFICATION_TYPE.SUCCESS;
    }

    if (fileEvent.state === FILE_PROCESSING_STATE.CANCELED) {
        notificationTitle = t(`Notification.${fileEvent.type}_CANCELED.title`);
        notificationStatus = t(`Notification.${fileEvent.type}_CANCELED.status`);
        notificationType = FileManagerStore.NOTIFICATION_TYPE.WARNING;
    }

    const dateTime = new Date(fileEvent.startTime);
    const time = dateTime.toLocaleTimeString('en-US', { timeStyle: 'short', hour12: true })
        .replace(' ', '');
    const startTime = `${dateTime.toLocaleDateString()} at ${time}`;
    const icon = fileEvent.type === FILE_PROCESSING_TYPE.DOWNLOAD ? 'download_file' : 'upload_file';

    const data = {
        isScanning,
        notificationTitle,
        notificationStatus,
        notificationType,
        startTime,
        icon,
        fileMimeType: fileEvent.fileMimeType,
        categories: fileEvent.categories,
        country: fileEvent.ftrPolicy?.FileThreatRule?.SourceCountry || fileEvent.ftrPolicy?.DLPRule?.SourceCountry,
        fileSize: fileEvent.fileSize || fileEvent.size,
        filePreview: fileEvent.filePreview,
        // If state is ALLOWED, then "fileEvent.url" contains the internal url where the file has been downloaded.
        // For all other events it contains the true url
        fileUrl: fileEvent.fileUrl || fileEvent.url
    };

    return <Fragment>
        {render(data)}
    </Fragment>;
};

FileEventController.propTypes = {
    fileEvent: FileManagerStore.EventPropType,
    render: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(FileEventController);
