import PropTypes from 'prop-types';
import { action, extendObservable } from 'mobx';
import { sendToClient } from '../mirror/client/browserMessageBus';
import { API_BROWSER_TO_CLIENT } from '../mirror/sharedConstants';
import InputStore from './InputStore';

export default class BasicAuthStore {
    static PropType = PropTypes.shape({
        username: InputStore.PropType.isRequired,
        password: InputStore.PropType.isRequired,
        challengerUrl: PropTypes.string.isRequired,
    });

    constructor(data = {}) {
        extendObservable(this, /** @class BasicAuthStore */{
            username: new InputStore(),
            password: new InputStore(),
            challengerUrl: '',
        }, data);
    }

    cancel(frameEl) {
        sendToClient(frameEl, API_BROWSER_TO_CLIENT.sendBasicAuthorization, { cancel: true });
    }

    send(frameEl) {
        sendToClient(frameEl, API_BROWSER_TO_CLIENT.sendBasicAuthorization, this.getValues());
    }

    clear = action(() => {
        this.username.setValue('');
        this.password.setValue('');
    });

    getValues() {
        return {
            username: this.username.value + '',
            password: this.password.value + '',
        };
    }
}
