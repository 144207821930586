import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Interpolate, translate } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import Actions from '../Actions/Actions';
import Box from '../Box/Box';
import Modal from '../Modal';
import Button from '../Button';
import SnackStore from '../../stores/SnackStore';
import { ClearCookiesStoreProp } from '../../stores/props';
import { TBody } from '../Text/Text';


function ClearCookiesModal({ t, clearCookies, snack, onRefresh }) {
    return <Modal
        isOpen={clearCookies.modal.isOpen}
        shouldCloseOnOverlayClick={false}
    >
        <Box title={t('clearCookiesModal.title')}>
            <TBody>
                <Interpolate
                    i18nKey="clearCookiesModal.text.template_ped"
                    linebreak={<Fragment><br /><br /></Fragment>}
                />
            </TBody>
            <Actions style={{ marginTop: 30 }}>
                <Button
                    onClick={clearCookies.modal.close}
                    secondaryWhite
                    raised
                    fullWidth
                    disabled={clearCookies.isApiCallLoading}
                >{t('clearCookiesModal.cancel')}</Button>
                <Button
                    onClick={() => clearCookies.doClearCookies(() => {
                        // TODO: do refresh on store level
                        onRefresh();
                        snack.showSnack({ type: 'success', content: t('clearCookiesModal.success') });
                    })}
                    primaryGreen
                    raised
                    fullWidth
                    loading={clearCookies.isApiCallLoading}
                >{t('clearCookiesModal.ok')}</Button>
            </Actions>
        </Box>
    </Modal>;
}

ClearCookiesModal.propTypes = {
    t: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired,
    clearCookies: ClearCookiesStoreProp,
    snack: SnackStore.PropType.isRequired,
};

export default translate()(inject('clearCookies', 'snack')(observer(ClearCookiesModal)));
