import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Interpolate, translate } from 'react-i18next';
import b from 'b_';
import CustomerContent from '../CustomerContent';
import FuseWysiwygReadOnly from '../Fuse/FuseWysiwyg/FuseWysiwygReadOnly';
import Icon from '../Icon/Icon';
import Bg from '../Bg/Bg';
import { LogoProofpointWord, PfptLogo, ProofpointUrlIsolationLogo } from '../Logo/PfptLogo';
import { TBody } from '../Text/Text';
import './BlockPage.css';

const block = b.lock('BlockPage');

class MaliciousThreatPage extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        isUrlIsolation: PropTypes.bool,
        logo: PropTypes.string,
        headline: PropTypes.string,
        headlineIcon: PropTypes.bool,
        background: PropTypes.bool,
        message: PropTypes.string,
        content: PropTypes.array,
    };

    static defaultProps = {
        headlineIcon: true,
        background: true,
    };
    renderNew() {
        return <Bg
            className={block()}
            type={'none'}
        >
            <TBody className={block('UserContent')}>
                <FuseWysiwygReadOnly content={this.props.content} />
            </TBody>
        </Bg>;
    }

    renderOld() {
        const { t, isUrlIsolation, logo, headline, headlineIcon, message } = this.props;
        const defaultLogo = isUrlIsolation
            ? <ProofpointUrlIsolationLogo noShadow className={block('DefaultLogo')} />
            : <PfptLogo className={block('DefaultLogo')} />;
        return <Bg
            className={block()}
            type={'none'}
        >
            {logo ? <img alt="logo" src={logo} className={block('Logo')} /> : defaultLogo}
            {headline === '' ? null : <div className={block('Title')}>
                {headlineIcon ? <Icon type="report" className={block('TitleIcon')} /> : null}
                <div>{headline || t('browserErrorPage.blockedUnsafe.title')}</div>
            </div>}
            <div className={block('Message')}>
                <TBody className={block('Text')}>
                    {message ? <CustomerContent html={message} /> : <div>
                        <p>{t('browserErrorPage.blockedUnsafe.desc1')}</p>
                        <p>{t('browserErrorPage.blockedUnsafe.desc2')}</p>
                        <p><Interpolate
                            i18nKey="browserErrorPage.blockedUnsafe.reason1.template"
                            title={<strong>{t('browserErrorPage.blockedUnsafe.reason1.title')}</strong>}
                        /></p>
                        <p><Interpolate
                            i18nKey="browserErrorPage.blockedUnsafe.reason2.template"
                            title={<strong>{t('browserErrorPage.blockedUnsafe.reason2.title')}</strong>}
                        /></p>
                    </div>}
                </TBody>
            </div>
            <div className={block('Spacer')} />
            <div className={block('ProtectedBy')}>
                <div className={block('ProtectedByText')}>
                    {t('browserErrorPage.blockedUnsafe.protectedBy')}
                </div>
                <LogoProofpointWord style={{ width: 110, height: 21, background: 'rgba(255, 255, 255, .75)' }} />
            </div>
        </Bg>;
    }

    render() {
        return this.props.content ? this.renderNew() : this.renderOld();
    }
}

export default translate()(MaliciousThreatPage);
