import b from 'b_';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import FlagStore from '../../stores/FlagStore';
import BoolStore from '../../stores/helpers/BoolStore';
import Icon from '../Icon/Icon';
import { FTBody } from '../Fuse/Text';
import { Key } from '../../mirror/userInput';
import './FuseCheckbox.css';

const block = b.lock('FuseCheckbox');

class FuseCheckbox extends Component {
    static propTypes = {
        store: PropTypes.oneOfType([FlagStore.PropType, BoolStore.PropTypes]).isRequired,
        className: PropTypes.string,
        label: PropTypes.node,
        indeterminate: PropTypes.bool,
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        black: PropTypes.bool,
        focus: PropTypes.bool,
        hover: PropTypes.bool,
        active: PropTypes.bool,
        isRadioButton: PropTypes.bool,
        qa: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        label: '',
        indeterminate: false,
        readOnly: false,
        black: false,
        isRadioButton: false,
    };

    state = {
        focus: false,
    };

    tabPressed = false;

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        if (event.keyCode === Key.TAB) {
            this.tabPressed = true;
            setTimeout(() => {
                this.tabPressed = false;
            });
        }
    };

    render() {
        const {
            className,
            label,
            indeterminate,
            readOnly,
            disabled,
            black,
            focus,
            hover,
            active,
            store,
            isRadioButton,
            qa,
            ...inputProps
        } = this.props;

        const checkBoxIconType = indeterminate ? 'indeterminate_check_box' : 'check_box';
        const checkedIconType = isRadioButton ? 'radio_button_checked' : checkBoxIconType;
        const unCheckedIconType = isRadioButton ? 'radio_button_unchecked' : 'check_box_outline_blank';

        return <div className={className}>
            <label className={cn(block({
                checked: store.getValue(),
                disabled: readOnly || disabled,
                focused: focus === undefined ? this.state.focus : focus,
                hover: hover === true,
                active: active === true,
            }))}
            >
                <input
                    className={block('Input')}
                    type="checkbox"
                    disabled={readOnly || disabled}
                    onChange={store.toggle}
                    checked={store.getValue()}
                    onFocus={() => {
                        if (this.tabPressed) {
                            this.setState({ focus: true });
                        }
                    }}
                    onBlur={() => this.setState({ focus: false })}
                    {...qa ? { 'data-qa': qa } : {}}
                    {...inputProps}
                />
                <div className={
                    cn(block('Circle'), store.getValue() ? 'checked' : '')}
                {...qa ? { 'data-qa': qa + '-circle' } : {}}
                >
                    {store.getValue() ? <Icon
                        className={block('IconChecked', {
                            disabled: readOnly || disabled,
                            black,
                        })}
                        type={checkedIconType}
                    /> : <Icon
                        className={block('IconNotChecked', { disabled: readOnly || disabled })}
                        type={unCheckedIconType}
                    />}
                </div>
                <FTBody className={block('Text', { disabled, readOnly })}>{label}</FTBody>
            </label>
        </div>;
    }
}

export default observer(FuseCheckbox);
