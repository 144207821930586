import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { CSSTransitionGroup } from 'react-transition-group';
import { inject, observer } from 'mobx-react';
import b from 'b_';
import makeElementId from '../../makeElementId';
import SnackStore from '../../stores/SnackStore';
import FuseButton from '../FuseControls/FuseButton';
import './FuseSnackbar.css';

const block = b.lock('FuseSnackbar');

class FuseSnackbar extends Component {
    static propTypes = {
        snack: SnackStore.PropType.isRequired,
        t: PropTypes.func.isRequired,
    };

    contentId = makeElementId('SnackbarContent');

    render() {
        const { snack, t } = this.props;
        const role = snack.current && snack.current.type === 'error' ? 'alert' : 'dialog';

        return <div
            className={block()}
            aria-modal="false"
            role={role}
            aria-labelledby={this.contentId}
        >
            <div className={block('Snacks')}>
                <CSSTransitionGroup
                    transitionName={block('SnackbarAnimation')}
                    transitionEnterTimeout={400}
                    transitionLeaveTimeout={200}
                >
                    {snack.current ? <div className={block('SnackbarCurrent', { type: snack.current.type })}>
                        <div
                            className={block('Content')}
                            id={this.contentId}
                        >{snack.current.content}</div>
                        <FuseButton
                            className={block('Button', { type: snack.current.type })}
                            icon="close"
                            title={t('SnackBar.hideNotification')}
                            aria-label={t('SnackBar.hideNotification')}
                            onClick={snack.hideCurrentSnack}
                            invertBackgroundColor
                        />
                    </div> : null}
                </CSSTransitionGroup>
            </div>
        </div>;
    }
}

export default translate()(inject('snack')(observer(FuseSnackbar)));
