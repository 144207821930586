import b from 'b_';
import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Flag from '../Flag/Flag';
import LocationMenu from '../LocationMenu/LocationMenu';
import { ResearchStoreProp } from '../../stores/props';
import BrowserStore from '../../browser/stores/BrowserStore';
import FuseButton from '../FuseControls/FuseButton';
import './TopBarResearchLocation.css';

const tbResearchBlock = b.lock('TopBarResearchLocation');

class TopBarResearchLocation extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        research: ResearchStoreProp,
        onRefresh: PropTypes.func,
        className: PropTypes.string,
        btnProps: PropTypes.shape(FuseButton.propTypes),
    };

    getCommon() {
        const { research, t } = this.props;

        return {
            onClick: research.locationMenu.toggle,
            title: t('browserTopBar.menuButtonTitle'),
        };
    }
    getContent() {
        const { research, t, browser } = this.props;

        return <Fragment>
            <Flag
                className={tbResearchBlock('LocationFlagIcon', { minified: browser.isTopBarMinificationAllowed() })}
                type={research.getLocationById(research.location).flag}
            />
            <div className={tbResearchBlock('ResearchButtonText')}>
                {t('location.' + research.location)}
            </div>
        </Fragment>;
    }
    getModal() {
        return this.props.research.locationMenu.isOpen
            ? <LocationMenu afterChange={this.props.onRefresh} />
            : null;
    }
    renderAlternative() {
        const { browser, btnProps, className } = this.props;
        const isTopBarMinified = browser.isTopBarMinificationAllowed();

        return <div style={{ position: 'relative', display: 'flex' }} className={className}>
            <FuseButton
                type="quaternary"
                className={b.lock('interactive')({ minified: isTopBarMinified })}
                iconRight="arrow_drop_down"
                {...this.getCommon()}
                extraSmall={isTopBarMinified}
                {...btnProps}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 155,
                    }}
                >{this.getContent()}</div>
            </FuseButton>
            {this.getModal()}
        </div>;
    }
    render() {
        return this.renderAlternative();
    }
}
export default translate()(inject('env', 'research', 'browser')(observer(TopBarResearchLocation)));
