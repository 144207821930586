const requestAnimationFrame = window.requestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.mozRequestAnimationFrame;

const cancelAnimationFrame = window.cancelAnimationFrame
    || window.webkitCancelAnimationFrame
    || window.mozCancelAnimationFrame;

export default function requestAnimationFrameOrElse(fn, maxWait = 100) {
    let intervalId;
    let animationFrameId;
    let done = false;
    const fnOnce = () => {
        if (!done) {
            done = true;
            clearInterval(intervalId);
            cancelAnimationFrame(animationFrameId);
            fn();
        }
    };
    animationFrameId = requestAnimationFrame(fnOnce);
    intervalId = setInterval(fnOnce, maxWait);
}
