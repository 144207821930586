import debug from '../debug';

const logger = debug.create('EventLogger', false);
const SEPARATOR = ':';
export default class EventLogger {
    marks = new Map();
    markSteps = new Map();
    markDuplicateEvents = new Map();
    traceData = {};
    EVENT_SHOULD_NOT_HAPPEN = 'should_not_happen';
    eventQueue = [];
    MAX_QUANTITY_OF_EVENTS = 5;
    sendingEventsLoopTimeout = null;
    intervalBetweenEvents = 1000;
    slowDown = false;
    numberOfTimesOfASingleEventStore = {}
    OVERFLOW_OF_A_SINGLE_EVENT = 100;
    OVERFLOW_EVENTS_INTERVAL = 120 * 1000;
    eventsData = {};

    constructor() {
        this.startSendingEvents();
    }

    storeEventData(eventName, data, overrideIfExist = false) {
        const prevData = this.eventsData[eventName] || {};
        this.eventsData[eventName] = overrideIfExist ? { ...prevData, ...data } : { ...data, ...prevData };
    }

    setTraceData(traceData) {
        this.traceData = traceData || this.traceData;
    }

    makeMarkKey = (ids) => ids.join('_');

    mark(...args) {
        const key = this.makeMarkKey(args);
        this.marks.set(key, Date.now());
        return key;
    }

    markStep(eventName, step, data) {
        const time = Date.now();
        if (this.markSteps.has(eventName) && step) {
            this.markSteps.set(eventName, { ...this.markSteps.get(eventName),
                ...data,
                [step]: time - this.markSteps.get(eventName)._lastEventTime,
                _lastEventTime: time });
        } else {
            this.markSteps.set(eventName, { _lastEventTime: time, ...data });
        }
    }

    logNetworkInformation(eventName) {
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (connection) {
            const { effectiveType, rtt, downlink } = connection;
            if (effectiveType || rtt || downlink) {
                this.sendEvent(eventName, {
                    type: effectiveType,
                    rtt,
                    downlink,
                });
            }
        }
    }

    markStepById(eventName, id, step, data = {}) {
        this.markStep(this.getEventKeyById(eventName, id), step, data);
    }

    measureStepByIdAndSend(eventName, id, step, data = {}) {
        const steps = this.markAndCleanSteps(this.getEventKeyById(eventName, id), step, data);
        if (Object.keys(steps).length > 0) {
            this.sendEvent(eventName, { ...data, ...steps, id });
        }
    }
    getEventKeyById(eventName, id) {
        return eventName + SEPARATOR + id;
    }

    sendSteps(eventName, data, id) {
        const key = id ? this.getEventKeyById(eventName, id) : eventName;
        let steps = {};
        if (this.markSteps.has(key)) {
            steps = this.markSteps.get(key);
            delete steps._lastEventTime;
            this.markSteps.delete(key);
        }
        this.sendEvent(eventName, { ...data, ...steps });
    }

    markAndCleanSteps(eventName, step, data = {}) {
        this.markStep(eventName, step, data);
        const steps = this.markSteps.get(eventName);
        delete steps._lastEventTime;
        this.markSteps.delete(eventName);
        return steps;
    }

    measureStepAndSend(eventName, step, data = {}) {
        const steps = this.markAndCleanSteps(eventName, step, data);
        if (Object.keys(steps).length > 0) {
            this.sendEvent(eventName, { ...data, ...steps });
        }
    }

    measure(markName) {
        if (this.marks.has(markName)) {
            return (Date.now() - this.marks.get(markName)) | 0;
        }
        return 0;
    }

    context = {};

    extendContext(data, updateIfDefined = false) {
        if (updateIfDefined) {
            const definedData = {};
            if (data) {
                Object.keys(data).forEach((key) => {
                    if (data[key]) {
                        definedData[key] = data[key];
                    }
                });
                Object.assign(this.context, definedData);
            }
        } else {
            Object.assign(this.context, data);
        }
    }

    isContextInitialized() {
        return Boolean(this.context.cluster);
    }

    apiCall(data, traceData) {
        logger.log('apiCall', data, traceData);
    }

    sendEventWithoutDuplication(eventName, data, target) {
        if (this.markDuplicateEvents.has(eventName)) {
            if (this.markDuplicateEvents.get(eventName).values.indexOf(target) > -1) {
                return;
            }
            this.markDuplicateEvents.set(eventName, { values: [...this.markDuplicateEvents.get(eventName).values,
                target] });
        } else {
            this.markDuplicateEvents.set(eventName, { values: [target] });
        }
        this.sendEvent(eventName, data);
    }

    countSentEvent(name) {
        if (name) {
            this.numberOfTimesOfASingleEventStore[name] = (this.numberOfTimesOfASingleEventStore[name] || 0) + 1;
        }
    }

    getMaxNumberOfTimesOfASingleEvent() {
        return Math.max(0, ...Object.values(this.numberOfTimesOfASingleEventStore));
    }

    sendEvent(name, data, count = false) {
        // debug.info('EventLogger.sendEvent', name, data);
        let eventData;
        const collectedData = this.eventsData[name] || {};
        if (name) {
            let customMetadata;

            if (this.context && this.context.customMetadata) {
                try {
                    customMetadata = JSON.stringify(this.context.customMetadata);
                } catch (error) {
                    logger.error(error);
                }
            }

            eventData = {
                ...this.context,
                customMetadata,
                dateAdded: 'AUTO',
                ...data,
                ...collectedData,
            };
            if (count) {
                let updatedEventInQueue = false;
                this.eventQueue.forEach((event) => {
                    if (event.type === name) {
                        let sameData = true;
                        const eventDataInQueue = JSON.parse(event.data);
                        if (data) {
                            Object.keys(data).forEach((key) => {
                                if (data[key] !== eventDataInQueue[key]) {
                                    sameData = false;
                                }
                            });
                            if (sameData) {
                                eventDataInQueue.eventCounter = eventDataInQueue.eventCounter ?
                                    eventDataInQueue.eventCounter + 1 : 1;
                                event.data = JSON.stringify(eventDataInQueue);
                                updatedEventInQueue = true;
                            }
                        }
                    }
                });
                if (updatedEventInQueue) {
                    return;
                }
                eventData.eventCounter = 1;
            }
            this.countSentEvent(name);
            this.eventQueue.push({
                type: name,
                data: JSON.stringify(eventData),
            });
        }
    }

    stopSendingEvents = () => {
        if (this.sendingEventsLoopTimeout) {
            clearTimeout(this.sendingEventsLoopTimeout);
            this.sendingEventsLoopTimeout = null;
        }
    }

    startSendingEvents = () => {
        this.stopSendingEvents();
        this.sendingEventsLoop();
    };

    /**
     * @private
     */
    sendingEventsLoop = () => {
        this.sendingEventsLoopTimeout = setTimeout(() => {
            if (this.eventQueue.length > 0) {
                if (!this.slowDown) {
                    const eventsThatReadyToSend = this.eventQueue.splice(0, Math.min(this.MAX_QUANTITY_OF_EVENTS,
                        this.eventQueue.length)
                    );
                    if (eventsThatReadyToSend.length > 0) {
                        if (this.intervalBetweenEvents < this.OVERFLOW_EVENTS_INTERVAL &&
                            // slow down when there are to many event from single event
                            this.OVERFLOW_OF_A_SINGLE_EVENT < this.getMaxNumberOfTimesOfASingleEvent()) {
                            this.intervalBetweenEvents = this.OVERFLOW_EVENTS_INTERVAL;
                        }
                        this.apiCall(eventsThatReadyToSend);
                    }
                } else {
                    this.slowDown = false;
                }
            }
            this.sendingEventsLoop();
        }, this.intervalBetweenEvents);
    }

    measureAndSend(eventName, data = {}, markName = eventName, threshhold = 0) {
        const timeMs = this.measure(markName);
        if (!threshhold || timeMs >= threshhold) {
            this.sendEvent(eventName, { ...data, timeMs });
        }
    }

    profileFunctionAndSend(eventName, fn, data = {}) {
        const markName = this.mark(eventName, Math.random());
        const result = fn();
        this.measureAndSend(eventName, data, markName);
        return result;
    }

    getResourcesLoadingPerformanceByResourcesNameSuffix = (str) => {
        const resourcesEntries = window.performance.getEntriesByType('resource');
        if (resourcesEntries) {
            const resource = resourcesEntries.find((resourcesEntry) =>
                resourcesEntry.name.indexOf(str) > -1);
            if (resource) {
                const loadingInfo = {
                    readyToFetchMs: Math.round(resource.fetchStart),
                    timeMs: Math.round(resource.responseEnd - resource.fetchStart),
                };
                if (resource.requestStart > 0) {
                    loadingInfo.latencyTimeMs = Math.round(resource.responseStart - resource.requestStart);
                    loadingInfo.contentDownloadMs = Math.round(resource.responseEnd - resource.responseStart);
                }
                if (resource.transferSize > 0) {
                    loadingInfo.transferSize = resource.transferSize;
                }
                return loadingInfo;
            }
        }
    };

    getNavigationPerformanceRawData = () => {
        const navigationEntry = window.performance.getEntriesByType('navigation');
        if (navigationEntry && navigationEntry.length === 1) {
            const navigationKeys = ['nextHopProtocol', 'name', 'startTime', 'redirectStart', 'redirectEnd',
                'redirectCount', 'fetchStart', 'domainLookupStart', 'domainLookupEnd', 'connectStart',
                'secureConnectionStart', 'connectEnd', 'requestStart', 'responseStart', 'responseEnd', 'transferSize',
                'encodedBodySize', 'decodedBodySize', 'domInteractive', 'domContentLoadedEventStart',
                'domContentLoadedEventEnd', 'domComplete', 'loadEventStart', 'loadEventEnd', 'type'];
            const navigationPerformance = {};
            navigationPerformance.originTimestamp = Date.now() - Math.round(window.performance.now());
            navigationKeys.forEach((key) => {
                navigationPerformance[key] = navigationEntry[0][key];
            });
            return navigationPerformance;
        }
        return null;
    }

    getNavigationPerformance = (location = false) => {
        const navigationPerformance = this.getNavigationPerformanceRawData();
        if (navigationPerformance) {
            const loadingInfo = {
                readyToFetchMs: Math.round(navigationPerformance.fetchStart),
                latencyTimeMs: Math.round(navigationPerformance.responseStart - navigationPerformance.requestStart),
                contentDownloadMs: Math.round(navigationPerformance.responseEnd
                    - navigationPerformance.responseStart),
                type: navigationPerformance.type,
            };
            if (location && !!navigationPerformance.name) {
                loadingInfo.location = navigationPerformance.name;
            }
            if (navigationPerformance.domComplete > 0) {
                loadingInfo.renderTimeMs = Math.round(navigationPerformance.domComplete -
                    navigationPerformance.responseEnd);
            }

            if (navigationPerformance.loadEventEnd > 0) {
                loadingInfo.timeMs = Math.round(navigationPerformance.loadEventEnd -
                    navigationPerformance.fetchStart);
            }
            if (navigationPerformance.loadEventEnd > 0) {
                loadingInfo.onLoadMs = Math.round(navigationPerformance.loadEventEnd -
                    navigationPerformance.domComplete);
            }

            if (navigationPerformance.transferSize > 0) {
                loadingInfo.transferSize = navigationPerformance.transferSize;
            }
            return loadingInfo;
        }
    };

    logTimeElapsedSinceTheTimeOrigin = (eventType, originTime = undefined) => {
        if (originTime) {
            this.sendEvent(eventType, { timeMs: Math.round(originTime) });
        } else if (window.performance && window.performance.now) {
            this.sendEvent(eventType, { timeMs: Math.round(window.performance.now()) | 0 });
        }
    };
}
