import PropTypes from 'prop-types';
import cn from 'classnames';
import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { CSSTransitionGroup } from 'react-transition-group';
import b from 'b_';
import { observer, inject } from 'mobx-react';
import BrowserStore from '../../browser/stores/BrowserStore';
import CommonBrowserModals from '../CommonBrowserModals/CommonBrowserModals';
import Bg from '../Bg/Bg';
import UDSpinner from './Fragments/UDSpinner';
import TopBarAlternative from './../TopBarAlternative/TopBarAlternative';
import UDHumanCheckPage from './UDHumanCheckPage';
import ThePage from '../ThePage/ThePage';
import { TroubleshootingInstructions } from '../TroubleshootingModeScreen';
import { EnvStoreProps } from '../../stores/props';
import './UDBrowser.css';
import './../../Components/MinimizableBrowser.css';

const block = b.lock('UDBrowser');
const mBlock = b.lock('MinimizableBrowser');

const PageOverlayAnimation = (props) => <CSSTransitionGroup
    transitionName={block('PageOverlayAnimation').toString()}
    transitionEnterTimeout={150}
    transitionLeave={false}
    {...props}
/>;

class UDBrowser extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        iframeSrc: PropTypes.string,
        iframeOpacity: PropTypes.number,
        isLoading: PropTypes.bool,
        onLoad: PropTypes.func,
        onRefresh: PropTypes.func,
        env: EnvStoreProps,
    };

    render() {
        const { iframeSrc, browser, t, env } = this.props;
        const { isHomePage } = browser;
        const isHumanCheck = browser.notification === BrowserStore.NOTIFICATION.HUMAN_CHECK;

        return <div className={cn(block(), mBlock({
            minimizable: !isHomePage && browser.isTopBarMinimizable(),
            mobile: env.screen.isMobile,
        }))}
        >
            <TopBarAlternative onRefresh={this.props.onRefresh} />

            <div
                className={block('AbsoluteContainer', {
                    canExit: browser.canExitIsolation(),
                    minimized: browser.isMinimized(),
                })}
            >
                {isHumanCheck ? <UDHumanCheckPage /> : <Fragment>
                    {browser.troubleshootingInstructionsScreen ? <TroubleshootingInstructions /> : null}

                    {iframeSrc && !browser.isDanger() && !browser.troubleshootingInstructionsScreen ? <ThePage
                        iframeSrc={this.props.iframeSrc}
                        iframeOpacity={this.props.iframeOpacity}
                        onLoad={this.props.onLoad}
                        blur={this.props.isLoading || browser.topBarMenu.isOpen}
                    /> : null}

                    <PageOverlayAnimation>
                        {this.props.isLoading ? <UDSpinner
                            className={block('Spinner')}
                            type="large"
                            title={t('Browser.spinnerTitle')}
                        /> : null}
                    </PageOverlayAnimation>

                    {browser.shouldDisplayBlockPage() ? <iframe
                        title="Url Isolation Block Page"
                        key={browser.blockPage}
                        src={browser.blockPage}
                        className={block('BlockPage')}
                        name="block_page"
                    /> : null}

                    {browser.directDownloadPage.isOpen
                        ? <Bg key="bg" className={block('PageBg')} type="bottom" />
                        : null}
                </Fragment>}
            </div>

            <CommonBrowserModals onRefresh={this.props.onRefresh} />
        </div>;
    }
}

export default translate()(inject('browser', 'env')(observer(UDBrowser)));
