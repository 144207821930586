import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import TextareaAutosize from 'react-autosize-textarea';
import b from 'b_';
import Button from '../Button';
import Modal from '../Modal';
import Icon from '../Icon/Icon';
import Box from '../Box/Box';
import { ResearchStoreProp, EnvStoreProps } from '../../stores/props';
import { userAgents } from '../../userAgents';
import { TBody, TCaption } from '../Text/Text';
import TopBarDropdown from '../TopBarDropdown/TopBarDropdown';
import './UserAgentMenu.css';

const block = b.lock('UserAgentMenu');

class UserAgentMenu extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        research: ResearchStoreProp,
        env: EnvStoreProps,
        afterChange: PropTypes.func,
    };

    static defaultProps = {
        afterChange: () => {},
    };

    state = {
        displayApplyButton: false,
        activeDropdownKey: null,
    };

    onCustomInputChange = (e) => {
        this.setState({ displayApplyButton: e.target.value !== '' });
    };

    onDropdownClick = (key) => {
        this.setState({ activeDropdownKey: this.state.activeDropdownKey === key ? null : key });
    };

    setUserAgent = (newValue) => {
        this.props.research.setUserAgent(newValue);
        this.props.research.userAgentMenu.close();
        this.props.afterChange();
    };

    renderTitle() {
        const { t, research } = this.props;

        return <Fragment>
            <div className={block('TitleText', { center: !research.userAgent })}>
                {research.userAgent ? t('userAgentMenu.popupTitle') : t('userAgentMenu.popupTitleAction')}
            </div>
            {research.userAgent ? <Fragment>
                <div className={block('Spacer')} />
                <Button
                    raised
                    className={block('Reset')}
                    onClick={() => this.setUserAgent(null)}
                >{t('userAgentMenu.reset')}</Button>
            </Fragment> : null}
        </Fragment>;
    }

    renderContent() {
        const { t, research } = this.props;
        const { activeDropdownKey } = this.state;

        return <Box
            noPadding
            theme="research"
            title={this.renderTitle()}
            titleClassName={block('Title')}
            contentClassName={block('ContentWrapper')}
        >
            {Object.keys((userAgents)).map((os) => <div className={block('OS')} key={os}>
                <TBody
                    className={block('OSCaption')}
                    onClick={() => this.onDropdownClick(os)}
                >
                    <Icon
                        type={`os_${os.toLowerCase()}`}
                        style={{ fontSize: 20 }}
                    />
                    <span className={block('OSCaptionText')}>
                        {t('userAgentMenu.' + os)}
                    </span>
                    <Icon
                        style={{ fontSize: 20 }}
                        type={activeDropdownKey === os ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    />
                </TBody>
                <div>
                    {activeDropdownKey === os ? Object.entries(userAgents[os]).map(([browser, ua]) => <TCaption
                        key={browser}
                        className={block('Browser')}
                        onClick={() => this.setUserAgent(ua)}
                    >
                        <Icon
                            type={`browser_${browser.toLowerCase()}`}
                            style={{ fontSize: 20, marginRight: 10 }}
                        />
                        <span>{t('userAgentMenu.' + browser)}</span>
                    </TCaption>) : null}
                </div>
            </div>)}
            <div style={{ padding: '10px 15px' }}>
                <TBody style={{ marginBottom: 10 }}>{t('userAgentMenu.custom')}</TBody>
                <TextareaAutosize
                    className={block('CustomInput')}
                    defaultValue={research.userAgent || ''}
                    innerRef={(el) => { this.textarea = el; }}
                    onChange={this.onCustomInputChange}
                />
                {this.state.displayApplyButton && <div className={block('CustomApplyWrapper')}>
                    <Button
                        raised
                        primaryGreen
                        className={block('CustomApply')}
                        onClick={() => this.setUserAgent(this.textarea.value)}
                    >{t('userAgentMenu.apply')}</Button>
                </div>}
            </div>
        </Box>;
    }

    render() {
        const { research, env } = this.props;

        return (env.screen.isMobile || env.screen.isTablet || env.isTouchDevice)
            ? <Modal
                isOpen
                onClose={research.userAgentMenu.close}
                shouldCloseOnOverlayClick={false}
                style={{ content: { maxWidth: '420px', width: '100%' } }}
            >{this.renderContent()}</Modal>
            : <TopBarDropdown
                className={block()}
                type="research"
                onClose={research.userAgentMenu.close}
            >{this.renderContent()}</TopBarDropdown>;
    }
}

export default translate()(inject('research', 'env')(observer(UserAgentMenu)));
