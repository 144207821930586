import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import b from 'b_';
import ConsoleModal from '../../ConsoleModal/ConsoleModal';
import { LogoProofpointWord } from '../../Logo/PfptLogo';
import EnvStore from '../../../stores/EnvStore';
import Accordion from '../../Accordion/Accordion';
import BrowserStore from '../../../browser/stores/BrowserStore';
import './TroubleshootingInstructionsModal.css';

const block = b.lock('TroubleshootingInstructionsModal');

class TroubleshootingInstructionsModal extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        env: EnvStore.PropType.isRequired,
        browser: BrowserStore.PropType.isRequired,
    };

    render() {
        const { t, env, browser } = this.props;

        return <ConsoleModal
            isOpen={browser.troubleshootingInstructionsModal.isOpen}
            onClose={browser.troubleshootingInstructionsModal.close}
            positionCenter
            headerClassName={block('ModalHeader')}
            contentClassName={block('ModalContent')}
            title={<div>
                <div className={block('ModalTitle')}>
                    <LogoProofpointWord className={block('LogoPfpt')} />
                    <span className={block('ProductName')}>
                        {env.isUrlIsolation ? 'URL Isolation' : 'Browser Isolation'}
                    </span>
                </div>
                <span className={block('SubTitle')}>
                    {t('troubleshootingMode.instructions.modalTitle')}
                </span>
            </div>}
        >
            {new Array(3).fill(0).map((_, index) => {
                const textIndex = index + 1;
                return <Accordion
                    key={textIndex}
                    preventCollapse
                    disableFocus
                    contentClassName={block('AccordionContent')}
                    label={t('troubleshootingMode.instructions.header' + textIndex)}
                >
                    {t('troubleshootingMode.instructions.text' + textIndex)}
                </Accordion>;
            })}
        </ConsoleModal>;
    }
}

export default translate()(inject('env', 'browser')(observer(TroubleshootingInstructionsModal)));
