import keyBy from 'lodash/keyBy';
import valueWatcher from './watchers/valueWatcher';
import selectedWatcher from './watchers/selectedWatcher';
import checkedWatcher from './watchers/checkedWatcher';
import scrollWatcher from './watchers/scrollWatcher';
import playbackWatcher from './watchers/playbackWatcher';
import volumeWatcher from './watchers/volumeWatcher';

const watchers = {
    valueWatcher,
    selectedWatcher,
    checkedWatcher,
    scrollWatcher,
    playbackWatcher,
    volumeWatcher,
};

export {
    valueWatcher,
    selectedWatcher,
    checkedWatcher,
    scrollWatcher,
    playbackWatcher,
    volumeWatcher,
};

const watchersList = Object.values(watchers);

export default {
    ...watchers,
    list: watchersList,
    byName: keyBy(watchers, 'name'),
    unregisterFromAll: (uid) => watchersList.forEach((watcher) => watcher.unregister(uid)),
};
