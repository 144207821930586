// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./bg-bottom.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "./bg-center.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bg {\n    min-height: 100%;\n}\n\n    .Bg_type_bottom {\n        background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") bottom center / 1658px 330px no-repeat, #ffffff;\n    }\n\n    .Bg_type_center {\n        background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") center center / 906px 800px no-repeat, #3599CE;\n    }\n\n    @media (max-width: 640px) {\n\n    .Bg_type_center {\n            background-image: none\n    }\n        }\n", "",{"version":3,"sources":["webpack://./src/main/frontend/Components/Bg/Bg.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;IAEI;QACI,mGAAkF;IACtF;;IAEA;QACI,kGAAiF;IACrF;;IAEA;;IAEA;YACQ;IACR;QACI","sourcesContent":[".Bg {\n    min-height: 100%;\n}\n\n    .Bg_type_bottom {\n        background: url('./bg-bottom.png') bottom center / 1658px 330px no-repeat, #ffffff;\n    }\n\n    .Bg_type_center {\n        background: url('./bg-center.png') center center / 906px 800px no-repeat, #3599CE;\n    }\n\n    @media (max-width: 640px) {\n\n    .Bg_type_center {\n            background-image: none\n    }\n        }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
