import React, { Component } from 'react';
import PropTypes from 'prop-types';

const MutationObserver = window.MutationObserver
    || window.WebKitMutationObserver
    || window.MozMutationObserver;

export default class Contenteditable extends Component {
    static propTypes = {
        html: PropTypes.string.isRequired,
        onInput: PropTypes.func.isRequired,
        setRef: PropTypes.func,
    };

    el = { current: null };

    onRef = (el) => {
        this.el = { current: el }; // keeping it for backwards compatibility
        if (this.props.setRef) {
            this.props.setRef(el);
        }
    }

    observer = null;

    shouldComponentUpdate(nextProps) {
        return !this.el.current || (nextProps.html !== this.el.current.innerHTML);
    }

    componentDidMount() {
        if (this.el.current) {
            this.observer = new MutationObserver(() => {
                this.props.onInput({ target: this.el.current });
            });
            this.observer.observe(this.el.current, {
                characterData: true,
                subtree: true,
                childList: true,
            });
        }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    render() {
        const { html, setRef, ...props } = this.props;
        return <div
            contentEditable
            dangerouslySetInnerHTML={{ __html: html }}
            ref={this.onRef}
            {...props}
        />;
    }
}
