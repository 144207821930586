import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon/Icon';
import StatefulLoader from './ScanStatus';
import BrowserStore from '../../browser/stores/BrowserStore';

const LoaderIconEl = ({ type, color }) => {
    const styleAlignToBtu = {
        position: 'relative',
        bottom: '-1px',
    };
    const alignToBtu = ['warning', 'report'].indexOf(type) > -1;
    const extraStyles = alignToBtu ? styleAlignToBtu : {};

    return <Icon type={type} style={{ color, ...extraStyles }} />;
};


LoaderIconEl.propTypes = {
    type: PropTypes.oneOf(Object.keys(Icon.codes)).isRequired,
    color: PropTypes.string.isRequired,
};


const palette = {
    green: '#4baf50',
    blue: '#0477ba',
    red: '#f44337',
};

const statuses = {
    [BrowserStore.SCAN_STATUS.SCANNING]: {
        textRight: 'ScanStatuses.common.pending.textRight',
        pending: true,
        color: palette.blue,
    },
    [BrowserStore.SCAN_STATUS.CLEAN]: {
        iconRight: 'check_circle_rounded',
        textRight: 'ScanStatuses.common.ok.textRight',
        color: palette.green,
    },
    [BrowserStore.SCAN_STATUS.ERROR]: {
        iconRight: 'error',
        textRight: 'ScanStatuses.common.error.textRight',
        color: palette.red,
    },
    [BrowserStore.SCAN_STATUS.SUSPECT]: {
        iconRight: 'warning',
        textRight: 'ScanStatuses.tsa.suspect.textRight',
        color: palette.red,
    },
    [BrowserStore.SCAN_STATUS.BLOCK]: {
        iconRight: 'report',
        textRight: 'ScanStatuses.common.bad.textRight',
        color: palette.red,
    },
};

const scans = [{
    textLeft: 'ScanStatuses.ti.textLeft',
    iconLeft: 'public',
}, {
    textLeft: 'ScanStatuses.tsa.textLeft',
    iconLeft: 'hook',
}, {
    textLeft: 'ScanStatuses.sandbox.textLeft',
    iconLeft: 'cube',
}, {
    textLeft: 'ScanStatuses.threat.textLeft',
    iconLeft: 'cube',
}];

const ScanStatuses = translate()(({ t, tiStatus, tsaStatus, sandboxStatus, threatStatus }) =>
    <div className="ScanStatuses">
        {[tiStatus, tsaStatus, sandboxStatus, threatStatus].map((scanStatus, i) => {
            const scan = scans[i];
            const status = statuses[scanStatus];
            if (!status) {
                return null;
            }
            return <StatefulLoader
                key={scan.textLeft}
                textLeft={t(scan.textLeft)}
                iconLeft={<Icon type={scan.iconLeft} />}
                textRight={t(status.textRight)}
                iconRight={status.iconRight ? LoaderIconEl({ type: status.iconRight, color: status.color }) : null}
                loader={{ styleMoving: { backgroundColor: status.color }, complete: !status.pending }}
            />;
        }).filter(Boolean)}
    </div>);

const scanPropType = PropTypes.oneOf(Object.keys(BrowserStore.SCAN_STATUS));
ScanStatuses.propTypes = {
    tiStatus: scanPropType,
    tsaStatus: scanPropType,
    sandboxStatus: scanPropType,
    threadStatus: scanPropType,
};

export default ScanStatuses;
