import { F_CHANGE, F_NODE, REMOVED_ATTRIBUTE, REMOVED_NODE } from './sharedConstants';
import debug from '../debug';

function merge(a, b, mergeFn) {
    if (a === undefined) {
        return b;
    }
    if (b === undefined) {
        return a;
    }
    return mergeFn(a, b);
}

const objectAssignFn = (...args) => Object.assign(...args);
const pickSecond = (a, b) => b;

// Style object could look like
// 1. just `undefined`
// 2. REMOVED_ATTRIBUTE `0`
// 3. { css: '...' }
// 4. { order: [...] }
// 5. { values: {...} }
// 6. { order: [...], values: {...} }
export function mergeStyle(currentStyle, newStyle) {
    // new style takes precedence
    if (newStyle === REMOVED_ATTRIBUTE || newStyle.css !== undefined) {
        return newStyle;
    }

    // nothing changed (sanity check)
    if (newStyle.order === undefined && newStyle.values === undefined) {
        return currentStyle;
    }

    const newOrder = merge(currentStyle.order, newStyle.order, pickSecond);
    const mergedValues = merge(currentStyle.values, newStyle.values, objectAssignFn);
    const result = {};

    if (newOrder !== undefined) {
        result.order = newOrder;
    }

    if (mergedValues !== undefined) {
        if (newOrder === undefined) {
            result.values = mergedValues;
        } else {
            let hasValues = false;
            const newValues = newOrder.reduce((res, propId) => {
                if (mergedValues[propId] !== undefined) {
                    hasValues = true;
                    res[propId] = mergedValues[propId];
                }
                return res;
            }, {});
            if (hasValues) {
                result.values = newValues;
            }
        }
    }

    return result;
}

const mergeFns = {
    [F_CHANGE.init]: (current, next) => next,
    [F_CHANGE.documentId]: (current, next) => next,
    [F_CHANGE.domContentLoadedInServerBrowser]: (current, next) => current || next,
    [F_CHANGE.dom]: (current, next) => {
        Object.entries(next).forEach(([nodeId, data]) => {
            if ((current[nodeId] === undefined) // new diff - no merging
                || (data === REMOVED_NODE) // node removed
                || data[F_NODE.TAG] // node fully serialized for contenteditable
            ) {
                current[nodeId] = data;
            } else {
                const currentAttr = current[nodeId][F_NODE.ATTR];
                const currentAttrNS = current[nodeId][F_NODE.ATTR_NS];
                const currentStyle = current[nodeId][F_NODE.STYLE];

                const nodeData = Object.assign(current[nodeId], data);
                // merge new attribute values into old ones
                if (currentAttr && nodeData[F_NODE.ATTR] !== undefined) {
                    nodeData[F_NODE.ATTR] = Object.assign(currentAttr, nodeData[F_NODE.ATTR]);
                }

                // merge style attributes
                if ((currentStyle !== undefined) && (data[F_NODE.STYLE] !== undefined)) {
                    nodeData[F_NODE.STYLE] = mergeStyle(currentStyle, data[F_NODE.STYLE]);
                }

                // merge new attribute namespaceURI values into old ones
                if (currentAttrNS && nodeData[F_NODE.ATTR_NS] !== undefined) {
                    nodeData[F_NODE.ATTR_NS] = Object.assign(currentAttrNS, nodeData[F_NODE.ATTR_NS]);
                }
                if (nodeData[F_NODE.ATTR_NS] !== undefined) {
                    Object.keys(nodeData[F_NODE.ATTR_NS]).forEach((attrName) => {
                        if (nodeData[F_NODE.ATTR] && nodeData[F_NODE.ATTR][attrName] === REMOVED_ATTRIBUTE) {
                            delete nodeData[F_NODE.ATTR_NS][attrName];
                        }
                    });
                }
                current[nodeId] = nodeData;
            }
        });
        return current;
    },
    [F_CHANGE.deepCleanUp]: (current, next) => current.concat(next),
    [F_CHANGE.selection]: (current, next) => next,
    [F_CHANGE.css]: (current, next) => {
        Object.entries(next).forEach(([nodeId, data]) => {
            if (current[nodeId] === undefined) {
                current[nodeId] = data;
            } else {
                current[nodeId] = current[nodeId].concat(data);
            }
        });
        return current;
    },
    [F_CHANGE.actionCounter]: (current, next) => Math.max(current, next),
    [F_CHANGE.values]: (current, next) => {
        Object.entries(next).forEach(([valueType, data]) => {
            current[valueType] = current[valueType] || {};
            Object.entries(data).forEach(([nodeId, value]) => {
                current[valueType][nodeId] = value;
            });
        });
        return current;
    },
    [F_CHANGE.media]: (current, next) => current.concat(next),
    [F_CHANGE.canvas]: (current, next) => Object.assign(current, next),
    [F_CHANGE.viewport]: (current, next) => Object.assign(current, next),
    [F_CHANGE.removedFrameNodeIds]: (current, next) => current.concat(next),
    [F_CHANGE.customElements]: (current, next) => current.concat(next),
};

export default function mergeUpdate(current, next) {
    Object.keys(next).forEach((field) => {
        if (mergeFns[field] !== undefined) {
            current[field] = merge(current[field], next[field], mergeFns[field]);
        }
    });
    return current;
}
