import { action, extendObservable } from 'mobx';
import PropTypes from 'prop-types';

import InputStore from '../InputStore';

import { apiCall } from '../../API';
import AsyncActionStore from '../AsyncActionStore';

export default class AccessCodesSettingsStore {
    static PropType = PropTypes.shape({
        applyStatus: AsyncActionStore.PropType,
        inputErrorMsg: InputStore.PropType,
    });

    constructor(data = {}) {
        extendObservable(this, /** @class AccessCodesSettingsStore */{
            inputErrorMsg: new InputStore({ value: '' }),
            accessCodeInput: new InputStore({ value: '' }),
            applyStatus: new AsyncActionStore(),
            apiApply: apiCall('/browser/access_code/apply'),
            apiDestroy: apiCall('/browser/access_code/destroy'),
        }, data);
    }

    onAccessCodeSubmit = action(() => {
        if (!this.applyStatus.isLoading) {
            this.applyStatus.setLoading(this.apiApply({ value: this.accessCodeInput.value }));
        }
        return this.applyStatus.promise;
    });
}
