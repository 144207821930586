import jstz from 'jstz';
import { CLIENT_CONFIG_VERSION } from './mirror/sharedConstants';
import i18n from './browser/i18n';
import debug from './debug';
import codecs from './codecs';
import LS, { LS_KEY } from './LocalStorage';
import Screen from './Screen';
import { IS_TOUCH_DEVICE, IS_DEV, HAS_ADOPTED_STYLESHEETS } from './env';

const COOKIE_NAME = 'cc';

function getCssSupport() {
    const el = document.createElement('div');
    const supportsApi = !!(window.CSS && window.CSS.supports);

    return {
        webkitTransform: el.style.webkitTransform !== undefined,
        styleFloat: el.style.styleFloat !== undefined,
        supportsApi,
    };
}

function getWebpSupport() {
    let support = LS.get(LS_KEY.clientConfigWebp);
    if (support === null) {
        try {
            const el = document.createElement('canvas');
            support = el.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        } catch (e) {
            support = false;
        }
        LS.set(LS_KEY.clientConfigWebp, support);
    }
    return support;
}

function isFormatSupported(format, dataUri) {
    return new Promise((resolve) => {
        const image = new window.Image();
        image.src = `data:image/${format};base64,${dataUri}`;
        image.onload = () => resolve(true);
        image.onerror = () => resolve(false);
    });
}

// We can't use canvas.toDataURL('image/avif') - it does not work. Could not find any synchronous approach.
// https://stackoverflow.com/questions/71680803/how-to-check-if-the-browser-supports-avif-images
// https://stackoverflow.com/questions/75459594/why-doesnt-edge-support-avif-images
const prepareAvifSupport = async () => {
    const current = LS.get(LS_KEY.clientConfigAvif);
    if (current === null) {
        LS.set(LS_KEY.clientConfigAvif, await isFormatSupported(
            'avif',
            // eslint-disable-next-line max-len
            'AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A='
        ));
    }
};

const prepareUserAgentData = async () => {
    try {
        const current = LS.get(LS_KEY.clientConfigUserAgentData);

        if (window.navigator?.userAgentData?.getHighEntropyValues) {
            if (
                current
                && window.navigator.userAgentData.brands.length === current.brands?.length
                && (current.brands || []).every((brand) => window.navigator.userAgentData.brands.includes(brand))
            ) {
                return;
            }

            /**
             * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
             * @see https://wicg.github.io/ua-client-hints/#http-ua-hints
             * @see {@link index.background.js} the webRequestOnBeforeSendHeaders function
             */
            const entropyFields = [
                'architecture',
                'bitness',
                'model',
                'platformVersion',
                'fullVersionList',
                'uaFullVersion',
                'formFactor',
                'wow64'
            ];
            const userAgentData = await window.navigator.userAgentData.getHighEntropyValues(entropyFields);

            LS.set(LS_KEY.clientConfigUserAgentData, userAgentData);
        }
    } catch (error) {
        debug.recordError(error);
    }
};

class ClientConfig {
    constructor() {
        this.version = CLIENT_CONFIG_VERSION;
        this.language = i18n.language;
        this.codecs = codecs.encode();
        this.platform = window.navigator.platform;
        this.appVersion = window.navigator.appVersion;
        this.vendor = window.navigator.vendor;
        this.hasMediaSource = typeof window.MediaSource === 'function';
        this.srcset = ('srcset' in document.createElement('img')) | 0;
        this.css = getCssSupport();
        this.hasWebp = getWebpSupport();
        this.hasAvif = LS.get(LS_KEY.clientConfigAvif, false);
        this.userAgentData = LS.get(LS_KEY.clientConfigUserAgentData, false);
        this.hasShadow = !!window.Element.prototype.attachShadow;
        this.tzName = jstz.determine().name();
        this.hasAdoptedStyleSheets = HAS_ADOPTED_STYLESHEETS;
        this.enableDebugEvents = !!LS.get(LS_KEY.enableDebugEvents);
        this.enableCanvasDebugging = !!LS.get(LS_KEY.debugCanvas);
        this.screen = Screen.getCurrentScreen();
        this.hasTouchInput = IS_TOUCH_DEVICE;

        const ads = LS.get(LS_KEY.adBlock);
        if (ads !== null) {
            this.ads = ads | 0;
        }

        if (LS.get(LS_KEY.researchMode)) {
            this.researchMode = true;
            this.userAgent = LS.get(LS_KEY.researchUserAgent);
            this.location = LS.get(LS_KEY.researchLocation);
        }

        this.webFilters = LS.get('webFiltersOverride', undefined);

        if (IS_DEV) {
            // used for mobile testing on local network
            this.localHttpBase = window.location.hostname;
            // used to save Anton's mind
            this.disableWindowSizeChange = LS.get(LS_KEY.disableWindowSizeChange, false);
        }
        // for debugging mouse and touch
        this.debugPointerEvents = LS.get(LS_KEY.debugPointerEvents, undefined);
    }
}

function updateConfig() {
    try {
        const config = new ClientConfig();
        const cookieValue = encodeURIComponent(JSON.stringify(config));
        if (cookieValue.length > 4000) {
            debug.recordError('client config cookie 4k size limit reached', { config, size: cookieValue.length });
        }

        document.cookie = `${COOKIE_NAME}=${cookieValue}; path=/`
            + (IS_DEV ? '' : '; Secure'); // ISO-759, pentest complained about it
    } catch (e) {
        debug.recordError(e);
    }
}

const clean = () => {
    // ISO-8888 changing the format of the value from { userAgent:..., supported: ...} to boolean
    const avifData = LS.get(LS_KEY.clientConfigAvif);
    if (avifData?.userAgent) {
        LS.set(LS_KEY.clientConfigAvif, avifData.supported);
    }

    // Clean in case of a browser update
    const ua = window.navigator.userAgent;
    if (LS.get(LS_KEY.clientConfigUserAgent) !== ua) {
        LS.set(LS_KEY.clientConfigUserAgent, ua);

        LS.remove(LS_KEY.clientConfigAvif);
        LS.remove(LS_KEY.clientConfigWebp);
    }
};

const prepareConfig = () => Promise.all([
    clean(),
    prepareAvifSupport(),
    prepareUserAgentData(),
]);

export default {
    prepareConfig,
    updateConfig,
    ClientConfig
};
