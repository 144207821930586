import React from 'react';
import b from 'b_';
import cn from 'classnames';
import './Text.css';

const block = b.lock('Text');

function renderText(cls, { className, children, ...otherProps }) {
    return <div className={cn(block(cls), className)} {...otherProps}>
        {children}
    </div>;
}

export function THeadline(props) { // maintainance
    return renderText('Headline', props);
}

export function TTitle(props) { // Box
    return renderText('Title', props);
}

export function TSubheader(props) {
    return renderText('Subheader', props);
}

export function TBody(props) {
    return renderText('Body', props);
}

export function TStrong(props) {
    return renderText('Strong', props);
}

export function TCaption(props) {
    return renderText('Caption', props);
}

export function ConsoleBody(props) {
    return renderText('ConsoleBody', props);
}

