import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import b from 'b_';
import cn from 'classnames';
import BrowserStore from '../../browser/stores/BrowserStore';
import AdBlockerModal from '../AdBlockerModal/AdBlockerModal';
import FuseButton from '../FuseControls/FuseButton';
import { ResearchStoreProp } from '../../stores/props';
import './../TopBarGuide.css';
import './TopBarAdBlock.css';

const guideBlock = b.lock('TopBarGuide');

class TopBarAdBlock extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        research: ResearchStoreProp,
        isWizard: PropTypes.bool,
        onRefresh: PropTypes.func.isRequired,
        className: PropTypes.string,
        btnProps: PropTypes.shape(FuseButton.propTypes),
    };

    static getWizard({ t, isWizard }) {
        return (isWizard ? (<div className={guideBlock({ type: 'bottom', alternative: true })}>
            {t('browserTopBar.guides.adBlockButton')}
        </div>) : null);
    }

    static getModal({ browser, onRefresh }, alternative = false) {
        return browser.adBlockModal.isOpen ? <AdBlockerModal
            alternative={alternative}
            afterChange={() => {
                if (!browser.isHomePage) {
                    onRefresh();
                }
            }}
        /> : null;
    }

    render() {
        const { research, t, browser, isWizard, className, btnProps } = this.props;

        return <div className={cn('TopBarAdBlock', className)}>
            <FuseButton
                type="quaternary"
                icon={browser.adBlock.isEnabled ? 'ads_blocked' : 'ads_allowed'}
                title={t('browserTopBar.adBlockerButtonTitle')}
                aria-label={t('browserTopBar.adBlockerButtonAriaLabel')}
                color={research.researchMode.isEnabled ? 'black' : null}
                active={browser.adBlockModal.isOpen}
                onClick={browser.adBlockModal.toggle}
                id={browser.adBlockTopBarButtonId} // @see AdBlockerModal.js role attribute
                aria-expanded={browser.adBlockModal.isOpen}
                {...(isWizard ? { tabIndex: -1 } : {})}
                {...btnProps}
            />
            {TopBarAdBlock.getModal(this.props, true)}
            {TopBarAdBlock.getWizard(this.props)}
        </div>;
    }
}
export default translate()(inject('browser', 'research')(observer(TopBarAdBlock)));
