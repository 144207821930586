import PropTypes from 'prop-types';
import { BUILD_TIME } from '../../env';

export default class DebugStore {
    static PropTypes = PropTypes.shape({
        onPageLoadStarted: PropTypes.func.isRequired,
        onPageLoadFinish: PropTypes.func.isRequired,
        onWebsocketConnected: PropTypes.func.isRequired,
        onWebsocketError: PropTypes.func.isRequired,
        onWebsocketDisconnected: PropTypes.func.isRequired,
        openDevTools: PropTypes.func.isRequired,
    })

    constructor() {
        this.currentPageId = '';
        this.currentBrowserId = 'unknown';
        this.serverBrowserVersion = 'unknown';

        this.pageLoadState = 'not started';
        this.pageLoadType = '';
        this.pageLoadStarted = 0;
        this.pageLoadEnded = 0;

        this.pageUrlRequested = '';
        this.clientPageId = '';
        this.iframeSrc = '';

        this.additionalLoadInfo = null;

        this.websocketState = 'not connected yet';
    }

    onPageLoadStarted = ({ url, clientPageId, iframeSrc }) => {
        this.websocketState = 'not connected yet';
        this.pageLoadStarted = Date.now();
        this.pageLoadEnded = 0;
        this.pageLoadState = 'started';
        this.currentPageId = '';
        this.pageLoadType = '';
        this.serverBrowserVersion = 'unknown';
        this.pageUrlRequested = url;
        this.clientPageId = clientPageId;
        this.iframeSrc = iframeSrc;
        this.additionalLoadInfo = null;
    };

    onPageLoadFinish = ({ browserId, pageId, loadType, serverBrowserVersion, additionalLoadInfo }) => {
        this.pageLoadEnded = Date.now();
        this.pageLoadState = 'finished';
        this.pageLoadType = loadType;
        this.currentBrowserId = browserId;
        this.currentPageId = pageId;
        this.serverBrowserVersion = serverBrowserVersion;
        this.additionalLoadInfo = additionalLoadInfo;
    };

    onWebsocketConnected = () => {
        this.websocketState = 'connected';
    };

    onWebsocketError = () => {
        this.websocketState = 'error';
    };

    onWebsocketDisconnected = () => {
        this.websocketState = 'disconnected';
    };

    openDevTools = () => {
        const win = window.open('about:blank');
        win.document.documentElement.innerHTML = `
            <title>DEBUG</title>
            <body>
                <div><b>Browser UI Version</b>: ${BUILD_TIME}</div>
                <div><b>Page load state</b>: ${this.pageLoadState}</div>
                ${this.pageLoadState !== 'not started' ? `
                    <div><b>URL Requested</b>: ${this.pageUrlRequested}</div>
                    <div><b>Client Page Id</b>: ${this.clientPageId}</div>
                    <div><b>Iframe SRC</b>: ${this.iframeSrc}</div>
                ` : ''}
                ${this.pageLoadState === 'finished' ? `
                    <div><b>Page ID</b>: ${this.currentPageId}</div>
                    <div><b>Browser ID</b>: ${this.currentBrowserId}</div>
                    <div><b>How load finished</b>: ${this.pageLoadType}</div>
                    <div><b>Load timing (seconds)</b>: ${
                        Math.floor((this.pageLoadEnded - this.pageLoadStarted) / 1000)
                    }</div>
                    <div><b>Server Browser Version</b>: ${this.serverBrowserVersion}</div>
                    <div><b>Webscoket state</b>: ${this.websocketState}</div>
                    <div><b>Additional load info</b>: ${JSON.stringify(this.additionalLoadInfo, null, 4)}</div>
                ` : ''}
            </body>
        `;
    };
}
