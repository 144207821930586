import { action, extendObservable } from 'mobx';
import PropTypes from 'prop-types';

export default class RadioButtonStore {
    static PropType = PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        originalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        setValue: PropTypes.func.isRequired,
        getValue: PropTypes.func.isRequired,
        equals: PropTypes.func.isRequired,
        isChecked: PropTypes.func.isRequired,
        hasChanged: PropTypes.func.isRequired,
    });

    /**
     * @param {string | number | boolean} defaultValue
     */
    constructor(defaultValue = '') {
        extendObservable(this, /** @class RadioButtonStore */{
            value: defaultValue,
            originalValue: defaultValue,
        });
    }

    setValue = action((value) => {
        this.value = value;
    });

    getValue = () => this.value;

    equals = (value) => this.value === value;

    isChecked = (value) => this.value === value;

    hasChanged = () => this.originalValue !== this.value;

    commit = () => {
        this.originalValue = this.value;
    }

    reset = () => {
        this.value = this.originalValue;
    }
}

