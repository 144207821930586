import { action, extendObservable } from 'mobx';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { API_BROWSER_TO_CLIENT, FILE_PROCESSING_STATE, FILE_PROCESSING_TYPE } from '../mirror/sharedConstants';
import FlagStore from './FlagStore';
import { sendToClient } from '../mirror/client/browserMessageBus';
import LocalStorage, { LS_KEY } from '../LocalStorage';
import debug, { DEBUG } from '../debug';
import { IS_BROWSER_IE11 } from '../env';

const logger = debug.create('FileManagerStore', DEBUG);
const NOTIFICATION_TIMEOUT = 4000;

function getFileContent(url, cb) {
    try {
        const xhr = new window.XMLHttpRequest();
        xhr.responseType = 'blob';

        xhr.addEventListener('readystatechange', () => {
            if (xhr.readyState === 4) {
                cb(true, xhr.response);
            }
        });
        xhr.addEventListener('error', () => cb(false));
        xhr.open('GET', url, true);
        xhr.send(null);
    } catch (error) {
        logger.error('XHR failed getting file content.', url);
        cb(false);
    }
}

export default class FileManagerStore {
    static NOTIFICATION_TYPE = {
        INFO: 'info',
        SUCCESS: 'success',
        WARNING: 'warning',
    };

    static NOTIFICATION = {
        FILE_PROCESSING: 'FILE_PROCESSING',
    };

    static EventPropType = PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string,
        startTime: PropTypes.string.isRequired,
        size: PropTypes.number,
        type: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        errorId: PropTypes.number,
    });

    static PropType = PropTypes.shape({
        fileProcessingDataArr: MobxPropTypes.arrayOrObservableArrayOf(FileManagerStore.EventPropType),
        hasBlockedEvents: PropTypes.func.isRequired,
        cancelAllUploads: PropTypes.func.isRequired,
    });

    constructor(data = {}, useLocalStorage = true) {
        this.useLocalStorage = useLocalStorage;
        const initBlockedFilesEvents = useLocalStorage ?
            LocalStorage.get(LS_KEY.fileManagerBlockedFilesEvents, []) : [];
        extendObservable(this, /** @class FileManagerStore */{
            fileProcessingDataArr: [],
            blockedFilesToggle: new FlagStore(false),
            blockedFilesEvents: initBlockedFilesEvents,
            frameEl: null,
            closeNotificationFunc: null,
            hasBlockedEvents() {
                return this.blockedFilesEvents.length > 0;
            },
        }, data);

        if (useLocalStorage) {
            window.addEventListener('storage', (event) => {
                if (event.key === LS_KEY.fileManagerBlockedFilesEvents) {
                    this.blockedFilesEvents = LocalStorage.get(LS_KEY.fileManagerBlockedFilesEvents) || [];
                }
            });
        }
    }

    checkToClearAllBlockedEvents = action((browserId) => {
        if (!this.useLocalStorage || !browserId) {
            return;
        }
        const previousBrowserId = LocalStorage.get(LS_KEY.previousBrowserId);
        if (!!previousBrowserId && browserId !== previousBrowserId) {
            this.blockedFilesEvents.replace([]);
            LocalStorage.set(LS_KEY.fileManagerBlockedFilesEvents, []);
            logger.log('checkToClearAllBlockedEvents: events removed');
        }
        LocalStorage.set(LS_KEY.previousBrowserId, browserId);
    });

    closeNotification = (eventData) => {
        setTimeout((event) => {
            const idx = this.fileProcessingDataArr.findIndex((data) => data.id === event.id);
            if (idx >= 0) {
                this.fileProcessingDataArr.splice(idx, 1);
                if (this.fileProcessingDataArr.length === 0 && this.closeNotificationFunc) {
                    this.closeNotificationFunc(FileManagerStore.NOTIFICATION.FILE_PROCESSING);
                }
            }
        }, NOTIFICATION_TIMEOUT, eventData);
    };

    pushNewFileEventData = action((eventData, closeNotificationFn, showNotificationFn) => {
        logger.log('event data id:', eventData.id, 'state:', eventData.state, 'ignore?', eventData.ignore);
        if (!this.closeNotificationFunc && closeNotificationFn) {
            this.closeNotificationFunc = closeNotificationFn;
        }

        if (eventData.state === FILE_PROCESSING_STATE.CANCELED) {
            if (!eventData.ignore) {
                this.cancelFileProcessing(null, eventData.id, true);
            }
            return;
        }

        // before downloading we need to check if user requested to canceled event
        const eventIndex = this.blockedFilesEvents.findIndex((data) => data.id === eventData.id);
        if (eventIndex >= 0 && this.blockedFilesEvents[eventIndex].state === FILE_PROCESSING_STATE.CANCELED) {
            logger.log('ignoring download. Found user cancel request');
            return;
        }

        const index = this.fileProcessingDataArr.findIndex((data) => data.id === eventData.id);
        logger.log('existing events index:', index >= 0 ? 'found' : 'not found');

        if (index >= 0) {
            this.fileProcessingDataArr.splice(index, 1, eventData);
        } else {
            this.fileProcessingDataArr.push(eventData);
        }

        if (this.useLocalStorage && !eventData.ignore) {
            LocalStorage.set(LS_KEY.fileManagerLastFileEvent, eventData);
        }

        if (!eventData.ignore) {
            if (eventData.state === FILE_PROCESSING_STATE.ALLOWED && eventData.type === FILE_PROCESSING_TYPE.DOWNLOAD) {
                this.downloadFile(eventData, () => this.closeNotification(eventData));
            } else if (eventData.state === FILE_PROCESSING_STATE.BLOCKED && eventData.name) {
                this.saveBlockedFilesEvent(eventData);
            } else if (eventData.state !== FILE_PROCESSING_STATE.SCANNING) {
                this.closeNotification(eventData);
            }
        }

        if (showNotificationFn) {
            showNotificationFn(FileManagerStore.NOTIFICATION.FILE_PROCESSING);
        }
    });

    clearScanningNotifications = action(() => {
        this.fileProcessingDataArr.replace(
            this.fileProcessingDataArr.filter((event) => event.state === FILE_PROCESSING_STATE.ALLOWED));
    });

    cancelFileProcessing = action((frameEl, id, alsoUpload) => {
        const idx = this.fileProcessingDataArr.findIndex((data) => data.id === id);
        if (idx >= 0) {
            if (alsoUpload || this.fileProcessingDataArr[idx].type === FILE_PROCESSING_TYPE.DOWNLOAD) {
                const canceledEvent = { ...this.fileProcessingDataArr[idx], state: FILE_PROCESSING_STATE.CANCELED };
                this.fileProcessingDataArr.splice(idx, 1, canceledEvent);
                this.saveBlockedFilesEvent(canceledEvent);
                this.closeNotification(canceledEvent);
            } else {
                sendToClient(frameEl, API_BROWSER_TO_CLIENT.cancelUpload, id);
            }
        }
    });

    cancelAllUploads = action(() => {
        this.fileProcessingDataArr
            .filter((event) =>
                event.type === FILE_PROCESSING_TYPE.UPLOAD && event.state === FILE_PROCESSING_STATE.SCANNING)
            .forEach((eventData, index) => {
                const canceledEvent = { ...eventData, state: FILE_PROCESSING_STATE.CANCELED };
                this.fileProcessingDataArr.splice(index, 1, canceledEvent);
                this.saveBlockedFilesEvent(canceledEvent);
                this.closeNotification(canceledEvent);
            });
    });

    saveBlockedFilesEvent = (eventData) => {
        const eventIndex = this.blockedFilesEvents.findIndex((data) => data.id === eventData.id);
        if (eventIndex >= 0) {
            this.blockedFilesEvents.splice(eventIndex, 1);
        }
        this.blockedFilesEvents.push(eventData);
        if (this.useLocalStorage) {
            const previousBlockedFilesEvents = LocalStorage.get(LS_KEY.fileManagerBlockedFilesEvents) || [];
            const storageEventIndex = previousBlockedFilesEvents.findIndex((data) => data.id === eventData.id);
            if (storageEventIndex >= 0) {
                previousBlockedFilesEvents.splice(storageEventIndex, 1);
            }
            previousBlockedFilesEvents.push(eventData);
            LocalStorage.set(LS_KEY.fileManagerBlockedFilesEvents, previousBlockedFilesEvents);
        }
    };

    downloadFile = (fileProcessingData, cb) => {
        logger.log('initiating download?', fileProcessingData.url ? 'true' : 'false');
        if (fileProcessingData.url) {
            if (IS_BROWSER_IE11 && window.navigator.msSaveOrOpenBlob) {
                getFileContent(fileProcessingData.url, (isOk, content) => {
                    if (!isOk) {
                        logger.error('failed to serve the file to client.', fileProcessingData.url, fileProcessingData);
                        cb();
                        return;
                    }

                    window.navigator.msSaveOrOpenBlob(content, fileProcessingData.name || fileProcessingData.url);
                    cb();
                });

                return;
            }

            const aElement = document.createElement('a');
            aElement.setAttribute('href', fileProcessingData.url);
            aElement.setAttribute('download', fileProcessingData.name || true);
            aElement.click();
            cb();
        }
    };
}
