import { extendObservable, action } from 'mobx';
import PropTypes from 'prop-types';
import { apiCall, apiEventEmitter } from '../API';
import LocalStorage, { LS_KEY } from '../LocalStorage';
import FlagStore from './FlagStore';

let platformAccessTokenExpiration = 0;

export function setAdminTimeoutData(platformAccessTokenExpirationSeconds) {
    platformAccessTokenExpiration = platformAccessTokenExpirationSeconds;
}

export default class AdminTimeoutStore {
    static PropType = PropTypes.shape({
        modal: FlagStore.PropType.isRequired,
        cancel: PropTypes.func.isRequired,
        timeLeft: PropTypes.number.isRequired,
        timeTotal: PropTypes.number.isRequired,
    });

    constructor(data = {}, useLocalStorage = true) {
        this.useLocalStorage = useLocalStorage;
        this.intervalId = null;
        this.countdownStart = null;
        this.activityTm = null;
        this.warningTime = 30;

        extendObservable(this, /** @class AdminTimeoutStore */{
            apiUpdateActivity: apiCall('/console/activity/update'),
            modal: new FlagStore(false),
            timeout: 0,
            isPlatformLoginUsed: false,
            timeTotal: -1,
            timeLeft: -1,
            onTimeout: () => {
                if (this.isPlatformLoginUsed) {
                    const personasId = new URLSearchParams(window.location.search).get('persona-id') || null;
                    const personasIdUrlParamStr = personasId ?
                        `?persona-id=${personasId}` : '';
                    window.location.href = '/console/logout' + personasIdUrlParamStr;
                } else {
                    window.location.href = '/console';
                }
            },
        }, data);

        if (useLocalStorage) {
            LocalStorage.set(LS_KEY.adminTimeoutReset, Date.now());

            window.addEventListener('storage', (event) => {
                if (event.key === LS_KEY.adminTimeoutReset) {
                    this.reset();
                }
            });

            apiEventEmitter.on('adminTimeoutReset', ({ url }) => {
                if (url.startsWith('/console/') || url.startsWith('/super_api/')) {
                    LocalStorage.set(LS_KEY.adminTimeoutReset, Date.now());
                    this.reset();
                }
            });
        }

        this.reset();
    }

    reset = () => {
        if (platformAccessTokenExpiration > 0) {
            this.timeout = platformAccessTokenExpiration;
        }
        if (!this.timeout) {
            return;
        }

        this.stopCountdown();
        this.modal.close();
        clearTimeout(this.activityTm);
        this.activityTm = setTimeout(action(() => {
            this.stopCountdown();
            this.modal.open();
            this.countdownStart = Date.now();
            this.timeTotal = Math.min(30, this.timeout);
            this.timeLeft = this.timeTotal;
            this.intervalId = setInterval(this.checkTime, 250);
        }), Math.max(0, this.timeout - this.warningTime) * 1000);
    };

    stopCountdown = action(() => {
        this.timeLeft = -1;
        clearInterval(this.intervalId);
    });

    checkTime = action(() => {
        const timeLeft = this.timeTotal - Math.floor((Date.now() - this.countdownStart) / 1000);
        this.timeLeft = timeLeft < 0 ? -1 : timeLeft;
        if (this.timeLeft < 0) {
            this.stopCountdown();
            this.onTimeout();
        }
    });

    cancel = action(() => {
        this.modal.close();
        this.stopCountdown();
        this.apiUpdateActivity();
    });
}
