import { Provider, inject, observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { translate, Interpolate } from 'react-i18next';
import PropTypes from 'prop-types';
import bem from 'b_';
import BrowserStore from '../../browser/stores/BrowserStore';
import Icon from '../Icon/Icon';
import { PfptOnPC, PfptWordsVertical } from '../Logo/PfptLogo';
import { TBody, TSubheader } from '../Text/Text';
import Button from '../Button';
import Modal from '../Modal';
import Box from '../Box/Box';
import debug from '../../debug';
import { LOCATION_ORIGIN } from '../../env';
import initData from '../../browser/browserInitData';
import initBrowserStores from '../../browser/initBrowserStores';
import TopBarAlternative from '../TopBarAlternative/TopBarAlternative';
import FlagStore from '../../stores/FlagStore';
import './BrowserWelcome.css';

const block = bem.lock('BrowserWelcome');

function Step({ title, children, number, selected, onClick }) {
    const id = `welcome_steps_${number}`;

    return <div className={block('Step', { active: number === selected })}>
        <button htmlFor={id} data-number={number} className={block('Title')} onClick={() => onClick(number)}>
            {title}
        </button>
        <TBody className={block('Content')}>
            {children}
        </TBody>
    </div>;
}

Step.propTypes = {
    title: PropTypes.node.isRequired,
    number: PropTypes.number.isRequired,
    selected: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

function getDefaultActivationLink() {
    return {
        value: '',
        isError: false,
    };
}

function ActivateDifferentBrowserModal({ isOpen, onClose }) {
    const title = <Interpolate i18nKey="BrowserWelcome.activateDifferentBrowserModal.title" />;
    return <Modal isOpen={isOpen} onClose={onClose}>
        <Box title={title} className={block('ActivateDifferentBrowserModal')}>
            <TBody>
                <TSubheader className={block('ModalTitle')}>
                    <Icon type="link" style={{ fontSize: 24, marginRight: 10 }} />
                    <Interpolate
                        i18nKey="BrowserWelcome.activateDifferentBrowserModal.subTitle"
                    />
                </TSubheader>
                <ul>
                    <li><Interpolate i18nKey="BrowserWelcome.activateDifferentBrowserModal.listCopied" /></li>
                    <li><Interpolate i18nKey="BrowserWelcome.activateDifferentBrowserModal.listSwitch" /></li>
                    <li><Interpolate i18nKey="BrowserWelcome.activateDifferentBrowserModal.listPaste" /></li>
                </ul>
            </TBody>
            <Button
                className={block('PopupAction')}
                onClick={onClose}
                raised
                secondaryWhite
            ><Interpolate i18nKey="BrowserWelcome.activateDifferentBrowserModal.closeButton" /></Button>
            <TBody>
                <Interpolate
                    i18nKey="BrowserWelcome.activateDifferentBrowserModal.noteText"
                    generateLinkButtonName={<Interpolate i18nKey="BrowserWelcome.activationStep.generateLink" />}
                    note={<strong>
                        <Interpolate i18nKey="BrowserWelcome.activateDifferentBrowserModal.note" />
                    </strong>}
                />
            </TBody>
        </Box>
    </Modal>;
}

ActivateDifferentBrowserModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

function parseUserAgentIntoBrowser(userAgent) {
    if (!userAgent) {
        return null;
    }

    if (userAgent.includes('rv:11.0')) {
        return {
            icon: 'browser_ie',
            name: 'Internet Explorer 11',
        };
    }

    const hasChrome = userAgent.includes('Chrome/');
    const hasEdge = userAgent.includes('Edge') || userAgent.includes(' Edg/');

    if (hasChrome && !hasEdge) {
        return {
            icon: 'browser_chrome',
            name: 'Google Chrome',
        };
    }

    if (hasChrome && hasEdge) {
        return {
            icon: 'browser_edge',
            name: 'Microsoft Edge',
        };
    }

    if (userAgent.includes('Macintosh') && userAgent.includes('Safari') && !hasChrome && !hasEdge) {
        return {
            icon: 'browser_safari',
            name: 'Safari',
        };
    }

    if (userAgent.includes('Firefox') && !hasEdge) {
        return {
            icon: 'browser_firefox',
            name: 'Mozilla Firefox',
        };
    }

    return null;
}

class BrowserWelcome extends React.Component {
    static propTypes = {
        browser: BrowserStore.PropType.isRequired,
        selected: PropTypes.number,
    };

    state = {
        activationLink: getDefaultActivationLink(),
        selected: this.props.selected || 1,
        currentBrowser: parseUserAgentIntoBrowser(navigator.userAgent),
        isActivateAnotherBrowserModalOpened: false,
    };

    isUnmounted = false;

    mockStoresForDemoTopBar = initBrowserStores({
        browser: {
            ...initData.stores.browser,
            pageStatus: BrowserStore.PAGE_STATUS.SECURE_SITE,
            isHomePage: false,
            topBarUnpinned: new FlagStore(false),
            topBarNeedsMinimization: new FlagStore(true),
            adBlockSwitchEnabled: this.props.browser.adBlockSwitchEnabled,
        },
    }, false);

    componentDidMount() {
        this.getToken();
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    getToken() {
        if (this.props.browser.isAutoProvisioned) {
            return;
        }

        this.setState({
            activationLink: getDefaultActivationLink(),
        });

        this.props.browser.getActivationToken()
            .then(({ value }) => {
                if (this.isUnmounted) {
                    return;
                }
                try {
                    this.setState({
                        activationLink: {
                            value: `${LOCATION_ORIGIN}/register?token=${value}`,
                            isError: false,
                        },
                    });
                } catch (e) {
                    debug.recordError(e);
                }
            })
            .catch((e) => {
                if (!this.isUnmounted) {
                    this.setState({
                        activationLink: {
                            value: '',
                            isError: true,
                        },
                    });
                }
                debug.warn(e);
            });
    }

    copyText() {
        const scrollTop = window.pageYOffset;
        this.input.focus();
        this.input.select();
        let succeed = false;
        try {
            succeed = document.execCommand('copy');
        } catch (e) {
            succeed = false;
        }
        this.input.blur();
        if (!succeed) {
            debug.recordError(new Error('BrowserWelcome.copyText: Copy failed'));
        }
        if (scrollTop !== window.pageYOffset) {
            // cause selection change causes scroll sometimes
            window.pageYOffset = scrollTop;
        }
    }

    copyInputRef = (node) => {
        this.input = node;
    };

    onCopyClick = () => {
        this.copyText();

        this.setState({
            isActivateAnotherBrowserModalOpened: true,
        });

        this.getToken();
    };

    onModalClose = () => {
        this.setState({
            isActivateAnotherBrowserModalOpened: false,
        });
    };

    onSelect = (selected) => {
        this.setState({ selected });
    };

    onDone = () => {
        this.props.browser.setHomePageTabId(0);
    };

    renderActivationStep = (stepIndex) => {
        return <Step
            title={<Interpolate i18nKey="BrowserWelcome.activationStep.title" />}
            number={stepIndex}
            selected={this.state.selected}
            onClick={this.onSelect}
        >
            <div className={block('StepText')} style={{ textAlign: 'center' }}>
                <Interpolate i18nKey="BrowserWelcome.activationStep.congratulationText" />
            </div>
            <div className={block('StepContentCentered')} style={{ marginTop: '35px' }}>
                {this.state.currentBrowser ? <div className={block('Browser')}>
                    <Interpolate
                        i18nKey="BrowserWelcome.activationStep.currentBrowser"
                        className={block('BrowserWithIcon')}
                        browser={<Fragment>
                            <Icon
                                type={this.state.currentBrowser.icon}
                                className={block('BrowserIcon')}
                            />
                            <span>{this.state.currentBrowser.name}</span>
                        </Fragment>}
                    />
                </div> : null}
                <Button onClick={() => this.onSelect(stepIndex + 1)} raised primaryGreen style={{ minWidth: 150 }}>
                    <Interpolate i18nKey="BrowserWelcome.activationStep.continueWithCurrentBrowser" />
                </Button>
                {!this.state.activationLink.isError ? <Fragment>
                    <div className={block('Or')}>
                        <Interpolate i18nKey="BrowserWelcome.activationStep.or" />
                    </div>
                    <input
                        readOnly
                        type="text"
                        tabIndex={-1}
                        value={this.state.activationLink.value}
                        ref={this.copyInputRef}
                        className={block('CopyInput')}
                        aria-hidden="true"
                    />
                    <div className={block('ActivateDifferentBrowser')}>
                        <Interpolate i18nKey="BrowserWelcome.activationStep.activateDifferentBrowser" />
                        <Button
                            raised
                            secondaryWhite
                            onClick={this.onCopyClick}
                            loading={!this.state.activationLink.value}
                            style={{ marginLeft: '10px', marginRight: '10px', verticalAlign: 'bottom' }}
                        >
                            <Interpolate i18nKey="BrowserWelcome.activationStep.generateLink" />
                        </Button>
                        <Interpolate i18nKey="BrowserWelcome.activationStep.oneTimeActivationLink" />
                    </div>
                </Fragment> : null}
            </div>
        </Step>;
    };

    renderUIStep = () => (stepIndex) => {
        return <Step
            title={<Interpolate i18nKey="BrowserWelcome.uiStep.title" />}
            number={stepIndex}
            selected={this.state.selected}
            onClick={this.onSelect}
        >
            <div className={block('StepText')}>
                <Interpolate i18nKey="BrowserWelcome.uiStep.text" />
            </div>
            <div className={block('StepContentCentered')}>
                <div className={block('TopBarWrapper')}>
                    <Provider {...this.mockStoresForDemoTopBar}>
                        <div style={{ position: 'relative' }}>
                            <TopBarAlternative isWizard />
                        </div>
                    </Provider>
                </div>
                <Button onClick={() => this.onSelect(stepIndex + 1)} raised primaryGreen style={{ minWidth: 150 }}>
                    <Interpolate i18nKey="BrowserWelcome.continueButton" />
                </Button>
            </div>
        </Step>;
    };

    renderBookmarkStep = (stepIndex) => {
        return <Step
            title={<Interpolate i18nKey="BrowserWelcome.bookmarkStep.title" />}
            number={stepIndex}
            selected={this.state.selected}
            onClick={this.onSelect}
        >
            <div className={block('StepText')}>
                <Interpolate
                    i18nKey="BrowserWelcome.bookmarkStep.text"
                    link={<a style={{ color: '#03A9F4' }} href={LOCATION_ORIGIN}>{LOCATION_ORIGIN}</a>}
                />
            </div>
            <div className={block('StepContentCentered')} style={{ marginTop: '28px' }}>
                <Interpolate i18nKey="BrowserWelcome.bookmarkStep.shortcutText" />
                <PfptOnPC style={{ width: 164, height: 101, marginTop: 15, marginBottom: 30 }} />
                <Button onClick={this.onDone} raised primaryGreen style={{ minWidth: 150 }}>
                    <Interpolate i18nKey="BrowserWelcome.doneButton" />
                </Button>
            </div>
        </Step>;
    };

    render() {
        const { browser } = this.props;
        const steps = browser.isAutoProvisioned
            ? [this.renderUIStep(browser), this.renderBookmarkStep]
            : [this.renderActivationStep, this.renderUIStep(browser), this.renderBookmarkStep];

        return <div className={block()}>
            <div className={block('Inner')}>
                <ActivateDifferentBrowserModal
                    isOpen={this.state.isActivateAnotherBrowserModalOpened}
                    onClose={this.onModalClose}
                />
                <PfptWordsVertical className={block('Header')} />
                <div className={block('List')}>
                    {steps.map((render, i) => <Fragment key={i}>{render(i + 1)}</Fragment>)}
                </div>
            </div>
        </div>;
    }
}
export default translate()(inject('browser')(observer(BrowserWelcome)));
