import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';

const Translate = ({ t, i18nKey }) => {
    return <span>{t(i18nKey)}</span>;
};

Translate.propTypes = {
    t: PropTypes.func.isRequired,
    i18nKey: PropTypes.string.isRequired,
};

export function translateWithScreen(translateFunc, isMobile, key) {
    return translateFunc(key + (isMobile ? 'Mobile' : ''));
}

export default translate()(Translate);
