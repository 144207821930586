import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { LOCATION_ORIGIN } from '../env';

class PopUpDetectedController extends Component {
    componentDidMount() {
        try {
            window.localStorage.latestOpenedURL = '';
        } catch (e) { /* whatever */ }
        this.storageCheckInterval = setInterval(this.checkStorage, 100);
    }

    componentWillUnmount() {
        this.stopCheckingStorage();
    }

    checkStorage = () => {
        let openedHref = '';

        try {
            openedHref = window.localStorage.latestOpenedURL;
        } catch (e) {
            return;
        }

        if (openedHref === this.getHref()) {
            this.props.onWindowDeny();
            this.stopCheckingStorage();
        }
    };

    stopCheckingStorage() {
        clearInterval(this.storageCheckInterval);
    }

    getHref() {
        return LOCATION_ORIGIN + this.props.url;
    }

    render() {
        return <Fragment>
            {this.props.render(this.getHref())}
        </Fragment>;
    }
}

PopUpDetectedController.propTypes = {
    onWindowDeny: PropTypes.func,
    url: PropTypes.string.isRequired,
    render: PropTypes.func,
};

export default PopUpDetectedController;
