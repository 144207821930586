import { action, extendObservable } from 'mobx';
import PropTypes from 'prop-types';
import { apiCall } from '../../API';
import FlagStore from '../../stores/FlagStore';

const clearCookiesApiCall = apiCall('/cookies/clear');

// TODO: refactor to use AsyncActionState
export default class ClearCookiesStore {
    static PropType = PropTypes.shape({
        modal: FlagStore.PropType,
        isApiCallLoading: PropTypes.bool.isRequired,
        doClearCookies: PropTypes.func.isRequired,
    });

    constructor(data = {}) {
        extendObservable(this, /** @class ClearCookiesStore */{
            modal: new FlagStore(false),

            isApiCallLoading: false,

            doClearCookies: action((onSuccess) => {
                this.isApiCallLoading = true;
                clearCookiesApiCall().then(action(() => {
                    this.isApiCallLoading = false;
                    this.modal.close();
                    if (onSuccess) {
                        onSuccess();
                    }
                })).catch((action(() => {
                    this.isApiCallLoading = false;
                    this.modal.close();
                    // TODO: error message
                })));
            }),
        }, data);
    }
}
