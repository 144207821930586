import { action, extendObservable } from 'mobx';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import InputStore from './InputStore';
import FlagStore from './FlagStore';

export default class FuseSelectStore {
    static ValuePropType = PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]);

    static OptionPropType = PropTypes.shape({
        value: FuseSelectStore.ValuePropType.isRequired,
        label: PropTypes.string,
        info: PropTypes.string,
        icon: PropTypes.string,
        textIcon: PropTypes.string,
        disabled: PropTypes.bool,
        hidden: PropTypes.bool,
        error: PropTypes.bool, // Like "hidden" -> shows in a red pill, BUT is not removed from "options"
        getFirstValueOrEmpty: PropTypes.func,
    });

    static _PropType = {
        setRef: PropTypes.func.isRequired,
        options: MobxPropTypes.arrayOrObservableArrayOf(FuseSelectStore.OptionPropType).isRequired,
        selectedValues: MobxPropTypes.arrayOrObservableArrayOf(FuseSelectStore.ValuePropType),
        filterValues: MobxPropTypes.arrayOrObservableArrayOf(FuseSelectStore.ValuePropType),
        filter: InputStore.PropType.isRequired,
        error: PropTypes.string,
        disabled: FlagStore.PropType.isRequired,
        loading: FlagStore.PropType.isRequired,
        onSelect: PropTypes.func.isRequired,
        setSelectedValues: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,
        getFilteredOptions: PropTypes.func.isRequired,
        selectedValuesSet: PropTypes.instanceOf(Set),
        sorterFactory: PropTypes.func,
    };
    static PropType = PropTypes.shape(FuseSelectStore._PropType)

    constructor(data = {}) {
        this.ref = null;

        extendObservable(this, /** @class FuseSelectStore */{
            options: [],
            selectedValues: [],
            filterValues: null,
            filter: new InputStore(),
            disabled: new FlagStore(),
            loading: new FlagStore(),
            selectedValuesSet: new Set(),
            filterFn: (option, filter) => (option.label || '').toLowerCase().includes(filter),

            getFilteredOptions: () => {
                if (this.filterValues) {
                    return this.options.filter((option) => this.filterValues.indexOf(option.value) >= 0);
                }
                const filter = this.filter.value.toLowerCase();
                if (!filter) {
                    return this.options;
                }
                return this.options.filter((option) => this.filterFn(option, filter));
            },

            onSelect: () => null,

            isEmpty() {
                return this.selectedValues.length === 0;
            },
        }, data);
    }

    setRef = (newRef) => {
        this.ref = newRef;
    };

    focus = () => {
        if (this.ref && this.ref.focus) {
            this.ref.focus();
        }
    };

    blur = () => {
        if (this.ref && this.ref.blur) {
            this.ref.blur();
        }
    };

    getFirstValueOrEmpty = () => {
        return this.selectedValues.length > 0 ? this.selectedValues[0] : '';
    };

    getLabelForSelectedValue = () => {
        let retVal = null;
        const value = this.getFirstValueOrEmpty();
        const found = this.options.find((el) => el.value === value);
        if (found) {
            retVal = found.label;
        }
        return retVal;
    };

    /**
     * Sets the Array of Objects with {value: , label: }
     * @param {Array} options
     * @param {Boolean} keepSelected
     */
    setOptions = action((options, keepSelected = false) => {
        if (keepSelected) {
            const newOptionsHash = this.hashByValue(options);
            const uniqueOptionsToKeep = this.options.filter(({ value }) =>
                !newOptionsHash[value] &&
                this.selectedValuesSet.has(value)
            );
            options = options.concat(uniqueOptionsToKeep);
        }
        this.options = options;
    });

    setSelectedValues = action((selectedValues) => {
        this.selectedValues = this.options
            .filter((option) => selectedValues.some((value) => option.value === value))
            .map((option) => option.value);
        this.selectedValuesSet = new Set(this.selectedValues);
    });

    hashByValue = (arr) => {
        return arr.reduce((acc, item) => {
            acc[item.value] = item;
            return acc;
        }, {});
    }

    setFilterValues = action((filterValues) => {
        this.filterValues = filterValues;
    });

    clear = action(() => {
        this.options = [];
        this.clearSelectedValues();
    });

    clearSelectedValues = action(() => {
        this.selectedValues = [];
        this.filter.setValue('');
        this.selectedValuesSet = new Set();
    });

    setError = action((error) => {
        this.error = error;
    });

    getSelectedItems = () =>
        this.options.filter(({ value }) => this.selectedValuesSet.has(value));

    static haveDifferentFlatItems = (arr1, arr2) => {
        const toSelectValue = (item) => (typeof item.id !== 'undefined' ? item.id : (item.name || item));
        return arr1.length !== arr2.length ||
            arr1.some((item, index) => toSelectValue(item) !== toSelectValue(arr2[index]));
    };
}

