import { reaction } from 'mobx';
import LocalStorage from '../../LocalStorage';

function setValueFromLS(store, lsKey) {
    const savedValue = LocalStorage.get(lsKey);
    if (savedValue !== null) {
        if (store.setValue) {
            store.setValue(savedValue);
            if (store.commit) {
                store.commit();
            }
        }
    }
}

// for some reason WeakSet was not polyfilled in storybook and was crashing ie11
const syncedStores = window.WeakSet ? new WeakSet() : new Set();

// sync "global application state" like "research mode" among all tabs through localStorage
export default function syncStoreWithLocalStorage(store, lsKey, updateStoreOnLSEvent = false) {
    if (!syncedStores.has(store)) {
        syncedStores.add(store);

        setValueFromLS(store, lsKey);

        reaction(() => store.getValue(), (newValue) =>
            LocalStorage.set(lsKey, newValue));


        if (updateStoreOnLSEvent) {
            window.addEventListener('storage', (event) => {
                if (event.key === lsKey) {
                    setValueFromLS(store, lsKey);
                }
            });
        }
    }

    return store;
}
