import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Interpolate, translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import cn from 'classnames';
import b from 'b_';
import BrowserStore from '../../browser/stores/BrowserStore';
import makeElementId from '../../makeElementId';
import EnvStore from '../../stores/EnvStore';
import { SECTIONS } from '../FAQ/FAQContent';
import FuseWysiwygReadOnly from '../Fuse/FuseWysiwyg/FuseWysiwygReadOnly';
import FuseAlert from '../FuseControls/FuseAlert';
import FuseButton from '../FuseControls/FuseButton';
import TimerSpinner from '../TimerSpinner/TimerSpinner';
import Icon from '../Icon/Icon';
import Actions from '../Actions/Actions';
import Dropdown from '../Dropdown';
import { ObservableTextfield } from '../Textfield';
import PopUpDetectedController from '../../browser/PopUpDetectedController';
import NotificationContent from './NotificationContent';
import LoaderBar from '../Loaders/LoaderBar';
import FileEventController from '../../browser/FileEventController';
import { FILE_PROCESSING_STATE } from '../../mirror/sharedConstants';
import KeyboardShortcuts from '../KeyboardShortcuts';
import SnackStore from '../../stores/SnackStore';
import './Notification.css';
import './../TopBarCaret.css';

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const block = b.lock('Notification');

class RightNotification extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        env: EnvStore.PropType.isRequired,
        snack: SnackStore.PropType.isRequired,
    };

    popoverId = makeElementId('rightNotificationCornerPopover');
    cornerId = makeElementId('rightNotificationCorner');
    titleId = makeElementId('rightNotificationTitle');
    textId = makeElementId('rightNotificationText');
    dropRef = React.createRef();

    copyToClipboard = async (urlToExit) => {
        const { snack, t } = this.props;
        try {
            await navigator.clipboard.writeText(urlToExit);
            snack.showSuccess(t('Notification_Snack.Copy_URL_Success'));
        } catch (err) {
            snack.showError(<Interpolate
                i18nKey="Notification_Snack.Copy_URL_Failed"
                error={err?.message ?? ''}
            />);
        }
    }

    getCornerTitle = () => {
        const { browser, t } = this.props;
        const notification = browser.notification;

        if (notification && this[notification] && notification !== BrowserStore.NOTIFICATION.FILE_PROCESSING) {
            if (notification === BrowserStore.NOTIFICATION.INPUT_DISABLED) {
                return t(browser.isHarScanInputRestrictions()
                    ? 'Notification.INPUT_DISABLED.altTitle'
                    : 'Notification.INPUT_DISABLED.title'
                );
            }

            if (notification === BrowserStore.NOTIFICATION.NEW_CONFIRM_EXIT_ISOLATION) {
                return browser.exitWarningText ?? t('UDTopBar.newExitWarning');
            }

            if (notification === BrowserStore.NOTIFICATION.REQUEST_BASIC_AUTHORIZATION) {
                return `${browser.basicAuth.challengerUrl} ${t('Notification.BASIC_AUTHORIZATION.title')}`;
            }

            if (notification === BrowserStore.NOTIFICATION.CUSTOMER_MESSAGE) {
                const chunks = (browser.originalQuery.notification ?? '').split(/[{}]/g);

                if (chunks && chunks[0]) {
                    return chunks[0];
                }
            }

            return t(`Notification.${notification}.title`);
        }

        return t('Notification.DEFAULT_RIGHT.title');
    };

    renderInfoBlock(name) {
        const { t } = this.props;
        return <div className={block('InfoBlock')}>
            <div
                className={block('InfoTitle')}
                id={this.titleId}
            >{t(`Notification.${name}.title`)}</div>
            <div
                className={block('InfoText')}
                id={this.textId}
            >{t(`Notification.${name}.text`)}</div>
        </div>;
    }

    renderActionDialog(name, action1, action2, action1autoFocus = false) {
        const { t, browser } = this.props;
        return <Fragment>
            {this.renderInfoBlock(name)}
            <Actions className={block('InfoActions')}>
                <FuseButton
                    type={'secondary'}
                    color={'blue'}
                    fullWidth
                    onClick={action1}
                    autoFocus={action1autoFocus && !browser.isConsolePreview}
                >{t(`Notification.${name}.action1`)}</FuseButton>
                <FuseButton
                    type={'primary'}
                    color={'blue'}
                    fullWidth
                    onClick={action2}
                >{t(`Notification.${name}.action2`)}</FuseButton>
            </Actions>
        </Fragment>;
    }

    // We should always have a file url, at least for new policies. Adding the extra check just in case of old policies,
    // which are unlikely to be tested as thoroughly
    maybeRenderFileUrl = (data) => (data.fileUrl ? <Fragment>
        <div className={block('FileLabel')}>URL Source</div>
        <div className={block('FileUrl', { dataUrl: data.fileUrl.startsWith('data:') })}>{data.fileUrl}</div>
    </Fragment> : null)

    renderBlockedFiles() {
        const { fileManagerStore } = this.props.browser;

        return <NotificationContent
            icon={'file_scan'}
            title={this.props.t('Notification.FILES_BLOCKED.title')}
            toggleStore={fileManagerStore.blockedFilesToggle}
        >
            {fileManagerStore.blockedFilesEvents.filter((event) => event.state !== FILE_PROCESSING_STATE.CANCELED)
                .map((event) => {
                    return <FileEventController
                        key={event.id}
                        fileEvent={event}
                        render={(data) => <Fragment>
                            <div className={block('BlockedFilesEventBody')}>
                                <Icon className={block('FileEventIcon')} type={data.icon}/>
                                <div className={block('BlockedFilesEventInfo')}>
                                    <div className={block('FileLabel')}>{event.startTime}</div>
                                    <div className={block('FileName')}>{event.name}</div>
                                    {this.maybeRenderFileUrl(data)}
                                    <div className={
                                        block('FileStatusText', { type: data.notificationType, isList: true })
                                    }
                                    >
                                        {data.notificationStatus}
                                    </div>
                                </div>
                            </div>
                        </Fragment>}
                    />;
                })}
        </NotificationContent>;
    }

    [BrowserStore.NOTIFICATION.INPUT_DISABLED] = (toggleStore) => <NotificationContent
        icon="keyboard"
        title={this.props.t(this.props.browser.isHarScanInputRestrictions()
            ? 'Notification.INPUT_DISABLED.altTitle'
            : 'Notification.INPUT_DISABLED.title'
        )}
        toggleStore={toggleStore}
        learnMoreSection={SECTIONS.DLP}
    >
        {this.props.t(this.props.browser.isHarScanInputRestrictions()
            ? 'Notification.INPUT_DISABLED.altText'
            : 'Notification.INPUT_DISABLED.text'
        )}
    </NotificationContent>;

    [BrowserStore.NOTIFICATION.PASTE_DISABLED] = (toggleStore) => <NotificationContent
        icon="assignment"
        title={this.props.t('Notification.PASTE_DISABLED.title')}
        toggleStore={toggleStore}
        learnMoreSection={SECTIONS.DLP}
    >{this.props.t('Notification.PASTE_DISABLED.text')}</NotificationContent>;

    [BrowserStore.NOTIFICATION.PASTE_TRUNCATED] = (toggleStore) => <NotificationContent
        icon="assignment"
        title={this.props.t('Notification.PASTE_TRUNCATED.title')}
        toggleStore={toggleStore}
        learnMoreSection={SECTIONS.DLP}
    >{this.props.t('Notification.PASTE_TRUNCATED.text')}</NotificationContent>;

    [BrowserStore.NOTIFICATION.CONFIRM_EXIT_ISOLATION] = () => {
        return this.renderActionDialog('CONFIRM_EXIT_ISOLATION',
            () => this.props.browser.navigateToOriginalUrl(),
            () => this.props.browser.closeNotification(),
            true);
    };
    getNodesAndFocusedIndex = () => {
        const exitConfirmationNodes = [...this.dropRef.current.container.querySelectorAll(
            '[role="alert"],[role="button"]')];
        const focusedItemIndex = exitConfirmationNodes.indexOf(document.activeElement);
        return [exitConfirmationNodes, focusedItemIndex];
    };
    focusNextItem = () => {
        const [nodeArr, focusedItemIndex] = this.getNodesAndFocusedIndex();
        if (focusedItemIndex > -1 && focusedItemIndex < nodeArr.length - 1) {
            nodeArr[(focusedItemIndex + 1)].focus();
        } else {
            nodeArr[0].focus();
        }
    }
    focusPrevItem = () => {
        const [nodes, focusedIndex] = this.getNodesAndFocusedIndex();
        if (focusedIndex > 0) {
            nodes[(focusedIndex - 1)].focus();
        } else {
            nodes[nodes.length - 1].focus();
        }
    };

    [BrowserStore.NOTIFICATION.COPY_URL_TO_EXIT] = () => {
        const { t, browser } = this.props;
        const urlToExit = browser.urlInputValue.trim().length === 0 ?
            browser.getOriginalUrlByClickId(browser.sandboxClickId) : browser.urlInputValue;
        return <>
            {this.renderInfoBlock('COPY_URL_TO_EXIT')}
            <div className={block('InfoBottomSectionWithJustButton')}>
                <FuseButton
                    fullWidth
                    role="button"
                    type="primary"
                    color="blue"
                    onClick={() => {
                        browser.closeNotification();
                        this.copyToClipboard(urlToExit);
                    }}
                >{t('Notification.COPY_URL_TO_EXIT.action')}</FuseButton>
            </div>
        </>;
    };

    [BrowserStore.NOTIFICATION.NEW_CONFIRM_EXIT_ISOLATION] = () => {
        const { t, browser } = this.props;
        let exitMsg = t('UDTopBar.newExitWarning');
        if (browser.exitWarningText) {
            if (typeof browser.exitWarningText !== 'string') {
                exitMsg = <FuseWysiwygReadOnly content={browser.exitWarningText}/>;
            } else {
                exitMsg = browser.exitWarningText;
            }
        }

        return <Fragment>
            <KeyboardShortcuts
                down={this.focusNextItem}
                up={this.focusPrevItem}
            />
            <FuseAlert
                id={this.titleId}
                role="alert"
                type="warning"
                icon="warning"
                tabIndex="0"
                style={{
                    margin: '15px 15px 5px 15px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                }}
            >{exitMsg}</FuseAlert>
            <Actions className={block('InfoActions')}>
                <FuseButton
                    role="button"
                    style={{ minWidth: 100 }}
                    type={'secondary'}
                    color={'blue'}
                    fullWidth
                    onClick={() => {
                        this.props.browser.closeNotification();
                        this.props.browser.navigateToOriginalUrl();
                    }}
                    autoFocus={!this.props.browser.isConsolePreview}
                >{t('UDTopBar.newExit')}</FuseButton>
                <FuseButton
                    role="button"
                    style={{ minWidth: 100 }}
                    type={'primary'}
                    color={'blue'}
                    fullWidth
                    onClick={() => this.props.browser.closeNotification()}
                >{t('UDTopBar.closeNewExit')}</FuseButton>
            </Actions>
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.CLIPBOARD_PERMISSION] = () => {
        return this.renderActionDialog('CLIPBOARD_PERMISSION',
            () => this.props.browser.closeNotification(),
            () => this.props.browser.allowSetClipboard());
    };

    [BrowserStore.NOTIFICATION.FULL_SCREEN_PERMISSION] = () => {
        return this.renderActionDialog('FULL_SCREEN_PERMISSION',
            () => this.props.browser.exitFullscreen(),
            () => this.props.browser.openFullscreen());
    };

    [BrowserStore.NOTIFICATION.EMAIL_PERMISSION] = () => {
        return this.renderActionDialog('EMAIL_PERMISSION',
            () => this.props.browser.closeNotification(),
            () => this.props.browser.openMailClient());
    };

    [BrowserStore.NOTIFICATION.REQUEST_BASIC_AUTHORIZATION] = () => {
        const { t, browser } = this.props;
        const onSubmit = (event) => {
            event.preventDefault();
            browser.sendBasicAuth();
        };

        return <form onSubmit={onSubmit}>
            <div className={block('InfoBlock')}>
                <div className={block('InfoTitle')} id={this.titleId}>
                    {browser.basicAuth.challengerUrl} {t('Notification.BASIC_AUTHORIZATION.title')}
                </div>
                <div className={block('InfoText')}>
                    <ObservableTextfield
                        className={block('Field')}
                        label={t('Notification.BASIC_AUTHORIZATION.username')}
                        fullWidth
                        store={browser.basicAuth.username}
                    />
                    <ObservableTextfield
                        className={block('Field')}
                        label={t('Notification.BASIC_AUTHORIZATION.password')}
                        type="password"
                        fullWidth
                        store={browser.basicAuth.password}
                    />
                </div>
            </div>

            {<Actions className={block('InfoActions')}>
                <FuseButton
                    type={'secondary'}
                    color={'blue'}
                    fullWidth
                    onClick={browser.cancelBasicAuth}
                >{t('Notification.BASIC_AUTHORIZATION.cancel')}</FuseButton>
                <FuseButton
                    type={'primary'}
                    color={'blue'}
                    fullWidth
                    submit
                >{t('Notification.BASIC_AUTHORIZATION.submit')}</FuseButton>
            </Actions>}
        </form>;
    };

    [BrowserStore.NOTIFICATION.PAUSING_SESSION] = () => {
        const { t, browser } = this.props;
        return <Fragment>
            {this.renderInfoBlock('PAUSING_SESSION')}
            <div className={block('InfoBottomSection')}>
                <div
                    className={block('InfoBottomTextContainer')}
                    style={{ minWidth: 150 }}
                >
                    <TimerSpinner
                        totalTime={browser.inactivity.timeTotal}
                        currentTime={browser.inactivity.timeLeft}
                        className={block('ActionSectionIcon')}
                        counterClockwise
                    />
                    <span className={block('InfoBottomText')}>
                        {browser.inactivity.timeLeft + ' ' + t('Notification.PAUSING_SESSION.seconds')}
                    </span>
                </div>
                <FuseButton
                    type={'primary'}
                    color={'blue'}
                    className={block('SingleActionButton')}
                    onClick={() => { /* InactivityStore has listener on body */
                    }}
                >{t('Notification.PAUSING_SESSION.action')}</FuseButton>
            </div>
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.PAUSING_SESSION_CANCELLED] = () => {
        const { t } = this.props;
        return <Fragment>
            {this.renderInfoBlock('PAUSING_SESSION_CANCELLED')}
            <div className={block('InfoBottomSection')}>
                <div className={block('InfoBottomTextContainer')}>
                    <span className={block('InfoBottomText')}>
                        {t('Notification.PAUSING_SESSION_CANCELLED.message')}
                    </span>
                </div>
            </div>
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.SESSION_PAUSED] = () => {
        const { t } = this.props;
        return <Fragment>
            <div className={block('InfoBlock')}>
                <div
                    className={block('InfoTitle')}
                    id={this.titleId}
                >{t('Notification.SESSION_PAUSED.title')}</div>
                <div
                    className={block('InfoText')}
                    id={this.textId}
                >
                    <Interpolate
                        i18nKey="Notification.SESSION_PAUSED.text.template"
                        linebreak={<Fragment><br/><br/></Fragment>}
                        highlight={<strong>{t('Notification.SESSION_PAUSED.text.highlight')}</strong>}
                    />
                </div>
            </div>
            <div className={block('InfoBottomSection')}>
                <div className={block('InfoBottomTextContainer')}>
                    <Icon
                        className={block('ActionSectionIcon', { red: true })}
                        type="pause_circle_outline"
                    />
                    <span className={block('InfoBottomText')}>{t('Notification.SESSION_PAUSED.message')}</span>
                </div>
                <FuseButton
                    type={'primary'}
                    color={'blue'}
                    className={block('SingleActionButton')}
                    onClick={() => window.top.location.reload()}
                >{t('Notification.SESSION_PAUSED.action')}</FuseButton>
            </div>
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.ANOTHER_TAB_THREAT_DETECTED] = () => {
        const { t } = this.props;
        return <Fragment>
            <div className={block('InfoBlock')}>
                <div className={block('InfoTitle')} id={this.titleId}>
                    {t('Notification.ANOTHER_TAB_THREAT_DETECTED.title')}
                </div>
                <div className={block('InfoText')} id={this.textId}>
                    {t('Notification.ANOTHER_TAB_THREAT_DETECTED.text')}
                </div>
            </div>
            <div className={block('InfoBottomSection')}>
                <div className={block('InfoBottomTextContainer')}>&nbsp;</div>
                <FuseButton
                    type={'primary'}
                    color={'blue'}
                    className={block('SingleActionButton')}
                    onClick={() => window.top.location.reload()}
                >{t('Notification.ANOTHER_TAB_THREAT_DETECTED.action')}</FuseButton>
            </div>
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.POPUP_DETECTED] = () => {
        const { t, browser } = this.props;
        return <Fragment>
            <div className={block('InfoBlock')}>
                <div
                    className={block('InfoTitle')}
                    id={this.titleId}
                >{t('Notification.POPUP_DETECTED.title')}</div>
                <div className={block('InfoText')} id={this.textId}>
                    <Interpolate
                        i18nKey="Notification.POPUP_DETECTED.text"
                        linebreak={<Fragment><br/><br/></Fragment>}
                        domain={<strong>{window.location.hostname}</strong>}
                    />
                </div>
            </div>
            <Actions className={block('InfoActions')}>
                <FuseButton
                    type={'secondary'}
                    color={'blue'}
                    fullWidth
                    onClick={() => browser.closeNotification()}
                >{t('Notification.POPUP_DETECTED.action1')}</FuseButton>
                <PopUpDetectedController
                    onWindowDeny={() => this.props.browser.closeNotification()}
                    url={browser.popupData.browserUrl}
                    render={(hrefToNewWindow) => (
                        <FuseButton
                            href={hrefToNewWindow}
                            target="_blank"
                            type={'primary'}
                            color={'blue'}
                            fullWidth
                            onClick={() => this.props.browser.closeNotification()}
                        >{t('Notification.POPUP_DETECTED.action2')}</FuseButton>
                    )}
                />
            </Actions>
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.CONNECTION_ESTABLISHED] = () => <NotificationContent
        icon={'wifi'}
        isDarkIcon
        title={this.props.t('Notification.CONNECTION_ESTABLISHED.title')}
    >{this.props.t('Notification.CONNECTION_ESTABLISHED.text')}</NotificationContent>;

    [BrowserStore.NOTIFICATION.TRYING_TO_CONNECT] = () => {
        const { t } = this.props;
        return <Fragment>
            <div className={block('InfoBlock')}>
                <div
                    className={block('InfoTitle')}
                    id={this.titleId}
                >{t('Notification.TRYING_TO_CONNECT.title')}</div>
                <div className={block('InfoText')} id={this.textId}>
                    <Interpolate
                        i18nKey={'Notification.TRYING_TO_CONNECT.text.template'}
                        linebreak={<Fragment><br/><br/></Fragment>}
                        highlight={<strong>{t('Notification.TRYING_TO_CONNECT.text.highlight')}</strong>}
                        list={<ul className={block('InfoBlockList')}>
                            <li>{t('Notification.TRYING_TO_CONNECT.text.listItem1')}</li>
                            <li>{t('Notification.TRYING_TO_CONNECT.text.listItem2')}</li>
                        </ul>}
                    />
                </div>
            </div>
            <div className={block('InfoBottomSection')}>
                <div className={block('InfoBottomTextContainer')}>
                    <Icon
                        className={block('ActionSectionIcon', { sync: true })}
                        type={'sync'}
                    />
                    <span className={block('InfoBottomText')}>{t('Notification.TRYING_TO_CONNECT.message')}</span>
                </div>
            </div>
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.DISCONNECTED] = () => {
        const { t } = this.props;
        return <Fragment>
            {this.renderInfoBlock('DISCONNECTED_V2')}
            <div className={block('InfoBottomSectionWithJustButton')}>
                <FuseButton
                    fullWidth
                    role="button"
                    type={'primary'}
                    color={'blue'}
                    onClick={() => window.top.location.reload()}
                >{t('Notification.DISCONNECTED_V2.action')}</FuseButton>
            </div>
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.CUSTOMER_MESSAGE] = () => {
        const { browser } = this.props;
        const { notification = '', notificationLink } = browser.originalQuery;
        const chunks = notification.split(/[{}]/g);
        return <div className={block('InfoBlock')}>
            <div
                className={block('InfoTitle')}
                style={{ marginBottom: 0 }}
            >
                <span id={this.titleId}>{chunks[0]}</span>
                <span>{notificationLink && chunks[1] ? <a
                    href={notificationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#0277BA', textDecoration: 'underline' }}
                >{chunks[1]}</a> : chunks[1]}</span>
                <span id={this.textId}>{chunks[2]}</span>
            </div>
        </div>;
    };

    [BrowserStore.NOTIFICATION.LOCAL_APPLICATION_BLOCKED] = () => <NotificationContent
        title={this.props.t('Notification.LOCAL_APPLICATION_BLOCKED.title')}
    >{this.props.t('Notification.LOCAL_APPLICATION_BLOCKED.text')}</NotificationContent>;

    [BrowserStore.NOTIFICATION.DOWNLOADS_DISABLED] = (toggleStore) => <NotificationContent
        icon="download_file"
        title={this.props.t('Notification.DOWNLOADS_DISABLED.title')}
        toggleStore={toggleStore}
        learnMoreSection={SECTIONS.DOWNLOADS}
    >{this.props.t('Notification.DOWNLOADS_DISABLED.disabled')}</NotificationContent>;

    [BrowserStore.NOTIFICATION.UPLOADS_DISABLED] = (toggleStore) => <NotificationContent
        icon="upload_file"
        title={this.props.t('Notification.UPLOADS_DISABLED.title')}
        toggleStore={toggleStore}
        learnMoreSection={SECTIONS.UPLOADS}
    >{this.props.t('Notification.UPLOADS_DISABLED.disabled')}</NotificationContent>;

    [BrowserStore.NOTIFICATION.DEFAULT_RIGHT] = () => {
        const { browser } = this.props;
        const { fileManagerStore } = browser;

        return <Fragment>
            {this.renderInfoBlock('DEFAULT_RIGHT')}
            {browser.inputRestrictions === BrowserStore.INPUT_RESTRICTION.INPUT_DISABLED
                ? this[BrowserStore.NOTIFICATION.INPUT_DISABLED](browser.inputRestrictionsDetails) : null}
            {browser.inputRestrictions === BrowserStore.INPUT_RESTRICTION.PASTE_DISABLED
                ? this[BrowserStore.NOTIFICATION.PASTE_DISABLED](browser.inputRestrictionsDetails) : null}
            {browser.inputRestrictions === BrowserStore.INPUT_RESTRICTION.PASTE_TRUNCATED
                ? this[BrowserStore.NOTIFICATION.PASTE_TRUNCATED](browser.inputRestrictionsDetails) : null}
            {browser.isDownloadDisabled
                ? this[BrowserStore.NOTIFICATION.DOWNLOADS_DISABLED](browser.downloadsDisabledDetails) : null}
            {browser.isUploadDisabled
                ? this[BrowserStore.NOTIFICATION.UPLOADS_DISABLED](browser.uploadsDisabledDetails) : null}
            {fileManagerStore.hasBlockedEvents() ? this.renderBlockedFiles() : null}
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.FILE_PROCESSING] = (toggle) => {
        const { t, browser } = this.props;
        const { fileManagerStore, frameEl } = browser;

        return fileManagerStore.fileProcessingDataArr.map((fileEvent) => (
            <FileEventController
                key={fileEvent.id}
                fileEvent={fileEvent}
                render={(data) => {
                    return <Fragment>
                        <NotificationContent
                            icon={data.icon}
                            title={data.notificationTitle}
                            type={data.notificationType}
                            toggleStore={toggle}
                            className={block('FileEventNotification')}
                        >
                            <div className={block('FileLabel')}>{data.startTime}</div>
                            <div className={block('FileName')}>{fileEvent.name}</div>
                            {this.maybeRenderFileUrl(data)}
                            {data.categories && <Fragment>
                                <div className={block('FileLabel')}>Category</div>
                                <div className={block('FileName')}>{data.categories.join(', ')}</div>
                            </Fragment>}
                            {data.fileMimeType && <Fragment>
                                <div className={block('FileLabel')}>File Type</div>
                                <div className={block('FileName')}>{data.fileMimeType}</div>
                            </Fragment>}
                            {data.country && <Fragment>
                                <div className={block('FileLabel')}>Source Country</div>
                                <div className={block('FileName')}>{data.country}</div>
                            </Fragment>}
                            {data.fileSize > 0 && <Fragment>
                                <div className={block('FileLabel')}>File Size</div>
                                <div className={block('FileName')}>{formatBytes(data.fileSize)}</div>
                            </Fragment>}

                            <div className={block('FileStatus')}>
                                {data.isScanning ?
                                    <div className={block('FileProgressWrapper')}>
                                        <LoaderBar className={block('FileLoaderWrapperStyle')}/>
                                        <Icon
                                            className={block('FileCancelProgress')}
                                            type={'cancel'}
                                            onClick={() => fileManagerStore.cancelFileProcessing(frameEl, fileEvent.id)}
                                        />
                                    </div> : null
                                }
                            </div>
                            <div className={block('FileStatusText', { type: data.notificationType })}>
                                {data.notificationStatus}
                            </div>
                        </NotificationContent>
                        {data.filePreview ? <div className={block('InfoBottomSection')}>
                            <div className={block('InfoBottomTextContainer')}>
                                <span className={block('InfoBottomText')}>
                                    {t('Notification.FILE_PROCESSING.preview.message')}
                                </span>
                            </div>

                            <FuseButton
                                type={'primary'}
                                color={'blue'}
                                className={block('SingleActionButton')}
                                onClick={() => {
                                    window.top.open(data.filePreview);
                                    browser.closeNotification();
                                }}
                            >{t('Notification.FILE_PROCESSING.preview.action')}</FuseButton>
                        </div> : null}
                    </Fragment>;
                }}
            />
        ));
    };

    renderDropdown() {
        const notification = this.props.browser.notification;
        if (notification && this[notification]) {
            return <Dropdown
                ref={this.dropRef}
                tabIndex="-1"
                className={block({ right: true })}
                onClose={this.onClickOutside}
                id={this.popoverId}
                role="region"
                aria-labelledby={this.titleId}
                aria-describedby={this.textId}
            >{this[notification]()}</Dropdown>;
        }
        return null;
    }

    getCorner() {
        const { browser, env } = this.props;
        const { notification, fileManagerStore } = browser;
        const isAnyNotificationOpen = !!notification;
        const isRightNotificationOpen = isAnyNotificationOpen && !!this[notification];
        const isDefaultRightNotificationOpen = notification === BrowserStore.NOTIFICATION.DEFAULT_RIGHT;
        const isNonDefaultRightNotificationOpen = isRightNotificationOpen && !isDefaultRightNotificationOpen;

        if (!isRightNotificationOpen || (notification === browser.networkState)) {
            if (browser.networkState === BrowserStore.NOTIFICATION.DISCONNECTED) {
                return { icon: 'no_wifi', iconMods: { red: true } };
            }
            if (browser.networkState === BrowserStore.NOTIFICATION.SESSION_PAUSED) {
                return { icon: 'pause_circle_outline', iconMods: { red: true } };
            }
            if (browser.networkState === BrowserStore.NOTIFICATION.ANOTHER_TAB_THREAT_DETECTED) {
                return { icon: 'web', iconMods: { green: true } };
            }
            if (browser.networkState === BrowserStore.NOTIFICATION.TRYING_TO_CONNECT) {
                return { icon: 'sync', iconMods: { sync: true } };
            }
        }

        if (isNonDefaultRightNotificationOpen) {
            return { icon: 'close', centered: true };
        }
        if (env.screen.isMobile && browser.urlInputFocus.isTrue) {
            return {};
        }
        if (env.isUrlIsolation && browser.isDanger()) {
            return {};
        }
        if (!env.isUrlIsolation && (browser.isHomePage
            || browser.isLoading
            || browser.isErrorPage
            || browser.errorPage.isTrue
            || browser.tsaBlockPage.isTrue)
        ) {
            return {};
        }

        const hasInputRestrictions = browser.inputRestrictions !== BrowserStore.INPUT_RESTRICTION.NONE;
        const hasAnyRestrictions = hasInputRestrictions
            || browser.isDownloadDisabled
            || browser.isUploadDisabled;

        if (fileManagerStore.hasBlockedEvents() && !hasAnyRestrictions) {
            return { icon: 'file_scan', iconMods: { red: true } };
        }

        if (isDefaultRightNotificationOpen || hasAnyRestrictions) {
            return { icon: 'warning', iconMods: { red: true } };
        }

        return {};
    }

    onCornerClick = () => {
        const { browser } = this.props;

        browser.topBarMenu.close();

        if (browser.notification === BrowserStore.NOTIFICATION.FULL_SCREEN_PERMISSION) {
            browser.exitFullscreen();
            return;
        }

        if (browser.notification === BrowserStore.NOTIFICATION.REQUEST_BASIC_AUTHORIZATION) {
            browser.cancelBasicAuth();
            return;
        }

        if ((browser.networkState === BrowserStore.NOTIFICATION.DISCONNECTED)
            || (browser.networkState === BrowserStore.NOTIFICATION.SESSION_PAUSED)
            || (browser.networkState === BrowserStore.NOTIFICATION.ANOTHER_TAB_THREAT_DETECTED)
            || (browser.networkState === BrowserStore.NOTIFICATION.TRYING_TO_CONNECT)) {
            browser.toggleNotification(browser.networkState);
        } else {
            browser.toggleNotification(BrowserStore.NOTIFICATION.DEFAULT_RIGHT);
        }
    };

    onClickOutside = () => {
        const { browser } = this.props;

        if ([
            BrowserStore.NOTIFICATION.DEFAULT_RIGHT,
            BrowserStore.NOTIFICATION.NEW_CONFIRM_EXIT_ISOLATION,
        ].includes(browser.notification)) {
            browser.closeNotification();
        }
    };

    renderCorner() {
        const { browser } = this.props;
        const { icon, centered, iconMods } = this.getCorner();
        const open = !!browser.notification && !!this[browser.notification];
        const fade = !!browser.notification && !this[browser.notification];
        const minified = browser.isTopBarMinificationAllowed();

        const titleText = this.getCornerTitle();

        return icon ? <FuseButton
            id={this.cornerId}
            aria-expanded={open}
            aria-controls={this.popoverId}
            className={block('Corner', {
                right: true,
                open,
                centered,
                fade,
                minified,
            })}
            aria-label={titleText}
            title={titleText}
            type={'quaternary'}
            icon={icon}
            iconClassName={block('CornerIcon', { ...iconMods, minified })}
            onClick={this.onCornerClick}
            {...!centered ? {
                noCenter: true,
                iconRight: 'arrow_drop_down',
                iconRightClassName: cn(block('Caret'), b.lock('TopBarCaret')({
                    active: open,
                    minified,
                    iconOnly: true,
                })),
            } : {}}
        /> : null;
    }

    render() {
        return <Fragment>
            {this.renderCorner()}
            {this.renderDropdown()}
        </Fragment>;
    }
}

export default translate()(inject('browser', 'env', 'snack')(observer(RightNotification)));
