import throttle from 'lodash/throttle';
import debug from '../../debug';
import { makeSafePropName } from '../../DOMUtils';
import { IS_CLIENT, IS_EXTENSION, supportsPassiveEvents } from '../../env';
import { TAG_BODY, isRoot } from '../DOM';
import { API_CLIENT_TO_BROWSER, F_VALUE } from '../sharedConstants';
import AbstractWatcher from './AbstractWatcher';
import reg from '../DOMRegistry';
import { messageToBrowser } from '../client/browserMessageBus';

const logger = debug.create('scrollWatcher', false);

const scrollListenerOptions = supportsPassiveEvents ? { passive: true } : false;

// send more scroll updates from server to client for nicer visual
const THROTTLE = IS_CLIENT ? 160 : 40;

function normalizeValue(value) {
    return [
        Math.max(0, Math.floor(value[0])),
        Math.max(0, Math.floor(value[1])),
        value[2],
    ];
}

function addStyle(doc, id, css) {
    let styleEl = doc.getElementById(id);
    if (!styleEl) {
        styleEl = doc.createElement('style');
        styleEl.id = id;
        (doc.head || doc.body || doc.documentElement).appendChild(styleEl);
    }
    styleEl.innerHTML = css;
}

function fixBodyOverflow(doc) {
    if (doc && doc.body) {
        const computedStyle = window.getComputedStyle(doc.body);
        if (computedStyle.overflow === 'auto') {
            addStyle(doc, makeSafePropName('MIRROR_BODY_OVERFLOW_FIX'),
                'html > body { overflow: visible !important }');
        }
    }
}

if (IS_EXTENSION) {
    setTimeout(() => addStyle(document, makeSafePropName('MIRROR_SCROLL_BEHAVIOR'),
        '* { scroll-behavior: auto !important }'));
}

// node value format: [scrollTop, scrollLeft, actionCounter]
export class ScrollWatcher extends AbstractWatcher {
    shouldWatch(node, tag) {
        if (tag === TAG_BODY) {
            return false; // watching HTML instead of BODY
        }

        const head = node.ownerDocument && node.ownerDocument.head;
        if (head && head.contains(node)) {
            return false; // ignore scrolls on head nodes
        }

        return true;
    }

    compareValues(a, b) {
        if (a === b) {
            return true;
        }
        if (!a || !b) {
            return false;
        }

        return a[0] === b[0] && a[1] === b[1];
    }

    afterRegister(node, uid) {
        this.valueByUid[uid] = [0, 0, 0];

        const throttleEmitChange = throttle(() => this.emitChange(node, this.valueByUid[uid]),
            { wait: THROTTLE, leading: false, trailing: true });

        // not using checkNode because there is more difference than similarity
        const onScroll = () => {
            if (node.__IGNORE_SCROLL || !this.valueByUid[uid]) {
                return;
            }

            const value = this.getValue(node);
            const oldValue = this.valueByUid[uid];
            this.valueByUid[uid] = value;

            if (this.compareValues(value, oldValue)) {
                return;
            }

            if (this.IS_CLIENT) {
                this.valueByUid[uid][2] += 1; // increment action counter

                messageToBrowser(API_CLIENT_TO_BROWSER.windowScroll, { pageYOffset: node.scrollTop });
            }

            throttleEmitChange();
        };

        let target = node;
        if (isRoot(node)) {
            if (IS_EXTENSION) {
                fixBodyOverflow(node.ownerDocument);
            }

            target = node.ownerDocument.defaultView;
        }

        // this should never happen™
        if (!target) {
            logger.error('this should never happen: no target for scrollWatcher', uid, node);
            return;
        }

        reg.addEventListener(target, 'scroll', onScroll, scrollListenerOptions);
    }

    getValue(node) {
        const currentValue = this.valueByUid[reg.getUid(node)];

        if (isRoot(node)) {
            if (!node.ownerDocument.defaultView) {
                return currentValue || [0, 0, 0];
            }
            const { pageXOffset, pageYOffset } = node.ownerDocument.defaultView;

            logger.info('root getValue:', [node.scrollTop, node.scrollLeft], node);

            return normalizeValue([pageYOffset, pageXOffset, currentValue[2]]);
        }

        logger.info('getValue:', [node.scrollTop, node.scrollLeft], node);

        return normalizeValue([node.scrollTop, node.scrollLeft, currentValue[2]]);
    }

    setValue(node, uid, [scrollTop, scrollLeft, actionCounter]) {
        const currentValue = this.valueByUid[reg.getUid(node)];
        const currentActionCounter = currentValue[2];
        currentValue[2] = Math.max(actionCounter, currentActionCounter);

        if (IS_CLIENT && (actionCounter < currentActionCounter)) {
            return;
        }

        if (IS_EXTENSION) {
            // ignore clients scroll only if all scroll is disabled
            // it's not worth to scroll only one "unlocked" axis
            const style = window.getComputedStyle(node);
            if ((style.overflowX === 'hidden') && (style.overflowY === 'hidden')) {
                this.checkNode(node, true);
                return;
            }
        }

        logger.info('setValue:', [scrollTop, scrollLeft], node);

        if (isRoot(node)) {
            if (node.ownerDocument.defaultView) {
                node.ownerDocument.defaultView.scrollTo(scrollLeft, scrollTop);
            }
        } else {
            node.scrollTop = scrollTop;
            node.scrollLeft = scrollLeft;
        }
        this.valueByUid[uid] = this.getValue(node);
    }
}

export default new ScrollWatcher(F_VALUE.scroll, false);
