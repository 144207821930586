import isEq from 'lodash/isEqual';
import { IS_CLIENT, IS_EXTENSION } from '../env';
import debug from '../debug';
import reg from './DOMRegistry';
import { getDocumentActiveElement, getRoot, getRootNode } from '../DOMUtils';
import { REMOVED_ATTRIBUTE } from './sharedConstants';
import { Mod } from './userInput';

const logger = debug.create('DOM', false);

export const TAG_A = 'A';
export const TAG_AREA = 'AREA';
export const TAG_APPLET = 'APPLET';
export const TAG_AUDIO = 'AUDIO';
export const TAG_BASE = 'BASE';
export const TAG_BODY = 'BODY';
export const TAG_BR = 'BR';
export const TAG_CANVAS = 'CANVAS';
export const TAG_DATALIST = 'DATALIST';
export const TAG_EMBED = 'EMBED';
export const TAG_FONT = 'FONT';
export const TAG_FORM = 'FORM';
export const TAG_FRAME = 'FRAME';
export const TAG_IFRAME = 'IFRAME';
export const TAG_IMAGE = 'IMAGE';
export const TAG_IMG = 'IMG';
export const TAG_INPUT = 'INPUT';
export const TAG_ISINDEX = 'ISINDEX';
export const TAG_HEAD = 'HEAD';
export const TAG_HTML = 'HTML';
export const TAG_LINK = 'LINK';
export const TAG_META = 'META';
export const TAG_NOEMBED = 'NOEMBED';
export const TAG_NOFRAMES = 'NOFRAMES';
export const TAG_NOSCRIPT = 'NOSCRIPT';
export const TAG_OBJECT = 'OBJECT';
export const TAG_OPTION = 'OPTION';
export const TAG_PARAM = 'PARAM';
export const TAG_SCRIPT = 'SCRIPT';
export const TAG_SELECT = 'SELECT';
export const TAG_SLOT = 'SLOT';
export const TAG_SOURCE = 'SOURCE';
export const TAG_STYLE = 'STYLE';
export const TAG_TEMPLATE = 'TEMPLATE';
export const TAG_TEXTAREA = 'TEXTAREA';
export const TAG_TRACK = 'TRACK';
export const TAG_VIDEO = 'VIDEO';

export function normalizeTagName(node) {
    if (node && node.tagName && node.tagName.toUpperCase) {
        return node.tagName.toUpperCase();
    }

    if (node && node.tagName) {
        debug.recordError(new Error('Element has tagName that is no a string'), {
            // eslint-disable-next-line no-proto
            nodeProto: node.__proto__ ? node.__proto__.toString() : 'noproto!',
            tagNameToString: node.tagName.toString ? node.tagName.toString() : 'notostring!',
            nodeConstructorName: (node.constructor ? node.constructor.name : '') || 'noname!',
            nodeConstructorToString: (node.constructor ? node.constructor.toString() : '') || 'noconstructorstring!',
            outerHTML: (node.outerHTML && node.outerHTML.slice) ? node.outerHTML.slice(0, 100) : 'noouterhtml!',
            isConnected: node.isConnected !== undefined ? node.isConnected : 'undefined!',
            tagName: node.tagName,
            tagNameType: typeof node.tagName,
            toUpperCaseType: typeof node.tagName.toUpperCase,
            toStringType: typeof node.tagName.toString,
        });
    }

    return 'WEBLIFEINVALID';
}

export function isElementOneOf(node, ...tagNames) {
    return tagNames.indexOf(normalizeTagName(node)) !== -1;
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Browser_compatibility
export const INPUT_TYPE_BUTTON = 'button';
export const INPUT_TYPE_CHECKBOX = 'checkbox';
export const INPUT_TYPE_COLOR = 'color';
export const INPUT_TYPE_DATE = 'date';
export const INPUT_TYPE_DATETIME_LOCAL = 'datetime-local';
export const INPUT_TYPE_EMAIL = 'email';
export const INPUT_TYPE_FILE = 'file';
export const INPUT_TYPE_HIDDEN = 'hidden';
export const INPUT_TYPE_IMAGE = 'image';
export const INPUT_TYPE_MONTH = 'month';
export const INPUT_TYPE_NUMBER = 'number';
export const INPUT_TYPE_PASSWORD = 'password';
export const INPUT_TYPE_RADIO = 'radio';
export const INPUT_TYPE_RANGE = 'range';
export const INPUT_TYPE_RESET = 'reset'; // TODO: rest behavior
export const INPUT_TYPE_SEARCH = 'search';
export const INPUT_TYPE_SUBMIT = 'submit';
export const INPUT_TYPE_TEL = 'tel';
export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_TIME = 'time';
export const INPUT_TYPE_URL = 'url';
export const INPUT_TYPE_WEEK = 'week';

export const { TEXT_NODE, ELEMENT_NODE, DOCUMENT_FRAGMENT_NODE } = window.Node;

export function normalizeInputType(node) {
    if (node && (typeof node.type === 'string')) {
        return node.type.toLowerCase();
    }
}

export function isFileInput(node) {
    return node
        && normalizeTagName(node) === TAG_INPUT
        && normalizeInputType(node) === INPUT_TYPE_FILE;
}

export function walkDOM(node, cb) {
    if (!node) {
        return;
    }
    // traverse node children ONLY if callback returns truth
    if (cb(node)) {
        walkDOM(node.shadowRoot, cb);

        node = node.firstChild;
        while (node) {
            const _node = node;
            node = node.nextSibling;
            walkDOM(_node, cb);
        }
    }
}

export function isRoot(node) {
    if (node && node.ownerDocument) {
        return node === getRoot(node.ownerDocument);
    }
    return false;
}

export function removeNode(node) {
    if (node && node.parentNode) {
        node.parentNode.removeChild(node);
    }
}

export function activeElementFromNode(node) {
    if (node && node.ownerDocument) {
        return getDocumentActiveElement(node.ownerDocument);
    }
}

export function isTextNode(node) {
    return !!(node && node.nodeType === TEXT_NODE);
}

export function isBlockerCssLinkNode(node) {
    if (isElementOneOf(node, TAG_LINK)) {
        if (!node.rel || node.rel !== 'stylesheet' || !node.href) {
            return false;
        }
        if (node.disabled || node.defer || node.async) {
            return false;
        }
        if (node.media && node.media !== 'all' && window.matchMedia(node.media)) {
            return false;
        }
        return true;
    } else {
        return false;
    }
}

export function isElementNode(node) {
    return !!(node && node.nodeType === ELEMENT_NODE);
}

export function isDocumentFragmentNode(node) {
    return node?.nodeType === DOCUMENT_FRAGMENT_NODE;
}

export function isImageNode(node) {
    return normalizeTagName(node) === TAG_IMG;
}

export function isEditable(node) {
    if (!node) {
        return false;
    }
    if (isTextNode(node)) {
        node = node.parentNode;
    }
    return node.isContentEditable || isElementOneOf(node, TAG_INPUT, TAG_TEXTAREA);
}

// proper contentEditable check
// NOTE: in IE11 textarea and input have isContentEditable set to true
export function isCE(node) {
    if (!node) {
        return false;
    }
    if (isTextNode(node)) {
        node = node.parentNode;
    }
    if (node && node.isContentEditable) {
        return !isElementOneOf(node, TAG_INPUT, TAG_TEXTAREA);
    }
    return false;
}

export function supportsSelection(node) {
    const tag = normalizeTagName(node);
    if (tag === TAG_TEXTAREA) {
        return true;
    }
    if (tag === TAG_INPUT) {
        const type = normalizeInputType(node);
        return type === INPUT_TYPE_TEXT
            || type === INPUT_TYPE_SEARCH
            || type === INPUT_TYPE_PASSWORD
            || type === INPUT_TYPE_URL
            || type === INPUT_TYPE_TEL;
    }
    return false;
}

export function isInputWithoutSelectionSupport(node) {
    return !!node && (normalizeTagName(node) === TAG_INPUT) && !supportsSelection(node);
}

// focus topmost parent of node that is CE, do nothing if one of node parent's is the activeElement
function focusGrandestCEParent(node) {
    const activeEl = activeElementFromNode(node);
    let iterNode = isTextNode(node) ? node.parentNode : node;
    while (iterNode) {
        if (!isCE(iterNode) || iterNode === activeEl) {
            return;
        }
        // if parentNode is not CE, current node is the grandest CE parent
        if (!isCE(iterNode.parentNode)) {
            break;
        }
        iterNode = iterNode.parentNode;
    }
    if (iterNode && iterNode.focus) {
        iterNode.focus();
    }
}


// selection micro-format: [selectionType, ...selection data]
const SELECTION_TYPE_INPUT = 0;
const SELECTION_TYPE_TEXT = 1;
const SELECTION_TYPE_FOCUS = 2;

export function isInputSelection(selection) {
    return selection && selection[0] === SELECTION_TYPE_INPUT;
}

export function isTextSelection(selection) {
    return selection && selection[0] === SELECTION_TYPE_TEXT;
}

export function isFocusSelection(selection) {
    return selection && selection[0] === SELECTION_TYPE_FOCUS;
}


export function getInputSelection(node) {
    const nodeId = reg.getNodeId(node);
    if (nodeId) {
        return [
            SELECTION_TYPE_INPUT,
            nodeId,
            node.selectionStart,
            node.selectionEnd,
        ];
    }
}

/**
 * @param  {Document} doc
 * @returns {number[]}
 */
export function getCESelection(doc) {
    const activeElement = getDocumentActiveElement(doc);
    if (activeElement && activeElement.ownerDocument && isCE(activeElement)) {
        const root = getRootNode(activeElement);
        const sel = root.getSelection ? root.getSelection() : activeElement.ownerDocument.getSelection();

        if (sel && sel.rangeCount > 0) {
            const range = sel.getRangeAt(0);
            if (range) {
                const startId = reg.getNodeId(range.startContainer) || reg.getClientNodeId(range.startContainer);
                const endId = reg.getNodeId(range.endContainer) || reg.getClientNodeId(range.endContainer);
                if (startId && endId) {
                    return [SELECTION_TYPE_TEXT, startId, range.startOffset, endId, range.endOffset];
                }
            }
        }
    }
}

export function getSelection(doc) {
    try {
        const activeElement = getDocumentActiveElement(doc);
        const activeElementId = reg.getNodeId(activeElement);
        if (!activeElementId) {
            return;
        }

        if (supportsSelection(activeElement)) {
            return getInputSelection(activeElement);
        }

        if (isCE(activeElement)) {
            return getCESelection(doc);
        }

        return [SELECTION_TYPE_FOCUS, activeElementId];
    } catch (e) {
        debug.warn('DOM.getSelection error', e, doc);
    }
}

export function focusDocument(doc) {
    if (doc && doc.defaultView && doc.defaultView.focus) {
        try {
            doc.defaultView.focus();
        } catch (e) {
            // IE 11 issue
            debug.error(e);
        }
    }
}

export function clearSelectionFromDocument(doc) {
    try {
        doc.getSelection().removeAllRanges();
    } catch (e) {
        logger.warn(e, 'clearSelectionFromDocument');
    }
}

export function setSelection(selection, frameId) {
    if (!selection) {
        return true;
    }

    try {
        const node = reg.getNode(frameId, selection[1]);
        const doc = node && node.ownerDocument;
        if (!node || !doc) {
            logger.warn('setSelection: No node node to set selection', frameId, selection);
            return false;
        }

        const currentSelection = getSelection(doc);

        if (isEq(currentSelection, selection)) {
            logger.log('setSelection', 'Selection is the same', frameId, currentSelection, selection);
            return true;
        }

        if (isInputSelection(selection)) {
            if (supportsSelection(node)) {
                node.focus();
                node.selectionStart = selection[2];
                node.selectionEnd = selection[3];
                return true;
            }

            // hacks for email inputs on ext
            if (IS_EXTENSION && (normalizeTagName(node) === TAG_INPUT)) {
                const { type } = node;
                node.focus();
                node.type = 'text';
                node.setSelectionRange(selection[2], selection[3]);
                node.type = type;
                return true;
            }
        } else if (isFocusSelection(selection)) {
            if (normalizeTagName(node) === TAG_IFRAME) {
                logger.warn('setSelection: no iframe selection — drop current focus', frameId, selection);
                return false;
            }

            if (typeof node.focus === 'function') {
                if (activeElementFromNode(node) !== node) {
                    node.focus();
                } else {
                    logger.log('setSelection: node has already had a focus. Do NOT call node.focus()');
                }

                return true;
            }

            logger.warn('setSelection: no focus method', frameId, selection, node);
            return false;
        } else if (isTextSelection(selection)) {
            const startNode = node;
            const endNode = reg.getNode(frameId, selection[3]);
            if (endNode) {
                // fix for Edge, issue #1306
                if (IS_CLIENT) {
                    focusGrandestCEParent(endNode);
                }

                const sel = doc.getSelection();
                if (!sel) {
                    logger.warn('setSelection: No selection to set selection', frameId, selection);
                    return false;
                }
                const range = doc.createRange();
                range.setStart(startNode, startNode.nodeType === TEXT_NODE
                    ? Math.min(startNode.length, selection[2])
                    : selection[2]);
                range.setEnd(endNode, endNode.nodeType === TEXT_NODE
                    ? Math.min(endNode.length, selection[4])
                    : selection[4]);
                sel.removeAllRanges();
                sel.addRange(range);

                // debug.info('set selection ce',
                //     JSON.stringify(startNode.innerText || startNode.nodeValue),
                //     startNode.nodeType === Node.TEXT_NODE
                //         ? Math.min(startNode.length, selection[2])
                //         : selection[2]
                // );
            } else {
                logger.warn('setSelection: No endNode to set selection', frameId, selection);
                return false;
            }
        }
    } catch (e) {
        debug.error('setSelection error', e, selection);
    }

    return true;
}


export function getDoctypeString(doc) {
    return doc.doctype ? new window.XMLSerializer().serializeToString(doc.doctype) : '';
}


export function setAttribute(node, name, value, ns) {
    // TODO: get rid of try catch by server attr filtering
    try {
        if (value === REMOVED_ATTRIBUTE || value === null) {
            node.removeAttribute(name);
        } else if (ns !== undefined && ns !== null) {
            node.setAttributeNS(ns, name, value);
        } else {
            node.setAttribute(name, value);
        }
    } catch (e) {
        debug.warn('failed setAttribute', { name, value, ns, e });
    }
}


// COORDS
const toPercentage = (x) => ((x * (10 ** 8)) | 0) / (10 ** 6); // 0.2(3) => 23.333333
const rectToCoordinates = (rect, event) => ([
    rect.width ? toPercentage((event.clientX - rect.left) / rect.width) : 0, // x
    rect.height ? toPercentage((event.clientY - rect.top) / rect.height) : 0, // y
]);

export function getPercentageCoordsFromMouseEvent(event, relativeTarget) {
    relativeTarget = relativeTarget || (event && event.target);
    if (!event || !relativeTarget || !relativeTarget.getBoundingClientRect) {
        debug.warn('getPercentageCoordsFromMouseEvent failed', event, event && event.target, relativeTarget);
        return [0, 0];
    }

    const rect = relativeTarget.getBoundingClientRect();
    return rectToCoordinates(rect, event);
}

export function getPercentageCoordsFromDragDropEvent(event) {
    const target = event && event.target;

    if (!event || !target) {
        debug.warn('getPercentageCoordsFromDragDropEvent failed', event, target);
        return [0, 0];
    }

    let rect;
    if (isTextNode(target)) {
        const range = document.createRange();
        range.selectNode(target);
        rect = range.getBoundingClientRect();
    } else {
        rect = target.getBoundingClientRect();
    }

    return rectToCoordinates(rect, event);
}

// SAFE JSON
export function escapeJSON(jsonString) {
    return jsonString
        .replace(/\u2028/g, '\\u2028') // linebreak break js parser
        .replace(/\u2029/g, '\\u2029') // another linebreak
        .replace(/</g, '\\u003C'); // breaks html parser e.g. "</script>"
}


// Synthetic events
export function dispatchChangeEvent(node) {
    if (node) {
        node.dispatchEvent(new window.Event('change', { bubbles: true, cancelable: false }));
    }
}

export function dispatchInputEvent(node) {
    if (node) {
        // added composed property for polymer elements so that event can cross from the shadow DOM bug#4808
        // from MDN
        // > All UA-dispatched UI events are composed
        // (click/touch/mouseover/copy/paste, etc.)
        // most other types of events are not composed and so will return false,
        // for example synthetic events that have been created
        // without their composed option being set to true.
        node.dispatchEvent(new window.Event('input', { bubbles: true, cancelable: false, composed: true }));
    }
}
/**
 * Send synthetic mouse event
 * @param {Node} node
 * @param {String} type
 * @param {Number} mod
 * @param {Number} clientX
 * @param {Number} clientY
 */
export function dispatchMouseEvent(node, type, mod, clientX, clientY) {
    const event = new window.MouseEvent(type, {
        bubbles: true,
        cancelable: true,
        clientX,
        clientY,
        screenX: clientX,
        screenY: clientY,
        ctrlKey: mod & Mod.CTRL,
        altKey: mod & Mod.ALT,
        metaKey: mod & Mod.META,
        shiftKey: mod & Mod.SHIFT,
    });

    return node.dispatchEvent(event);
}

/**
 * Send synthetic touch event
 * @param {Node} node
 * @param {String} type
 * @param {Number} mod
 * @param {Array} touchesData
 */
export function dispatchTouchEvent(node, type, mod, touchesData) {
    type = type.toLowerCase();
    const isTouchEnd = ['touchend', 'touchcancel'].includes(type);

    const touchList = touchesData.map((touchData) => {
        return new window.Touch({
            identifier: touchData.id,
            target: node,
            force: touchData.force,
            radiusX: touchData.radiusX,
            radiusY: touchData.radiusY,
            clientX: touchData.x,
            clientY: touchData.y,
            screenX: touchData.x,
            screenY: touchData.y,
        });
    });

    const event = new window.TouchEvent(type, {
        bubbles: true,
        cancelable: true,
        ctrlKey: mod & Mod.CTRL,
        altKey: mod & Mod.ALT,
        metaKey: mod & Mod.META,
        shiftKey: mod & Mod.SHIFT,
        touches: isTouchEnd ? [] : touchList,
        targetTouches: touchList,
        changedTouches: touchList,
    });

    return node.dispatchEvent(event);
}

// "once" for event that will happen before "setTimeout" triggers
// e.g. catch "input" after "keypress", but before next "keypress"
export function onTheVeryNextEvent(
    target, eventName, fn, capture = false, wait = 0, timeOutFn = () => { }
) {
    let listener;
    let timeout;

    const removeListener = () => target.removeEventListener(eventName, listener, capture);

    listener = (...args) => {
        removeListener();
        clearTimeout(timeout);
        return fn(...args);
    };

    target.addEventListener(eventName, listener, capture);

    timeout = setTimeout(() => {
        removeListener();

        if (timeOutFn) {
            timeOutFn();
        }
    }, wait);
}

// detecting mouse over scrollbar
function lazy(fn) {
    let res;
    return () => {
        if (res === undefined) {
            res = fn();
        }
        return res;
    };
}

function isScrollOverflow(overflow) {
    return overflow === 'scroll' || overflow === 'auto' || overflow === 'overlay';
}

const DEFAULT_OVERLAY_SCROLLBAR_SIZE = 18; // an expected default scrollbar size in case of overflow-x/y=overlay
function getOverlayScrollbarSize(target, property) {
    const scrollbarStyles = window.getComputedStyle(target, '::-webkit-scrollbar');

    // +1 for handling clicks on scroll borders
    return (parseInt(scrollbarStyles[property], 10) || DEFAULT_OVERLAY_SCROLLBAR_SIZE) + 1;
}

export function isScrollBarInteraction(target, clientX, clientY) {
    const getRect = lazy(() => target.getBoundingClientRect());
    const getStyle = lazy(() => window.getComputedStyle(target));
    // Note: <html> will have a scroll bar and overflow 'visible'
    // vertical scroll
    if ((target.clientHeight > 5) // sanity check for 0 by 0 elements
        && (target.scrollHeight > target.clientHeight)
        && (isRoot(target) || isScrollOverflow(getStyle().overflowY))
    ) {
        let clickXPos = clientX - getRect().left;

        if (getStyle().overflowY === 'overlay') {
            clickXPos += getOverlayScrollbarSize(target, 'width');
        }

        if (clickXPos > target.clientWidth) {
            // debug.info('mouse on vertical scrollbar', event, getRect(), getStyle().overflowY);
            return true;
        }
    }
    // horizontal scroll
    if ((target.clientWidth > 5)
        && (target.scrollWidth > target.clientWidth)
        && (isRoot(target) || isScrollOverflow(getStyle().overflowX))
    ) {
        let clickYPos = clientY - getRect().top;

        if (getStyle().overflowX === 'overlay') {
            clickYPos += getOverlayScrollbarSize(target, 'height');
        }

        if (clickYPos > target.clientHeight) {
            // debug.info('mouse on horizontal scrollbar', event, getRect(), getStyle().overflowX);
            return true;
        }
    }
    return false;
}


const IGNORE_INPUT_TYPES = new Set([
    INPUT_TYPE_COLOR,
    INPUT_TYPE_DATE,
    INPUT_TYPE_DATETIME_LOCAL,
    INPUT_TYPE_MONTH,
    INPUT_TYPE_RANGE,
    INPUT_TYPE_TIME,
    INPUT_TYPE_WEEK,
]);

export function shouldIgnoreInteractionsWithNode(node) {
    const tagName = normalizeTagName(node);
    if (tagName === TAG_OPTION || tagName === TAG_SELECT) {
        return true;
    }
    if (tagName === TAG_INPUT && IGNORE_INPUT_TYPES.has(node.type)) {
        return true;
    }
    return false;
}
