import PropTypes from 'prop-types';
import { action, extendObservable } from 'mobx';

let singleton = null;

export default class SnackStore {
    static PropType = PropTypes.shape({
        showSnack: PropTypes.func.isRequired,
        showError: PropTypes.func.isRequired,
        showSuccess: PropTypes.func.isRequired,
    });

    constructor(data = {}) {
        if (singleton) {
            return singleton;
        }

        this.nextId = 1;

        extendObservable(this, /** @class SnackStore */{
            current: null,
        }, data);

        singleton = this;
    }

    hideSnack = action((id) => {
        if (this.current && this.current.id === id) {
            this.current = null;
        }
    });

    hideCurrentSnack = action(() => {
        this.current = null;
    });

    showSnack = action(({ content, type = 'error', autoDismiss }) => {
        const id = this.nextId;
        this.nextId += 1;
        this.current = { id, content, type };
        if (autoDismiss) {
            setTimeout(this.hideSnack, autoDismiss * 1000, id);
        }
    });

    showError = action((content) =>
        this.showSnack({ content, type: 'error' }));

    showSuccess = action((content) =>
        this.showSnack({ content, type: 'success', autoDismiss: 5 }));
}
