// https://support.apple.com/en-us/HT201236 - RTFM Mac shortcuts
export const IS_MAC = /mac/i.test(navigator.platform);

export function modifierFromEvent(event, asList = false) {
    if (asList) {
        return [
            event.altKey | 0,
            event.ctrlKey | 0,
            event.metaKey | 0,
            event.shiftKey | 0,
        ];
    }
    return (1 * !!event.altKey) + (2 * !!event.ctrlKey) + (4 * !!event.metaKey) + (8 * !!event.shiftKey);
}


// keyboard modifiers
export const Mod = {
    NONE: modifierFromEvent({}),
    CTRL: modifierFromEvent({ ctrlKey: true }),
    META: modifierFromEvent({ metaKey: true }),
    SHIFT: modifierFromEvent({ shiftKey: true }),
    ALT: modifierFromEvent({ altKey: true }),
    CTRL_SHIFT: modifierFromEvent({ ctrlKey: true, shiftKey: true }),
    META_SHIFT: modifierFromEvent({ metaKey: true, shiftKey: true }),
    CTRL_ALT: modifierFromEvent({ ctrlKey: true, altKey: true }),
    META_ALT: modifierFromEvent({ metaKey: true, altKey: true }),
    SHIFT_ALT: modifierFromEvent({ shiftKey: true, altKey: true }),
    CTRL_SHIFT_ALT: modifierFromEvent({ ctrlKey: true, shiftKey: true, altKey: true }),
    META_SHIFT_ALT: modifierFromEvent({ metaKey: true, shiftKey: true, altKey: true }),
    META_CTRL: modifierFromEvent({ metaKey: true, ctrlKey: true }),

    fromEvent: modifierFromEvent,

    oneOf: (mod, ...mods) => (mods.indexOf(mod) !== -1),
};


// keyboard key codes
export const Key = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    CTRL: 17,
    ALT: 18,
    PAUSE_BREAK: 19,
    CAPS_LOCK: 20,
    ESC: 27,
    SPACE: 32,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    PRINT_SCREEN: 44,
    DELETE: 46,
    NUM_0: 48,
    NUM_1: 49,
    NUM_9: 57,
    A: 65,
    B: 66,
    C: 67,
    E: 69,
    F: 70,
    I: 73,
    J: 74,
    K: 75,
    N: 78,
    O: 79,
    P: 80,
    T: 84,
    U: 85,
    V: 86,
    X: 88,
    SYSTEM1: 91, // windows key / left ⌘
    SYSTEM2: 92, // right window key
    SYSTEM3: 93, // windows menu / right ⌘
    F1: 112,
    F4: 115,
    F12: 123,
    NUM_LOCK: 144,
    SCROLL_LOCK: 145,
    PROCESS: 229,

    isNumber: (key) => ((key >= Key.NUM_0) && (key <= Key.NUM_9)),
    isArrow: (key) => ((key >= Key.LEFT) && (key <= Key.DOWN)),
    isFunctionKey: (key) => ((key >= Key.F1) && (key <= Key.F12)),

    oneOf: (key, ...keys) => (keys.indexOf(key) !== -1),
};


// systems keys that do not move caret nor change text - should not mirror from client to server
const harmlessSystemKeys = [
    Key.PAUSE_BREAK,
    Key.CAPS_LOCK,
    Key.PRINT_SCREEN,
    Key.SYSTEM1,
    Key.SYSTEM2,
    Key.SYSTEM3,
    Key.NUM_LOCK,
    Key.SCROLL_LOCK,
];

export function isHarmlessSystemKey(key, mod) {
    if (Key.isFunctionKey(key)) {
        return true;
    }

    // Mac emoji keyboard CMD + CTRL + SPACE
    if (key === Key.SPACE && mod === Mod.META_CTRL) {
        return true;
    }

    return harmlessSystemKeys.indexOf(key) !== -1;
}


// Mac specific shortcuts that modify text and could not be mirrored
const macTextAlteringKeysWithCtrl = [
    Key.K, // Delete the text between the insertion point and the end of the line or paragraph.
    Key.O, // Insert a new line after the insertion point.
    Key.T, // Swap the character behind the insertion point with the character in front of the insertion point.
];
export function isMacTextAlteringShortcut(key, mod) {
    if (IS_MAC) {
        if (mod === Mod.CTRL && (macTextAlteringKeysWithCtrl.indexOf(key) !== -1)) {
            return true;
        }
        if (mod === Mod.META && key === Key.BACKSPACE) {
            // Delete text between the insertion point and the begging of the line.
            return true;
        }
    }
    return false;
}


// Mac specific shortcuts that change caret position and/or select text
const macSelectionAlteringKeysWithCtrl = [
    Key.A, // Move to the beginning of the line or paragraph.
    Key.E, // Move to the end of a line or paragraph.
    Key.F, // Move one character forward.
    Key.B, // Move one character backward.
    Key.P, // Move up one line.
    Key.N, // Move down one line.
];
export function isMacSelectionAlteringShortcut(key, mod) {
    if (IS_MAC) {
        if (mod === Mod.CTRL && (macSelectionAlteringKeysWithCtrl.indexOf(key) !== -1)) {
            return true;
        }
    }
    return false;
}


// keys and shortcuts that could not change user input
const safeNonEditingKeys = [
    Key.SHIFT,
    Key.CTRL,
    Key.ALT,
    Key.ESC,
    Key.PAGE_UP,
    Key.PAGE_DOWN,
    Key.END,
    Key.HOME,
    Key.HOME,
];
export function isSafeNonEditingKey(key, mod) {
    if (Key.isArrow(key)) {
        return true;
    }
    if (safeNonEditingKeys.indexOf(key) !== -1) {
        return true;
    }
    if (isMacSelectionAlteringShortcut(key, mod)) {
        return true;
    }
    return false;
}
