import { action, extendObservable } from 'mobx';
import PropTypes from 'prop-types';

// TODO: rename methods to "onError", "onLoading" etc, or rename "onSuccess"
export default class AsyncActionStore {
    static PropType = PropTypes.shape({
        label: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isFailed: PropTypes.bool.isRequired,
        error: PropTypes.object,
    });

    static resolved(result) {
        return new AsyncActionStore({
            result,
            successCount: 1,
        });
    }

    static rejected(error) {
        return new AsyncActionStore({
            isFailed: true,
            error,
            errorCount: 1,
        });
    }

    constructor(data = {}) {
        extendObservable(this, /** @class AsyncActionStore */{
            label: 'generic asyncActionStore',
            isLoading: false,
            isFailed: false,
            error: null,
            errorCount: 0,
            result: null,
            successCount: 0,
            promise: null,
        }, data);
    }

    setBase = action(() => {
        this.isLoading = false;
        this.isFailed = false;
        this.error = null;
        this.errorCount = 0;
        this.result = null;
        this.successCount = 0;
        this.promise = null;
    });

    setLoading = action((promise, persistResult = false, delay) => {
        this.isLoading = true;
        this.isFailed = false;
        this.error = null;
        if (!persistResult) {
            this.result = null;
        }
        this.promise = promise;

        if (this.promise) {
            this.promise = this.promise
                .then(async (res) => {
                    if (delay != null) {
                        await new Promise((callback) => setTimeout(callback, delay));
                    }
                    this.onSuccess(res);
                    return res;
                })
                .catch((err) => {
                    this.onError(err);
                    throw err;
                });
        }

        return this.promise;
    });

    setFailed = action((error) => {
        this.isLoading = false;
        this.isFailed = true;
        this.error = error;
        this.errorCount += 1;
        this.result = null;
        this.promise = null;
    });

    onError = this.setFailed;

    onSuccess = action((result) => {
        this.isLoading = false;
        this.isFailed = false;
        this.error = null;
        this.result = result;
        this.successCount += 1;
        this.promise = null;
    });
}
