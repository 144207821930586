import React, { Component } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import FuseTooltip from './FuseTooltip';
import Icon from '../../Icon/Icon';
import './FuseInfoTooltip.css';

const block = b.lock('FuseInfoTooltip');

const TAB_KEY_CODE = 9;
const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

class FuseInfoTooltip extends Component {
    static propTypes = {
        position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
        className: PropTypes.string,
        inline: PropTypes.bool,
    };

    onKeyDown = (event) => {
        if (event.keyCode === TAB_KEY_CODE) {
            this.tooltip.hide();
        }
        if (event.keyCode === ENTER_KEY_CODE || event.keyCode === SPACE_KEY_CODE) {
            this.tooltip.toggle();
            event.preventDefault();
        }
    };

    onTooltipRef = (node) => {
        this.tooltip = node;
    };

    render() {
        const { position, className, inline, children } = this.props;

        return <FuseTooltip
            content={children}
            position={position}
            className={className}
            inline={inline}
            ref={this.onTooltipRef}
        >
            <div tabIndex={0} className={block('TooltipTrigger')} onKeyDown={this.onKeyDown}>
                <Icon tabIndex={'-1'} className={block('TooltipTriggerIcon', { hover: false })} type={'info_outline'} />
                <Icon tabIndex={'-1'} className={block('TooltipTriggerIcon', { hover: true })} type={'info'} />
            </div>
        </FuseTooltip>;
    }
}

export default FuseInfoTooltip;
