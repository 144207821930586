import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import b from 'b_';
import cn from 'classnames';
import FuseButton from '../FuseControls/FuseButton';
import './ConsoleModal.css';

const block = b.lock('ConsoleModal');

export default function ConsoleModal(props) {
    const {
        children,
        onClose,
        title,
        closeTitle,
        headerActions,
        className,
        headerClassName,
        contentClassName,
        positionCenter,
        large,
        small,
        ...otherProps
    } = props;

    return <ReactModal
        className={cn(block({ positionCenter, large, small }), className)}
        overlayClassName={block('Overlay')}
        onClose={onClose}
        closeTimeoutMS={200}
        {...otherProps}
    >
        <div className={cn(block('Header'), headerClassName)}>
            <div className={block('HeaderLeft')}>
                <FuseButton
                    className={block('Close')}
                    icon="close"
                    onClick={onClose}
                    aria-label={closeTitle}
                    title={closeTitle}
                />
                <div className={block('Title')}>
                    {title}
                </div>
            </div>
            <span className={block('HeaderRight')}>
                {headerActions}
            </span>
        </div>
        <div className={block('ContentWrapper')}>
            <div className={cn(block('Content'), contentClassName)}>
                {children}
            </div>
        </div>
    </ReactModal>;
}

ConsoleModal.propTypes = {
    onClose: PropTypes.func,
    title: PropTypes.node,
    closeTitle: PropTypes.string,
    headerActions: PropTypes.node,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    positionCenter: PropTypes.bool,
    large: PropTypes.bool,
    small: PropTypes.bool,
    ...ReactModal.propTypes,
};
