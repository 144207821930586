import PropTypes from 'prop-types';
import { action } from 'mobx';
import ValueStore from './ValueStore';

export default class BoolStore extends ValueStore {
    static _PropType = {
        ...ValueStore._PropType,
    };

    static PropTypes = PropTypes.shape(BoolStore._PropType);

    toggle = action(() => this.setValue(!this.getValue()));
}
