import EventEmitter from 'events';
import debug from '../debug';

export default class Lock extends EventEmitter {
    constructor(name) {
        super();
        this.name = name;
        this._lockCount = 0;
    }

    EVENT_LOCK = 'EVENT_LOCK';
    onLock(...args) {
        return this.on(this.EVENT_LOCK, ...args);
    }

    EVENT_UNLOCK = 'EVENT_UNLOCK';
    onUnlock(...args) {
        return this.on(this.EVENT_UNLOCK, ...args);
    }

    lock = () => {
        if (!this.isLocked()) {
            this.emit(this.EVENT_LOCK);
        }

        this._lockCount += 1;
    };

    unlock = () => {
        this._lockCount -= 1;

        if (this._lockCount < 0) {
            debug.error('Lock below zero', this.name);
            this._lockCount = 0;
        }

        if (!this.isLocked()) {
            this.emit(this.EVENT_UNLOCK);
        }
    };

    isLocked() {
        return this._lockCount !== 0;
    }

    lockForAMoment(wait = 0) {
        this.lock();
        const timeout = setTimeout(this.unlock, wait);
        this.once(this.EVENT_UNLOCK, () => { clearTimeout(timeout); });
    }
}
