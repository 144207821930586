import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import b from 'b_';
import TextStore from '../../stores/helpers/TextStore';
import FuseConfirmationModal from '../ConfirmationModal/FuseConfirmationModal';
import BrowserStore from '../../browser/stores/BrowserStore';
import FuseConsoleModal from '../ConsoleModal/FuseConsoleModal';
import { Card } from '../Fuse';
import FuseAccordion from '../FuseControls/FuseAccordion';
import FuseAlert from '../FuseControls/FuseAlert';
import FuseButton from '../FuseControls/FuseButton';
import { ObservableFuseSelect } from '../FuseControls/FuseSelect';
import FuseTextArea from '../FuseControls/FuseTextArea';
import FuseTextfield from '../FuseControls/FuseTextfield';
import SnackStore from '../../stores/SnackStore';
import './SendFeedbackModal.css';

const block = b.lock('SendFeedbackModal');

class SendFeedbackModal extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        snack: SnackStore.PropType.isRequired,
    };

    componentDidMount() {
        this.props.browser.sendFeedbackStore.type.setOptions([
            { value: 'Feature Suggestion', label: this.props.t('feedbackTypes.featureSuggestion') },
            { value: 'General Feedback', label: this.props.t('feedbackTypes.generalFeedback') },
            { value: 'Website Is Not Working', label: this.props.t('feedbackTypes.websiteIsNotWorking') },
        ]);
    }

    getString = (suffixKeyName) => {
        const prefix = this.props.browser.sendFeedbackStore.isCustomerSupport
            ? 'sendFeedbackModal.customerSupport.'
            : 'sendFeedbackModal.';
        return this.props.t(prefix + suffixKeyName);
    };

    onSend = (event) => {
        event.preventDefault();

        const { browser, snack } = this.props;

        browser.sendFeedbackStore.debugData = browser.getDiagnostics();
        browser.sendFeedbackStore.sendFeedback()
            .then(() => browser.sendFeedbackStore.isCustomerSupport && browser.setTroubleshootingMode(false))
            .catch((e) =>
                snack.showError(`${this.getString('sendFailureMessage')} ${e && e.message || ''}`));
    };

    onExitConfirm = () => {
        const { browser } = this.props;

        browser.sendFeedbackStore.clearAndCloseModal();
        if (browser.sendFeedbackStore.isCustomerSupport) {
            browser.setTroubleshootingMode(false);
        }
    };

    renderBrowserState() {
        const debugData = this.props.browser.getDiagnostics();

        if (!debugData) {
            return <div className={block('DataTitle')}>No browser state from server.</div>;
        }

        return <code className={block('DebugData')}>
            {JSON.stringify(debugData, null, 4)}
        </code>;
    }

    getInvalidEmailError() {
        const { email } = this.props.browser.sendFeedbackStore;

        if (email.hasChanged() && !email.isValid()) {
            return this.getString('invalidEmailMessage');
        }

        return '';
    }

    renderWarning() {
        return <FuseAlert
            className={block('Field')}
            type="warning"
            title={this.props.t('sendFeedbackModal.remarkTitle')}
        >{this.getString('remarkMessage')}</FuseAlert>;
    }

    render() {
        const { browser } = this.props;
        const { sendFeedbackStore } = browser;

        return <Fragment>
            <FuseConsoleModal
                isOpen={sendFeedbackStore.modal.isOpen}
                onClose={sendFeedbackStore.closeModal}
                title={this.getString('title')}
                closeTitle={this.getString('closeTitle')}
                style={{ content: { width: '100%', maxWidth: 620 } }}
                headerActions={<FuseButton
                    style={{ minWidth: 100 }}
                    disabled={!sendFeedbackStore.isValid()}
                    loading={sendFeedbackStore.sendStatus.isLoading}
                    onClick={this.onSend}
                    color="green"
                    qa="send-feedback-modal-close"
                >{this.getString('sendFeedbackButton')}</FuseButton>}
            >
                <Card>
                    {sendFeedbackStore.isCustomerSupport ? <>
                        <FuseTextfield
                            className={block('Field')}
                            label={this.getString('caseId')}
                            store={new TextStore(browser.troubleshootingCaseId)}
                            disabled
                            qa="send-feedback-modal-troubleshooting-case-id"
                        />
                        <FuseTextfield
                            className={block('Field')}
                            label={`${this.getString('emailLabel')} ${this.getString('required')}`}
                            placeholder={this.getString('emailPlaceholder')}
                            store={sendFeedbackStore.email}
                            error={!!this.getInvalidEmailError()}
                            bottomText={this.getInvalidEmailError()}
                            qa="send-feedback-modal-email"
                        />
                    </> : <>
                        {this.renderWarning()}
                        <ObservableFuseSelect
                            containerClassName={block('Field', { select: true })}
                            singleSelection
                            selectLabel={this.getString('selectTypeLabel')}
                            placeholder={this.getString('selectTypePlaceholder')}
                            store={sendFeedbackStore.type}
                            qa="send-feedback-modal-type-select"
                        />
                    </>}
                    <FuseTextArea
                        store={sendFeedbackStore.comment}
                        label={`${this.getString('commentLabel')} ${this.getString('required')}`}
                        placeholder={this.getString('commentPlaceholder')}
                        qa="send-feedback-modal-comment"
                    />
                </Card>
                <FuseAccordion
                    title={this.getString('recordedDataButton')}
                    small
                    qa="send-feedback-modal-data"
                >{this.renderBrowserState()}</FuseAccordion>
            </FuseConsoleModal>

            <FuseConfirmationModal
                title={this.getString('exitConfirmationModal.title')}
                text={this.getString('exitConfirmationModal.body')}
                confirmLabel={this.getString('exitConfirmationModal.confirmButton')}
                cancelLabel={this.getString('exitConfirmationModal.cancelButton')}
                confirmCheckBox={sendFeedbackStore.isCustomerSupport ?
                    this.getString('exitConfirmationModal.confirmCheckBox') : null}
                store={sendFeedbackStore.exitConfirmationModal}
                onConfirm={this.onExitConfirm}
                onClose={sendFeedbackStore.isCustomerSupport ? sendFeedbackStore.modal.close : null}
            />

            <FuseConfirmationModal
                title={this.getString('submitModal.title')}
                cancelLabel={this.getString('submitModal.closeButton')}
                store={sendFeedbackStore.submitModal}
            >
                <p className={block('Field')}>
                    {this.getString('submitModal.message')}
                </p>

                {!sendFeedbackStore.isCustomerSupport ? this.renderWarning() : null}
            </FuseConfirmationModal>
        </Fragment>;
    }
}

export default translate()(inject('browser', 'snack')(observer(SendFeedbackModal)));
