import b from 'b_';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { translate } from 'react-i18next';
import BrowserStore from '../../browser/stores/BrowserStore';
import EnvStore from '../../stores/EnvStore';
import { LogoProofpointWord } from '../Logo/PfptLogo';
import Button from '../Button';
import FAQContent from './FAQContent';
import './FAQModal.css';

const block = b.lock('FAQModal');

class FAQModal extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        env: EnvStore.PropType.isRequired,
        browser: BrowserStore.PropType.isRequired,
    };
    renderLogoText(env) {
        if (env.isUrlIsolation) { return 'URL Isolation'; }
        if (env.isSaasIsolation) { return 'SaaS Isolation'; }
        return 'Isolation';
    }
    render() {
        const { t, env, browser } = this.props;
        return <ReactModal
            contentLabel={t('FAQ.title')}
            className={block()}
            overlayClassName={block('Overlay')}
            isOpen={browser.faqModal.isOpen}
            onClose={browser.faqModal.close}
            shouldCloseOnEsc
        >
            <Button
                className={block('Close')}
                icon="close"
                onClick={browser.faqModal.close}
                title={t('FAQ.closeButton')}
                aria-label={t('FAQ.closeButton')}
            />
            <div className={block('Head')}>
                <div className={block('LogoWrapper')}>
                    <LogoProofpointWord className={block('Logo')} />
                    <div className={block('LogoText')}>
                        {this.renderLogoText(env)}
                    </div>
                </div>
                <div className={block('Title')}>{t('FAQ.title')}</div>
            </div>
            <FAQContent openSection={browser.faqSection} />
        </ReactModal>;
    }
}

export default translate()(inject('env', 'browser')(observer(FAQModal)));
