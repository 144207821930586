import debug from '../../debug';
import { LOCATION_ORIGIN } from '../../env';
import { API_CLIENT_TO_BROWSER } from '../sharedConstants';

export const isInsideBrowser = window.parent !== window;

export function messageToBrowser(method, args, windowTo = window.parent) {
    if (window !== windowTo) {
        windowTo.postMessage(JSON.stringify({ method, args }), LOCATION_ORIGIN);
    }
}

export function listenBrowser(callback) {
    const listener = (event) => {
        if ((event.origin || event.originalEvent.origin) !== LOCATION_ORIGIN) {
            return;
        }

        let json = null;
        try {
            json = JSON.parse(event.data);
        } catch (e) {
            return;
        }

        callback(json.method, json.args);
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
}

export function sendToClient(iframe, method, args) {
    if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(JSON.stringify({ method, args }), LOCATION_ORIGIN);
        return true;
    }
    return false;
}

export function messageToOpener(method, args) {
    try {
        if (window.top.opener && window.top.opener !== window.top) {
            messageToBrowser(method, args, window.top.opener);
        }
    } catch (e) {
        debug.error(e);
    }
}

const listenClient = listenBrowser;

let nextRequestId = 0;
export const sendToClientWithResponse = (
    iframe,
    method,
    args,
    opts = { timeoutMS: 6000 }
) => new Promise((resolve, reject) => {
    const requestId = nextRequestId;

    if (!sendToClient(iframe, method, [requestId, args])) {
        reject(new Error('Unable to send a message'));
        return;
    }
    nextRequestId += 1;
    let completed = false;
    const unsubscribe = listenClient((msgMethod, [targetRequestId, respArgs]) => {
        if (!completed && msgMethod === API_CLIENT_TO_BROWSER.response && targetRequestId === requestId) {
            unsubscribe();
            if (respArgs.error) {
                reject(new Error(respArgs.error));
                return;
            }
            resolve(respArgs);
        }
    });
    setTimeout(() => {
        completed = true;
        unsubscribe();
        reject(new Error('Request Timeout'));
    }, opts.timeoutMS);
});
