import { action, extendObservable } from 'mobx';
import PropTypes from 'prop-types';
import { apiCall } from '../API';
import { emailValidator } from '../email';
import AsyncActionStore from './AsyncActionStore';
import FlagStore from './FlagStore';
import InputStore from './InputStore';
import debug from '../debug';

export default class PasswordlessAuthStore {
    static PropType = PropTypes.shape({
        email: InputStore.PropType.isRequired,
        submitEmail: PropTypes.func.isRequired,
        submitEmailState: AsyncActionStore.PropType.isRequired,
        code: InputStore.PropType.isRequired,
        expiredCode: FlagStore.PropType.isRequired,
        expiredLink: FlagStore.PropType.isRequired,
        submitCode: PropTypes.func.isRequired,
        submitCodeState: AsyncActionStore.PropType.isRequired,
        getRegistrationType: PropTypes.func.isRequired,
        startAgain: PropTypes.func.isRequired,
    });

    constructor(data = {}) {
        this.expiredTimeoutTm = null;

        extendObservable(this, /** @class PasswordlessAuthStore */{
            loginError: null,
            originalQuery: null,
            expiredCode: new FlagStore(false),
            expiredLink: new FlagStore(false),
            email: new InputStore({ value: '', isTouched: true }),
            apiSubmitEmail: apiCall('/login'),
            submitEmailState: new AsyncActionStore(),
            code: new InputStore({ value: '', isTouched: true }),
            apiSubmitCode: apiCall('/login/code'),
            apiGetSSOLink: apiCall('/sso/browser/start'),
            getSSOLinkState: new AsyncActionStore(),
            isSSOManuallyLoadingState: new FlagStore(false),
            flipNextStore: new FlagStore(false),
            submitCodeState: new AsyncActionStore(),
            noticeContent: null,

            onSubmitCodeSuccess: ({ url }) => {
                window.location = '/browser' + (url ? `?url=${encodeURIComponent(url)}` : '');
            },

            getRegistrationType: () => {
                const result = this.submitEmailState.result;
                return ['code', 'link', 'registrationNotice', 'sso']
                    .indexOf(result && result.type) !== -1 ? result.type : null;
            },
        }, data);

        this.email.setValidator(emailValidator);

        if (this.loginError === 'token-expired') {
            this.onExpired('link');
        }

        // TODO: verify that works
        if (this.originalQuery && this.originalQuery.tokenId) {
            this.submitEmailState.onSuccess({ type: 'code', tokenId: this.originalQuery.tokenId });
            if (this.originalQuery.code) {
                this.code.setValue(this.originalQuery.code);
            }
            if (this.originalQuery.email) {
                this.email.setValue(this.originalQuery.email);
            }
        }
    }

    acceptPrivacyPolicy = action(() => {
        this.submitEmail(true);
    });

    submitEmail = action((privacyPolicyAccepted = false) => {
        if (this.submitEmailState.isLoading) {
            return;
        }

        this.expiredCode.setFalse();
        this.expiredLink.setFalse();
        if (!privacyPolicyAccepted) {
            this.submitEmailState.setBase();
            this.submitCodeState.setBase();
            this.code.reset();
            this.email.setTouched(false);
            if (!this.email.isValid) {
                this.email.focus();
                return;
            }
            this.email.blur();
        }

        this.submitEmailState.setLoading(this.apiSubmitEmail({ email: this.email.value, privacyPolicyAccepted })
            .then(action((res) => {
                if (res && res.type === 'sso') {
                    debug.info('going to redirect to SSO');
                    this.isSSOManuallyLoadingState.setTrue();
                    if (this.noticeContent !== null) {
                        res.content = this.noticeContent;
                    }
                    this.getSSOLinkState.setLoading(this.apiGetSSOLink({ value: this.email.value })
                        .then(((ssoResponse) => { window.location = ssoResponse.value; }))
                        .catch((err) => debug.error('Error: ', err)))
                        .finally(() => this.isSSOManuallyLoadingState.setFalse());
                }
                if (res && ['code', 'link', 'registrationNotice'].includes(res.type)) {
                    this.flipNextStore.setTrue();
                }
                if (res && (res.type === 'code' || res.type === 'link')) {
                    // 24h - 60s till the error is displayed
                    this.expiredTimeoutTm =
                        setTimeout(() => this.onExpired(res.type), 24 * 60 * 60 * 1000 - (60 * 1000));
                    if (res.type === 'code') {
                        setTimeout(this.code.focus, 250);
                    }
                }
                if (res && res.type === 'registrationNotice') {
                    res.content = JSON.parse(res.content);
                    this.noticeContent = res.content;
                }
                return res;
            })), true)
            .catch(() => this.email.focus());

        return this.submitEmailState.promise;
    });

    submitCode = action(() => {
        if (this.submitCodeState.isLoading) {
            return;
        }

        this.code.setTouched(false);
        if (!this.code.isValid) {
            this.code.focus();
            return;
        }

        this.code.blur();
        this.submitCodeState.setLoading();
        this.apiSubmitCode({
            code: this.code.value.replace(/\D/g, ''),
            tokenId: this.submitEmailState.result.tokenId,
        })
            .then(this.onSubmitCodeSuccess)
            .catch((error) => {
                this.submitCodeState.setFailed(error);
                this.code.focus();
            });

        return this.submitCodeState.promise;
    });

    onExpired = action((registrationType) => {
        this.startAgain();
        if (registrationType === 'code') {
            this.expiredCode.setTrue();
        }
        if (registrationType === 'link') {
            this.expiredLink.setTrue();
        }
    });

    startAgain = action(() => {
        this.submitCodeState.setBase();
        this.code.setValue('');
        this.submitEmailState.setBase();
        this.email.setValue('');
        clearTimeout(this.expiredTimeoutTm);
        setTimeout(() => this.email.focus(), 250);
        this.flipNextStore.setFalse();
    });
}
