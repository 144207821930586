export const OS = {
    WINDOWS: 'WINDOWS',
    OSX: 'OSX',
    ANDROID: 'ANDROID',
    IOS: 'IOS',
};

export const BROWSER = {
    CHROME: 'CHROME',
    FIREFOX: 'FIREFOX',
    SAFARI: 'SAFARI',
    EDGE: 'EDGE',
    IE: 'IE',
};

/* eslint-disable max-len */
export const userAgents = {
    [OS.WINDOWS]: {
        [BROWSER.CHROME]: 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36',
        [BROWSER.FIREFOX]: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:109.0) Gecko/20100101 Firefox/118.0',
        [BROWSER.IE]: 'Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; rv:11.0) like Gecko',
        [BROWSER.EDGE]: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36 Edg/117.0.2045.47'
    },

    [OS.OSX]: {
        [BROWSER.CHROME]: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36',
        [BROWSER.SAFARI]: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 14_0) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.5 Safari/605.1.15',
    },

    [OS.ANDROID]: {
        [BROWSER.CHROME]: 'Mozilla/5.0 (Linux; Android 10) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.5938.140 Mobile Safari/537.36',
    },

    [OS.IOS]: {
        [BROWSER.CHROME]: 'Mozilla/5.0 (iPhone; CPU iPhone OS 17_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/117.0.5938.117 Mobile/15E148 Safari/604.1',
        [BROWSER.SAFARI]: 'Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.5 Mobile/15E148 Safari/604.1',
    },
};
/* eslint-enable max-len */

export const userAgentInfo = Object.keys(userAgents).reduce((acc, os) => {
    Object.entries(userAgents[os]).forEach(([browser, ua]) => {
        acc[ua] = { os, browser };
    });
    return acc;
}, {});
