import { API_BACKGROUND_TO_CLIENT } from '../sharedConstants';
import { Mod, Key } from '../userInput';
import api from './ClientAPI';
import Frame from './Frame';
import debug from '../../debug';
import reg from '../DOMRegistry';

const MAX_IMAGE_WAIT_TRIES = 150; // around 3 seconds

function checkIfImagesIsReady(frame, tryCounter = 0) {
    return new Promise((resolve, reject) => {
        if (tryCounter >= MAX_IMAGE_WAIT_TRIES) {
            resolve();
            return;
        }

        if (!frame.initialRenderingIsDone) {
            setTimeout(() => checkIfImagesIsReady(frame, tryCounter + 1).then(resolve).catch(reject), 100);
            return;
        }

        if (!frame.doc) {
            reject('frame.doc is null or undefined');
            return;
        }

        const images = Array.from(frame.doc.querySelectorAll('img'));

        if (!images.length) {
            resolve();
            return;
        }

        const notLoadedImages = images.filter((img) => !(img.finished || (img.src || '').trim() === ''));
        if (notLoadedImages.length > 0) {
            setTimeout(() => checkIfImagesIsReady(frame, tryCounter + 1).then(resolve).catch(reject), 20);
            return;
        }

        resolve();
    });
}

export function startPrint(frame) {
    if (!frame) {
        return;
    }

    if (!frame.doc) {
        return;
    }

    frame.printInProgress = true;

    checkIfImagesIsReady(frame)
        .then(() => {
            frame.printIsReady = true;

            frame.doc.defaultView.print();

            frame.printIsReady = false;
        })
        .catch((error) => {
            debug.warn('Failed to print:', error);
        })
        .finally(() => {
            frame.printInProgress = false;

            api.finishPrintOnServer(frame.id);
        });
}

export default function initPrintController() {
    api.onMethod(API_BACKGROUND_TO_CLIENT.enableFakePrint, (frameId) => {
        const frame = Frame.get(frameId);
        if (!frame) {
            return;
        }

        if (!frame.doc) {
            return;
        }

        frame.useNativePrint = false;

        reg.addEventListener(frame.doc, 'keydown', (event) => {
            const mod = Mod.fromEvent(event);

            if (event.keyCode === Key.P && (mod === Mod.CTRL || mod === Mod.META)) {
                event.preventDefault();
                if (event.stopImmediatePropagation) {
                    event.stopImmediatePropagation();
                } else if (event.stopPropagation) {
                    event.stopPropagation();
                }

                api.startPrintOnServer(frameId);
            }
        }, true);

        const originalPrint = frame.doc.defaultView.print;

        frame.doc.defaultView.print = () => {
            if (frame.useNativePrint || frame.printIsReady || !api.isConnected) {
                originalPrint.call(frame.doc.defaultView);
            } else if (!frame.printInProgress) {
                api.startPrintOnServer(frameId);
            }
        };
    });
}
