import { IS_MOBILE, IS_BROWSER, IS_CLIENT, IS_TOP, IS_EXTENSION } from './env';

const screenOrientations = {
    // it does not matter really which wrong way you holding your phone
    // thats why we cast secondary to primary
    'landscape-primary': 'landscapePrimary',
    'landscape-secondary': 'landscapePrimary',
    'portrait-primary': 'portraitPrimary',
    'portrait-secondary': 'portraitPrimary',
};

const allowedScreenOrientations = new Set(Object.values(screenOrientations));

export const IFRAME_REAL_SCREEN_SIZE_ATTR = 'data-is-iframe-real-screen';

function getScreenOrientation() {
    const screenOrientation = {
        angle: 0,
        type: 'landscape-primary',
    };

    if (window.screen.orientation) {
        screenOrientation.angle = window.screen.orientation.angle;
        screenOrientation.type = window.screen.orientation.type;
    } else if (window.orientation !== undefined) {
        const angle = Math.abs(window.orientation);
        screenOrientation.angle = angle;
        if (!(angle % 180)) {
            screenOrientation.type = 'portrait-primary';
        } else {
            screenOrientation.type = 'landscape-primary';
        }
    }

    screenOrientation.type = screenOrientations[screenOrientation.type];
    return screenOrientation;
}

export function isPortrait(orientation) {
    if (!orientation || !orientation.type) {
        return false;
    }

    return orientation.type === 'portraitPrimary' || orientation.type === 'portraitSecondary';
}

export default class Screen {
    width = 0;
    height = 0;
    screenWidth = 0;
    screenHeight = 0;
    deviceScaleFactor = 0;
    mobile = false;
    fitWindow = false;
    screenOrientation = {
        type: 'landscapePrimary',
        angle: 0,
    };

    constructor(dto) {
        if (dto) {
            this.merge(dto);
        }
    }

    static getCurrentScreen() {
        const screen = new Screen();
        screen.init();
        return screen;
    }

    static isEqualSize(screenA, screenB) {
        if (screenA === screenB) {
            return true;
        }
        if (!screenA || !screenB) {
            return false;
        }
        return screenA.width === screenB.width
            && screenA.height === screenB.height;
    }

    static isEqualOrientation(screenA, screenB) {
        if (screenA === screenB) {
            return true;
        }
        if (!screenA || !screenB) {
            return false;
        }
        return isPortrait(screenA.screenOrientation) === isPortrait(screenB.screenOrientation);
    }

    clone() {
        return new Screen(this);
    }

    init() {
        let height = IS_BROWSER ? window.innerHeight - 51 : window.innerHeight;
        let width = window.innerWidth;

        if (!IS_EXTENSION) {
            let iframeContainer = null;
            if (IS_BROWSER) {
                iframeContainer = document.querySelector(`[${IFRAME_REAL_SCREEN_SIZE_ATTR}]`);
            } else if (!IS_TOP && IS_CLIENT) {
                iframeContainer = window.frameElement.ownerDocument.querySelector(`[${IFRAME_REAL_SCREEN_SIZE_ATTR}]`);
                height = window.parent.innerHeight;
            }

            // we want to read dimensions of container
            // because dimensions of iframe's window could be much bigger due to some bugs/features on iOS
            // also this allows us to read dimensions that exclude all the browser UI
            // (which may be included on certain platforms)
            if (iframeContainer) {
                width = iframeContainer.offsetWidth || width;
                // offsetHeight could be 1 pixel while flex is growing on iOS, sometimes...
                height = iframeContainer.offsetHeight > 100 ? iframeContainer.offsetHeight : height;
            }
        }

        this.width = width;
        this.height = height;
        this.mobile = IS_MOBILE;
        this.screenWidth = window.screen.width;
        this.screenHeight = window.screen.height;
        this.screenOrientation = getScreenOrientation();
    }

    merge(screenDto) {
        this.width = screenDto.width | 0;
        this.height = screenDto.height | 0;
        this.screenWidth = screenDto.screenWidth | 0;
        this.screenHeight = screenDto.screenHeight | 0;
        this.deviceScaleFactor = screenDto.deviceScaleFactor | 0;
        this.mobile = !!screenDto.mobile;
        // don't set fitWindow, used only on server and false always
        // this.fitWindow = !!screenDto.fitWindow;

        if (screenDto.screenOrientation) {
            this.screenOrientation = { ...screenDto.screenOrientation };

            if (!allowedScreenOrientations.has(this.screenOrientation.type)) {
                this.screenOrientation.type = screenOrientations['landscape-primary'];
                this.screenOrientation.angle = 0;
            }

            this.screenOrientation.angle |= 0;
        } else {
            this.screenOrientation = undefined;
        }
    }
}
