import cn from 'classnames';
import { Interpolate, translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import b from 'b_';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BrowserStore from '../../browser/stores/BrowserStore';
import { EnvStoreProps } from '../../stores/props';
import { IS_BROWSER_MICROSOFT, IS_MOBILE } from '../../env';
import debug from '../../debug';
import { translateWithScreen } from '../../Translate';
import LeftNotification from '../Notification/LeftNotification';
import Button from '../Button';
import SnackStore from '../../stores/SnackStore';
import FuseButton from '../FuseControls/FuseButton';
import RightNotification from '../Notification/RightNotification';
import './TopBarUrl.css';
import './../TopBarGuide.css';

const blockUrl = b.lock('TopBarUrl');
const blockGuide = b.lock('TopBarGuide');

class TopBarUrl extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        snack: SnackStore.PropType.isRequired,
        env: EnvStoreProps,
        onSubmit: PropTypes.func,
        isWizard: PropTypes.bool,
        className: PropTypes.string,
    };

    state = {
        // Key is used to fully replace input element, to remove focus on text,
        // because there is an issue with changing selection after focusing iframe
        keyForInput: 0,
    };

    inputBlurTimeout = null;

    componentDidMount() {
        if (IS_MOBILE) {
            // force input blur on mobile when user switched focus out of window (e.g. into iframe)
            // to hide the keyboard. Simple input.blur() does not work
            window.addEventListener('blur', this.onInputBlur);
        }
    }

    componentWillUnmount() {
        if (IS_MOBILE) {
            window.removeEventListener('blur', this.onInputBlur);
        }
    }

    onInputKeydown = (event) => {
        if (event.keyCode === 13) {
            this.props.browser.submitUrlInput();
            this.props.onSubmit(this.props.browser.urlInputValue);
        }
    };

    onInputFocus = () => {
        if (this.inputBlurTimeout) {
            clearTimeout(this.inputBlurTimeout);
            this.inputBlurTimeout = null;
        }

        if (!this.props.browser.urlInputFocus.isTrue) {
            this.props.browser.urlInputFocus.setTrue();
        }
    };

    onInputBlur = () => {
        // timeout is needed to allow to click on clear button and to avoid UI jumps
        this.inputBlurTimeout = setTimeout(() => {
            this.props.browser.urlInputFocus.setFalse();
            this.setState({ keyForInput: this.state.keyForInput + 1 });
        }, 50);
    };

    onInputPaste = (event) => {
        const original = event.clipboardData.getData('text');
        const flat = original.replace(/[\r\n]/gi, '');
        const trimmed = flat.trim();
        if (original !== trimmed) {
            try {
                event.clipboardData.setData('text', trimmed.length ? trimmed : flat);
            } catch (e) {
                debug.warn('failed to sanitize paste', e);
            }
        }
    };

    onClearButtonClick = () => {
        this.props.browser.setUrlInputValue('');
        this.props.browser.focusUrlInput();
    };

    onClick = () => {
        const { browser, env } = this.props;

        if ((env.isUrlIsolation || env.isSaasIsolation) && !browser.notification) {
            browser.toggleNotification(BrowserStore.NOTIFICATION.DEFAULT_LEFT);
        }

        if (env.isBrowserDefense && !browser.notification) {
            browser.focusUrlInput();
        }
    }

    copyToClipboard = async (urlToExit) => {
        const { snack, t } = this.props;
        try {
            await navigator.clipboard.writeText(urlToExit);
            snack.showSuccess(t('Notification_Snack.Copy_URL_Success'));
        } catch (err) {
            snack.showError(<Interpolate
                i18nKey="Notification_Snack.Copy_URL_Failed"
                error={err?.message ?? ''}
            />);
        }
    }

    // It's hard to prevent cpp of things inside a real input for IE and all others
    nonCopyPastePseudoInput() {
        const { browser, env, t } = this.props;
        const fade = !!browser.notification;
        const state = browser.getVisualState();
        const minified = browser.isTopBarMinificationAllowed();
        const urlToExit = browser.urlInputValue.trim().length === 0 ?
            browser.getOriginalUrlByClickId(browser.sandboxClickId) : browser.urlInputValue;
        const showCopyButton = browser.allowCopyUrl.getValue() || (env.isUrlIsolation && browser.canExitIsolation());

        return (
            <div
                className={blockUrl('UrlTextWrapper', { state, minified })}
                onClick={this.onClick}
                title={browser.urlInputValue}
            >
                <div className={blockUrl('UrlTextFade', { fade })}>
                    <div className={blockUrl('UrlText', { minified })}>
                        {browser.urlInputValue}
                    </div>
                </div>
                {showCopyButton ? (
                    <FuseButton
                        id="copy-url-to-clipboard"
                        type="primary"
                        icon="copy"
                        small
                        iconClassName={blockUrl('CopyButtonOverlay', {
                            fade,
                            minified,
                        })}
                        aria-label={t('Notification_Snack.Copy_URL_Label')}
                        title={t('Notification_Snack.Copy_URL_Label')}
                        onClick={() => {
                            this.copyToClipboard(urlToExit);
                        }}
                        qa="copy-url-to-clipboard"
                    />
                ) : null}
            </div>
        );
    }
    render() {
        const { t, browser, isWizard, env, className } = this.props;
        const isMinified = browser.isTopBarMinificationAllowed();
        const preventSearch = env.isUrlIsolation || env.isSaasIsolation;

        let inputPlaceholderText = translateWithScreen(t, env.screen.isMobile, 'TopBar.urlPlaceholder');

        if (env.isWebmailDefense) {
            inputPlaceholderText = t('browserTopBar.webmailPlaceholder');
        }

        return <div
            className={cn(
                blockUrl('InputWrapper', { focus: browser.urlInputFocus.isTrue, minified: isMinified }),
                blockUrl(),
                className || ''
            )}
            onClick={() => (preventSearch ? browser.topBarMenu.toggle() : null)}
        >
            <div
                className={cn(blockUrl('InputGrid', {
                    focus: browser.urlInputFocus.isTrue,
                    open: !!browser.notification,
                    minified: isMinified,
                    mobile: env.screen.isMobile,
                }))}
                onClick={this.onClick}
            >
                <LeftNotification />
                {preventSearch ? this.nonCopyPastePseudoInput() : <input
                    name="urlInput"
                    tabIndex={isWizard ? -1 : undefined}
                    className={blockUrl('Input', {
                        defaultFont: IS_BROWSER_MICROSOFT,
                        notificationOpen: !!browser.notification,
                        minified: browser.isTopBarMinificationAllowed() })}
                    type="text"
                    key={this.state.keyForInput}
                    defaultValue={browser.urlInputValue || ''}
                    ref={browser.setUrlInputEl}
                    onChange={browser.updateUrlInputValue}
                    onKeyDown={this.onInputKeydown}
                    onMouseUp={browser.onUrlInputMouseUp}
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    onPaste={this.onInputPaste}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    placeholder={inputPlaceholderText}
                    aria-label={inputPlaceholderText}
                    title={browser.urlInputValue || inputPlaceholderText}
                />}

                {env.screen.isMobile || env.isTouchDevice ? <Button
                    icon="cancel"
                    onClick={this.onClearButtonClick}
                    className={blockUrl('InputClearButton', {
                        show: browser.urlInputFocus.isTrue && !!browser.urlInputValue,
                    })}
                /> : null}

                <RightNotification />
            </div>
            {isWizard ? <Fragment>
                <div className={blockGuide({
                    type: env.screen.isMobile ? 'bottomCenter' : 'top',
                })}
                >
                    {t('browserTopBar.guides.input')}
                </div>
                <div className={blockGuide({ type: 'bottomLeft' })}>
                    {t('browserTopBar.guides.leftCorner')}
                </div>
                <div className={blockGuide({ type: 'bottomRight' })}>
                    {t('browserTopBar.guides.rightCorner')}
                </div>
            </Fragment> : null}
        </div>;
    }
}
export default translate()(inject('browser', 'research', 'history', 'env', 'snack')(observer(TopBarUrl)));

