import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import cn from 'classnames';
import b from 'b_';
import { inject, observer } from 'mobx-react';
import BrowserStore from '../../browser/stores/BrowserStore';
import makeElementId from '../../makeElementId';
import FuseButton from '../FuseControls/FuseButton';
import { EnvStoreProps, ResearchStoreProp } from '../../stores/props';
import './../TopBarGuide.css';
import './TopBarMenuButton.css';

const guideBlock = b.lock('TopBarGuide');

class TopBarMenuButton extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        research: ResearchStoreProp,
        browser: BrowserStore.PropType.isRequired,
        env: EnvStoreProps,
        isWizard: PropTypes.bool,
        btnProps: PropTypes.shape(FuseButton.propTypes),
    };

    buttonId = makeElementId('openMenuTopBarButton');

    static getWizard({ isWizard, t, browser, env }) {
        return (isWizard ? (<div
            className={guideBlock({
                type: browser.adBlockSwitchEnabled || env.screen.isMobile ? 'top' : 'bottom',
                inBigButton: true,
                alternative: true,
            })}
        >
            {t('browserTopBar.guides.menuButton')}
        </div>) : null);
    }

    onButtonClick = (event) => {
        const { browser, onClose } = this.props;

        event.stopPropagation();
        browser.topBarMenu.toggle();

        if (!browser.topBarMenu.isOpen && document.activeElement) {
            onClose();
        }
    }

    render() {
        const { browser, t, className, btnProps } = this.props;
        const isTopBarMinified = browser.isTopBarMinificationAllowed();
        const title = browser.topBarMenu.isOpen
            ? t('browserTopBar.closeMenuButtonTitle')
            : t('browserTopBar.menuButtonTitle');

        return <div className={cn('TopBarMenuButton', className)}>
            <FuseButton
                id={this.buttonId}
                aria-haspopup="true"
                aria-expanded={browser.topBarMenu.isOpen}
                aria-controls="topBarMenu"
                aria-label={title}
                title={title}
                icon={browser.topBarMenu.isOpen ? 'close' : 'menu'}
                type="quaternary"
                color={this.props.research.researchMode.isEnabled ? 'black' : null}
                active={browser.topBarMenu.getValue()}
                onClick={this.onButtonClick}
                {...(this.props.isWizard ? { tabIndex: -1 } : {})}
                extraSmall={isTopBarMinified}
                {...btnProps}
            />
            {TopBarMenuButton.getWizard(this.props)}
        </div>;
    }
}

export default translate()(inject('browser', 'research', 'env')(observer(TopBarMenuButton)));

