// TODO: split into "client" and "server" env

const ua = navigator.userAgent;
const loc = window.location;

export const { NODE_ENV, APP, BUILD_TIME, SENTRY_CREDENTIALS, CONTENT_KEY } = process.env;
export const IS_PROD = NODE_ENV === 'production';
export const IS_DEV = !IS_PROD;
export const IS_EXTENSION = APP === 'ext';
export const IS_CLIENT = APP === 'client';
export const IS_BROWSER = APP === 'browser';

export const IS_BROWSER_IE11 = !IS_EXTENSION && (!!window.MSInputMethodContext && !!document.documentMode);
export const IS_BROWSER_EDGE = !IS_EXTENSION && ua.includes('Edge');
export const IS_BROWSER_CHROME = IS_EXTENSION || (ua.includes('Chrome') && !ua.includes('Edge'));
export const IS_BROWSER_SAFARI = ua.includes('Safari') && !ua.includes('Chrome');
export const IS_BROWSER_MICROSOFT = IS_BROWSER_IE11 || ua.includes('Trident') || ua.includes('Edge/');
export const IS_BROWSER_ON_WINDOWS = IS_BROWSER_IE11 || ua.includes('Windows');
export const IS_BROWSER_ON_MAC = ua.includes('Mac OS');
export const AGENT_PORT = IS_PROD ? '10080' : '10089';

// increment if new browser.js API would be incompatible with the previous release
export const BROWSER_API_VERSION = 3;

export const LOCATION_ORIGIN = loc.origin
    || (loc.protocol + '//' + loc.hostname
        + ((loc.port && loc.port !== '80' && loc.port !== '443') ? ':' + loc.port : ''));

let isTop = false;
try {
    isTop = window.top === window;
} catch (e) { /* whatever */ }
export const IS_TOP = isTop;


// feature detection for passive event optimization
// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
export const supportsPassiveEvents = (() => {
    let support = IS_EXTENSION; // server chrome always supports passive
    if (!support) {
        try {
            window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
                get: () => { support = true; },
            }));
        } catch (e) { /* whatever */ }
    }
    return support;
})();

// https://github.com/airbnb/is-touch-device/blob/master/src/index.js
function isTouchDeviceFn() {
    if ('ontouchstart' in window) {
        return true;
    }

    if (window.DocumentTouch && (document instanceof window.DocumentTouch)) {
        return true;
    }

    return !!(navigator.maxTouchPoints || navigator.msMaxTouchPoints);
}

function hasAdoptedStyleSheets() {
    return 'adoptedStyleSheets' in window.Document.prototype && !!window?.CSSStyleSheet?.prototype?.replace;
}

export const IS_TOUCH_DEVICE = isTouchDeviceFn();
export const IS_IOS = IS_TOUCH_DEVICE && 'ongestureend' in window;
export const IS_ANDROID = ua.includes('Android');
export const IS_MOBILE = IS_TOUCH_DEVICE && !IS_BROWSER_ON_WINDOWS && (IS_IOS || IS_ANDROID);
export const HAS_ADOPTED_STYLESHEETS = hasAdoptedStyleSheets();
