import debug, { DEBUG } from '../../debug';
import { HAS_ADOPTED_STYLESHEETS, IS_CLIENT } from '../../env';
import LS, { LS_KEY } from '../../LocalStorage';
import * as DOM from '../DOM';
import reg from '../DOMRegistry';

export function cleanUpNode(root) {
    if (!DOM.isElementNode(root)) {
        return;
    }

    DOM.walkDOM(root, (node) => {
        if (!reg.getNodeId(node)) {
            DOM.removeNode(node);
        } else {
            return true;
        }
    });
}

export function debugNode(node) {
    if (DEBUG && node && node.setAttribute) {
        node.setAttribute('wid', reg.getNodeId(node));
        node.setAttribute('fid', reg.getFrameId(node));
    }
}

export function isDebugAttr(attr) {
    return (DEBUG && (attr === 'wid' || attr === 'fid'))
        || attr?.startsWith('weblife-');
}

export function insertBefore(node, parentNode, nextNode) {
    try {
        // TODO: IE goes crazy if node is an empty text node
        parentNode.insertBefore(node, nextNode);
    } catch (error) {
        debug.group('If not in IE evey bad!', {
            error,
            parentNode,
            node,
            nodeParentNode: node && node.parentNode,
            nextNode,
            nextNodeParent: nextNode && nextNode.parentNode,
            equals: (node && node.parentNode) === (nextNode && nextNode.parentNode),
        });
    }
}

const shadowRootDebug = LS.get(LS_KEY.shadowRootDebug, false);
let shadowDebugStyle;
let shadowRootCounterSheet;
let shadowRootCounter = 0;

function renderShadowRootCounter() {
    if (!IS_CLIENT) {
        return;
    }

    const counterStyles = `
        :root::after {
            position: fixed;
            top: 5px;
            left: 5px;
            padding: 10px;
            background: white;
            border: 1px solid black;
            font-size: 21px;
            z-index: 99999;
            content: 'Shadow Roots: ${shadowRootCounter}';
        }
    `;

    if (HAS_ADOPTED_STYLESHEETS) {
        shadowRootCounterSheet.replaceSync(counterStyles);
    } else {
        shadowRootCounterSheet.textContent = counterStyles;
    }
}

if (shadowRootDebug) {
    if (HAS_ADOPTED_STYLESHEETS) {
        shadowDebugStyle = new window.CSSStyleSheet();
        shadowDebugStyle.replaceSync(':host { outline: 4px dashed red !important; }');

        shadowRootCounterSheet = new window.CSSStyleSheet();
        document.adoptedStyleSheets = [shadowRootCounterSheet, ...(document.adoptedStyleSheets || [])];
    } else {
        shadowRootCounterSheet = document.createElement('style');
        document.documentElement.appendChild(shadowRootCounterSheet);
    }
    renderShadowRootCounter();
}

export function debugShadowRoot(shadow) {
    if (!shadowRootDebug) {
        return;
    }

    if (HAS_ADOPTED_STYLESHEETS) {
        shadow.adoptedStyleSheets = [shadowDebugStyle, ...(shadow.adoptedStyleSheets || [])];
    } else {
        shadow.host.style = (shadow.host.style || '') + ';outline: 4px dashed red !important;';
    }

    shadowRootCounter += 1;
    renderShadowRootCounter();
}
