import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import b from 'b_';
import { ERROR_VIEW_CODE } from '../../constants';
import BrowserStore from '../../browser/stores/BrowserStore';
import makeElementId from '../../makeElementId';
import EnvStore from '../../stores/EnvStore';
import Button from '../Button';
import FuseButton from '../FuseControls/FuseButton';
import Dropdown from '../Dropdown';
import ScanStatuses from '../ScanStatuses/ScanStatuses';
import NotificationContent from './NotificationContent';
import './Notification.css';
import './../TopBarCaret.css';

const block = b.lock('Notification');

class LeftNotification extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        browser: BrowserStore.PropType.isRequired,
        env: EnvStore.PropType.isRequired,
    };

    popoverId = makeElementId('leftNotificationCornerPopover');
    cornerId = makeElementId('leftNotificationCornerCorner');
    cornerButtonId = makeElementId('leftNotificationCornerButton');

    [BrowserStore.NOTIFICATION.NEVER_ISOLATE] = () => <NotificationContent
        title={this.props.t('Notification.NEVER_ISOLATE.title')}
        style={{ paddingLeft: 4 }} // vertically align with icon
    >{this.props.t('Notification.NEVER_ISOLATE.text')}</NotificationContent>;

    getTranslationSection = () => {
        const { browser } = this.props;
        const pageStatus = browser.getPageStatus();

        if (pageStatus === BrowserStore.PAGE_STATUS.NONE) {
            return null;
        }

        let translationSection;

        if (pageStatus === BrowserStore.PAGE_STATUS.THREAT_SCAN) {
            translationSection = browser.notSecureWarning.isTrue
                ? BrowserStore.PAGE_STATUS.NOT_SECURE_SITE
                : BrowserStore.PAGE_STATUS.SECURE_SITE;
        } else {
            translationSection = pageStatus;
        }

        return translationSection;
    }

    [BrowserStore.NOTIFICATION.DEFAULT_LEFT] = () => {
        const { t, browser } = this.props;
        const pageStatus = browser.getPageStatus();
        let displayURL = browser.urlInputValue;
        if (pageStatus === BrowserStore.PAGE_STATUS.NONE) {
            return null;
        }

        if (pageStatus === BrowserStore.PAGE_STATUS.NOT_WEB_MAIL) {
            return <NotificationContent
                title={t('Notification.NOT_WEB_MAIL.title')}
            >{t('Notification.NOT_WEB_MAIL.text')}</NotificationContent>;
        }

        const isMalicious = pageStatus === BrowserStore.PAGE_STATUS.MALICIOUS_SITE;
        if (isMalicious && browser.subFrameMaliciousUrl !== null) {
            displayURL = browser.subFrameMaliciousUrl;
        }
        const currentUrl = browser.urlInputValue ? <div className={block('CurrentUrl')}>
            <div className={block('CurrentUrlTitle')}>
                {t(isMalicious ? 'UDTopBar.maliciousUrl' : 'UDTopBar.currentUrl')}
            </div>
            <div className={block('CurrentUrlValue', { dataUrl: (displayURL ?? '').startsWith('data:') })}>
                {displayURL}
            </div>
        </div> : null;

        if (pageStatus === BrowserStore.PAGE_STATUS.ERROR_PAGE) {
            return <NotificationContent>{currentUrl}</NotificationContent>;
        }

        const translationSection = this.getTranslationSection();

        return <Fragment>
            <NotificationContent
                title={t(`Notification.${translationSection}.title`)}
            >
                {t(`Notification.${translationSection}.text`)}
                {currentUrl}
                {pageStatus === BrowserStore.PAGE_STATUS.SCAN_ERROR ? <Button
                    raised
                    primary
                    style={{ minWidthL: 210, marginTop: 10 }}
                    onClick={() => browser.closeNotification()}
                >{this.props.t('Notification.SCAN_ERROR.close_button')}</Button> : null}
            </NotificationContent>
            {browser.areAllScansDisabled() ? null : <div className={block('InfoBlock', { borderTop: true })}>
                <div
                    className={block('InfoTitle')}
                    style={{ marginBottom: 15 }}
                >{this.props.t('ScanStatuses.title')}</div>
                <ScanStatuses
                    tiStatus={browser.tiScan}
                    tsaStatus={BrowserStore.SCAN_STATUS.NONE}
                    sandboxStatus={BrowserStore.SCAN_STATUS.NONE}
                    threatStatus={browser.getThreatScanStatus()}
                />
            </div>}
        </Fragment>;
    };

    [BrowserStore.NOTIFICATION.HUMAN_CHECK] = () => <NotificationContent
        title={this.props.t('Notification.HUMAN_CHECK.title')}
        style={{ paddingLeft: 4 }} // vertically align with icon
    >{this.props.t('Notification.HUMAN_CHECK.text')}</NotificationContent>;


    onCornerClick = () => {
        const { browser } = this.props;

        browser.topBarMenu.close();

        if (this.notification === BrowserStore.NOTIFICATION.NEVER_ISOLATE) {
            return;
        }

        const hasDefault = browser.getPageStatus() !== BrowserStore.PAGE_STATUS.NONE;
        if (hasDefault) {
            browser.toggleNotification(BrowserStore.NOTIFICATION.DEFAULT_LEFT);
        } else if (browser.notification) {
            browser.closeNotification();
        }
    };

    onClickOutside = () => {
        const { browser } = this.props;

        browser.closeNotification();
    };

    renderDropdown() {
        const notification = this.props.browser.notification;
        if (notification && this[notification]) {
            return <Dropdown
                className={block({ left: true })}
                onClose={this.onClickOutside}
                role="region"
                aria-labelledby={this.cornerId}
                id={this.popoverId}
            >{this[notification]()}</Dropdown>;
        }
        return null;
    }

    cornerByStatus = {
        [BrowserStore.PAGE_STATUS.SECURE_SITE]: {
            icon: 'lock',
            iconMods: { green: true },
        },
        [BrowserStore.PAGE_STATUS.NOT_SECURE_SITE]: {
            icon: 'info_outline',
            iconMods: { red: true },
            text: 'notSecure',
        },
        [BrowserStore.PAGE_STATUS.EXPIRED_CERTIFICATE]: {
            icon: 'warning',
            iconMods: { red: true },
            text: 'certificate',
        },
        [BrowserStore.PAGE_STATUS.NOT_WEB_MAIL]: {
            icon: 'info_outline',
            iconMods: { red: true },
        },
        [BrowserStore.PAGE_STATUS.SCAN_ERROR]: {
            icon: 'error',
            iconMods: { red: true },
            text: 'scanError',
        },
        [BrowserStore.PAGE_STATUS.THREAT_SCAN]: {
            icon: 'sandbox_loading',
        },
    };

    getCorner() {
        const { browser, env } = this.props;
        const { notification } = this.props.browser;
        const isLeftNotificationOpen = !!browser.notification && !!this[browser.notification];
        const isDefaultLeftNotificationOpen = notification === BrowserStore.NOTIFICATION.DEFAULT_LEFT;
        const isNonDefaultLeftNotificationOpen = isLeftNotificationOpen && !isDefaultLeftNotificationOpen;

        if (notification === BrowserStore.NOTIFICATION.NEVER_ISOLATE) {
            return { icon: 'info_outline', centered: true };
        }

        if (isNonDefaultLeftNotificationOpen) {
            return { icon: 'close', centered: true };
        }

        if (env.screen.isMobile && browser.urlInputFocus.isTrue) {
            return {};
        }

        if (browser.isDanger()) {
            return { icon: 'report', iconMods: { red: true, danger: true }, text: 'malware' };
        }

        if (browser.isPhishing()) {
            return { icon: 'warning', iconMods: { red: true, phishing: true }, text: 'phishing' };
        }

        const pageStatus = browser.getPageStatus();

        if (pageStatus === BrowserStore.PAGE_STATUS.ERROR_PAGE) {
            const errorView = browser.errorPageDetail ? browser.errorPageDetail.errorViewCode : ERROR_VIEW_CODE.GENERIC;
            if (errorView === ERROR_VIEW_CODE.GENERIC
                || errorView === ERROR_VIEW_CODE.UNREACHABLE_SITE
            ) {
                return { icon: 'block', iconMods: { red: true } };
            }
            if (errorView === ERROR_VIEW_CODE.SERVICE_FAILURE
                || errorView === ERROR_VIEW_CODE.DOCUMENT_RENDER_ERROR
                || errorView === ERROR_VIEW_CODE.EMPTY_PAGE_CONTENT
                || errorView === ERROR_VIEW_CODE.TIMED_OUT_SITE) {
                return { icon: 'error_outline', iconMods: { red: true } };
            }
            if (errorView === ERROR_VIEW_CODE.BLOCKED_SITE
                || errorView === ERROR_VIEW_CODE.BLOCKED_UNSAFE) {
                return { icon: 'report', iconMods: { red: true } };
            }
        }

        return this.cornerByStatus[pageStatus] || { icon: 'search', centered: true, tabIndex: -1 };
    }

    renderCorner() {
        const { t, browser } = this.props;
        const { icon, centered, iconMods, text, tabIndex } = this.getCorner();
        const open = !!browser.notification && !!this[browser.notification];
        const fade = !!browser.notification && !this[browser.notification];
        const minified = browser.isTopBarMinificationAllowed();

        let otherProps = { tabIndex };
        if (!centered) {
            otherProps = {
                ...otherProps,
                noCenter: true,
                iconRight: 'arrow_drop_down',
                iconRightClassName: cn(block('Caret'), b.lock('TopBarCaret')({
                    active: open,
                    minified,
                    iconOnly: !text,
                })),
            };
        }

        const translationSection = this.getTranslationSection();
        const titleText = translationSection
            ? t(`Notification.${translationSection}.title`)
            : t('Notification.cornerText.leftNotificationsTitle');

        return icon ? <FuseButton
            id={this.cornerButtonId}
            aria-expanded={open}
            aria-controls={this.popoverId}
            className={block('Corner', {
                left: true,
                open,
                centered,
                fade,
                minified,
            })}
            type={'quaternary'}
            icon={icon}
            iconClassName={block('CornerIcon', { ...iconMods, minified })}
            aria-label={titleText}
            title={titleText}
            onClick={this.onCornerClick}
            {...otherProps}
        >
            {text ? <div className={block('CornerText', { minified })}>
                {t(`Notification.cornerText.${text}`)}
            </div> : null}
        </FuseButton> : null;
    }

    render() {
        return <Fragment>
            {this.renderCorner()}
            {this.renderDropdown()}
        </Fragment>;
    }
}

export default translate()(inject('browser', 'env')(observer(LeftNotification)));
