import debug from '../debug';

export default class LockedQueue {
    queue = null;
    inProgress = false;
    isDestroyed = false;
    _counter = 0;

    add(callback, onDestroy) {
        if (this.isDestroyed) {
            return;
        }

        if (!this.queue) {
            this.queue = [];
        }

        this.queue.push([callback, onDestroy]);

        this.execute();
    }

    execute() {
        if (this.isDestroyed) {
            return;
        }

        if (this.inProgress) {
            return;
        }

        if (!this.queue) {
            return;
        }

        if (!this.queue.length) {
            this.queue = null;
            return;
        }

        if (!this.timer) {
            this.timer = setTimeout(() => {
                this._counter = 0;
                this.timer = 0;
            });
        }

        this._counter += 1;

        if (this._counter > 50) {
            debug.error('Dead lock');
        }

        if (this._counter > 10000) {
            debug.recordError('Dead lock');
        }

        this.inProgress = true;

        const [callback, onDestroy] = this.queue.shift();

        this._onDestroy = onDestroy;

        let used = false;
        callback(() => {
            if (used) {
                debug.error('Tried to call next item in queue twice');
                return;
            }

            this._onDestroy = null;

            used = true;

            this.inProgress = false;
            this.execute();
        });
    }

    destroy() {
        this.isDestroyed = true;
        if (this.queue) {
            this.queue.forEach(([, onDestroy]) => {
                if (!onDestroy) {
                    return;
                }
                try {
                    onDestroy();
                } catch (e) {
                    debug.recordError(e);
                }
            });

            this.queue = null;
        }
        if (this._onDestroy) {
            this._onDestroy();
        }
        this.inProgress = false;
    }
}
