import debounce from 'lodash/debounce';
import { messageToBrowser } from './browserMessageBus';
import Lock from '../Lock';

import {
    API_CLIENT_TO_BROWSER,
} from '../sharedConstants';

const NETWORK_TIMEOUT = 9000;

let isNetworkTimedOut = true;
const lock = new Lock('Lock that prevents Network Timeout message from happening');

const onNetworkTimeout = debounce(() => {
    isNetworkTimedOut = true;
    messageToBrowser(API_CLIENT_TO_BROWSER.socketStatus, { ok: false });
}, NETWORK_TIMEOUT);

lock.onUnlock(() => {
    onNetworkTimeout.cancel();
    onNetworkTimeout();
});

function onNetworkMessage() {
    if (lock.isLocked()) {
        return;
    }

    onNetworkTimeout.cancel();
    onNetworkTimeout();

    if (isNetworkTimedOut) {
        isNetworkTimedOut = false;
        messageToBrowser(API_CLIENT_TO_BROWSER.socketStatus, { ok: true });
    }
}

function onBeforeRendering() {
    lock.lock();
    onNetworkTimeout.cancel();
}

function onAfterRendering() {
    lock.unlock();
}

export default {
    onNetworkMessage,
    onBeforeRendering,
    onAfterRendering,
    init: onNetworkMessage,
};
