import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import b from 'b_';
import cn from 'classnames';
import autosize from 'autosize';
import InputStore from '../stores/InputStore';
import './Textfield.css';

const block = b.lock('Textfield');

export default class Textfield extends Component {
    static propTypes = {
        className: PropTypes.string,
        inputClassName: PropTypes.string,
        labelClassName: PropTypes.string,
        type: PropTypes.string,
        label: PropTypes.string,
        errorLabel: PropTypes.string,
        error: PropTypes.bool,
        isValid: PropTypes.bool,
        multiLine: PropTypes.bool,
        fullWidth: PropTypes.bool,
        placeholder: PropTypes.string,
        validationMessage: PropTypes.string,
        helpMessage: PropTypes.string,
        requiredText: PropTypes.string,
        onFocusChange: PropTypes.func,
    };

    state = {
        focus: false,
    };

    componentDidMount() {
        if (this.props.multiLine && this.inputEl) {
            autosize(this.inputEl);
        }
    }

    componentDidUpdate() {
        if (this.inputEl) {
            autosize.update(this.inputEl);
        }
    }

    onRef = (el) => {
        this.inputEl = el;
    };

    setFocus = (value) => {
        this.setState({ focus: value });
        if (this.props.onFocusChange) {
            setTimeout(() => this.props.onFocusChange(value));
        }
    };

    render() {
        const {
            className,
            inputClassName,
            labelClassName,
            type = 'text',
            label,
            errorLabel,
            isValid,
            error,
            multiLine,
            fullWidth,
            placeholder,
            validationMessage,
            helpMessage,
            requiredText,
            disabled,
            // eslint-disable-next-line no-unused-vars
            onFocusChange,
            ...otherProps
        } = this.props;
        const isError = !!error || !!errorLabel;
        const message = (isError && errorLabel) || (!isValid && validationMessage) || helpMessage;
        const focus = this.state.focus;

        return <label className={cn(block({ fullWidth }), className)}>
            {label ? <div className={cn(block('Label', { isError, isValid, focus, disabled }), labelClassName)}>
                {label}
                {otherProps.required ? <span className={block('RequiredText')}>{requiredText || '*'}</span> : null}
            </div> : null}

            {multiLine ? <textarea
                className={cn(block('Input', { isError, isValid, disabled }), inputClassName)}
                ref={this.onRef}
                rows="6"
                placeholder={placeholder}
                onFocus={() => this.setFocus(true)}
                onBlur={() => this.setFocus(false)}
                disabled={disabled}
                {...otherProps}
            /> : <input
                className={cn(block('Input', { isError, isValid, disabled }), inputClassName)}
                type={type}
                ref={this.onRef}
                placeholder={placeholder}
                onFocus={() => this.setFocus(true)}
                onBlur={() => this.setFocus(false)}
                disabled={disabled}
                {...otherProps}
            />}

            {message ? <div className={block('Message', { isError, isValid, disabled })}>
                {message}
            </div> : null}
        </label>;
    }

    getValue() {
        return (this.inputEl && this.inputEl.value) || '';
    }

    setValue(value) {
        if (this.inputEl) {
            this.inputEl.value = value;
        }
    }

    focus() {
        if (this.inputEl) {
            this.inputEl.focus();
        }
    }

    blur() {
        if (this.inputEl) {
            this.inputEl.blur();
        }
    }
}

export const ObservableTextfield = observer(({ store, untouchedWhenFocusChanged, ...props }) => {
    return <Textfield
        value={store.value}
        onChange={store.onChangeHandler}
        ref={store.setRef}
        isValid={!store.isEmpty && store.isValid}
        onFocusChange={() => untouchedWhenFocusChanged && store.setTouched(false)}
        {...props}
    />;
});

ObservableTextfield.propTypes = {
    store: InputStore.PropType.isRequired,
    ...Textfield.propTypes,
};
