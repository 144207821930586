export const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

// just copied second half of reEmail
export const reEmailDomain = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export const emailValidator = (value) => reEmail.test(value);

export const emailDomainValidator = (value) => reEmailDomain.test(value);

// https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
// Email len seems to be 254 due to an error in spec, logically it would have been domain max len + 64 = 320
export const EMAIL_SECTION_MAX_LEN = 64;
export const EMAIL_MAX_LEN = 254;
// https://en.wikipedia.org/wiki/Domain_name
export const DOMAIN_MAX_LEN = 254;

const lengthValidation = (len) => (v) => v.length <= len;

export const lenAwareDomainValidator = (v) => emailDomainValidator(v) &&
    lengthValidation(DOMAIN_MAX_LEN)(v) &&
    v.split('.').every(lengthValidation(EMAIL_SECTION_MAX_LEN));

export const lenAwareEmailValidator = (v) => {
    if (!emailValidator(v) || !lengthValidation(EMAIL_MAX_LEN)(v)) {
        return false;
    }
    const [name = '', domain = ''] = v.split('@');

    return lenAwareDomainValidator(domain) && lengthValidation(EMAIL_SECTION_MAX_LEN)(name);
};
