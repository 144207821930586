import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import b from 'b_';
import Icon from '../../Icon/Icon';
import './TroubleshootingInstructions.css';

const block = b.lock('TroubleshootingInstructions');

class TroubleshootingInstructions extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;

        return <div className={block()}>
            <div className={block('Content')}>
                <h3 className={block('InstructionHeader')}>
                    <Icon type={'check'} className={block('CheckIcon')} />
                    {t('troubleshootingMode.instructions.header1')}
                </h3>
                <p className={block('InstructionText')}>
                    {t('troubleshootingMode.instructions.text1')}
                </p>

                <h3 className={block('InstructionHeader')}>
                    {t('troubleshootingMode.instructions.header2')}
                </h3>
                <p className={block('InstructionText')}>
                    {t('troubleshootingMode.instructions.text2')}
                </p>

                <h3 className={block('InstructionHeader')}>
                    {t('troubleshootingMode.instructions.header3')}
                </h3>
                <p className={block('InstructionText')}>
                    {t('troubleshootingMode.instructions.text3')}
                </p>
            </div>
        </div>;
    }
}

export default translate()(TroubleshootingInstructions);
