import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import b from 'b_';
import Dropdown from '../Dropdown';
import './TopBarDropdown.css';

const block = b.lock('TopBarDropdown');

export default class TopBarDropdown extends Component {
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.string,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        type: 'default',
        onClose: () => {},
    };

    render() {
        const { children, className, type, onClose, ...otherProps } = this.props;
        return <Dropdown
            className={cn(block({ type }), className)}
            onClose={onClose}
            {...otherProps}
        >
            {children}
        </Dropdown>;
    }
}
