import LS, { LS_KEY } from '../../LocalStorage';
import api from './ClientAPI';

class CanvasDebugUI {
    static _instance = null;
    _snapshots = {};
    _debugBoxTimeout = {};
    _canvasColors = {};

    static getInstance() {
        CanvasDebugUI._instance ??= new CanvasDebugUI();
        return this._instance;
    }

    static isCanvasDebuggingEnabled = () => !!LS.get(LS_KEY.debugCanvas);

    // Be fancy - choose a random pseudo-unique color based on the node id
    getCanvasDebugColor(nodeID) {
        const genColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        this._canvasColors[nodeID] = this._canvasColors[nodeID] || genColor();
        return this._canvasColors[nodeID];
    }

    // Keeping systems time in sync is hard - we've observed wildly inaccurate responses if we just use timestamps.
    // To work around this a single machine must do the counting. Before the content page sends the image,
    // it started a counter on the extension. We now tell the extension to stop counting and tell us the duration.
    //  Since our "stop counting" message needs some time to reach the destination, we offset by half the
    // time it took for our message to come back with a response
    getLatencyApproximation = (frameId, nodeId, actionId) => {
        const now = Date.now();

        return api.getCanvasFrameLoadingMs(frameId, nodeId, actionId)
            // The original duration on the server was actually slightly longer because of the time
            // it takes this message to reach the server. We do measure the whole round trip, but we divide it by 2
            // assuming that's how long one way would take
            .then(({ response }) => {
                const roundTrip = Date.now() - now;
                const oneWayTrip = Math.round(roundTrip / 2);
                const approximation = response.loadMs - oneWayTrip;

                return {
                    // In case we just started debugging there might not have been a started frame timer on the server
                    loadMs: approximation > 0 ? approximation : '?',
                    msToAgent: oneWayTrip,
                };
            });
    }

    // This function would either show the debugging UI if the user has enabled it, or cleanup any existing counters
    // if the user has it turned off. Triggers:
    // _.startDebuggingCanvas({ x: ..., y: ... }) and _.stopDebuggingCanvas()
    handleDebugUI = ({ frameId, context, update, nodeId, node, executionId }) => {
        const debugData = LS.get(LS_KEY.debugCanvas);
        const { actionId, quality, res } = update.data;

        if (debugData) {
            node.style.outline = `dotted 3px ${this.getCanvasDebugColor(nodeId)}`;
            this.drawCanvasDebugBox({
                ctx: context,
                loadMs: '_',
                size: Math.round(update.data.size / 1000),
                actionId,
                boxX: debugData.x,
                boxY: debugData.y,
                nodeId,
                executionId,
                res,
                quality
            });

            // This executes is in parallel with the UI draw. The loadMs is actually updated on a subsequent render
            this.getLatencyApproximation(frameId, nodeId, actionId).then((approximation) => {
                const curShot = this._snapshots[nodeId].find((shot) => shot.actionId === actionId);

                // An example case might be if we have already gotten rid of this frame
                if (curShot) {
                    curShot.loadMs = approximation.loadMs;
                    api.recordImportantEvent('Canvas frame load speed finished approximating', {
                        ...curShot,
                        ...approximation
                    });
                }
            });
        } else {
            node.style.outline = '';
            Object.keys(this._debugBoxTimeout).forEach((key) => {
                clearTimeout(this._debugBoxTimeout[key]);
            });
        }
    }


    drawCanvasDebugBox(data) {
        const { ctx, loadMs, size, actionId, boxX, boxY, nodeId, res, executionId, quality } = data;

        this._snapshots[nodeId] = this._snapshots[nodeId] || [];

        const shots = this._snapshots[nodeId];
        const current = shots[0];

        // Are we about to render a brand-new frame?
        if (current?.actionId !== actionId) {
            // Update the screen time the previous current frame stayed on the screen. Do nothing for frame 0.
            if (current) {
                current.totalScreenTime = Date.now() - current.drawnAt;
                clearTimeout(this._debugBoxTimeout[nodeId]);

                if (shots.length > 21) {
                    // Only keep last 20 [not counting the current one]
                    shots.pop();
                }
            }
            shots.unshift({ loadMs, actionId, size, drawnAt: Date.now(), res, executionId, quality });
        }
        const fps = shots.filter(({ drawnAt }) => drawnAt > Date.now() - 1000).length;

        // Define box properties
        const boxWidth = 400;
        const LINE_HEIGHT = 20;
        const PADDING = 8;
        const boxHeight = PADDING * 4 + LINE_HEIGHT * (shots.length + 2);
        ctx.clearRect(boxX, boxY, boxWidth, boxHeight);

        // Accept a number or a word and make sure it's justified
        const pad = (target, count = 3) => (target + '').padStart(count, ' ');

        // tpl gets a frame [aka shot] as an argument and returns a string like this:
        //      "   9 [ 890ms, 110kb, high ]  413ms"
        const tpl =
            ({
                actionId: actId,
                loadMs: ms,
                size: s,
                drawnAt: drawnDate,
                totalScreenTime,
                res: resolution,
                quality: qual
            }) =>
                pad(actId) + ` [ ${pad(ms)}ms, ${pad(s)}kb, ${pad(resolution, 4)}, ${pad(qual, 4)} ] ` +
                pad(totalScreenTime || Date.now() - drawnDate, 4) + 'ms';

        // Draw the box
        ctx.fillStyle = this.getCanvasDebugColor(nodeId);
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2;
        ctx.strokeRect(boxX, boxY, boxWidth, boxHeight);
        ctx.fillRect(boxX, boxY, boxWidth, boxHeight);

        // Draw text inside the box
        const textX = boxX + PADDING; // X-coordinate for text
        let textY = boxY + PADDING + LINE_HEIGHT; // Y-coordinate for text


        ctx.fillStyle = 'black';
        ctx.font = 'bold 18px Arial';
        ctx.fillText(`FPS: ${fps}`, textX, textY);
        ctx.textAlign = 'right';
        ctx.fillText(`Node: ${nodeId}`, boxX + boxWidth - PADDING, textY);
        ctx.textAlign = 'left';
        ctx.fillText('-- Frames --', textX + 100, textY);
        textY += LINE_HEIGHT;
        ctx.font = '16px monospace';
        ctx.fillText(`${pad('#')} [ loadT,  size,  res, qlty ] screenT`, textX, textY);
        textY += PADDING;
        shots.forEach((oldFrame) => {
            textY += LINE_HEIGHT;
            ctx.fillText(tpl(oldFrame), textX, textY);
        });

        this._debugBoxTimeout[nodeId] = setTimeout(() => this.drawCanvasDebugBox(data), 10);
    }
}

export default CanvasDebugUI;
